import React from "react";
import styled from "styled-components";
import { Section } from "../Page/Section";
import { Card } from "../shared/Card";

const Partner = styled(Card)`
  height: 128px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  :hover {
    box-shadow: ${props => props.theme.shadows.cardShadowHover};
  }

  img {
    height: 50%;
    width: 50%;
    object-fit: contain;
  }
`;

interface PartnersProps {
  shouldOpenInNewTab: boolean;
}

// Partners section
export const Partners: React.FC<PartnersProps> = ({ shouldOpenInNewTab }) => {
  const partners = [
    {
      src: "/static/assets/partners/rigshospitalet.png",
      name: "Rigshospitalet",
      url: "https://www.rigshospitalet.dk"
    },
    {
      src: "/static/assets/partners/upenn.png",
      name: "University of Pennsylvania",
      url: "https://www.upenn.edu"
    },
    {
      src: "/static/assets/partners/ucc.png",
      name: "University College Cork",
      url: "https://www.ucc.ie/en/"
    },
    {
      src: "/static/assets/partners/jacobs.png",
      name: "Jacobs Institute",
      url: "https://jacobsinstitute.org"
    },
    {
      src: "/static/assets/partners/endologix.png",
      name: "Endologix",
      url: "https://endologix.com"
    },
    {
      src: "/static/assets/partners/toray.png",
      name: "Toray",
      url: "https://www.toray.com"
    },
    {
      src: "/static/assets/partners/medtronic.png",
      name: "Medtronic",
      url: "https://www.medtronic.com/"
    },
    {
      src: "/static/assets/partners/terumo.png",
      name: "Terumo",
      url: "https://www.terumo.com"
    }
  ];

  // Define props to spread in link, _if_ we want to open in a new tab
  const linkProps = shouldOpenInNewTab && {
    target: "_blank",
    rel: "noopener"
  };

  return (
    <Section title="Partners" maxColumns={4}>
      {partners.map(partner => (
        <a key={partner.name} href={partner.url} {...linkProps}>
          <Partner>
            <img src={partner.src} alt={partner.name} title={partner.name} />
          </Partner>
        </a>
      ))}
    </Section>
  );
};
