import React from "react";
import { gql, useMutation } from "@apollo/client";
import Button from "../../components/shared/Button";

const SyncTalent = () => {
  const [
    syncWithTalent,
    { loading: syncLoading, data: syncData },
  ] = useMutation(
    gql`
      mutation SyncWithTalent {
        syncWithTalent
      }
    `
  );

  return (
    <>
      <Button onClick={() => syncWithTalent()}>Sync with talent</Button>
      <p>{syncLoading && "Sync in progress"}</p>
      <p>{syncData && !syncLoading && "Sync completed"}</p>
    </>
  );
};

export default SyncTalent;
