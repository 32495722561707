import { gql, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import Add from "../../components/icons/Add";
import { PageLayout } from "../../components/Page/PageLayout";
import { Section } from "../../components/Page/Section";
import { SectionHeader } from "../../components/Page/SectionHeader";
import Button from "../../components/shared/Button";
import { Card } from "../../components/shared/Card";
import { H3, StyledLink } from "../../components/shared/typography";
import { SortOrder } from "../../generated/graphql";
import { ButtonSizes } from "../../shared/enums";
import RecordTable from "./RecordTable";

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 40px 40px;
  flex-wrap: wrap;
`;

const RecordsCard = styled(Card)`
  overflow-x: auto;
  padding: 24px 0;
`;

const LogbookPage = () => {
  const match = useRouteMatch();
  const theme = useContext(ThemeContext);
  const { data: activitiesData, error: activitiesError } = useQuery(
    gql`
      query FetchActivities($searchParams: ProcedureActivitySearchParams!) {
        activities(searchParams: $searchParams) {
          totalCount
          activities {
            id
            dateOfProcedure
            optionalIdentifier
            hospital
            attending {
              firstName
              lastName
            }
            participants {
              user {
                first_name
                last_name
              }
            }
            procedure {
              name
            }
          }
        }
      }
    `,
    {
      variables: {
        searchParams: {
          limit: 3,
          sortOrder: SortOrder.Desc,
        },
      },
    }
  );
  return (
    <PageLayout title="Mentice Live | Logbook">
      <SectionHeader title="Logbook" />
      <Section
        title="IR Log"
        maxColumns={1}
        primaryActionText="View All"
        primaryActionLink={`${match.path}/records`}
      >
        <RecordsCard>
          <CardHeader>
            <H3>Recent records</H3>
            <StyledLink
              to={`${match.path}/records/create`}
              id="cy-create-logbook-link"
            >
              <Button
                icon={<Add color={theme.colors.darkGrey} />}
                size={ButtonSizes.Medium}
              >
                Create record
              </Button>
            </StyledLink>
          </CardHeader>
          <RecordTable
            tableData={
              activitiesData &&
              !activitiesError &&
              activitiesData.activities.activities
            }
            condensed={true}
          ></RecordTable>
        </RecordsCard>
      </Section>
    </PageLayout>
  );
};

export default LogbookPage;
