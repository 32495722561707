import React from "react";
import styled from "styled-components";
import { activeLinkStyle, h6Style } from "../typography";

const SidebarListItemContainer = styled.li<{ active: boolean }>`
  margin-bottom: 24px;
  cursor: pointer;
  width: fit-content;

  a {
    ${h6Style}
    ${activeLinkStyle}
  }
`;

interface SidebarListItemProps {
  active: boolean;
}

const SidebarListItem: React.FC<SidebarListItemProps> = ({
  children,
  active,
}) => {
  return (
    <SidebarListItemContainer active={active}>
      {children}
    </SidebarListItemContainer>
  );
};

export default SidebarListItem;
