import React, { useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { PageLayout } from "../../components/Page/PageLayout";
import styled from "styled-components";
import SidebarCard from "../../components/shared/sidebar-card/SidebarCard";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import { SectionHeader } from "../../components/Page/SectionHeader";
import { StyledLink } from "../../components/shared/typography";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router";
import Account from "./Account";
import Groups from "./Groups";
import SidebarListItem from "../../components/shared/sidebar-card/SidebarListItem";
import { RouteObj } from "../administration";

const SidebarListA = styled.a`
  text-decoration: none;
  font-family: ${(props) => props.theme.fonts.heading};
  cursor: pointer;
  background: unset;
  border: none;
  font-size: 16px;
  line-height: 34px;
  padding: 0;
  color: #3a3e42;
`;

interface ProfileRoute extends RouteObj {
  onClick: () => void;
}

const ProfilePage: React.FC = () => {
  const { keycloak } = useKeycloak();
  const keycloakUrl: string =
    keycloak.authServerUrl + "realms/mentice/account/password";
  const match = useRouteMatch();

  const routes: ProfileRoute[] = [
    {
      path: `${match.path}/account`,
      exact: true,
      name: "Account",
      main: () => <Account />,
      onClick: () => {
        setBreadcrumb({ path: `${match.path}/account`, name: "Account" });
      },
    },
    {
      path: `${match.path}/groups`,
      exact: true,
      name: "Groups",
      main: () => <Groups />,
      onClick: () =>
        setBreadcrumb({ path: `${match.path}/groups`, name: "Groups" }),
    },
  ];

  const location = useLocation();
  var sidebarList = routes.map((route, index) => (
    <SidebarListItem
      active={location.pathname === route.path}
      key={`${route.path} + ${index}`}
    >
      <StyledLink to={route.path} onClick={route.onClick}>
        {route.name}
      </StyledLink>
    </SidebarListItem>
  ));

  // Insert link to password in sidebar explicitly — this is hosted on Keycloak instead of Live
  sidebarList.splice(
    1,
    0,
    <SidebarListItem active={false} key={keycloakUrl}>
      <SidebarListA href={keycloakUrl}>Password</SidebarListA>
    </SidebarListItem>
  );

  const [breadcrumb, setBreadcrumb] = useState<{ path: string; name: string }>({
    path: routes[0].path,
    name: routes[0].name,
  });

  return (
    <PageLayout title="Profile">
      <Breadcrumbs
        crumbs={[
          {
            path: `${match.path}`,
            name: "Your Profile",
          },
          {
            path: `${breadcrumb.path}`,
            name: breadcrumb.name,
          },
        ]}
      />
      <SectionHeader title="Your Profile">
        <SidebarCard sidebarList={sidebarList}>
          <Switch>
            <Redirect exact from={match.path} to={`${match.path}/account`} />
            {routes.map((route, index) => (
              <Route
                key={`${route.path} + ${index}`}
                path={route.path}
                exact={route.exact}
                children={<route.main />}
              />
            ))}
          </Switch>
        </SidebarCard>
      </SectionHeader>
    </PageLayout>
  );
};

export default ProfilePage;
