import React from "react";
import UserAvatar from "../../components/icons/UserAvatar";
import {
  ActionButton,
  ItemGroup,
  ListItem,
} from "../../components/shared/list/List";
import { Text } from "../../components/shared/typography";
import { Attending } from "../../generated/graphql";
import { getFullName } from "../logbook/helpers";

interface AttendingListItemProps {
  attending: Attending;
  onEdit: (id: number, name: string, email?: string | null) => void;
  onRemove: (id: number, name: string) => void;
}

const AttendingListItem: React.FC<AttendingListItemProps> = ({
  attending,
  onEdit,
  onRemove,
}) => {
  return (
    <ListItem key={attending.id}>
      <ItemGroup>
        <UserAvatar
          isAdmin={false}
          firstName={attending.firstName}
          lastName={attending.firstName.includes(",") ? "" : attending.lastName}
        />
        <Text>{getFullName(attending.lastName, attending.firstName)}</Text>
      </ItemGroup>
      <ItemGroup>
        <ActionButton
          onClick={() =>
            onEdit(
              attending.id,
              getFullName(attending.lastName, attending.firstName),
              attending?.email
            )
          }
        >
          Edit
        </ActionButton>
        <ActionButton
          onClick={() =>
            onRemove(
              attending.id,
              getFullName(attending.lastName, attending.firstName)
            )
          }
        >
          Remove
        </ActionButton>
      </ItemGroup>
    </ListItem>
  );
};

export default AttendingListItem;
