import React from "react";

const Close: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g fill="none">
        <path
          fill="#575C61"
          d="M19.31 17.28l6.192-6.178c.565-.564.565-1.48 0-2.044-.564-.565-1.48-.565-2.044 0L17.28 15.25l-6.178-6.192c-.564-.565-1.48-.565-2.044 0-.565.564-.565 1.48 0 2.044l6.192 6.178-6.192 6.178c-.273.27-.426.638-.426 1.022s.153.752.426 1.022c.27.273.638.426 1.022.426s.752-.153 1.022-.426l6.178-6.192 6.178 6.192c.27.273.638.426 1.022.426s.752-.153 1.022-.426c.273-.27.426-.638.426-1.022s-.153-.752-.426-1.022L19.31 17.28z"
          transform="translate(-8 -8)"
        />
      </g>
    </svg>
  );
};

export default Close;
