import React from "react";
import { TalentCourseCategory } from "../../shared/enums";
import CourseCard from "./CourseCard";
import { StyledLink } from "../shared/typography";
import { ProgressBar } from "../shared/ProgressBar";

interface TalentCourseProps {
  talentCourseId: number;
  category: TalentCourseCategory;
  title: string;
  subtitle: string;
  progress: number;
  code: string;
  talentBranchName: string;
  talentBranchId: string;
}

// Course "card" for TalentLMS courses
export const TalentCourse: React.FC<TalentCourseProps> = ({
  talentCourseId,
  category,
  title,
  subtitle,
  progress,
  code,
  talentBranchName,
  talentBranchId,
}) => {
  return (
    <StyledLink
      to={`/learning-spaces/${talentBranchId}/${talentBranchName}/${talentCourseId}`}
    >
      <CourseCard
        category={category}
        subtitle={subtitle}
        title={title}
        code={code}
        learningSpaceName={talentBranchName}
        progressBar={<ProgressBar progress={progress} />}
      />
    </StyledLink>
  );
};
