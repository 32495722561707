import React from "react";
import styled, { css } from "styled-components";

export const GridRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    flex-wrap: wrap;
  }
`;

const sidebarCardCol = css`
  &:first-child {
    padding: 12px 20px 0 0;
  }
  &:last-child {
    padding: 12px 0 0 20px;
  }
  &:only-child {
    padding: 12px 50% 12px 0;
  }
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    flex: unset;
    width: 100%;
    &:first-child,
    &:last-child,
    &:only-child {
      padding: 12px 0;
    }
  }
`;

const col = css`
  > div {
    padding: 14px 34px;
  }
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    flex: unset;
    width: 100%;
  }
`;

const ColWrapper = styled.div<{ sidebarCard: boolean; size?: number }>`
  ${(props) => (props.sidebarCard ? sidebarCardCol : col)}
  flex: ${(props: { size?: number }) => (props.size ? props.size : 3)};
`;

export const GridCol = ({
  children,
  size,
  sidebarCard = false,
}: {
  children?: React.ReactNode;
  size?: number;
  sidebarCard?: boolean;
}) => (
  <ColWrapper size={size} sidebarCard={sidebarCard}>
    <div>{children}</div>
  </ColWrapper>
);
