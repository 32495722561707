import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import MoreIcon from "../../icons/MoreIcon";

const MenuWrapper = styled.div`
  img {
    cursor: pointer;
  }
  // if context menu cannot be positioned relative to parent (due to overflow visibility),
  // the position of the menu needs to be calculated from parent position
  ${(prop: MenuProps) =>
    !(
      prop.groupRelativePosition ||
      prop.recordRelativePosition ||
      prop.isOnCloudSim
    ) && "position : relative;"}
`;

interface MenuProps {
  top?: number;
  left?: number;
  groupRelativePosition?: boolean;
  recordRelativePosition?: boolean;
  isOnCloudSim?: boolean;
}

const Menu = styled.div<MenuProps>`
  position: absolute;
  left: ${(props) =>
    `calc(${
      props.groupRelativePosition
        ? props.left + `px + ${props.theme.sizes.spacing4}`
        : props.isOnCloudSim
        ? props.theme.sizes.spacing20
        : "100%"
    } - ${props.theme.sizes.spacing24} + ${props.theme.sizes.spacing4});`};
  ${(props) =>
    (props.groupRelativePosition || props.isOnCloudSim) &&
    `top: ${props.top}px;`};
  width: ${(props) => props.theme.sizes.spacing24};
  margin-top: ${(props) => props.theme.sizes.spacing5};
  z-index: 100;
  border-radius: 4px;
  cursor: pointer;
  border: solid 1px ${(props) => props.theme.colors.lightGrey};
  background-color: ${(props) => props.theme.colors.white};
  display: inline-block;
  white-space: nowrap;
  box-shadow: ${(props) => props.theme.shadows.cardShadow};
`;

const ImageContainer = styled.div<{ active: boolean; isOnCloudSim: boolean }>`
  width: ${(props) => (props.isOnCloudSim ? "48px" : "32px")};
  height: ${(props) => (props.isOnCloudSim ? "48px" : "32px")};
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.active && props.theme.colors.paleGrey};
  :hover {
    background-color: ${(props) => props.theme.colors.paleGrey};
  }
`;

interface ContextMenuProps {
  children: React.ReactNode;
  groupRelativePosition?: boolean;
  recordRelativePosition?: boolean;
  isOnCloudSim?: boolean;
}

export const ContextMenu: React.FC<ContextMenuProps> = ({
  children,
  groupRelativePosition = false,
  recordRelativePosition = false,
  isOnCloudSim = false,
}) => {
  const [contextMenu, setContextMenu] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", (e) => handleClick(e));
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e: MouseEvent) => {
    if (menuRef.current?.contains(e.target as Node)) {
      return;
    } else {
      setContextMenu(false);
    }
  };

  const handleMoreMenuClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setContextMenu(!contextMenu);
  };

  const theme = useContext(ThemeContext);

  return (
    <MenuWrapper
      id="cy-context-menu"
      onClick={(e) => handleMoreMenuClick(e)}
      ref={menuRef}
      groupRelativePosition={groupRelativePosition}
      recordRelativePosition={recordRelativePosition}
      isOnCloudSim={isOnCloudSim}
    >
      {contextMenu && (
        <Menu
          top={
            isOnCloudSim
              ? 80
              : menuRef.current?.getBoundingClientRect().top &&
                menuRef.current?.getBoundingClientRect().top +
                  window.pageYOffset
          }
          left={menuRef.current?.getBoundingClientRect().left}
          groupRelativePosition={groupRelativePosition}
          recordRelativePosition={recordRelativePosition}
          isOnCloudSim={isOnCloudSim}
        >
          {children}
        </Menu>
      )}
      <ImageContainer active={contextMenu} isOnCloudSim={isOnCloudSim}>
        <MoreIcon
          color={isOnCloudSim ? theme.colors.grey : "#ADB3B8"}
          scale={isOnCloudSim ? 2 : undefined}
        />
      </ImageContainer>
    </MenuWrapper>
  );
};
