/**
 * Deals with auth and making calls to our API so you simply need to pass it a query
 */

import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { print, DocumentNode } from "graphql";

interface GraphQLResponse {
  data: any;
  errors: any;
}

const endpoint =
  process.env.NODE_ENV === "development"
    ? `http://localhost:3001/api/graphql`
    : "/api/graphql";

export const useGraphQL = (
  query: DocumentNode,
  variables?: any
): [GraphQLResponse] => {
  const { keycloak } = useKeycloak();
  const [response, setResponse] = useState<GraphQLResponse>({
    data: null,
    errors: null
  });

  const jsonQuery = JSON.stringify({ query: print(query), variables });

  useEffect(() => {
    // Don't try before login
    if (!keycloak.token) return;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + keycloak.token
      },
      body: jsonQuery
    };

    const callApi = async () => {
      try {
        const response = await fetch(endpoint, options);
        if (response.ok) {
          const json = await response.json();
          setResponse(json);
        } else {
          const body = await response.text();
          setResponse({
            data: null,
            errors: [
              new Error(
                `HTTP ${response.status}: ${response.statusText}: ${body}`
              )
            ]
          });
        }
      } catch (e) {
        setResponse({ data: null, errors: [e] });
      }
    };
    callApi();
  }, [keycloak.token]); // eslint-disable-line react-hooks/exhaustive-deps

  return [response];
};

export const useMutation = (
  mutation: DocumentNode,
  variables?: any
): [() => void, GraphQLResponse] => {
  const { keycloak } = useKeycloak();
  const [response, setResponse] = useState<GraphQLResponse>({
    data: null,
    errors: null
  });

  const jsonQuery = JSON.stringify({ query: print(mutation), variables });

  const runMutation = () => {
    // Don't try before login
    if (!keycloak.token) return;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + keycloak.token
      },
      body: jsonQuery
    };

    const callApi = async () => {
      try {
        const response = await fetch(endpoint, options);
        if (response.ok) {
          const json = await response.json();
          setResponse(json);
        } else {
          const body = await response.text();
          setResponse({
            data: null,
            errors: [
              new Error(
                `HTTP ${response.status}: ${response.statusText}: ${body}`
              )
            ]
          });
        }
      } catch (e) {
        setResponse({ data: null, errors: [e] });
      }
    };
    callApi();
  };

  return [runMutation, response];
};

export const useLazyQuery = useMutation;
