import React, { useState } from "react";
import styled from "styled-components";

const BurgerContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  position: relative;
`;

interface BurgerButtonProps {
  open: boolean;
}

const BurgerButton = styled.button<BurgerButtonProps>`
  z-index: 999;
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: ${({ open }) =>
    !open
      ? 'url("../../../static/assets/hamburger.svg")'
      : 'url("../../../static/assets/close-large.svg")'};
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  border: none;
`;

interface SideMenuProps {
  open: boolean;
}

const SideMenu = styled.ul<SideMenuProps>`
  width: 70%;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.barShadow};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  flex-flow: row nowrap;
  transition: transform 0.3s ease-in-out;
  list-style: none;
  padding: 100px 0px 32px 48px;
  margin-top: 0;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  li {
    padding-bottom: 32px;
  }
`;

interface HamburgerProps {
  children: React.ReactNode;
}

const Hamburger: React.FC<HamburgerProps> = ({ children }) => {
  const [open, setOpen] = useState(false);

  return (
    <BurgerContainer>
      <BurgerButton open={open} onClick={() => setOpen(!open)} />
      <SideMenu open={open}>{children}</SideMenu>
    </BurgerContainer>
  );
};

export default Hamburger;
