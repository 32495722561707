import React from "react";
import styled from "styled-components";
import { semiBoldTextStyle, textStyle } from "../typography";

const TableLikeList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 8px 0;
  width: 100%;
  li:nth-child(odd) {
    background-color: ${(props) => props.theme.colors.superLightGrey};
  }
  li:not(:last-of-type) {
    border-bottom: none;
  }
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: ${(props) => props.theme.sizes.spacing2};
  margin: 0;
  border: solid 1px ${(props) => props.theme.colors.paleGrey};
`;

export const ItemGroup = styled.div`
  ${textStyle}
  display: flex;
  align-items: center;
  margin: 0;
  height: 32px;
  > :first-child {
    margin-right: ${(props) => props.theme.sizes.spacing2};
  }
`;

export const ActionButton = styled.button`
  ${semiBoldTextStyle}
  cursor: pointer;
  background: none;
  border: none;
`;

interface ListProps {
  id?: string;
}

export const List: React.FC<ListProps> = ({ children, id }) => {
  return <TableLikeList id={id}>{children}</TableLikeList>;
};
