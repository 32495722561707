import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Donut = styled.div<LoadingProps>`
  width: ${(props) => (props.small ? "20px" : props.theme.sizes.spacing5)};
  height: ${(props) => (props.small ? "20px" : props.theme.sizes.spacing5)};
  margin: ${(props) =>
    props.noMargin
      ? "0px 2px" // Make it "square"
      : props.small
      ? "0px 10px"
      : props.theme.sizes.spacing5};
  border-radius: 50%;
  border: ${(props) => (props.small ? "2px" : props.theme.sizes.spacing0_5)}
    solid
    rgba(
      ${(props) =>
        props.white
          ? props.theme.colors.whiteRGB
          : props.theme.colors.darkGreyRGB},
      0.3
    );
  border-top-color: ${(props) =>
    props.white ? props.theme.colors.white : props.theme.colors.darkGrey};
  animation: 1.5s ${spin} infinite linear;
`;
interface LoadingProps {
  white?: boolean;
  small?: boolean;
  noMargin?: boolean;
}

const Loading: React.FC<LoadingProps> = ({
  white = false,
  small = false,
  noMargin = false,
}) => {
  return <Donut white={white} small={small} noMargin={noMargin} />;
};

export default Loading;
