import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { debounce } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { PageLayout } from "../../components/Page/PageLayout";
import { Card } from "../../components/shared/Card";
import TextField from "../../components/shared/TextField";
import { spanStyle, StyledLink } from "../../components/shared/typography";
import { ButtonSizes, ToastFormat, ToastType } from "../../shared/enums";
import RecordFilter from "./RecordFilter";
import RecordTable from "./RecordTable";
import {
  ProcedureActivity,
  ProcedureActivityMeta,
  ProcedureActivitySortField,
  Sedation,
  SortOrder,
} from "../../generated/graphql";
import { exportToExcel } from "../../lib/exportToExcel";
import { formatDate, getTodaysDateTime } from "../../lib/formatDateAndTime";
import { EblMap, PlateletMap, INRMap } from "./ClinicalDetailSection";
import Download from "../../components/icons/Download";
import { useToast } from "../../lib/useToast";
import Add from "../../components/icons/Add";
import { FilterValues } from "./types";
import { getFullName } from "./helpers";
import Dialog from "../../components/shared/Dialog";
import Button from "../../components/shared/Button";
import { renderLoadingText } from "../../components/shared/LoadingIndicator";
import ActionBar from "../../components/shared/ActionBar";

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 48px 0 24px;
  height: 32px;
  position: relative;
  ${(props: { activitiesLoading: boolean }) =>
    props.activitiesLoading && "pointer-events: none; opacity: 0.5;"}
`;

const Page = styled.div`
  ${spanStyle}
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  border: ${(props: { activePage: boolean; theme: any }) =>
    props.activePage ? "none" : "1px solid " + props.theme.colors.lightGrey};
  margin: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props: { activePage: boolean; theme: any }) =>
    props.activePage && props.theme.colors.white};
  background-color: ${(props: { activePage: boolean; theme: any }) =>
    props.activePage
      ? props.theme.colors.primary300
      : props.theme.colors.white};
`;

const PageArrow = styled.img`
  cursor: pointer;
`;

const ScrollableTableContainer = styled.div`
  overflow-x: visible;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    overflow-x: auto;
  }
`;

const TableCard = styled(Card)`
  padding-left: 0;
  padding-right: 0;
  margin-top: ${(props) => props.theme.sizes.spacing3};
  min-height: ${(props) => props.theme.sizes.spacing90};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    margin-top: ${(props) => props.theme.sizes.spacing3};
  }
`;

const RecordsPage: React.FC = ({ children }) => {
  const match = useRouteMatch();
  const theme = useContext(ThemeContext);
  const [offset, setOffset] = useState(0);
  const limit = 25;
  const maxExportLimit = 1000;
  const paginationLimit = 5;
  const [activePage, setActivePage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [activities, setActivities] = useState<
    undefined | ProcedureActivityMeta
  >(undefined);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [filterStartDate, setFilterStartDate] = useState<string | undefined>(
    undefined
  );
  const [filterEndDate, setFilterEndDate] = useState<string | undefined>(
    undefined
  );
  const [sortColumn, setSortColumn] = useState(
    ProcedureActivitySortField.DateOfProcedure
  );
  const fileName = "logbook-" + getTodaysDateTime();
  const [sortOrder, setSortOrder] = useState(SortOrder.Desc);
  const [deleteRecordDialog, setDeleteRecordDialog] = useState<string>("");
  const [filterValues, setFilterValues] = useState<FilterValues>({
    hospital: "",
    attending: "",
    participantName: "",
    interventionType: "",
    organSystem: "",
    category: "",
    subCategory: "",
    modalityPrimary: "",
    complications: undefined,
  });
  const [, createToast] = useToast();

  const [
    fetchActivities,
    { loading: activitiesLoading, data: activitiesData },
  ] = useLazyQuery(
    gql`
      query FetchActivities($searchParams: ProcedureActivitySearchParams!) {
        activities(searchParams: $searchParams) {
          totalCount
          activities {
            id
            groupId
            dateOfProcedure
            optionalIdentifier
            hospital
            attending {
              firstName
              lastName
            }
            participants {
              user {
                first_name
                last_name
                email
                keycloak_id
              }
              role
              level
            }
            procedure {
              name
              code
            }
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setActivities(data.activities);
        setTotalPages(Math.ceil(data.activities.totalCount / limit));
      },
    }
  );

  const [fetchExportActivities] = useLazyQuery(
    gql`
      query FetchActivities($searchParams: ProcedureActivitySearchParams!) {
        activities(searchParams: $searchParams) {
          totalCount
          activities {
            id
            dateOfProcedure
            optionalIdentifier
            hospital
            sex
            ageCategory
            setting
            attending {
              firstName
              lastName
            }
            participants {
              user {
                first_name
                last_name
                email
                keycloak_id
              }
              role
              level
            }
            procedureIndication
            procedureComments
            procedure {
              name
            }
            modalityPrimary
            modalitySecondary
            doseTime
            doseCtdi
            doseDlp
            doseDap
            doseSkin
            onCall
            success
            successComments
            dictated
            complications
            complicationsComments
            ebl
            sedation
            platelet
            inr
            inrReversal
            closureDevice
            closureDeviceComments
            imageFollowup
            imageFollowupNotes
            consultFollowup
            consultFollowupNotes
          }
          createdAt
          modifiedAt
          modifiedBy {
            first_name
            last_name
            email
            keycloak_id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data.activities.activities.length) {
          try {
            exportToExcel(
              data.activities.activities.map((record: ProcedureActivity) => {
                const {
                  __typename,
                  dateOfProcedure: Date,
                  procedure: ProcedureName,
                  hospital: Hospital,
                  attending: Attending,
                  optionalIdentifier: OptionalIdentifier,
                  procedureComments: ProcedureComment,
                  procedureIndication: ProcedureIndication,
                  id: ProcedureId,
                  participants: Trainees,
                  ...obj
                } = record;
                return {
                  Date,
                  OptionalIdentifier,
                  ProcedureName: ProcedureName?.name,
                  Hospital,
                  Attending: `${Attending?.lastName}, ${Attending?.firstName}`,
                  "Trainee 1":
                    Trainees?.[0] &&
                    getFullName(
                      Trainees[0].user?.first_name,
                      Trainees[0].user?.last_name
                    ),
                  "Trainee 1 level": Trainees?.[0]?.level,
                  "Trainee 1 role": Trainees?.[0]?.role,
                  "Trainee 2":
                    Trainees?.[1] &&
                    getFullName(
                      Trainees[1].user?.first_name,
                      Trainees[1].user?.last_name
                    ),
                  "Trainee 2 level": Trainees?.[1]?.level,
                  "Trainee 2 role": Trainees?.[1]?.role,
                  "Trainee 3":
                    Trainees?.[2] &&
                    getFullName(
                      Trainees[2].user?.first_name,
                      Trainees[2].user?.last_name
                    ),
                  "Trainee 3 level": Trainees?.[2]?.level,
                  "Trainee 3 role": Trainees?.[2]?.role,
                  ProcedureIndication,
                  ProcedureComment,
                  ...obj,
                  imageFollowup: formatDate(obj.imageFollowup),
                  consultFollowup: formatDate(obj.consultFollowup),
                  ebl: EblMap[obj.ebl ?? ""],
                  platelet: PlateletMap[obj.platelet ?? ""],
                  inr: INRMap[obj.inr ?? ""],
                  sedation:
                    obj?.sedation === Sedation.ModerateConscious
                      ? "Moderate (Conscious)"
                      : obj?.sedation,
                  ProcedureId,
                };
              }),
              fileName
            );
            createToast({
              title: "Successfully exported records",
              type: ToastType.SUCCESS,
              format: ToastFormat.BANNER,
            });
          } catch {
            createToast({
              title: "Record export failed. Try again later.",
              type: ToastType.ERROR,
              format: ToastFormat.TOAST,
            });
          }
        } else {
          createToast({
            title: "You have no records to export",
            type: ToastType.ERROR,
            format: ToastFormat.TOAST,
          });
        }
      },
      onError: (error) => {
        console.log(error);
        createToast({
          title: "Record export failed. Try again later.",
          type: ToastType.ERROR,
          format: ToastFormat.TOAST,
        });
      },
    }
  );

  const { data: filterData } = useQuery(
    gql`
      query FetchFormData {
        IRLogFormData {
          interventionTypes {
            name
            organSystems {
              name
              categories {
                name
                subCategories {
                  name
                  subCategory
                  code
                }
              }
            }
          }
          attending {
            id
            firstName
            lastName
          }
          hospitals {
            id
            name
          }
        }
      }
    `
  );

  const [
    deleteLogbookRecord,
    {
      loading: loadingDeleteRecord,
      data: mutationResponse,
      error: mutationError,
    },
  ] = useMutation(
    gql`
      mutation DeleteActivity(
        $activity: ProcedureActivityInput!
        $searchParams: ProcedureActivitySearchParams!
      ) {
        deleteActivity(activity: $activity) {
          refetch {
            activities(searchParams: $searchParams) {
              totalCount
              activities {
                id
                groupId
                dateOfProcedure
                optionalIdentifier
                hospital
                attending {
                  firstName
                  lastName
                  id
                }
                participants {
                  user {
                    first_name
                    last_name
                  }
                  name
                }
                procedure {
                  name
                  code
                }
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setActivities(data.deleteActivity.refetch.activities);
        setTotalPages(
          data.deleteActivity.refetch.activities
            ? Math.ceil(
                data.deleteActivity.refetch.activities.totalCount / limit
              )
            : 0
        );
        setDeleteRecordDialog("");
      },
    }
  );

  const handleRecordDelete = (id: string) => {
    const record = activitiesData.activities.activities.find(
      (item: ProcedureActivity) => item.id === id
    );

    if (!record) {
      createToast({
        title: "Specified record does not exist or is unavailable",
        type: ToastType.ERROR,
        format: ToastFormat.TOAST,
      });
      return;
    }

    if (!activitiesData.activities.activities.length) {
      createToast({
        title: "No records exist or are available",
        type: ToastType.ERROR,
        format: ToastFormat.TOAST,
      });
      return;
    }

    deleteLogbookRecord({
      variables: {
        activity: {
          id: record.id,
          procedure: { code: record.procedure.code },
          groupId: record.groupId,
        },
        searchParams: {
          limit: limit,
          offset: offset,
          sortField: sortColumn,
          sortOrder: sortOrder,
          searchText: searchText,
          startDate: filterStartDate,
          endDate: filterEndDate,
          hospital: filterValues.hospital,
          attending: filterValues.attending,
          participantName: filterValues.participantName,
          interventionType: filterValues.interventionType,
          organSystem: filterValues.organSystem,
          category: filterValues.category,
          subCategory: filterValues.subCategory,
          modalityPrimary: filterValues.modalityPrimary,
          complications: filterValues.complications,
        },
      },
    });
  };

  useEffect(() => {
    if (mutationResponse) {
      createToast({
        title: "Record deleted.",
        type: ToastType.SUCCESS,
        format: ToastFormat.TOAST,
      });
    }
    if (mutationError) {
      createToast({
        title: "Record deletion failed.",
        type: ToastType.ERROR,
        format: ToastFormat.TOAST,
      });
    }
  }, [createToast, mutationError, mutationResponse]);

  useEffect(() => {
    fetchActivities({
      variables: {
        searchParams: {
          limit: limit,
          offset: offset,
          sortField: sortColumn,
          sortOrder: sortOrder,
          searchText: searchText,
          startDate: filterStartDate,
          endDate: filterEndDate,
          hospital: filterValues.hospital,
          attending: filterValues.attending,
          participantName: filterValues.participantName,
          interventionType: filterValues.interventionType,
          organSystem: filterValues.organSystem,
          category: filterValues.category,
          subCategory: filterValues.subCategory,
          modalityPrimary: filterValues.modalityPrimary,
          complications: filterValues.complications,
        },
      },
    });
  }, [
    fetchActivities,
    filterEndDate,
    filterStartDate,
    filterValues.attending,
    filterValues.hospital,
    filterValues.interventionType,
    filterValues.organSystem,
    filterValues.participantName,
    filterValues.category,
    filterValues.subCategory,
    searchText,
    filterValues.modalityPrimary,
    filterValues.complications,
    offset,
    limit,
    sortColumn,
    sortOrder,
  ]);

  const request = debounce((value) => {
    setSearchText(value);
  }, 300);

  const debouceRequest = useCallback((value) => request(value), []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    debouceRequest(e.currentTarget.value);
    handlePageChange(0);
  };

  const handleClearFilter = () => {
    setFilterEndDate(undefined);
    setFilterStartDate(undefined);
    setFilterValues({
      hospital: "",
      attending: "",
      participantName: "",
      interventionType: "",
      organSystem: "",
      category: "",
      subCategory: "",
      modalityPrimary: "",
      complications: undefined,
    });
  };

  const handleFilterChange = (event: any) => {
    if (event.target) {
      setFilterValues((filterValues) => ({
        ...filterValues,
        [event.target.name]: event.target.value,
      }));
    } else {
      setFilterValues((filterValues) => ({
        ...filterValues,
        [event.name]: event.value,
      }));
    }
    handlePageChange(0);
  };

  const handlePageChange = (page: number) => {
    const positiveTotalPages =
      page <= 1
        ? 1
        : totalPages <= 1
        ? 1
        : page > totalPages
        ? totalPages
        : page;
    setActivePage(positiveTotalPages);
    setOffset((positiveTotalPages - 1) * limit);
  };

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

  const getPagesList = () => {
    let pages = [];
    for (var i = 2; i <= totalPages - 1; i++) {
      if (i <= paginationLimit && activePage < paginationLimit) {
        pages.push(i);
      } else if (i >= totalPages - 4 && activePage > totalPages - 3) {
        pages.push(i);
      } else if (i >= activePage - 2 && i <= activePage + 1) {
        pages.push(i);
      }
    }
    return pages;
  };

  const handleColumnClick = (value: ProcedureActivitySortField) => {
    if (sortColumn === value) {
      setSortOrder(
        sortOrder === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc
      );
    } else {
      setSortColumn(value);
    }
  };

  const handleExport = () => {
    if (activitiesData?.activities?.totalCount > maxExportLimit) {
      createToast({
        title: `You have exceeded the limit of record exports (${maxExportLimit}). Try filtering records to reduce the export amount.`,
        type: ToastType.ERROR,
        format: ToastFormat.TOAST,
      });
    } else {
      fetchExportActivities({
        variables: {
          searchParams: {
            limit: maxExportLimit,
            sortField: sortColumn,
            sortOrder: sortOrder,
            searchText: searchText,
            startDate: filterStartDate,
            endDate: filterEndDate,
            hospital: filterValues.hospital,
            attending: filterValues.attending,
            participantName: filterValues.participantName,
            interventionType: filterValues.interventionType,
            organSystem: filterValues.organSystem,
            category: filterValues.category,
            subCategory: filterValues.subCategory,
            modalityPrimary: filterValues.modalityPrimary,
            complications: filterValues.complications,
          },
        },
      });
    }
  };

  const dots = (
    <img src="/static/assets/more.svg" width="16px" alt="Hidden pages"></img>
  );

  return (
    <PageLayout title="Mentice Live | Logbook">
      {children}
      <ActionBar
        backTo="/logbook"
        title={"Existing Records"}
        searchField={
          <TextField
            searchable
            onClearSearch={() => setSearchText("")}
            placeholder="Search for records by any keyword"
            onChange={handleSearchChange}
            id="cy-records-search-field"
          />
        }
        button={
          <StyledLink
            to={`${match.path}/create`}
            style={{ maxWidth: "fit-content" }}
            id="cy-create-logbook-link"
          >
            <Button
              icon={<Add color={theme.colors.darkGrey} />}
              size={ButtonSizes.Medium}
            >
              Create record
            </Button>
          </StyledLink>
        }
        buttonGroup={
          <>
            <Button
              size={ButtonSizes.Medium}
              icon={<Download />}
              onClick={handleExport}
              secondary
            />
            <RecordFilter
              setFilterStartDate={setFilterStartDate}
              setFilterEndDate={setFilterEndDate}
              onClear={handleClearFilter}
              filterData={filterData && filterData.IRLogFormData}
              onChange={handleFilterChange}
              values={filterValues}
            />
          </>
        }
      />
      <TableCard>
        <ScrollableTableContainer>
          <RecordTable
            onColumnClick={handleColumnClick}
            onRecordDelete={(id) => setDeleteRecordDialog(id)}
            sortOrder={sortOrder}
            sortColumn={sortColumn}
            loading={activitiesLoading}
            tableData={activities && activities.activities}
          />
        </ScrollableTableContainer>
        <Pagination activitiesLoading={activitiesLoading}>
          <PageArrow
            src="/static/assets/arrow-prev-month.svg"
            alt="Previous page"
            onClick={() => {
              handlePageChange(activePage - 1);
              scrollToTop();
            }}
          />
          {totalPages !== 1 && (
            <Page
              activePage={activePage === 1}
              onClick={() => {
                handlePageChange(1);
                scrollToTop();
              }}
            >
              {1}
            </Page>
          )}
          {activePage >= paginationLimit && dots}
          {getPagesList().map((page: number) => (
            <Page
              key={page}
              activePage={page === activePage}
              onClick={() => {
                handlePageChange(page);
                scrollToTop();
              }}
            >
              {page}
            </Page>
          ))}
          {activePage <= totalPages - 3 && dots}
          {totalPages !== 0 && (
            <Page
              activePage={activePage === totalPages}
              onClick={() => {
                handlePageChange(totalPages);
                scrollToTop();
              }}
            >
              {totalPages}
            </Page>
          )}
          <PageArrow
            src="/static/assets/arrow-next-month.svg"
            alt="Next page"
            onClick={() => {
              handlePageChange(activePage + 1);
              scrollToTop();
            }}
          />
        </Pagination>
      </TableCard>
      {deleteRecordDialog && (
        <Dialog
          title="Confirm delete record"
          onClose={() => setDeleteRecordDialog("")}
          buttons={
            <>
              <Button secondary onClick={() => setDeleteRecordDialog("")}>
                Cancel
              </Button>
              <Button
                onClick={() => handleRecordDelete(deleteRecordDialog)}
                disabled={loadingDeleteRecord}
              >
                {renderLoadingText(loadingDeleteRecord, "Delete record")}
              </Button>
            </>
          }
        >
          Are you sure you want to delete this record?
        </Dialog>
      )}
    </PageLayout>
  );
};

export default RecordsPage;
