import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useToast } from "../../lib/useToast";
import { Toast, toastIsCountdownToast } from "../../lib/toastStore";
import { LargeText } from "../shared/typography";
import { ToastFormat, ToastType } from "../../shared/enums";
import { getFormattedDurationStringFromSeconds } from "../../lib/formatDateAndTime";

const ToastContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  margin-top: 24px;
  transform: translateX(-50%);
`;

const slideUp = keyframes`
  from {
      transform: translateY(100%);
      opacity: 0;
    }
  to {
      transform: translateY(0);
      opacity: 100%;
    }
`;

interface ToastItemProps {
  type: ToastType;
  theme: any;
}

const ToastItem = styled.div`
  padding: 12px 16px;
  min-height: 48px;
  width: fit-content;
  background: #fff;
  border-radius: 8px;
  box-shadow: ${(props: ToastItemProps) => props.theme.shadows.toastShadow};
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: ${slideUp} 0.7s;
  margin-bottom: 12px;
  border: 1px solid
    ${(props: ToastItemProps) =>
      props.type === ToastType.SUCCESS
        ? props.theme.colors.success100
        : props.theme.colors.error100};
  background-color: ${(props: ToastItemProps) =>
    props.type === ToastType.SUCCESS
      ? props.theme.colors.toastSuccess
      : props.theme.colors.toastError};
`;

const ImageContainer = styled.div`
  display: flex;
  margin-right: 12px;
  justify-content: center;
  align-items: center;

  img {
    height: 24px;
    width: 24px;
  }
`;

const CountdownText = styled(LargeText)`
  min-width: 64px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled.button`
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: url("../../../static/assets/close-thin.svg");
  padding: 0;
  margin: 0 0 0 56px;
  border: none;
`;

const ToastComponent: React.FC<{ toast: Toast; removeToast: () => void }> = ({
  toast,
  removeToast,
}) => {
  const icon = (() => {
    switch (toast.type) {
      case ToastType.SUCCESS:
        return (
          <img src={"/static/assets/successIcon.svg"} alt="success-icon" />
        );
      case ToastType.ERROR:
        return <img src={"/static/assets/errorIcon.svg"} alt="error-icon" />;
    }
  })();

  const [timeRemaining, setTimeRemaining] = useState<number | undefined>(
    toastIsCountdownToast(toast) ? toast.timeRemaining : undefined
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (typeof timeRemaining === "number") {
        setTimeRemaining(timeRemaining - 1);
      }
    }, 1000);

    if (typeof timeRemaining === "number" && timeRemaining < 0) {
      removeToast();
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [removeToast, timeRemaining]);

  switch (toast.format) {
    case ToastFormat.TOAST:
      return (
        <ToastItem type={toast.type} className={`cy-toast-${toast.type}`}>
          <ImageContainer>{icon}</ImageContainer>
          <Container>
            <LargeText>{toast.title}</LargeText>
          </Container>
        </ToastItem>
      );
    case ToastFormat.BANNER:
      return (
        <ToastItem type={toast.type} className={`cy-banner-${toast.type}`}>
          <ImageContainer>{icon}</ImageContainer>
          <Container>
            <LargeText>{toast.title}</LargeText>
          </Container>
          <Container>
            <CloseButton onClick={removeToast} />
          </Container>
        </ToastItem>
      );
    case ToastFormat.COUNTDOWN:
      return (
        <ToastItem type={toast.type} className={`cy-countdown-${toast.type}`}>
          <ImageContainer>{icon}</ImageContainer>
          <Container>
            <LargeText>{toast.title}</LargeText>
            <CountdownText>
              {"\u00a0"}
              {typeof timeRemaining === "number" &&
                getFormattedDurationStringFromSeconds(timeRemaining, true)}
            </CountdownText>
          </Container>
          <Container>
            <CloseButton onClick={removeToast} />
          </Container>
        </ToastItem>
      );
  }
};

interface ToasterProps {}

const Toaster: React.FC<ToasterProps> = () => {
  const [toasts, , removeToast] = useToast();
  return (
    <ToastContainer>
      {toasts.map((toast: Toast) => (
        <ToastComponent
          key={toast.id}
          toast={toast}
          removeToast={() => removeToast(toast)}
        />
      ))}
    </ToastContainer>
  );
};

export default Toaster;
