import React from "react";
import styled from "styled-components";
import { H3 } from "../typography";
import { SectionHeaderContainer, Title } from "../../Page/Section";

interface CardSectionContainerProps {
  maxColumns: number;
  title: string | undefined;
}

const SectionContainer = styled.div<CardSectionContainerProps>`
  width: 100%;
  display: grid;
  /* Automatically determine amount of rows */
  grid-template-columns: ${(props) =>
    props.maxColumns === 1
      ? "1fr"
      : "repeat(auto-fill, minmax(" +
        (1128 - 24 - 24 - 256 - 64 - 64 * (props.maxColumns - 1)) /
          props.maxColumns +
        "px, 1fr))"};
  grid-gap: 24px 24px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

interface SidebarCardSectionProps {
  title?: string;
  smallerTitle?: string;
  maxColumns: number;
}

export const SidebarCardSection: React.FC<SidebarCardSectionProps> = ({
  title,
  smallerTitle,
  maxColumns,
  children,
}) => {
  return (
    <SectionContainer maxColumns={maxColumns} title={title}>
      {title && !smallerTitle && (
        <Title>
          <SectionHeaderContainer>
            <H3>{title}</H3>
          </SectionHeaderContainer>
        </Title>
      )}
      {children}
    </SectionContainer>
  );
};
