import React from "react";
import { PageLayout } from "../../components/Page/PageLayout";
import { Card } from "../../components/shared/Card";
import { Section } from "../../components/Page/Section";
import { H4, Text, DeprecatedLi as Li } from "../../components/shared/typography";

// TODO: figure out neat way to reuse colors / components / typography,
// while at the same time having them wrapped in a pagelayout so they can be viewed individually
function DataProtectionPolicyPage() {
  return (
    <PageLayout title="Mentice Live | Data Protection Policy" hideNavBar={true}>
      <Section title="Data Protection Policy" maxColumns={1}>
        <Card>
          <H4>Our Data Protection Policy 2018:1</H4>

          <Text>
            For us at Mentice it is important that you feel confident with how
            we treat your personal data in a secure and legal way. This Data
            Protection Policy explains how Mentice collects and uses your
            personal data. The policy also describes the various rights that you
            have and how you can invoke these rights.
          </Text>

          <Text>
            All processing of your personal data is in accordance with
            applicable legislation. This means, among other things, that Mentice
            protects your personal information with the necessary measures and
            that you are always entitled to contact us to find out what personal
            information we have saved about you. You can read more about your
            rights in this policy. You can also visit{" "}
            <a href="www.datainspektionen.se" target="_blank" rel="noopener">
              www.datainspektionen.se
            </a>
            where you can read more about your rights. If you have any questions
            about our processing of your personal information, please contact us
            at (<a href="mailto:gdpr@mentice.com">gdpr@mentice.com</a>).
          </Text>

          <Text>
            As data controller, Mentice AB, corporate identity No. 556556-4241
            (”Mentice”) is responsible for your personal data and protects your
            personal integrity.
          </Text>

          <Text>
            Mentice collects personal information that you provide to us when
            you are in contact with us via our website, e-mail, mail, our social
            media or otherwise. This may happen when you subscribe to our
            newsletters, when you or your company contact us to concerning our
            services or products or if you apply for a job at our company.
            Mentice may also receive your personal data from other sources, such
            as administrative authorities or other public sources.
          </Text>

          <Text>
            Mentice processes the personal information provided to us and / or
            collected by us. It may be the name, e-mail address, telephone
            number, electronic addresses, address details, date of birth, social
            security number, ID number, bank details, CV, personal letter and /
            or other information that you have submitted to Mentice or is in
            general necessary for Mentice to be able to fulfill their
            commitments and services to you.
          </Text>

          <Text>
            Your personal data may be processed by Mentice for the following
            purposes:
          </Text>

          <ul>
            <Li>To get in contact with you.</Li>
            <Li>To process an order or issue a quotation.</Li>
            <Li>
              To fulfill our obligations in accordance with the services that
              you use from Mentice and our service in other respects to you.
            </Li>
            <Li>
              To provide sufficient service for you e.g. by responding to
              questions that you may have.
            </Li>
            <Li>
              To send relevant information and offers about Mentice’s various
              services and arrangements such as seminars and to update you with
              our newsletters.
            </Li>
            <Li>
              To use as basic data for invoicing or to debit a purchase at our
              digital payment platform.
            </Li>
            <Li>To handle eventual failures in the payment process.</Li>
            <Li>To comply with applicable legislation.</Li>
            <Li>
              To keep statistics in order to make our services better for as
              many as possible and for you.
            </Li>
            <Li>
              For internal identification such as contract number, internal or
              official customer number.
            </Li>
            <Li>To administrate and manage job applications.</Li>
          </ul>

          <Text>
            Mentice is entitled to process the personal data required for the
            fulfillment of Mentice commitment to you, if you have given consent
            to the process or if the process has a legitimate interest for the
            Mentice. Mentice may also need to process your personal data due to
            requirements in law or regulation.
          </Text>

          <Text>
            Your personal information is used by Mentice and the Personal Data
            Assistants authorized by Mentice. Mentice does not transfer any
            personal data to other companies or authorities if Mentice are not
            required to do so by law, regulation or official decision or to
            protect our rights or a third party’s rights, if the transfer to
            third parties aligns with our commitments towards you. Mentice never
            forward or sell your personal data for marketing purposes to third
            parties.
          </Text>

          <Text>
            Mentice may <em>transfer</em> your personal data to countries
            outside the EU / EEA if that is specifically stated when you submit
            your personal information to us or if such transfer is necessary. In
            this situation Mentice will apply Standard Contractual Clauses.
          </Text>

          <Text>
            Mentice only <em>saves</em> your personal information as long as it
            is necessary for Mentice to fulfill the purpose of the process in
            each case.
          </Text>

          <Text>
            You are entitled at any time (free of charge once a year) to request{" "}
            <em>information about what type of personal information</em> Mentice
            has saved about you (extract of files). Your request must reach us
            in a written document and include your name, social security number
            / identification number and be signed for in your name.
          </Text>

          <Text>
            If your personal information is incorrect, incomplete or irrelevant,
            you have the right to request <em>correction</em>. You also have the
            right to have your personal information <em>deleted</em>. However,
            Mentice cannot delete your personal information if there is a
            statutory requirement for storage, such as accounting rules, or when
            there are other legitimate reasons why personal data must be saved.
          </Text>

          <Text>
            You may at any time <em>withdraw your consent</em> that allows
            Mentice to process your personal information.
          </Text>

          <Text>
            Request for extract of files, information, correction of incorrect,
            incomplete or irrelevant personal data and deletion of personal data
            must be sent to (
            <a href="mailto:gdpr@mentice.com">gdpr@mentice.com</a>).
          </Text>

          <Text>
            You also have the right to lodge complaints with the relevant
            supervisory authority for processing personal data (the Swedish Data
            Inspection / Integrity Protection Authority).
          </Text>

          <Text>
            Mentice is obliged to have appropriate technical and organizational
            safety and to take all measures required in each case to protect
            your personal data and ensure that persons authorized to process
            personal data are under an appropriate obligation of confidentiality
            in accordance with current legislation.
          </Text>

          <Text>
            Mentice website uses small text files, called cookies, to facilitate
            your browsing on the site. Mentice uses cookies in particular to
            collect statistics about visitor’s choices on the site. You can read
            more about our use of cookies on our website (
            <a href="www.mentice.com" target="_blank" rel="noopener">
              www.mentice.com
            </a>
            ).
          </Text>

          <Text>
            This policy is subject to continuous changes. Updated versions will
            be authorized when available on our website (
            <a href="www.mentice.com" target="_blank" rel="noopener">
              www.mentice.com
            </a>
            ).
          </Text>
        </Card>
      </Section>
    </PageLayout>
  );
}

DataProtectionPolicyPage.getInitialProps = () => {
  return { public: true };
};

export default DataProtectionPolicyPage;
