import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: 44px;
  z-index: 1000;
  border-radius: 4px;
  flex-direction: column;
  min-width: ${(props) => props.theme.sizes.spacing40};
  box-shadow: ${(props) => props.theme.shadows.cardShadow};
  border: solid 1px ${(props) => props.theme.colors.lightGrey};
  background-color: ${(props) => props.theme.colors.white};
  overflow-x: visible;

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    position: fixed;
  }
`;
const ScrollableContainer = styled.div`
  max-height: ${(props) => props.theme.sizes.spacing112};
  overflow-y: auto;
  padding: 24px 32px 32px 32px;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    max-height: 100vh;
  }
`;

const DropdownFilter: React.FC = ({ children }) => {
  const filterRef = useRef<HTMLDivElement>(null);

  // Right-aligns dropdown if it overflows screen
  useLayoutEffect(() => {
    if (filterRef.current) {
      const offset = filterRef.current.getBoundingClientRect();
      if (window.innerWidth - offset.right < 12) {
        filterRef.current.style.right = "0";
      }
    }
  }, []);

  return (
    <Container ref={filterRef}>
      <ScrollableContainer>{children}</ScrollableContainer>
    </Container>
  );
};

export default DropdownFilter;
