import React from "react";
import styled from "styled-components";
import { H4, Span, Text } from "../shared/typography";
import { Card } from "../shared/Card";
import { VistCourseIcon } from "../../shared/enums";

const CourseCard = styled(Card)`
  display: flex;
  flex-direction: column;
  position:relative;

  :hover {
    box-shadow: ${(props) => props.theme.shadows.cardShadowHover};
  }

  img {
    width: 92px;
    height: 92px;
    align-self: center;
  }
`;

const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubHeaderText = styled(Span)`
  color: ${(props) => props.theme.colors.midGrey};
`;

const SimulatorIdText = styled(SubHeaderText)`
  max-width: fit-content;
  white-space: nowrap;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  flex-direction: column;
`;

export const LicenseContainer = styled.div`
  position: absolute;
  right: 20px;
  width: 20px;

  & span {
    background-color: #eeeeee;
    color: black;
    font-size: 100%;
    position: absolute;
    padding: 1px 5px 2px 5px;
    top: 25px;
    right: 0;
    box-shadow: 2px 2px 5px #555555;
    border-radius: 2px;
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
    white-space: pre;
  }

  :hover span {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
  }
`;

interface CaseProps {
  title: string;
  description: string;
  code?: string;
  version?: string;
  vistCourseIcon: VistCourseIcon;
  hasCaseCreator?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  simulationId?: string;
  license?: any;
}

export const VistCourseCard: React.FC<CaseProps> = ({
  title,
  description,
  code,
  version,
  vistCourseIcon,
  hasCaseCreator,
  disabled,
  onClick,
  simulationId,
  license
}) => {
  const getIconURL = () => {
    if (hasCaseCreator) {
      return "/static/assets/heart-with-border-purple.svg";
    } else {
      switch (vistCourseIcon) {
        case VistCourseIcon.Heart:
          return "/static/assets/heart-with-border.svg";
        case VistCourseIcon.Patient:
          return "/static/assets/female.svg";
        default:
          throw new Error("Invalid CaseIcon supplied: " + vistCourseIcon);
      }
    }
  };

  let licenseValid = license?.valid || true
  let licenseExpired = license?.expired || false
  let remainingdays = license?.remainingdays || 100000
  for (let s of license?.simulations || []) {
    licenseValid = licenseValid && s.valid
    licenseExpired = licenseExpired || s.expired
    remainingdays = Math.min(remainingdays, s.remainingdays)
  }

  const okIcon = remainingdays >= 30 ? "/static/assets/license-ok.svg" : "/static/assets/license-issue.svg"
  const licenseIcon =  licenseValid && !licenseExpired && remainingdays >= 0 ? okIcon : "/static/assets/errorIcon.svg"
  const licenseData = !license ? "" : (( licenseValid ? "LICENSE" : "INVALID LICENSE") +
    (licenseExpired ? ": EXPIRED " : (remainingdays >= 0 ? (": " + remainingdays + " days remaining") : "")) + "\n" +
    "Checked on " + license?.checked)

  return (
    <CourseCard disabled={disabled} onClick={onClick}>
      {!!license && <LicenseContainer><span className="tooltip">{licenseData}</span><img style={{width: '100%', height: 'auto'}} src={licenseIcon} alt={licenseData} /></LicenseContainer>}
      {title && <H4>{title}</H4>}
      <SubHeader>
        {code && (
          <SubHeaderText style={{ marginTop: "4px" }}>{code}</SubHeaderText>
        )}
        {version && <SubHeaderText>{version}</SubHeaderText>}
      </SubHeader>
      <Text>{description}</Text>
      <SubHeader>
        {simulationId && <SimulatorIdText>ID: {simulationId}</SimulatorIdText>}
      </SubHeader>
      <ImageContainer>
        <img src={getIconURL()} alt="Vist course icon" />
      </ImageContainer>
    </CourseCard>
  );
};
