import { FormikErrors } from "formik";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { H6 } from "./typography";

interface InputProps extends TextFieldProps {
  searchable?: boolean;
  error?: string | false | string[] | FormikErrors<any> | FormikErrors<any>[];
}

export const inputBase = css`
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  color: ${(props) => props.theme.colors.darkDarkGrey};
  &:focus {
    color: ${(props) => props.theme.colors.darkGrey};
    border: 1px solid ${(props) => props.theme.colors.primary500};
    outline: none;
  }
  &:focus::placeholder {
    opacity: 0;
  }
  &::placeholder {
    color: ${(props) => props.theme.colors.grey};
    font-style: italic;
  }
  &:not(:placeholder-shown) {
    color: ${(props) => props.theme.colors.darkGrey};
    border: 1px solid ${(props) => props.theme.colors.darkGrey};
  }
  &:disabled {
    background: none;
    opacity: 0.4;
    border: 1px solid ${(props) => props.theme.colors.midGrey};
    color: ${(props) => props.theme.colors.darkGrey};
  }
`;

const inputStyle = css<InputProps>`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 16px;
  padding: 8px 16px;
  width: 100%;
  margin-top: 8px;
  min-width: 144px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background: ${(props) =>
    props.error ? props.theme.colors.error25 : props.theme.colors.white};
  border-color: ${(props) =>
    props.error ? props.theme.colors.error500 : props.theme.colors.lightGrey};
  ${(props) => props.searchable && "padding-right: 104px;"};
  &:focus {
    ${(props) => props.error && `border-color: ${props.theme.colors.error500};`}
  }
`;

const Input = styled.input`
  ${inputBase}
  ${inputStyle}
  height: 40px;
`;

const TextArea = styled.textarea`
  ${inputBase}
  ${inputStyle}
  margin-bottom: -8px;
  height: unset;
  resize: none;
`;

const InputLabel = styled.label`
  white-space: nowrap;
`;

export const LabelText = styled(H6)`
  margin: 0;
  font-size: 14px;
  line-height: 1;
  padding: 0;
  opacity: ${(props: { disabled: boolean; theme: any }) =>
    props.disabled ? 0.4 : 1};
  color: ${(props: { disabled: boolean; theme: any }) =>
    props.disabled
      ? `${props.theme.colors.grey}`
      : `${props.theme.colors.darkGrey}`};

  > span {
    color: ${(props) => props.theme.colors.error500};
  }
`;

const SearchForm = styled.form`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: -8px;
`;

const ClearButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0 8px 0 0;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% + 4px);
  right: 20px;
  transform: translateY(-50%);
`;

export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.error500};
  font-weight: 500;
  font-size: 14px;
  margin-left: 16px;
  height: 28px;
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: -28px;
  }
`;

interface TextFieldProps {
  disabled?: boolean;
  value?: string | number;
  label?: string;
  onChange: (event: React.FormEvent<any>) => void;
  error?: string | false | string[] | FormikErrors<any> | FormikErrors<any>[];
  readOnly?: boolean;
  placeholder?: string;
  multiline?: boolean;
  type?: any;
  name?: string;
  onBlur?: any;
  searchable?: boolean;
  onClearSearch?: () => void;
  id?: string;
  min?: string;
  max?: string;
  pattern?: string;
}

const TextField: React.FC<TextFieldProps> = ({
  disabled = false,
  value = "",
  label,
  error = "",
  onChange,
  readOnly = false,
  placeholder = " ",
  multiline = false,
  type,
  name,
  onBlur,
  searchable = false,
  onClearSearch,
  id = undefined,
  min,
  max,
  pattern,
}) => {
  const [clearableValue, setClearableValue] = useState("");
  return (
    <>
      {label && (
        <InputLabel>
          <LabelText disabled={disabled}>{label}</LabelText>
        </InputLabel>
      )}
      {multiline ? (
        <>
          <TextArea
            id={id}
            rows={4}
            error={error}
            readOnly={readOnly}
            onChange={onChange}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            name={name}
            onBlur={onBlur}
            min={min}
            max={max}
            pattern={pattern}
          />
          <ErrorMessage>{error}</ErrorMessage>
        </>
      ) : !searchable ? (
        <>
          <Input
            id={id}
            error={error}
            readOnly={readOnly}
            onChange={onChange}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            type={type}
            name={name}
            onBlur={onBlur}
            min={min}
            max={max}
            pattern={pattern}
          />
          <ErrorMessage>{error}</ErrorMessage>
        </>
      ) : (
        <SearchForm onSubmit={(e) => e.preventDefault()}>
          <Input
            id={id}
            searchable={searchable}
            error={error}
            readOnly={readOnly}
            onChange={(e: React.ChangeEvent<any>) => {
              setClearableValue(e.target.value);
              onChange(e);
            }}
            disabled={disabled}
            placeholder={placeholder}
          />
          <IconContainer>
            <ClearButton
              type="reset"
              onClick={() => {
                setClearableValue("");
                onClearSearch && onClearSearch();
              }}
              className="clearSearchButton"
            >
              {clearableValue && (
                <img src={"/static/assets/clear-icon.svg"} alt="Button Icon" />
              )}
            </ClearButton>
            <img src={"/static/assets/search.svg"} alt="Button Icon" />
          </IconContainer>
        </SearchForm>
      )}
    </>
  );
};

export default TextField;
