import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { PageLayout } from "../../components/Page/PageLayout";
import { TalentCourse } from "../../components/TalentCourses/TalentCourse";
import { Section } from "../../components/Page/Section";
import { TalentCourseCategory } from "../../shared/enums";
import { Text } from "../../components/shared/typography";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import {
  TalentBranch,
  TalentCourse as TalentCourseEntity,
} from "../../generated/graphql";
import { KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import Breadcrumbs from "../../components/shared/Breadcrumbs";

interface TalentBranchParams {
  id?: string;
  name: string;
}

const LearningSpace: React.FC = () => {
  const { id, name } = useParams<TalentBranchParams>();
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const [resolvedId, setResolvedId] = useState<string | undefined>(id);
  const match = useRouteMatch();

  const { data: talentBranchData } = useQuery<{
    talentBranchByName: TalentBranch;
  }>(
    gql`
      query ($talentBranchName: String!) {
        talentBranchByName(talentBranchName: $talentBranchName) {
          id
          name
        }
      }
    `,
    {
      variables: { talentBranchName: name },
      onCompleted(data) {
        setResolvedId(data.talentBranchByName.id.toString());
      },
    }
  );

  const [fetchAllTalentData, { data, error }] = useLazyQuery(
    gql`
      query ($talentBranchId: String!, $emailAddress: String!) {
        allTalentBranchCourses(
          talentBranchId: $talentBranchId
          emailAddress: $emailAddress
        ) {
          id
          category
          name
          completion_status
          completion_percentage
          big_avatar
          code
        }
      }
    `,
    {
      variables: {
        talentBranchId: resolvedId ?? "",
        emailAddress: user?.email ?? "",
      },
    }
  );

  useEffect(() => {
    if (user && resolvedId) {
      fetchAllTalentData();
    }
  }, [user, resolvedId]); // eslint-disable-line react-hooks/exhaustive-deps
  const branchCourses = data && data.allTalentBranchCourses;

  const allActiveTalentCourses: [TalentCourseEntity] =
    branchCourses &&
    branchCourses.filter(
      (course: TalentCourseEntity) =>
        course.completion_status !== null &&
        !course.completion_status?.includes("not_attempted") &&
        !course.completion_status?.includes("completed")
    );

  const allAvailableTalentCourses: [TalentCourseEntity] =
    branchCourses &&
    branchCourses.filter(
      (course: TalentCourseEntity) =>
        course.completion_status === null ||
        course.completion_status?.includes("not_attempted")
    );

  const allCompletedTalentCourses: [TalentCourseEntity] =
    branchCourses &&
    branchCourses.filter(
      (course: TalentCourseEntity) =>
        course.completion_status !== null &&
        course.completion_status?.includes("completed")
    );

  const existsActiveTalentCourses = data && allActiveTalentCourses.length > 0;

  const existsAvailableTalentCourses =
    data && allAvailableTalentCourses.length > 0;

  const existsCompletedTalentCourses =
    data && allCompletedTalentCourses.length > 0;

  return (
    <PageLayout title="Mentice Live | Courses">
      <Breadcrumbs
        crumbs={[
          {
            path: "/",
            name: "Home",
          },
          {
            path: "/learning-spaces",
            name: "Learning Spaces",
          },
          {
            path: match.path,
            name: talentBranchData?.talentBranchByName.name ?? name ?? "",
          },
        ]}
      />

      {/* List active courses */}
      <Section title="Active Courses" maxColumns={2}>
        {/* Show loading message */}
        {!error && !data && <Text>Loading active courses...</Text>}

        {/* Show error message */}
        {error &&
          !data &&
          error.graphQLErrors.map((error: { message: string }) => (
            <Text>{error.message}</Text>
          ))}

        {/* If no active courses are available */}
        {!existsActiveTalentCourses && !error && data && (
          <Text>You have not started any courses yet.</Text>
        )}

        {/* Map if active courses are available */}
        {!error &&
          data &&
          existsActiveTalentCourses &&
          name &&
          allActiveTalentCourses.map((course: TalentCourseEntity) => (
            <TalentCourse
              key={course.id}
              talentCourseId={course.id}
              category={course.category as TalentCourseCategory}
              title={course.name}
              subtitle={course.completion_status ?? ""}
              progress={course.completion_percentage ?? 0}
              code={course.code ?? ""}
              talentBranchName={name}
              talentBranchId={id ?? resolvedId ?? ""}
            />
          ))}
      </Section>

      {/* Show courses that have not yet been started */}
      {!error && (
        <Section title={"Available Courses"} maxColumns={2}>
          {/* Show loading message */}
          {!error && !data && <Text>Loading available courses...</Text>}

          {/* If no available courses are available */}
          {!existsAvailableTalentCourses && !error && data && (
            <Text>You have already started all available courses.</Text>
          )}

          {!error &&
            data &&
            existsAvailableTalentCourses &&
            name &&
            allAvailableTalentCourses.map((course: TalentCourseEntity) => (
              <TalentCourse
                key={course.id}
                talentCourseId={course.id}
                code={course.code ?? ""}
                category={course.category as TalentCourseCategory}
                title={course.name}
                subtitle={course.completion_status ?? ""}
                progress={course.completion_percentage ?? 0}
                talentBranchName={name}
                talentBranchId={id ?? resolvedId ?? ""}
              />
            ))}
        </Section>
      )}

      {/* List completed courses */}
      {!error && (
        <Section title="Completed Courses" maxColumns={2}>
          {/* Show loading message */}
          {!error && !data && <Text>Loading completed courses...</Text>}

          {/* If no completed courses are available */}
          {!existsCompletedTalentCourses && !error && data && (
            <Text>You have not completed any courses yet.</Text>
          )}

          {/* Map if completed courses are available */}
          {!error &&
            data &&
            existsCompletedTalentCourses &&
            name &&
            allCompletedTalentCourses.map((course: TalentCourseEntity) => (
              <TalentCourse
                key={course.id}
                talentCourseId={course.id}
                category={course.category as TalentCourseCategory}
                title={course.name}
                subtitle={course.completion_status ?? ""}
                progress={course.completion_percentage ?? 0}
                code={course.code ?? ""}
                talentBranchName={name}
                talentBranchId={id ?? resolvedId ?? ""}
              />
            ))}
        </Section>
      )}
    </PageLayout>
  );
};

export default LearningSpace;
