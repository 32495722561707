import React, { useState } from "react";
import styled, { css } from "styled-components";
import Tooltip from "../Groups/Tooltip";
import { boldTextStyle } from "../shared/typography";

const Avatar = styled.div<{ tooltip?: boolean }>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.tooltip &&
    "position: relative; cursor: default; max-width: fit-content;"}
`;

export const AvatarStyle = css<{ isAdmin: boolean }>`
  height: 40px;
  width: 40px;
  background-color: ${(props) =>
    props.isAdmin
      ? props.theme.colors.primary25
      : props.theme.colors.secondary25};
  border: 1px solid
    ${(props) =>
      props.isAdmin
        ? props.theme.colors.primary500
        : props.theme.colors.secondary500};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InitialsIcon = styled.div`
  border-radius: 50%;
  ${AvatarStyle}
`;

const Initials = styled.span`
  ${boldTextStyle}
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  position: relative;
`;

interface UserAvatarProps {
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  tooltip?: boolean | string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  firstName,
  lastName,
  isAdmin,
  tooltip,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const getInitials = (firstName: string, lastName: string) => {
    const initials =
      firstName && lastName ? `${firstName[0]}${lastName[0]}` : "";
    return initials;
  };
  return (
    <Avatar
      tooltip={typeof tooltip === "boolean" || typeof tooltip === "string"}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onTouchStart={() => setShowTooltip(true)}
      onTouchEnd={() => setShowTooltip(false)}
    >
      <InitialsIcon isAdmin={isAdmin}>
        <Initials>{getInitials(firstName, lastName)}</Initials>
      </InitialsIcon>
      {tooltip && showTooltip && (
        <Tooltip
          label={
            typeof tooltip === "string" ? tooltip : firstName + " " + lastName
          }
        ></Tooltip>
      )}
    </Avatar>
  );
};

export default UserAvatar;
