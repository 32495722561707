import React, { useState } from "react";
import { PageLayout } from "../../components/Page/PageLayout";
import { Section } from "../../components/Page/Section";
import gql from "graphql-tag";
import { useGraphQL } from "../../lib/useGraphQL";
import { YoutubeVideo as YoutubeVideoEntity } from "../../shared/youtubeVideo";
import { YoutubeVideo } from "../../components/Videos/YoutubeVideo";
import VideoModal from "../../components/Videos/VideoModal";
import { Text } from "../../components/shared/typography";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import ActionBar from "../../components/shared/ActionBar";

const VideosPage = () => {
  const [{ data: youtubeVideosData, errors: youtubeVideosErrors }] = useGraphQL(
    gql`
      query FetchYoutubeVideos($maxResults: Int!) {
        youtubeVideos(maxResults: $maxResults) {
          title
          thumbnail
          id
        }
      }
    `,
    {
      maxResults: 499,
    }
  );

  const [selectedVideo, setSelectedVideo] = useState<string | undefined>(
    undefined
  );

  return (
    <PageLayout title="Videos">
      <Breadcrumbs
        crumbs={[
          {
            path: "/",
            name: "Home",
          },
          {
            path: "/videos",
            name: "Videos",
          },
        ]}
      />
      <ActionBar backTo="/" title={"Videos"} />
      {!youtubeVideosErrors && youtubeVideosData && (
        <Section title="All Videos" maxColumns={4}>
          {selectedVideo && (
            <VideoModal
              selectedVideo={selectedVideo}
              setSelectedVideo={() => setSelectedVideo(undefined)}
            />
          )}

          {!youtubeVideosErrors &&
            youtubeVideosData &&
            youtubeVideosData.youtubeVideos.map(
              (video: YoutubeVideoEntity, i: number) => (
                <YoutubeVideo
                  key={i}
                  title={video.title}
                  id={video.id}
                  thumbnail={video.thumbnail}
                  setSelectedVideo={setSelectedVideo}
                />
              )
            )}
        </Section>
      )}

      {/* List YouTube video errors */}
      {((youtubeVideosErrors && !youtubeVideosData) ||
        (youtubeVideosErrors && youtubeVideosData)) && (
        <Section title="All Videos" maxColumns={1} back="/">
          {youtubeVideosErrors.map((error: { message: string }) => (
            <Text>{error.message}</Text>
          ))}
        </Section>
      )}

      {/* Show loading message */}
      {!youtubeVideosErrors && !youtubeVideosData && (
        <Section title="All Videos" maxColumns={1} back="/">
          <Text>Loading YouTube Videos...</Text>
        </Section>
      )}
    </PageLayout>
  );
};

export default VideosPage;
