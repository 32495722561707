import React, { useEffect, useState } from "react";
import { PageLayout } from "./components/Page/PageLayout";
import { TalentBranch } from "./components/TalentBranches/TalentBranch";
import { YoutubeVideo } from "./components/Videos/YoutubeVideo";
import { Section } from "./components/Page/Section";
import { SectionHeader } from "./components/Page/SectionHeader";
import { Branches, ToastFormat, ToastType } from "./shared/enums";
import {
  TalentBranch as TalentBranchEntity,
  VistActivityItem as VistActivityItemEntity,
  ActivitySortField,
  SortOrder,
  CustomerGroup,
} from "./generated/graphql";
import { StyledLink, Text } from "./components/shared/typography";
import { NewsArticle } from "./components/News/NewsArticle";
import { NewsArticle as NewsArticleType } from "./shared/newsArticle";
import { ApolloError, gql, useLazyQuery, useMutation } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakAccessToken } from "./lib/keycloakAccessToken";
import { Partners } from "./components/Partners/partners";
import { YoutubeVideo as YoutubeVideoEntity } from "./shared/youtubeVideo";
import VideoModal from "./components/Videos/VideoModal";
import { useLocation } from "react-router-dom";
import { getServiceTag } from "./lib/getServiceTag";
import { VistActivityItem } from "./components/VistActivity/VistActivityItem";
import { useToast } from "./lib/useToast";
import { getVoucherCodeErrorMessage } from "./lib/getErrorMessage";
import Events from "./components/Events/Events";
import Applications from "./components/Applications/Applications";

function Home() {
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const serviceTag = getServiceTag();

  const [, createToast] = useToast();

  const inviteCode = new URLSearchParams(useLocation().search).get(
    "inviteCode"
  );
  const [joinGroup, { data: joinGroupData }] = useMutation(
    gql`
      mutation AcceptInvite($code: String!) {
        acceptInvite(code: $code) {
          groupName
        }
      }
    `,
    {
      variables: { code: inviteCode },
      onCompleted(data) {
        createToast({
          title:
            "You've successfully joined the group " +
            data.acceptInvite.groupName,
          type: ToastType.SUCCESS,
          format: ToastFormat.BANNER,
        });
      },
      onError(err) {
        console.log(err);
        createToast({
          title: getVoucherCodeErrorMessage(
            err.graphQLErrors[0].extensions?.code
          ),
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  useEffect(() => {
    if (
      inviteCode &&
      !inviteCode.startsWith("cmrav") &&
      !inviteCode.startsWith("cerav")
    ) {
      joinGroup();
    }
  }, [inviteCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const voucherCode = new URLSearchParams(useLocation().search).get(
    "voucherCode"
  );
  const [acceptVoucher] = useMutation(
    gql`
      mutation AcceptVoucher($code: String!) {
        acceptVoucher(code: $code) {
          course
          groupName
          learningSpaceNames
        }
      }
    `,
    {
      variables: { code: voucherCode },
      onCompleted(data) {
        createToast({
          title:
            "You’ve successfully been added to the group " +
            data.acceptVoucher.groupName +
            " and now have access to the course " +
            data.acceptVoucher.course +
            " from " +
            data.acceptVoucher.learningSpaceNames.join(", "),
          type: ToastType.SUCCESS,
          format: ToastFormat.BANNER,
        });
        if (user) {
          fetchTalentBranches();
        }
      },
      onError(err: ApolloError) {
        console.log(JSON.stringify(err));
        createToast({
          title: getVoucherCodeErrorMessage(
            err.graphQLErrors[0].extensions?.code,
            err.graphQLErrors[0].extensions?.validTo
          ),
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  useEffect(() => {
    if (voucherCode) {
      acceptVoucher();
    }
  }, [voucherCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const loginCode = new URLSearchParams(useLocation().search).get(
    "qrlogin"
  );
  const [loginQr] = useMutation(
    gql`
      mutation QRLogin($payload: String!) {
        QRLogin(payload: $payload) {
          status
        }
      }
    `,
    {
      variables: { payload: loginCode },
      onCompleted(data) {
        console.log(data);
        if (data.QRLogin.status === 'ok') {
          createToast({
            title:
              "You've successfully logged in to simulator: " +
              data.QRLogin.status,
            type: ToastType.SUCCESS,
            format: ToastFormat.BANNER,
          });
        }
        else {
          createToast({
            title: 'Failed to login to simulator: ' + data.QRLogin.status,
            type: ToastType.ERROR,
            format: ToastFormat.BANNER,
          });
        }
      },
      onError(err: ApolloError) {
        console.log(JSON.stringify(err));
        createToast({
          title: 'Failed to login to simulator',
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  useEffect(() => {
    if (loginCode) {
      loginQr();
    }
  }, [loginCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const [
    fetchYouttubeVideos,
    { data: youtubeVideosData, error: youtubeVideosErrors },
  ] = useLazyQuery(
    gql`
      query FetchYoutubeVideos($maxResults: Int!) {
        youtubeVideos(maxResults: $maxResults) {
          title
          thumbnail
          id
        }
      }
    `,
    {
      variables: { maxResults: 4 },
    }
  );

  const [fetchNews, { data: newsData, error: newsErrors }] = useLazyQuery(
    gql`
      query FetchNewsArticles {
        newsArticles {
          title
          date
          link
          image
        }
      }
    `
  );

  const [
    fetchVistActivity,
    { data: vistActivityData, error: vistActivityErrors },
  ] = useLazyQuery<{
    groups: CustomerGroup[];
    vistActivities: VistActivityItemEntity[];
  }>(
    gql`
      query FetchVistActivity(
        $groups: [Int!]!
        $searchParams: ActivitySearchParams
      ) {
        vistActivities(groups: $groups, searchParams: $searchParams) {
          activityStart
          activityEnd
          createdBy {
            first_name
            last_name
            email
          }
          id
          unitName
          courseName
          totalTimeInSeconds
          result
          progress
          sourceUrl
          exerciseId
          exerciseName
          moduleId
          moduleName
          moduleVersion
          serviceTag
          ipAddress
        }
      }
    `,
    {
      variables: {
        fetchPolicy: "network-only",
        groups: [],
        searchParams: {
          sortField: ActivitySortField.ActivityStart,
          sortOrder: SortOrder.Desc,
          searchText: "",
        },
      },
    }
  );

  const [
    fetchTalentBranches,
    { data: talentBranchesData, error: talentBranchesErrors },
  ] = useLazyQuery(
    gql`
      query FetchTalentBranches($emailAddress: String!) {
        enrolledTalentBranches(emailAddress: $emailAddress) {
          id
          code
          name
          description
          image
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      variables: { emailAddress: user?.email ?? "" },
    }
  );

  // Trigger request after user data loads
  useEffect(() => {
    if (user) {
      fetchTalentBranches();
      fetchYouttubeVideos();
      fetchNews();
      fetchVistActivity();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      fetchTalentBranches();
    }
  }, [joinGroupData]); // eslint-disable-line react-hooks/exhaustive-deps

  const shouldDisplayTalentBranches =
    !talentBranchesErrors && talentBranchesData;

  let sortedBranches = [];
  if (
    talentBranchesData &&
    talentBranchesData.enrolledTalentBranches &&
    talentBranchesData.enrolledTalentBranches.length > 0
  ) {
    sortedBranches = [...talentBranchesData.enrolledTalentBranches].sort(
      (a: TalentBranchEntity, b: TalentBranchEntity) => {
        if (
          a.code === Branches.goto ||
          a.code === Branches.staging ||
          b.code === Branches.free
        ) {
          return -1;
        } else if (
          a.code === Branches.free ||
          b.code === Branches.goto ||
          b.code === Branches.staging
        ) {
          return 1;
        } else {
          return 0;
        }
      }
    );
  }

  const [selectedVideo, setSelectedVideo] = useState<string | undefined>(
    undefined
  );

  return (
    <PageLayout title="Mentice Live">
      <SectionHeader title="Learning & Development">
        <Events
          showViewAllLink={true}
          maxEventsToShow={4}
          showActionBar={false}
        />
        <Section
          title="Learning Spaces"
          maxColumns={2}
          primaryActionText="View All"
          primaryActionLink="/learning-spaces"
        >
          {/* List branches */}
          {shouldDisplayTalentBranches &&
            sortedBranches &&
            sortedBranches.length > 0 &&
            sortedBranches.map((branch: TalentBranchEntity) => (
              <TalentBranch
                key={branch.id}
                talentBranchId={branch.id}
                talentBranchName={branch.code}
                code={branch.code}
                title={branch.name}
                description={branch.description}
                image={branch.image}
              />
            ))}
          {/* Show "enroll" prompt */}
          {shouldDisplayTalentBranches &&
            talentBranchesData.enrolledTalentBranches.length === 0 && (
              <Text>No learning spaces available</Text>
            )}
          {/* List errors */}
          {talentBranchesErrors &&
            talentBranchesErrors.graphQLErrors.map(
              (error: { message: string }, i: number) => (
                <Text key={i}>{error.message}</Text>
              )
            )}
          {/* Show loading message */}
          {!talentBranchesErrors && !talentBranchesData && (
            <Text>Loading Learning Spaces...</Text>
          )}
        </Section>
        {!vistActivityErrors &&
          vistActivityData &&
          vistActivityData?.vistActivities &&
          vistActivityData?.vistActivities.length > 0 && (
            <Section
              title="Activity log"
              maxColumns={1}
              primaryActionText="View All"
              primaryActionLink="/activity-log"
            >
              {/* List activity log items */}
              {vistActivityData.vistActivities
                .filter(
                  (activity) =>
                    (activity.moduleName && activity.exerciseName) ||
                    (activity.unitName && activity.courseName)
                )
                .slice(0, 3)
                .map((vistActivityItem: VistActivityItemEntity, i: number) => (
                  <StyledLink
                    to={`/activity-log/vist-activity/${vistActivityItem.id}`}
                    key={vistActivityItem.id}
                  >
                    <VistActivityItem activity={vistActivityItem} />
                  </StyledLink>
                ))}
            </Section>
          )}
      </SectionHeader>
      {/* VIST modules and single-user cloud cases */}
      <Applications />

      {selectedVideo && (
        <VideoModal
          selectedVideo={selectedVideo}
          setSelectedVideo={() => setSelectedVideo(undefined)}
        />
      )}

      <SectionHeader title="News">
        {/* List the four newest YouTube videos */}
        {youtubeVideosData && !youtubeVideosErrors && (
          <Section
            title="Videos"
            maxColumns={4}
            primaryActionText="View All"
            primaryActionLink="/videos"
          >
            {!youtubeVideosErrors &&
              youtubeVideosData &&
              youtubeVideosData.youtubeVideos.map(
                (video: YoutubeVideoEntity, i: number) => (
                  <YoutubeVideo
                    key={i}
                    title={video.title}
                    id={video.id}
                    thumbnail={video.thumbnail}
                    setSelectedVideo={setSelectedVideo}
                  />
                )
              )}
          </Section>
        )}

        {/* Show loading message for YouTube Videos */}
        {!youtubeVideosErrors && !youtubeVideosData && (
          <Section title="Videos" maxColumns={1}>
            <Text>Loading YouTube Videos...</Text>
          </Section>
        )}

        {/* List YouTube video errors */}
        {((youtubeVideosErrors && !youtubeVideosData) ||
          (youtubeVideosErrors && youtubeVideosData)) && (
          <Section title="Videos" maxColumns={1}>
            {youtubeVideosErrors.graphQLErrors.map(
              (error: { message: string }, i: number) => (
                <Text key={i}>{error.message}</Text>
              )
            )}
          </Section>
        )}

        {!newsErrors && newsData && (
          <Section title="Feed" maxColumns={3}>
            {newsData.newsArticles.map(
              (article: NewsArticleType, i: number) => (
                <NewsArticle
                  key={i}
                  title={article.title}
                  date={article.date}
                  link={article.link}
                  image={article.image}
                  shouldOpenInNewTab={!serviceTag}
                />
              )
            )}
          </Section>
        )}
      </SectionHeader>

      {/* List of partner logos with link to website */}
      <Partners shouldOpenInNewTab={!serviceTag} />
    </PageLayout>
  );
}

export default Home;
