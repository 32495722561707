import React, { useState } from "react";
import { Option } from "react-select/src/filters";
import styled from "styled-components";
import { GridCol, GridRow } from "../../components/shared/FormLayout";
import SelectField from "../../components/shared/SelectField";
import TextField from "../../components/shared/TextField";
import { H4 } from "../../components/shared/typography";
import { Inr, InrReversal, Sedation } from "../../generated/graphql";
import { addSpacesToDropdownLabel } from "./helpers";
import { StringKeyObject } from "./types";
import { DetailSectionProps } from "./record";
import AccordionIcon from "../../components/icons/AccordionIcon";
import DatePickerField from "../../components/shared/DatePickerField";
import { getLogbookOffsetDate } from "../../lib/formatDateAndTime";

export const EblMap: StringKeyObject = {
  ml50_100: "50-100 mL",
  lt50mL: "< 50 mL",
  gt100mLNotRequireingTransfusion: "> 100 mL, not requiring transfusion",
  gt100mLRequireingTransfusion: "> 100 mL, requiring transfusion",
  Minimal: "Minimal",
  None: "None",
};

export const PlateletMap: StringKeyObject = {
  lt50: "< 50",
  p51_75: "51 - 75",
  p76_100: "76 - 100",
  gt100: "> 100",
  None: "Unknown",
};

export const INRMap: StringKeyObject = {
  lt1_3: "< 1.3",
  i1_3to1_4: "1.3 - 1.4",
  i1_5to1_7: "1.5 - 1.7",
  i1_8to2_0: "1.8 - 2.0",
  gt2_0: "> 2.0",
  None: "Unknown",
};

const DetailsSection = styled.div`
  margin-bottom: 2px;
`;

const DetailsHeading = styled(H4)`
  color: ${(props) => props.theme.colors.grey};
  padding: 14px 34px;
  display: flex;
  align-items: center;
`;

const ClinicalDetailSection: React.FC<DetailSectionProps> = ({
  handleChange,
  values,
  setFieldValue,
}) => {
  const [showAdditionalData, setShowAdditionalData] = useState(false);
  const [showClinicalManager, setShowClinicalManager] = useState(false);
  const [textFieldValues, setTextFieldValues] = useState({
    imageFollowupNotes: values.imageFollowupNotes,
    consultFollowupNotes: values.consultFollowupNotes,
  });
  return (
    <>
      <DetailsSection>
        <DetailsHeading
          onClick={() => setShowAdditionalData(!showAdditionalData)}
        >
          <AccordionIcon isExpanded={showAdditionalData} />
          Additional Clinical Data
        </DetailsHeading>
        {showAdditionalData && (
          <>
            <GridRow>
              <GridCol>
                <SelectField
                  label="Estimated Blood Loss"
                  name="ebl"
                  defaultValue={{
                    value: values.ebl ?? "",
                    label: EblMap[values.ebl ?? ""],
                  }}
                  onChange={(option: Option) =>
                    setFieldValue("ebl", option.value)
                  }
                  options={Object.entries(EblMap).map(([key, value]) => ({
                    value: key,
                    label: value,
                  }))}
                />
              </GridCol>
              <GridCol>
                <SelectField
                  label="Anesthesia"
                  name="sedation"
                  defaultValue={{
                    value: values.sedation ?? "",
                    label:
                      values?.sedation === Sedation.ModerateConscious
                        ? "Moderate (Conscious)"
                        : values.sedation,
                  }}
                  onChange={(option: Option) =>
                    setFieldValue("sedation", option.value)
                  }
                  options={Object.entries(Sedation).map(([key, value]) => ({
                    value: value,
                    label:
                      value === Sedation.ModerateConscious
                        ? "Moderate (Conscious)"
                        : value,
                  }))}
                />
              </GridCol>
              <GridCol>
                <SelectField
                  label="Platelet"
                  name="platelet"
                  defaultValue={{
                    value: values.platelet ?? "",
                    label: PlateletMap[values.platelet ?? ""],
                  }}
                  onChange={(option: Option) =>
                    setFieldValue("platelet", option.value)
                  }
                  options={Object.entries(PlateletMap).map(([key, value]) => ({
                    value: key,
                    label: value,
                  }))}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <SelectField
                  label="INR"
                  name="inr"
                  defaultValue={{
                    value: values.inr ?? "",
                    label: INRMap[values.inr ?? ""],
                  }}
                  onChange={(option: Option) =>
                    setFieldValue("inr", option.value)
                  }
                  options={Object.entries(INRMap).map(([key, value]) => ({
                    value: key,
                    label: value,
                  }))}
                />
              </GridCol>
              <GridCol>
                {values.inr &&
                  values.inr !== Inr.Lt1_3 &&
                  values.inr !== Inr.None && (
                    <SelectField
                      label="Corrective Action"
                      name="inrReversal"
                      defaultValue={{
                        value: values.inrReversal ?? "",
                        label: values.inrReversal,
                      }}
                      onChange={(option: Option) =>
                        setFieldValue("inrReversal", option.value)
                      }
                      options={Object.entries(InrReversal).map(
                        ([key, value]) => ({
                          value: value,
                          label: addSpacesToDropdownLabel(value),
                        })
                      )}
                    />
                  )}
              </GridCol>
              <GridCol></GridCol>
            </GridRow>
          </>
        )}
      </DetailsSection>
      <DetailsSection>
        <DetailsHeading
          onClick={() => setShowClinicalManager(!showClinicalManager)}
        >
          <AccordionIcon isExpanded={showClinicalManager} />
          Clinical Manager
        </DetailsHeading>
        {showClinicalManager && (
          <>
            <GridRow>
              <GridCol size={1}>
                <DatePickerField
                  formikValue
                  label="Image Follow Up"
                  id="cy-record-image-follow-up"
                  name="imageFollowup"
                  value={
                    values.imageFollowup
                      ? getLogbookOffsetDate(values.imageFollowup)
                      : undefined
                  }
                  onChange={setFieldValue}
                />
              </GridCol>
              <GridCol size={2}></GridCol>
            </GridRow>
            {values.imageFollowup && (
              <GridRow>
                <GridCol size={2}>
                  <TextField
                    name="imageFollowupNotes"
                    multiline
                    value={textFieldValues.imageFollowupNotes ?? ""}
                    onChange={(e) =>
                      setTextFieldValues({
                        ...textFieldValues,
                        imageFollowupNotes: e.currentTarget.value,
                      })
                    }
                    onBlur={handleChange}
                    label="Image Follow Up Notes"
                    type="text"
                  />
                </GridCol>
                <GridCol size={1}></GridCol>
              </GridRow>
            )}
            <GridRow>
              <GridCol size={1}>
                <DatePickerField
                  formikValue
                  id="cy-record-consult-follow-up"
                  label="Consult Follow Up"
                  name="consultFollowup"
                  value={
                    values.consultFollowup
                      ? getLogbookOffsetDate(values.consultFollowup)
                      : undefined
                  }
                  onChange={setFieldValue}
                />
              </GridCol>
              <GridCol size={2}></GridCol>
            </GridRow>
            {values.consultFollowup && (
              <GridRow>
                <GridCol size={2}>
                  <TextField
                    name="consultFollowupNotes"
                    multiline
                    value={textFieldValues.consultFollowupNotes ?? ""}
                    onChange={(e) =>
                      setTextFieldValues({
                        ...textFieldValues,
                        consultFollowupNotes: e.currentTarget.value,
                      })
                    }
                    onBlur={handleChange}
                    label="Consult Follow Up Notes"
                    type="text"
                  />
                </GridCol>
                <GridCol size={1}></GridCol>
              </GridRow>
            )}
          </>
        )}
      </DetailsSection>
    </>
  );
};

export default ClinicalDetailSection;
