import React from "react";
import {
  Table,
  Tr,
  ThPrimary,
  ThSecondary,
  ThTertiary,
  Center,
} from "./RunningVistCardComponents";
import { H2, H3, H4 } from "../../shared/typography";
import MetricGroup from "./MetricGroup";
import { compareByOperator } from "./helpers";
import { Operator } from "../../../shared/enums";
import { Result } from "./RunningVistCard";
import { BenchmarkValue, Group } from "../../../generated/graphql";

interface ResultsPageProps {
  group: Group;
  benchmarks: BenchmarkValue[];
  results: Result[];
  isCompactView: boolean;
}

export interface RelevantMetrics {
  result?: Result; // Optional because we might not find a matching result
  benchmark?: BenchmarkValue; // Optional because we might not find a matching benchmark
  completed: boolean | null; // Optional because 'null' is a valid result for completion — i.e. "couldn't compare"
}

const ResultsPage: React.FC<ResultsPageProps> = ({
  benchmarks,
  group,
  results,
  isCompactView,
}) => {
  const matchResultBenchmark = (
    metric: string,
    results: Result[],
    benchmarks: BenchmarkValue[]
  ) => {
    const result = results?.find((result) => result.internal_id === metric);

    // Find the given benchmark for the current metric in this group
    const benchmark = benchmarks.find(
      (benchmark) => benchmark.metric.internal_id === metric
    );

    const completed =
      // Explicitly check against null or undefined, as 0 (a valid benchmark / result) is also falsy
      result?.value !== null &&
      result?.value !== undefined &&
      benchmark?.value !== null &&
      benchmark?.value !== undefined
        ? compareByOperator(
            benchmark.default_operator as Operator,
            result.value,
            benchmark.value
          )
        : null;

    return {
      result: result,
      benchmark: benchmark,
      completed: completed,
    };
  };

  // Calculate metric results of all groups and subgroups
  const totalAccumulatedMetrics = (
    group: Group,
    results: Result[],
    benchmarks: BenchmarkValue[]
  ) => {
    let totalMetrics: RelevantMetrics[] = [];
    // first level, either groups or metrics exist
    if (group.metrics) {
      totalMetrics = [
        ...totalMetrics,
        ...group.metrics.map((metric) =>
          matchResultBenchmark(metric, results, benchmarks)
        ),
      ];
    }
    if (group.groups) {
      group.groups.forEach((group) => {
        // second level, both group and metrics can exist
        if (group.metrics) {
          totalMetrics = [
            ...totalMetrics,
            ...group.metrics.map((metric) =>
              matchResultBenchmark(metric, results, benchmarks)
            ),
          ];
        }
        if (group.groups) {
          group.groups.forEach((group) => {
            // third level
            if (group.metrics) {
              totalMetrics = [
                ...totalMetrics,
                ...group.metrics.map((metric) =>
                  matchResultBenchmark(metric, results, benchmarks)
                ),
              ];
            }
            if (group.groups) {
              group.groups.forEach((group) => {
                // fourth level
                if (group.metrics) {
                  totalMetrics = [
                    ...totalMetrics,
                    ...group.metrics.map((metric) =>
                      matchResultBenchmark(metric, results, benchmarks)
                    ),
                  ];
                }
              });
            }
          });
        }
      });
    }
    return totalMetrics;
  };

  const validTotalMetrics = totalAccumulatedMetrics(
    group,
    results,
    benchmarks
  ).filter((result) => result.completed !== null);

  const failedMetrics = validTotalMetrics.filter(
    (result) => result.completed === false
  );

  const completedMetrics = validTotalMetrics.filter(
    (result) => result.completed === true
  );

  const completed = failedMetrics.length === 0;

  const resultsString = `Passed ${completedMetrics.length} out of ${validTotalMetrics.length} criteria`;

  return (
    <>
      <Center>
        {completed ? (
          <>
            <H2>Succesfully met all criteria</H2>
            <H4>{resultsString}</H4>
          </>
        ) : (
          <>
            <H2>Did not meet all criteria</H2>
            <H4>{resultsString}</H4>
          </>
        )}
      </Center>
      <>
        <H3>Exercise Results</H3>
        <Table>
          <thead>
            <Tr>
              <ThPrimary depth={0}>
                <H4>Metric</H4>
              </ThPrimary>
              <ThSecondary />
              <ThTertiary style={{ textAlign: "right" }}>
                <H4>Results</H4>
              </ThTertiary>
              <ThSecondary>
                <H4>Requirement</H4>
              </ThSecondary>
              <ThTertiary />
            </Tr>
          </thead>
          <tbody>
            {group?.groups &&
              group.groups.map((group, i: number) => {
                const validTotalGroupMetric = totalAccumulatedMetrics(
                  group,
                  results,
                  benchmarks
                ).filter((result) => result.completed !== null);
                return (
                  <MetricGroup
                    key={i}
                    group={group}
                    results={results}
                    benchmarks={benchmarks}
                    depth={0}
                    isCompactView={isCompactView}
                    totalMetrics={validTotalGroupMetric}
                  />
                );
              })}
          </tbody>
        </Table>
      </>
    </>
  );
};

export default ResultsPage;
