import { CustomerGroup } from "../../generated/graphql";

export const getFullName = (
  firstName: string | undefined,
  lastName: string | undefined
) => {
  return `${firstName || ""}, ${lastName || ""}`;
};

export const getUniqueCustomerIdsFromCustomerGroups = (
  customerGroups: CustomerGroup[]
): (number | undefined)[] => {
  const uniqueCustomerIds = new Set(
    customerGroups.map((item: CustomerGroup) => item?.customer?.id)
  );
  return Array.from(uniqueCustomerIds);
};

export const addSpacesToDropdownLabel = (string: String) =>
  string.replace(/([a-z])([A-Z])/g, "$1 $2");

export const getNameFromFullName = (fullName: string, position: number) =>
  fullName.split(",")[Number(`${position}`)]?.trim();
