import { KeycloakTokenParsed } from "keycloak-js";

export type KeycloakAccessToken = KeycloakTokenParsed & {
  email?: string;
  preferred_username?: string;
  name?: string;
  given_name?: string;
  family_name?: string;
  groups?: [string]
  account?: {
    type?: string
    branches?: [
      {id: string, role: string}
    ]
  };
};

export function haveRole(user: KeycloakAccessToken | undefined, role: string): boolean {
  return user?.realm_access?.roles.some(it => it === role) || false
}

export function userId(token: KeycloakAccessToken | undefined): string {
  return token?.sub || ""
}
