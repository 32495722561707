import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Section } from "../Page/Section";
import {
  EventResourceAccessVoucher,
  SortOrder as NumericSortOrder,
} from "../../generated/graphql";
import EventCard from "./EventCard";
import { Text } from "../shared/typography";
import { useLocation } from "react-router";
import {
  ToastFormat,
  ToastType,
  SortOrder as AlphabeticSortOrder,
} from "../../shared/enums";
import { useToast } from "../../lib/useToast";
import { getVoucherCodeErrorMessage } from "../../lib/getErrorMessage";
import ActionBar, {
  SelectFieldWidthProvider,
} from "../../components/shared/ActionBar";
import SelectField from "../shared/SelectField";
import { Option } from "react-select/src/filters";
import { eventResourceAccessVouchersFields } from "../../types/fragments/EventResourceAccessVoucherFragment";

interface EventProps {
  showViewAllLink: boolean;
  showActionBar: boolean;
  maxEventsToShow?: number;
}

export const getEventEndDate = (
  eventAt: Date,
  durationInSeconds: number
): Date => {
  const shutOffTimeInSeconds = 5 * 60;
  return new Date(
    eventAt.getTime() + (durationInSeconds + shutOffTimeInSeconds) * 1000
  );
};

const Events: React.FC<EventProps> = ({
  showViewAllLink,
  maxEventsToShow,
  showActionBar,
}) => {
  const [, createToast] = useToast();
  const location = useLocation();
  const inviteCode = new URLSearchParams(location.search).get("inviteCode");
  const [showEventModal, setShowEventModal] = useState<number | undefined>(
    undefined
  );

  const sortOrderOptions = [
    {
      value: "Date (Newest to oldest)",
      label: "Date (Newest to oldest)",
      data: {
        sortOrder: NumericSortOrder.Desc,
      },
    },
    {
      value: "Date (Oldest to newest)",
      label: "Date (Oldest to newest)",
      data: {
        sortOrder: NumericSortOrder.Asc,
      },
    },
    {
      value: "Name (A-Z)",
      label: "Name (A-Z)",
      data: {
        sortOrder: AlphabeticSortOrder.AZ,
      },
    },
    {
      value: "Name (Z-A)",
      label: "Name (Z-A)",
      data: {
        sortOrder: AlphabeticSortOrder.ZA,
      },
    },
  ];

  const [selectedSortOption, setSelectedSortOption] = useState<Option>(
    sortOrderOptions[0]
  );

  const { data, error, loading } = useQuery<{
    getEventResourceAccessVouchersAsTrainee: EventResourceAccessVoucher[];
  }>(
    gql`
      query GetEvents {
        getEventResourceAccessVouchersAsTrainee {
          ...EventResourceAccessVouchersFields
        }
      }
      ${eventResourceAccessVouchersFields}
    `
  );

  const [acceptResourceAccessVoucher] = useMutation(
    gql`
      mutation AcceptResourceAccessVoucher($code: String!) {
        acceptResourceAccessVoucher(code: $code) {
          refetch {
            getEventResourceAccessVouchersAsTrainee {
              ...EventResourceAccessVouchersFields
            }
          }
        }
      }
      ${eventResourceAccessVouchersFields}
    `,
    {
      variables: { code: inviteCode },
      onCompleted() {
        createToast({
          title: "Accepted voucher and gained access to event.",
          type: ToastType.SUCCESS,
          format: ToastFormat.BANNER,
        });
      },
      onError(err) {
        createToast({
          title: getVoucherCodeErrorMessage(
            err.graphQLErrors[0].extensions?.code
          ),
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  // Trigger accept voucher request when inviteCode entered into address bar
  useEffect(() => {
    if (inviteCode && inviteCode.startsWith("cerav")) {
      acceptResourceAccessVoucher();
    }
  }, [inviteCode]); // eslint-disable-line react-hooks/exhaustive-deps

  // All event are sorted by event date by default, ongoing events before available events
  const getSortedEvents = (
    events: EventResourceAccessVoucher[],
    sortOrder: AlphabeticSortOrder | NumericSortOrder
  ) =>
    [...events].sort((event1, event2) => {
      switch (sortOrder) {
        // Oldest to newest
        case NumericSortOrder.Asc:
          return new Date(event1.eventAt) > new Date(event2.eventAt) ? 1 : -1;
        case AlphabeticSortOrder.AZ:
          return event1.name.toLowerCase() > event2.name.toLowerCase() ? 1 : -1;
        case AlphabeticSortOrder.ZA:
          return event1.name.toLowerCase() < event2.name.toLowerCase() ? 1 : -1;
        // Newest to oldest
        case NumericSortOrder.Desc:
        default:
          return new Date(event1.eventAt) < new Date(event2.eventAt) ? 1 : -1;
      }
    });

  const isOngoingDate = (start: Date, stop: Date): boolean => {
    return isPastDate(start) && !isPastDate(stop);
  };

  const isPastDate = (date: Date) => {
    const now = new Date();
    return date < now;
  };

  const ongoingEvents =
    !error &&
    data &&
    data.getEventResourceAccessVouchersAsTrainee &&
    data.getEventResourceAccessVouchersAsTrainee.length > 0 &&
    data.getEventResourceAccessVouchersAsTrainee.filter((eventVoucher) => {
      return (
        isOngoingDate(
          new Date(eventVoucher.eventAt),
          getEventEndDate(
            new Date(eventVoucher.eventAt),
            eventVoucher.durationInSeconds
          )
        ) && !eventVoucher.archivedAt
      );
    });

  const upcomingEvents =
    !error &&
    data &&
    data.getEventResourceAccessVouchersAsTrainee &&
    data.getEventResourceAccessVouchersAsTrainee.length > 0 &&
    data.getEventResourceAccessVouchersAsTrainee.filter(
      (eventVoucher) =>
        new Date(eventVoucher.eventAt) > new Date() && !eventVoucher.archivedAt
    );

  const pastEvents =
    !error &&
    data &&
    data.getEventResourceAccessVouchersAsTrainee &&
    data.getEventResourceAccessVouchersAsTrainee.length > 0 &&
    data.getEventResourceAccessVouchersAsTrainee.filter(
      (eventVoucher) =>
        isPastDate(
          getEventEndDate(
            new Date(eventVoucher.eventAt),
            eventVoucher.durationInSeconds
          )
        ) && !eventVoucher.archivedAt
    );

  const ongoingAndUpcomingEvents = [
    ...(ongoingEvents && ongoingEvents.length > 0 ? ongoingEvents : []),
    ...(upcomingEvents && upcomingEvents.length > 0 ? upcomingEvents : []),
  ];

  const shouldSeeEvents =
    (pastEvents && pastEvents.length > 0) ||
    ongoingAndUpcomingEvents.length > 0;

  const handleShowEventModal = (id: number | undefined) =>
    setShowEventModal(id);

  return (
    <>
      {showActionBar && (
        <ActionBar
          backTo="/"
          title={"Events"}
          buttonGroup={
            <>
              <SelectFieldWidthProvider>
                <SelectField
                  name="Sort by"
                  isActionBar={true}
                  onChange={(option: Option) => setSelectedSortOption(option)}
                  value={{
                    value: selectedSortOption.value,
                    label: `Sort: ${selectedSortOption.label}`,
                    data: {
                      sortOrder: selectedSortOption.data.sortOrder,
                    },
                  }}
                  options={sortOrderOptions}
                />
              </SelectFieldWidthProvider>
            </>
          }
        />
      )}
      {!error && shouldSeeEvents && maxEventsToShow ? (
        <>
          <Section
            title="Events"
            maxColumns={4}
            primaryActionText={showViewAllLink ? "View All" : undefined}
            primaryActionLink={showViewAllLink ? "/events" : undefined}
          >
            {ongoingAndUpcomingEvents.length > 0
              ? getSortedEvents(ongoingAndUpcomingEvents, NumericSortOrder.Asc)
                  .slice(0, maxEventsToShow)
                  .map((eventVoucher) => (
                    <EventCard
                      key={eventVoucher.id}
                      eventVoucher={eventVoucher}
                      onShowModal={handleShowEventModal}
                      showModal={showEventModal === eventVoucher.id}
                    ></EventCard>
                  ))
              : pastEvents &&
                getSortedEvents(pastEvents, NumericSortOrder.Desc)
                  .slice(0, maxEventsToShow)
                  .map((eventVoucher) => (
                    <EventCard
                      key={eventVoucher.id}
                      eventVoucher={eventVoucher}
                      onShowModal={handleShowEventModal}
                      showModal={showEventModal === eventVoucher.id}
                    ></EventCard>
                  ))}
          </Section>
        </>
      ) : (
        <>
          {ongoingEvents && ongoingEvents.length > 0 && (
            <Section title="Ongoing Events" maxColumns={4}>
              {getSortedEvents(
                ongoingEvents,
                selectedSortOption.data.sortOrder
              ).map((eventVoucher) => (
                <EventCard
                  key={eventVoucher.name}
                  eventVoucher={eventVoucher}
                  onShowModal={handleShowEventModal}
                  showModal={showEventModal === eventVoucher.id}
                ></EventCard>
              ))}
            </Section>
          )}
          {upcomingEvents && upcomingEvents.length > 0 && (
            <Section title="Upcoming Events" maxColumns={4}>
              {getSortedEvents(
                upcomingEvents,
                selectedSortOption.data.sortOrder
              ).map((eventVoucher) => (
                <EventCard
                  key={eventVoucher.name}
                  eventVoucher={eventVoucher}
                  onShowModal={handleShowEventModal}
                  showModal={showEventModal === eventVoucher.id}
                ></EventCard>
              ))}
            </Section>
          )}
          {pastEvents && pastEvents.length > 0 && (
            <Section title="Past Events" maxColumns={4}>
              {getSortedEvents(
                pastEvents,
                selectedSortOption.data.sortOrder
              ).map((eventVoucher) => (
                <EventCard
                  key={eventVoucher.name}
                  eventVoucher={eventVoucher}
                  onShowModal={handleShowEventModal}
                  showModal={showEventModal === eventVoucher.id}
                ></EventCard>
              ))}
            </Section>
          )}
        </>
      )}
      {loading && (
        <Section
          title="Upcoming Events"
          maxColumns={1}
          primaryActionText={showViewAllLink ? "View All" : undefined}
          primaryActionLink={showViewAllLink ? "/events" : undefined}
        >
          <Text>Loading Upcoming Events...</Text>
        </Section>
      )}
      {error &&
        error.graphQLErrors.map((error: { message: string }, i: number) => (
          <Text key={i}>{error.message}</Text>
        ))}
    </>
  );
};

export default Events;
