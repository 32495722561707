import styled from "styled-components";

// And the ingredients (title, text) are already defined by the design system
interface CardProps {
  disabled?: boolean;
}
export const Card = styled.div.attrs<CardProps>(({ disabled, onClick }) => ({
  onClick: !disabled ? onClick : undefined, // If it's disabled, remove the onClick
}))`
  height: 100%;
  width: 100%;
  min-width: 0;

  padding: 24px;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.shadows.cardShadow};
  border: solid 1px ${(props) => props.theme.colors.secondaryButtonHover};
  background-color: ${(props) => props.theme.colors.white};
  transition: box-shadow 0.25s ease-out;
  -webkit-tap-highlight-color: transparent;

  ${(props: CardProps) =>
    props.disabled &&
    `
    opacity: 0.5;
  `}

  ${(props) =>
    props.onClick &&
    `
    cursor: pointer;

    :hover {
      box-shadow: ${props.theme.shadows.cardShadowHover};
    }

    :focus {
      outline: 0;
      box-shadow: ${props.theme.shadows.cardShadowHover};
      border: solid 2px ${props.theme.colors.primary500};
    }

    :active {
      background-color: ${props.theme.colors.paleGrey};
      box-shadow: ${props.theme.shadows.cardShadowHover};
    }
  `}
`;

export const OutlinedCard = styled(Card)`
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  box-shadow: none;
`;
