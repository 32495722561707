import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  H1,
  SemiBoldText,
  StyledLink,
} from "../../components/shared/typography";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import Close from "../../components/icons/Close";
import Dialog from "../../components/shared/Dialog";
import ParticipantList, { RemoteVISTUser } from "./ParticipantList";
import LoadingIndicator from "../../components/shared/LoadingIndicator";
import { useToast } from "../../lib/useToast";
import { ButtonSizes, ToastFormat, ToastType } from "../../shared/enums";
import { useParams } from "react-router";
import SelectField from "../../components/shared/SelectField";
import { Option } from "react-select/src/filters";
import Button from "../../components/shared/Button";
import { ContextMenu } from "../../components/shared/context-menu/ContextMenu";
import { MenuItem } from "../../components/shared/context-menu/MenuItem";
import { useHistory } from "react-router";
import { gql, useQuery } from "@apollo/client";
import { EventResourceAccessVoucher } from "../../generated/graphql";
import { cloudCaseVMFields } from "../../types/fragments/CloudCaseVMFragment";
import { eventResourceAccessVouchersFields } from "../../types/fragments/EventResourceAccessVoucherFragment";

export const FullScreen = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: black;
`;

export const IFrame = styled.iframe`
  height: 100vh;
  width: 100vw;
  border: 0;
  background: black;
`;

export const FloatingButtonContainer = styled.div`
  position: fixed;
  z-index: 999;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
`;

export const FloatingButton = styled.button`
  height: 50px;
  width: 50px;
  cursor: pointer;
  border: solid 1px ${(props) => props.theme.colors.lightGrey};
  background-color: ${(props) => props.theme.colors.white};
  width: ${(props) => props.theme.sizes.spacing6};
  height: ${(props) => props.theme.sizes.spacing6};
  border-radius: ${(props) => props.theme.sizes.spacing3};
  box-shadow: ${(props) => props.theme.shadows.cardShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    border: none;
    background-color: ${(props) => props.theme.colors.paleGrey};
  }
`;

enum LocalVISTAPICallbackNames {
  ON_USERLIST_UPDATED = "onUserListUpdated",
  ON_GAINED_CONTROL = "onGainedControl",
  ON_LOST_CONTROL = "onLostControl",
  ON_WEBSOCKET_CLOSED = "onWebsocketClosed",
  ON_CASE_LIST_UPDATED = "onCaseListUpdated",
  ON_SHUTDOWN = "onShutdown",
  ON_SESSION_NOTICE_TIME = "onSessionNoticeTime",
  ON_REFRESH_TOKEN_REQUEST = "onRefreshTokenRequest",
}

type LocalVISTAPICallbacks = {
  [LocalVISTAPICallbackNames.ON_USERLIST_UPDATED]: (userlist: any) => void;
  [LocalVISTAPICallbackNames.ON_GAINED_CONTROL]: () => void;
  [LocalVISTAPICallbackNames.ON_LOST_CONTROL]: () => void;
  [LocalVISTAPICallbackNames.ON_WEBSOCKET_CLOSED]: (paramList: any) => void;
  [LocalVISTAPICallbackNames.ON_CASE_LIST_UPDATED]: (
    caseList: string[]
  ) => void;
  [LocalVISTAPICallbackNames.ON_SHUTDOWN]: (msg: string) => void;
  [LocalVISTAPICallbackNames.ON_SESSION_NOTICE_TIME]: () => void;
  [LocalVISTAPICallbackNames.ON_REFRESH_TOKEN_REQUEST]: () => void;
};

type LocalVISTAPI = {
  takeControl: () => void;
  giveControl: (userId: string) => void;
  kickUser: (userId: string) => void;
  setDebugUIVisibility: (isVisible: boolean) => void;
  startCase: ({
    moduleId,
    caseId,
  }: {
    moduleId: string;
    caseId: string;
  }) => void;
  shutdown: (msg: string) => void;
  setSessionNoticeTime: (t: number) => void;
};

const CloudEvent = () => {
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const [userList, setUserList] = useState<RemoteVISTUser[]>([]);
  const [showParticipantDialog, setShowParticipantDialog] = useState(false);
  const [showCaseDialog, setShowCaseDialog] = useState(false);
  const [iframeWindow, setIFrameWindow] = useState<Window | undefined>(
    undefined
  );
  const iframeRef = useCallback((iframe: HTMLIFrameElement | null) => {
    if (iframe?.contentWindow) {
      setIFrameWindow(iframe.contentWindow);
    }
  }, []);
  const [, createToast] = useToast();
  const { voucherId } = useParams<{ voucherId: string }>();
  const history = useHistory();

  const { data } = useQuery<{
    getEventResourceAccessVoucherById: EventResourceAccessVoucher;
  }>(
    gql`
      query GetEventResourceAccessVoucherById($voucherId: Int!) {
        getEventResourceAccessVoucherById(voucherId: $voucherId) {
          ...EventResourceAccessVouchersFields
          vm {
            ...CloudCaseVMFields
          }
        }
      }
      ${eventResourceAccessVouchersFields}
      ${cloudCaseVMFields}
    `,
    {
      variables: {
        voucherId: Number(voucherId),
      },
    }
  );

  const caseOptions = data?.getEventResourceAccessVoucherById?.modules.flatMap(
    (module) =>
      module.cases.map((cloudCase) => ({
        value: `${module.name}: ${cloudCase.name}`,
        label: `${module.name}: ${cloudCase.name}`,
        data: {
          moduleId: module.moduleId,
          caseId: cloudCase.caseId,
        },
      }))
  );

  const [selectedCase, setSelectedCase] = useState(caseOptions?.[0]);

  const localVISTAPI: LocalVISTAPI = {
    takeControl: () => {
      iframeWindow?.postMessage(
        JSON.stringify({
          function: "takeControl",
          param: "",
        }),
        "*"
      );
    },
    giveControl: (userId) => {
      iframeWindow?.postMessage(
        JSON.stringify({
          function: "giveControl",
          param: userId,
        }),
        "*"
      );
    },
    kickUser: (userId) => {
      iframeWindow?.postMessage(
        JSON.stringify({
          function: "kickUser",
          param: userId,
        }),
        "*"
      );
    },
    setDebugUIVisibility: (visible) => {
      iframeWindow?.postMessage(
        JSON.stringify({
          function: "setDebugUIVisible",
          param: visible,
        }),
        "*"
      );
    },
    startCase: (caseName) => {
      iframeWindow?.postMessage(
        JSON.stringify({ function: "startCase", param: caseName }),
        "*"
      );
    },
    shutdown: (msg) => {
      iframeWindow?.postMessage(
        JSON.stringify({ function: "shutdown", param: msg }),
        "*"
      );
    },
    setSessionNoticeTime: (t) => {
      iframeWindow?.postMessage(
        JSON.stringify({ function: "setSessionNoticeTime", param: t }),
        "*"
      );
    },
  };

  useEffect(() => {
    localVISTAPI.setDebugUIVisibility(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeWindow]);

  useEffect(() => {
    const callbacks: LocalVISTAPICallbacks = {
      onUserListUpdated: (userList) => {
        setUserList(userList);
      },
      onGainedControl: () => {
        createToast({
          title: "You are in control.",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onLostControl: () => {
        createToast({
          title: "You no longer have control.",
          type: ToastType.ERROR,
          format: ToastFormat.TOAST,
        });
      },
      onWebsocketClosed: (paramObject: { code: number; reason: string }) => {
        /* TODO: Replace with onShutdown depending on parameter code */
        console.log("Websocket Closed:", paramObject);
        createToast({
          title: "Connection lost, trying to reconnect…",
          type: ToastType.ERROR,
          format: ToastFormat.TOAST,
        });
      },
      onSessionNoticeTime: () => {
        createToast({
          title: "Event will end automatically in",
          type: ToastType.SUCCESS,
          format: ToastFormat.COUNTDOWN,
          timeRemaining: 5 * 60,
        });
      },
      onShutdown: (msg) => {
        history.push(`/`);
        msg.includes(
          "No one joined session" &&
            "Event ended by time limit" &&
            "No one in session"
        )
          ? createToast({
              title: "Event has been ended by time limit.",
              type: ToastType.SUCCESS,
              format: ToastFormat.TOAST,
            })
          : createToast({
              title: "Event has been ended by organizer.",
              type: ToastType.SUCCESS,
              format: ToastFormat.TOAST,
            });
      },
      onCaseListUpdated: (caseList) => {
        console.log("Case List Updated:", caseList);
      },
      onRefreshTokenRequest: () => {
        console.log("Received request for new token");
        keycloak
          .updateToken(30)
          .then((refreshed) => {
            if (refreshed) {
              console.log("Refreshed token");
            } else {
              console.log("Current token still valid");
            }
            if (keycloak.token !== undefined) {
              iframeWindow?.postMessage(
                JSON.stringify({
                  function: "updateToken",
                  param: keycloak.token,
                }),
                "*"
              );
            } else {
              console.error("Updated to an undefined token");
            }
          })
          .catch((err) => {
            console.error("Failed to refresh token:", err);
          });
      },
    };

    const messageEventListenerCallback = (event: any) => {
      try {
        const data: { function: LocalVISTAPICallbackNames; param: any } =
          JSON.parse(event.data);
        if (data.function && callbacks.hasOwnProperty(data.function)) {
          callbacks[data.function](data.param);
        }
      } catch {}
    };
    window.addEventListener("message", messageEventListenerCallback, false);
    return () =>
      window.removeEventListener("message", messageEventListenerCallback);
  }, [iframeWindow, createToast, history, keycloak]);

  const isOrganizer =
    user &&
    data?.getEventResourceAccessVoucherById?.organizers &&
    data?.getEventResourceAccessVoucherById?.organizers.some(
      (organizer) => organizer?.email === user.email
    );

  const isParticipant =
    data?.getEventResourceAccessVoucherById?.users &&
    data?.getEventResourceAccessVoucherById?.users?.some(
      (participant) => participant.email === user?.email
    );

  const [invariantKeycloakToken, initializeInvariantKeycloakToken] = useState<
    string | undefined
  >(undefined);
  useEffect(() => {
    if (invariantKeycloakToken === undefined && keycloak.token !== undefined) {
      initializeInvariantKeycloakToken(keycloak.token);
    }
  }, [keycloak.token, invariantKeycloakToken]);

  const memoizedIFrameOnLoad = useCallback(() => {
    iframeWindow?.focus();
  }, [iframeWindow]);

  const memoizedIFrameSrc = useMemo(() => {
    let baseUrl = data?.getEventResourceAccessVoucherById?.vm?.url?.endsWith(
      "/"
    )
      ? data?.getEventResourceAccessVoucherById?.vm?.url
      : data?.getEventResourceAccessVoucherById?.vm?.url + "/";
    return `${baseUrl}${isOrganizer ? "proctor" : "viewer"}/?name=${
      user?.name
    }&userid=${user?.email}&access_token=${invariantKeycloakToken}`;
  }, [data, isOrganizer, user?.name, user?.email, invariantKeycloakToken]);

  const isReadyToStartLoadingIFrame =
    typeof data?.getEventResourceAccessVoucherById?.vm?.url === "string" &&
    typeof isOrganizer === "boolean" &&
    typeof user?.name === "string" &&
    typeof user?.email === "string" &&
    typeof invariantKeycloakToken === "string";

  if (
    typeof isOrganizer !== "boolean" ||
    typeof isParticipant !== "boolean" ||
    !isReadyToStartLoadingIFrame
  ) {
    return <LoadingIndicator fullHeight />;
  } else if (!isOrganizer && !isParticipant) {
    return <H1>Permisson denied</H1>;
  } else {
    return (
      <FullScreen>
        <meta name="viewport" content="viewport-fit=cover"></meta>
        <FloatingButtonContainer>
          <StyledLink to="/">
            <FloatingButton>
              <Close />
            </FloatingButton>
          </StyledLink>
          {isOrganizer && (
            <>
              <FloatingButton style={{ marginTop: "16px" }}>
                <ContextMenu isOnCloudSim={true}>
                  <MenuItem onClick={() => setShowParticipantDialog(true)}>
                    View participants
                  </MenuItem>
                  <MenuItem onClick={() => setShowCaseDialog(true)}>
                    Change case
                  </MenuItem>
                </ContextMenu>
              </FloatingButton>
            </>
          )}
        </FloatingButtonContainer>
        {showParticipantDialog && (
          <Dialog
            onClose={() => {
              setShowParticipantDialog(false);
              iframeWindow?.focus();
            }}
            title={"Team"}
          >
            {data?.getEventResourceAccessVoucherById?.users && (
              <ParticipantList
                title="Proctors"
                participants={userList.filter(
                  (participant) => participant.role === "proctor"
                )}
                onActionClick={localVISTAPI.giveControl}
              />
            )}
            {userList && (
              <ParticipantList
                title="Trainees"
                participants={userList.filter(
                  (participant) => participant.role === "viewer"
                )}
                onActionClick={localVISTAPI.giveControl}
              />
            )}
          </Dialog>
        )}
        {showCaseDialog && (
          <Dialog
            onClose={() => {
              setShowCaseDialog(false);
              iframeWindow?.focus();
            }}
            title={"Choose case"}
            buttons={
              <Button
                size={ButtonSizes.Medium}
                onClick={() => {
                  localVISTAPI.startCase({
                    moduleId: selectedCase?.data.moduleId ?? "",
                    caseId: selectedCase?.data.caseId ?? "",
                  });
                  setShowCaseDialog(false);
                  iframeWindow?.focus();
                }}
              >
                Start
              </Button>
            }
          >
            <SemiBoldText>Select case to start</SemiBoldText>
            <SelectField
              onChange={(option: Option) => setSelectedCase(option)}
              value={selectedCase}
              options={caseOptions}
            />
          </Dialog>
        )}
        {isReadyToStartLoadingIFrame && (
          <MemoizedIFrame
            src={memoizedIFrameSrc}
            onLoad={memoizedIFrameOnLoad}
            refFromParent={iframeRef}
          />
        )}
      </FullScreen>
    );
  }
};

const MemoizedIFrame = memo<{
  src: string;
  onLoad: () => void;
  refFromParent: (iframe: HTMLIFrameElement) => void;
}>(({ src, onLoad, refFromParent }) => {
  return (
    <IFrame
      onLoad={onLoad}
      ref={refFromParent}
      src={src}
      title="Cloud simulation"
      allowFullScreen
      id="vistFrame"
    />
  );
});

export default CloudEvent;
