import React from "react";
import styled from "styled-components";
import { H4 } from "../shared/typography";
import { Card } from "../shared/Card";
import Play from "../icons/Play";

const VideoWrapper = styled.a`
  color: unset;
  text-decoration: none;
  height: 100%;
`;

const VideoCard = styled(Card)`
  padding-bottom: 12px;
  overflow: hidden;

  :hover {
    box-shadow: ${(props) => props.theme.shadows.cardShadowHover};
  }
`;

const Thumbnail = styled.div`
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
`;

const ThumbnailPicture = styled.img`
  margin: -11% 0;
  width: 100%;
`;

interface YoutubeVideoProps {
  title: string;
  id: string;
  thumbnail: string;
  setSelectedVideo: (id: string) => void;
}

// YouTube video "card"
export const YoutubeVideo: React.FC<YoutubeVideoProps> = ({
  title,
  id,
  thumbnail,
  setSelectedVideo,
}) => {
  return (
    <div
      className="videoCard"
      style={{ cursor: "pointer" }}
      onClick={() => setSelectedVideo(id)}
    >
      <VideoWrapper>
        <VideoCard>
          <Thumbnail>
            <Play centeredInParent />
            <ThumbnailPicture src={thumbnail} />
          </Thumbnail>
          <H4>{title}</H4>
        </VideoCard>
      </VideoWrapper>
    </div>
  );
};
