import React from "react";
import styled from "styled-components";
import TextField from "./TextField";

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  input {
    margin: 0;
    color: ${(props) => props.theme.colors.grey};
    font-size: 14px;
    ${(props: { compact?: boolean }) =>
      props.compact ? "height: 28px" : "width: 70%"}
  }
`;

const CopyButton = styled.button`
  top: 4px;
  background: none;
  border: none;
  display: inline;
  height: 32px;
  padding: 0;
  margin: 0;

  :hover {
    cursor: pointer;
  }

  img {
    float: right;
    height: 20px;
    width: 20px;
  }
`;

interface CopyFieldProps {
  value: string;
  onCopyClick: () => void;
  disabled?: boolean;
  compact?: boolean;
}

const CopyField: React.FC<CopyFieldProps> = ({
  onCopyClick,
  value,
  disabled = true,
  compact,
}) => {
  return (
    <InputContainer compact={compact}>
      <TextField readOnly={true} value={value} onChange={() => undefined} />
      <CopyButton type="button" title="Copy Invite Link" onClick={onCopyClick}>
        <img src="/static/assets/copy.svg" alt="Copy Invite Link"></img>
      </CopyButton>
    </InputContainer>
  );
};

export default CopyField;
