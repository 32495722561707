import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import {
  spanStyle,
  semiBoldTextStyle,
} from "../../components/shared/typography";
import {
  ProcedureActivity,
  ProcedureActivitySortField,
  SortOrder,
} from "../../generated/graphql";
import { ContextMenu } from "../../components/shared/context-menu/ContextMenu";
import { MenuItem } from "../../components/shared/context-menu/MenuItem";
import { useHistory, useRouteMatch } from "react-router";
import { theme as menticeTheme } from "../../lib/theme";
import Loading from "../../components/icons/Loading";
import { getFullName } from "./helpers";
import {
  getLogbookOffsetDate,
  formatDatetoLocaleDateString,
} from "../../lib/formatDateAndTime";

interface TableProps {
  condensed?: boolean;
}

interface TableDataProps {
  centered?: boolean;
  noWrap?: boolean;
}

const Table = styled.table<TableProps>`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  min-width: ${(props) => props.theme.sizes.spacing80};
  position: relative;
`;

const TableHead = styled.thead`
  width: 100%;
  display: table-header-group;
  white-space: nowrap;
`;

const TableHeader = styled.th<TableProps>`
  ${semiBoldTextStyle}
  color: ${(props) => props.theme.colors.darkdarkGrey};
  text-align: left;
  padding: 12px;
  cursor: ${(props) => (props.condensed ? "default" : "pointer")};
`;

const SortableHeader = styled.div`
  display: flex;
  img {
    margin-left: 4px;
    margin-top: 2px;
    ${(props: { active: boolean }) => !props.active && "filter: opacity(30%);"}
  }
`;

const TableRow = styled.tr<TableProps>`
  td:first-of-type,
  th:first-of-type {
    padding-left: ${(props) => (props.condensed ? "40px" : "30px")};
  }
  td:last-of-type,
  th:last-of-type {
    padding-right: ${(props) => (props.condensed ? "40px" : "30px")};
  }
`;

const TableDataRow = styled(TableRow)`
  cursor: pointer;
  box-shadow: inset 0 1px 0 0 ${(props) => props.theme.colors.paleGrey};
  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colors.superLightGrey};
  }
  &:last-of-type {
    box-shadow: inset 0 1px 0 0 ${(props) => props.theme.colors.paleGrey},
      inset 0 -1px 0 0 ${(props) => props.theme.colors.paleGrey};
  }
`;

const TableData = styled.td`
  ${spanStyle}
  color: ${(props) => props.theme.colors.darkdarkGrey};
  padding: 20px 12px;
  min-width: ${(props) => props.theme.sizes.spacing30};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: ${(props: TableDataProps) =>
    props.centered ? "center" : "left"};
  ${(props) => props.noWrap && "white-space: nowrap;"}
`;

const EmptyTable = styled.div<TableProps>`
  ${spanStyle}
  color: ${(props) => props.theme.colors.midGrey};
  padding: 96px 30px 30px;
  height: ${(props) =>
    props.condensed
      ? props.theme.sizes.spacing32
      : props.theme.sizes.spacing80};
  display: flex;
  justify-content: center;
`;

const LoadingTable = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(20px + 24px);
  left: 0;
  width: 100%;
  height: calc(100% - 20px - 24px);
  background-color: rgba(255, 255, 255, 0.5);
`;
const StickyLoader = styled.div`
  position: fixed;
  top: 50%;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    left: calc(50% - 32px - 20px);
  }
`;
interface RecordTableProps {
  tableData?: ProcedureActivity[];
  condensed?: boolean;
  onColumnClick?: (value: ProcedureActivitySortField) => void;
  onRecordDelete?: (id: string) => void;
  sortOrder?: SortOrder;
  sortColumn?: ProcedureActivitySortField;
  loading?: boolean;
}

const RecordTable: React.FC<RecordTableProps> = ({
  tableData,
  condensed = false,
  onColumnClick,
  onRecordDelete,
  sortOrder,
  sortColumn,
  loading,
}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const isMobile = () =>
    window.innerWidth < parseInt(menticeTheme.sizes.breakpointL) ? true : false;
  const [mobile, setMobile] = useState(isMobile());

  const handleRowClick = (
    id: string,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.currentTarget !== event.target) return;
    condensed
      ? history.push(`${match.path}/records/${id}`)
      : history.push(`${match.path}/${id}`);
  };

  const handleResize = () => {
    if (isMobile()) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const columns = [
    { label: "Date", value: ProcedureActivitySortField.DateOfProcedure },
    {
      label: "Optional Identifier",
      value: ProcedureActivitySortField.OptionalIdentifier,
    },
    {
      label: "Hospital",
      value: ProcedureActivitySortField.Hospital,
    },
    {
      label: "Attending",
      value: ProcedureActivitySortField.Attending,
    },
    {
      label: "Participant",
      value: ProcedureActivitySortField.Participants,
    },
    {
      label: "Procedure Name",
      value: ProcedureActivitySortField.ProcedureName,
    },
  ];

  const mobileColumns = [
    { label: "Date", value: ProcedureActivitySortField.DateOfProcedure },

    {
      label: "Attending",
      value: ProcedureActivitySortField.Attending,
    },
    {
      label: "Procedure Name",
      value: ProcedureActivitySortField.ProcedureName,
    },
  ];

  const getColumns = () => (mobile ? mobileColumns : columns);

  return (
    <>
      <Table condensed={condensed}>
        <TableHead>
          <TableRow condensed={condensed}>
            {!condensed && !mobile && (
              <TableHeader style={{ width: "15%" }}> </TableHeader>
            )}
            {getColumns().map((item) => (
              <TableHeader
                condensed={condensed}
                style={
                  item.value === ProcedureActivitySortField.DateOfProcedure ||
                  item.value === ProcedureActivitySortField.Attending ||
                  item.value === ProcedureActivitySortField.Participants
                    ? { width: "25%" }
                    : item.value === ProcedureActivitySortField.ProcedureName
                    ? { width: "50%" }
                    : { width: "35%" }
                }
                key={item.value}
                onClick={() => onColumnClick && onColumnClick(item.value)}
              >
                {condensed ? (
                  item.label
                ) : (
                  <SortableHeader active={sortColumn === item.value}>
                    {item.label}
                    {sortColumn === item.value &&
                    sortOrder === SortOrder.Asc ? (
                      <img
                        src="/static/assets/sort-asc.svg"
                        alt="Sort Ascending"
                      ></img>
                    ) : (
                      <img
                        src="/static/assets/sort-desc.svg"
                        alt="Sort Descending"
                      ></img>
                    )}
                  </SortableHeader>
                )}
              </TableHeader>
            ))}
            {!condensed && (
              <TableHeader style={{ width: "20%" }}>Action</TableHeader>
            )}
          </TableRow>
        </TableHead>
        <tbody id="cy-record-table">
          {tableData?.map((row: ProcedureActivity) => {
            const {
              id,
              dateOfProcedure,
              optionalIdentifier,
              hospital,
              attending,
              participants,
              procedure,
            } = row;
            const participant = participants && participants[0];
            return (
              <TableDataRow
                key={id}
                onClick={(
                  event: React.MouseEvent<HTMLDivElement, MouseEvent>
                ) => handleRowClick(id, event)}
                condensed={condensed}
              >
                {!condensed && !mobile && (
                  <TableData
                    onClick={(
                      event: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => handleRowClick(id, event)}
                  >
                    {/*  TODO: Delete multiple records
                    <Checkbox
                      checkboxChecked={false}
                      onCheckboxClick={() => undefined}
                      small
                    /> */}
                  </TableData>
                )}
                <TableData
                  noWrap
                  onClick={(
                    event: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => handleRowClick(id, event)}
                >
                  {dateOfProcedure
                    ? formatDatetoLocaleDateString(
                        getLogbookOffsetDate(dateOfProcedure)
                      )
                    : undefined}
                </TableData>
                {!mobile && (
                  <TableData
                    onClick={(
                      event: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => handleRowClick(id, event)}
                  >
                    {optionalIdentifier}
                  </TableData>
                )}
                {!mobile && (
                  <TableData
                    onClick={(
                      event: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => handleRowClick(id, event)}
                  >
                    {hospital}
                  </TableData>
                )}
                <TableData
                  onClick={(
                    event: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => handleRowClick(id, event)}
                >
                  {attending &&
                    getFullName(attending?.lastName, attending?.firstName)}
                </TableData>
                {!mobile && (
                  <TableData
                    onClick={(
                      event: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => handleRowClick(id, event)}
                  >
                    {`${
                      participant?.user?.last_name
                        ? participant?.user?.last_name + ","
                        : ""
                    } ${participant?.user?.first_name ?? ""}`}
                    {participants &&
                      participants?.length > 1 &&
                      ` + ${participants?.length - 1}`}
                  </TableData>
                )}
                <TableData
                  onClick={(
                    event: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => handleRowClick(id, event)}
                >
                  {procedure?.name}
                </TableData>
                {!condensed && (
                  <TableData
                    centered
                    style={{ position: "relative", overflow: "visible" }}
                    onClick={(
                      event: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => handleRowClick(id, event)}
                  >
                    <ContextMenu recordRelativePosition>
                      <MenuItem
                        onClick={() => {
                          onRecordDelete && onRecordDelete(id);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </ContextMenu>
                  </TableData>
                )}
              </TableDataRow>
            );
          })}
          {tableData && loading && (
            <tr>
              <td>
                <LoadingTable>
                  <StickyLoader>
                    <Loading />
                  </StickyLoader>
                </LoadingTable>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {!tableData &&
        (loading ? (
          <EmptyTable>
            <Loading></Loading>
          </EmptyTable>
        ) : (
          <EmptyTable condensed={condensed}>
            {condensed
              ? "No recent records found"
              : "No records found, try clearing the filters or reloading the page"}
          </EmptyTable>
        ))}
    </>
  );
};

export default RecordTable;
