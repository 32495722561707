import { gql, useMutation } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import {
  CardDivider,
  CardText,
  UserListRow,
} from "../../components/Groups/GroupCard";
import UserAvatar from "../../components/icons/UserAvatar";
import { Card } from "../../components/shared/Card";
import { ContextMenu } from "../../components/shared/context-menu/ContextMenu";
import { MenuItem } from "../../components/shared/context-menu/MenuItem";
import CopyField from "../../components/shared/CopyField";
import {
  H4NoWrap,
  SemiBoldText,
  Span,
  H6,
  SpanNoWrap,
  SemiBoldTextNoWrap,
  Text,
} from "../../components/shared/typography";
import { CloudModulesResourceAccessVoucher } from "../../generated/graphql";
import { convertBase64ToImage, getDefaultLogo } from "../../lib/convertBase64";
import { copyToClipboard } from "../../lib/copyToClipboard";
import {
  formatDateShortMonth,
  getFormattedDurationStringFromSeconds,
} from "../../lib/formatDateAndTime";
import { useToast } from "../../lib/useToast";
import { ToastFormat, ToastType } from "../../shared/enums";
import { cloudModulesResourceAccessVouchersFields } from "../../types/fragments/CloudModulesResourceAccessVouchersFragment";

export const ResourceAccessVoucherCardWrapper = styled(Card)`
  height: auto;
  margin-top: ${(p) => p.theme.sizes.spacing2};
`;

export const LearningSpaceLogo = styled.img`
  height: auto;
  width: 56px;
  grid-row: 1 / 3;
`;

export const TopRowGrid = styled.div<{ middleAlignCreated: boolean }>`
  display: grid;
  grid-template-rows: 24px 16px;
  grid-template-columns: 56px 3fr 2fr 40px 40px;
  grid-column-gap: 16px;
  grid-auto-flow: column;
  margin-bottom: 32px;
  align-items: center;

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    grid-template-columns: 56px 1fr 40px;
  }

  > :nth-child(4) {
    /* createdAt */
    grid-row: ${(props) => (props.middleAlignCreated ? "1/3" : "1")};
  }
  > :nth-last-child(2) {
    /* Admin user avatar */
    grid-row: 1/3;
  }
  > :nth-last-child(1) {
    /* Context menu */
    grid-row: 1/3;
  }
`;

export const CreatedModifiedDate = styled(Span)`
  text-align: right;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    display: none;
  }
`;

export const MiddleRowGrid = styled.div`
  display: grid;
  grid-template-rows: 24px 64px;
  grid-template-columns: 1fr 1px 1fr;
  grid-column-gap: 16px;
  margin: 16px 0 0 0;
  grid-auto-flow: column;
  > :nth-child(2) {
    /* Copy invite link row */
    margin-top: 8px;
  }
  > :nth-child(3) {
    grid-row: 1/3;
  }
  > :nth-child(5) {
    /* User avatars */
    margin-bottom: 8px;
  }

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    grid-template-columns: 1fr;
    grid-template-rows: 24px 64px 24px 64px;
  }
`;

export const BottomRowGrid = styled.div`
  display: grid;
  grid-template-rows: 24px auto;
  grid-template-columns: 50fr 35fr 15fr;
  grid-column-gap: 16px;
  margin: 16px 0 0 0;
  grid-auto-flow: column;

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }
`;

export const VR = styled.div`
  width: 1px;
  background-color: #ebf0f5;

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    display: none;
  }
`;

const CloudModuleResourceAccessVoucherCard: React.FC<{
  voucher: CloudModulesResourceAccessVoucher;
  setIsEditingVoucher: () => void;
  editable: boolean;
  archivable: boolean;
}> = ({ voucher, setIsEditingVoucher, editable, archivable }) => {
  const [, createToast] = useToast();

  const [archiveResourceAccessVoucher] = useMutation(
    gql`
      mutation ArchiveResourceAccessVoucher($resourceAccessVoucherId: Int!) {
        archiveResourceAccessVoucher(
          resourceAccessVoucherId: $resourceAccessVoucherId
        ) {
          refetch {
            getAllCloudModulesResourceAccessVouchersAsAdmin {
              ...CloudModulesResourceAccessVouchersFields
            }
          }
        }
      }
      ${cloudModulesResourceAccessVouchersFields}
    `,
    {
      variables: {
        resourceAccessVoucherId: voucher.id,
      },
      onCompleted() {
        createToast({
          title: `Successfully archived cloud case voucher.`,
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError() {
        createToast({
          title: "Failed to archive voucher. Please try again later.",
          type: ToastType.ERROR,
          format: ToastFormat.TOAST,
        });
      },
    }
  );

  return (
    <ResourceAccessVoucherCardWrapper>
      <TopRowGrid middleAlignCreated={voucher.createdAt === voucher.modifiedAt}>
        <LearningSpaceLogo
          src={
            voucher.learningSpace.image
              ? convertBase64ToImage(voucher.learningSpace.image)
              : getDefaultLogo()
          }
          alt={voucher.learningSpace.name + " logo"}
        />
        <H4NoWrap>{`${voucher.learningSpace.name} (${voucher.customer.name})`}</H4NoWrap>
        <SpanNoWrap>Cloud Case Voucher</SpanNoWrap>
        <CreatedModifiedDate>
          {`Created: ${formatDateShortMonth(new Date(voucher.createdAt))}`}
        </CreatedModifiedDate>
        {voucher.modifiedAt !== voucher.createdAt && (
          <CreatedModifiedDate>
            {`Modified: ${formatDateShortMonth(new Date(voucher.modifiedAt))}`}
          </CreatedModifiedDate>
        )}
        <UserAvatar
          tooltip
          isAdmin={true}
          firstName={voucher.creator?.first_name ?? ""}
          lastName={voucher.creator?.last_name ?? ""}
        />
        {editable || archivable ? (
          <ContextMenu>
            {editable && (
              <MenuItem onClick={setIsEditingVoucher}>Edit voucher</MenuItem>
            )}
            {archivable && (
              <MenuItem onClick={archiveResourceAccessVoucher}>
                Archive voucher
              </MenuItem>
            )}
          </ContextMenu>
        ) : (
          <div />
        )}
      </TopRowGrid>
      <CardDivider />
      <MiddleRowGrid>
        <SemiBoldTextNoWrap>
          {`Invitation Link (Expires ${formatDateShortMonth(
            new Date(voucher.validTo)
          )})`}
        </SemiBoldTextNoWrap>
        <CopyField
          compact={true}
          value={`${window.location.origin}/?inviteCode=${voucher.code}`}
          onCopyClick={async () =>
            voucher.code &&
            (await copyToClipboard(
              `${window.location.origin}/?inviteCode=${voucher.code}`
            ))
              ? createToast({
                  title: "Invite link copied to clipboard",
                  type: ToastType.SUCCESS,
                  format: ToastFormat.TOAST,
                })
              : createToast({
                  title: "Failed to copy invite link, please try again later",
                  type: ToastType.ERROR,
                  format: ToastFormat.TOAST,
                })
          }
        />
        <VR />
        <SemiBoldText>
          {`Users ${
            voucher.maxUses ? `(${voucher.used} / ${voucher.maxUses})` : ""
          } - Time per user: ${
            Math.round(voucher.maxDurationInSeconds / 3600)
          } h`}
        </SemiBoldText>
        
        <UserListRow>
          {[...voucher.userQuota].slice(0, 9).map((userQuotum, i) => (
            <UserAvatar
              isAdmin={false}
              key={`${userQuotum.user.keycloak_id}${i}`}
              tooltip={`${userQuotum.user.first_name} ${
                userQuotum.user.last_name
              } (${
                userQuotum.durationLeft > 0
                  ? getFormattedDurationStringFromSeconds(
                      userQuotum.durationLeft
                    )
                  : "0m"
              } remaining)`}
              firstName={userQuotum.user.first_name}
              lastName={userQuotum.user.last_name}
            />
          ))}
          {voucher.userQuota.length - 9 > 0 && (
            <CardText style={{ marginBottom: "9px", marginLeft: "16px" }}>
              +{voucher.userQuota.length - 9}
            </CardText>
          )}
        </UserListRow>
      </MiddleRowGrid>
      <CardDivider />
      <BottomRowGrid style={{ display: "flow" }}>
        <H6 style={{ gridColumn: "1/4" }}>Selected Cases</H6>
        {(() => {
          const filteredModules = voucher.modules.filter(module => 
            module.name.toLowerCase().includes(voucher.customer.name.toLowerCase())
          );

          if (filteredModules.length === 0) {
            return <Text>No cases selected</Text>;
          }

          return filteredModules.map((module, i) => (
            <div key={`${module.moduleId}${i}`}>
              <SemiBoldText>{module.name}</SemiBoldText>
              {module.cases.length > 0 ? (
                module.cases.map((singleCase, j) => (
                  <Text key={`${singleCase.caseId}${i}${j}`}>
                    {singleCase.name}
                  </Text>
                ))
              ) : (
                <Text>No cases selected</Text>
              )}
            </div>
          ));
        })()}
      </BottomRowGrid>
    </ResourceAccessVoucherCardWrapper>
  );
};

export default CloudModuleResourceAccessVoucherCard;
