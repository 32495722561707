import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import Dialog from "../../components/shared/Dialog";
import LoadingIndicator, {
  renderLoadingText,
} from "../../components/shared/LoadingIndicator";
import SelectField from "../../components/shared/SelectField";
import {
  CloudModule,
  CloudModuleInput,
  CloudModulesResourceAccessVoucher,
  CreateCloudModulesResourceAccessVoucherInput,
  EditCloudModulesResourceAccessVoucherInput,
  Customer,
  CustomerLearningSpaceAccess,
  TalentBranch,
} from "../../generated/graphql";
import { customerFields } from "./Customers";
import { Text } from "../../components/shared/typography";
import DatePickerField from "../../components/shared/DatePickerField";
import TextField, { LabelText } from "../../components/shared/TextField";
import styled from "styled-components";
import Checkbox from "../../components/shared/Checkbox";
import { useToast } from "../../lib/useToast";
import { ToastFormat, ToastType } from "../../shared/enums";
import { cloudModulesResourceAccessVouchersFields } from "../../types/fragments/CloudModulesResourceAccessVouchersFragment";

export const HorizontalRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.sizes.spacing4};
`;

const VerticalRow = styled.div`
  display: grid;
  grid-row-gap: ${(p) => p.theme.sizes.spacing2};
  margin-top: ${(p) => p.theme.sizes.spacing4};
`;

interface Props {
  onClose: () => void;
  editingVoucher?: CloudModulesResourceAccessVoucher;
}

export interface CustomerOption {
  value: number;
  label: string;
  data: { customer: Customer };
}

export interface LearningSpaceOption {
  value: number;
  label: string;
  data: { branch: TalentBranch };
}

const CreateOrEditCloudModulesResourceAccessVoucherDialog: React.FC<Props> = ({
  onClose,
  editingVoucher,
}) => {
  const [, createToast] = useToast();

  const [selectedCustomer, setSelectedCustomer] = useState<
    Customer | undefined
  >(editingVoucher ? editingVoucher.customer : undefined);
  const [selectedLearningSpace, setSelectedLearningSpace] = useState<
    TalentBranch | undefined
  >(editingVoucher ? editingVoucher.learningSpace : undefined);
  const [selectedValidTo, setSelectedValidTo] = useState<Date | undefined>(
    editingVoucher ? new Date(editingVoucher.validTo) : undefined
  );
  const [selectedMaxDurationInHours, setSelectedMaxDurationInHours] =
    useState<number>(
      editingVoucher
        ? Math.floor(editingVoucher.maxDurationInSeconds / 3600)
        : 1
    );
  const [selectedMaxUses, setSelectedMaxUses] = useState<number | undefined>(
    editingVoucher && editingVoucher.maxUses
      ? editingVoucher.maxUses
      : undefined
  );
  const [availableCloudModules, setAvailableCloudModules] = useState<
    CloudModule[]
  >([]);
  const [selectedCloudModuleInputs, setSelectedCloudModuleInputs] = useState<
    CloudModuleInput[]
  >(
    editingVoucher
      ? editingVoucher.modules.map((cloudModule) => ({
          moduleId: cloudModule.moduleId,
          caseIds: cloudModule.cases.map((cloudCase) => cloudCase.caseId),
        }))
      : []
  );

  type inputError =
    | "validTo"
    | "customer"
    | "learningSpace"
    | "maxDurationInHours"
    | "modules"
    | "maxUsers";
  const [inputErrors, setInputErrors] = useState<inputError[]>([]);

  const {
    loading: customersLoading,
    data: customersData,
    error: customersErrors,
  } = useQuery<{
    customers: Customer[];
  }>(
    gql`
      query Customers {
        customers {
          ...CustomerFields
        }
      }
      ${customerFields}
    `
  );

  const [
    fetchCustomerLearningSpaceAccess,
    {
      loading: customerLearningSpaceAccessLoading,
      data: customerLearningSpaceAccessData,
      error: customerLearningSpaceAccessErrors,
    },
  ] = useLazyQuery<{
    customerLearningSpaceAccess: CustomerLearningSpaceAccess;
  }>(
    gql`
      query CustomerLearningSpaceAccess(
        $customerId: Int!
        $learningSpaceId: String!
      ) {
        customerLearningSpaceAccess(
          customerId: $customerId
          learningSpaceId: $learningSpaceId
        ) {
          cloudModules {
            moduleId
            name
            cases {
              caseId
              name
            }
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setAvailableCloudModules(data.customerLearningSpaceAccess.cloudModules);
      },
    }
  );

  const [
    createCloudModulesVoucher,
    { loading: loadingCreateCloudModulesVoucher },
  ] = useMutation<
    any,
    {
      createCloudModulesResourceAccessVoucher: CreateCloudModulesResourceAccessVoucherInput;
    }
  >(
    gql`
      mutation CreateCloudModulesResourceAccessVoucher(
        $createCloudModulesResourceAccessVoucher: CreateCloudModulesResourceAccessVoucherInput!
      ) {
        createCloudModulesResourceAccessVoucher(
          createCloudModulesResourceAccessVoucher: $createCloudModulesResourceAccessVoucher
        ) {
          refetch {
            getAllCloudModulesResourceAccessVouchersAsAdmin {
              ...CloudModulesResourceAccessVouchersFields
            }
          }
        }
      }
      ${cloudModulesResourceAccessVouchersFields}
    `,
    {
      onCompleted: () => {
        createToast({
          title: "Created new voucher",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
        onClose();
      },
      onError: (e) => {
        if (
          e.graphQLErrors[0].message ===
          "Too little spendable time in cloud cases time quota."
        ) {
          createToast({
            title:
              "Too little spendable time in cloud cases time quota to create voucher.",
            type: ToastType.ERROR,
            format: ToastFormat.TOAST,
          });
        } else {
          createToast({
            title: "Something went wrong creating the voucher",
            type: ToastType.ERROR,
            format: ToastFormat.TOAST,
          });
        }
      },
    }
  );

  const [editCloudModulesVoucher, { loading: loadingEditCloudModulesVoucher }] =
    useMutation<
      any,
      {
        editCloudModulesResourceAccessVoucher: EditCloudModulesResourceAccessVoucherInput;
      }
    >(
      gql`
        mutation EditCloudModulesResourceAccessVoucher(
          $editCloudModulesResourceAccessVoucher: EditCloudModulesResourceAccessVoucherInput!
        ) {
          editCloudModulesResourceAccessVoucher(
            editCloudModulesResourceAccessVoucher: $editCloudModulesResourceAccessVoucher
          ) {
            refetch {
              getAllCloudModulesResourceAccessVouchersAsAdmin {
                ...CloudModulesResourceAccessVouchersFields
              }
            }
          }
        }
        ${cloudModulesResourceAccessVouchersFields}
      `,
      {
        onCompleted: () => {
          createToast({
            title: "Updated voucher",
            type: ToastType.SUCCESS,
            format: ToastFormat.TOAST,
          });
          onClose();
        },
        onError: () => {
          createToast({
            title: "Something went wrong updating the voucher",
            type: ToastType.ERROR,
            format: ToastFormat.TOAST,
          });
        },
      }
    );

  useEffect(() => {
    // Choose one and only customer if only one available
    if (
      !editingVoucher &&
      customersData?.customers &&
      customersData?.customers?.length === 1
    ) {
      setSelectedCustomer(customersData?.customers[0]);
    }
  }, [customersData, setSelectedCustomer, editingVoucher]);

  useEffect(() => {
    if (selectedCustomer && selectedLearningSpace) {
      fetchCustomerLearningSpaceAccess({
        variables: {
          customerId: selectedCustomer.id,
          learningSpaceId: selectedLearningSpace.id.toString(),
        },
      });
    } else {
      setAvailableCloudModules([]);
    }
  }, [
    selectedCustomer,
    selectedLearningSpace,
    fetchCustomerLearningSpaceAccess,
    setAvailableCloudModules,
  ]);

  return (
    <Dialog
      title={
        editingVoucher
          ? "Edit Cloud Modules Voucher"
          : "Create Cloud Modules Voucher"
      }
      onClose={() => onClose()}
      buttons={
        <>
          <Button secondary onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            id="cy-create-group-dialog-button"
            onClick={() => {
              const newInputErrors: inputError[] = [];
              if (!selectedValidTo) {
                newInputErrors.push("validTo");
              }
              if (!selectedCustomer) {
                newInputErrors.push("customer");
              }
              if (!selectedLearningSpace) {
                newInputErrors.push("learningSpace");
              }
              if (!selectedMaxDurationInHours) {
                newInputErrors.push("maxDurationInHours");
              }
              if (!selectedMaxUses) {
                newInputErrors.push("maxUsers");
              }
              if (
                !(
                  selectedCloudModuleInputs.length > 0 &&
                  selectedCloudModuleInputs[0].caseIds.length > 0
                )
              ) {
                newInputErrors.push("modules");
              }

              if (
                selectedValidTo &&
                selectedCustomer &&
                selectedLearningSpace &&
                selectedMaxDurationInHours &&
                selectedMaxUses
              ) {
                setInputErrors([]);
                editingVoucher
                  ? editCloudModulesVoucher({
                      variables: {
                        editCloudModulesResourceAccessVoucher: {
                          voucherId: editingVoucher.id,
                          validTo: selectedValidTo.toISOString(),
                          maxUses: selectedMaxUses,
                          modules: selectedCloudModuleInputs,
                        },
                      },
                    })
                  : createCloudModulesVoucher({
                      variables: {
                        createCloudModulesResourceAccessVoucher: {
                          validTo: selectedValidTo.toISOString(),
                          customerId: selectedCustomer?.id,
                          learningSpaceId: selectedLearningSpace?.id,
                          maxUses: selectedMaxUses,
                          maxDurationInSeconds:
                            selectedMaxDurationInHours * 60 * 60,
                          modules: selectedCloudModuleInputs,
                        },
                      },
                    });
              } else {
                setInputErrors([...inputErrors, ...newInputErrors]);
              }
            }}
          >
            {editingVoucher
              ? renderLoadingText(
                  loadingEditCloudModulesVoucher,
                  "Save Voucher",
                  "Saving Voucher"
                )
              : renderLoadingText(
                  loadingCreateCloudModulesVoucher,
                  "Create Voucher",
                  "Creating Voucher"
                )}
          </Button>
        </>
      }
    >
      {!customersLoading && !customersErrors && customersData?.customers && (
        <>
          {!editingVoucher && customersData.customers.length > 1 && (
            <SelectField
              label={"Account"}
              onChange={(option: CustomerOption) => {
                setSelectedCustomer(option.data.customer);
                setSelectedLearningSpace(undefined);
                setInputErrors(
                  [...inputErrors].filter(
                    (inputError) => inputError !== "customer"
                  )
                );
              }}
              placeholder={"Select account"}
              name="customer"
              options={customersData.customers.map((customer) => ({
                value: customer.id,
                label: customer.name,
                data: {
                  customer,
                },
              }))}
              error={
                inputErrors.includes("customer")
                  ? "Select an account"
                  : undefined
              }
              value={
                selectedCustomer
                  ? {
                      value: selectedCustomer?.id,
                      label: selectedCustomer?.name,
                      data: {
                        customer: selectedCustomer,
                      },
                    }
                  : null
              }
            />
          )}
          {!editingVoucher && (
            <SelectField
              label={"Learning Space"}
              onChange={(option: LearningSpaceOption) => {
                setSelectedLearningSpace(option.data.branch);
                setInputErrors(
                  [...inputErrors].filter(
                    (inputError) => inputError !== "learningSpace"
                  )
                );
              }}
              placeholder={"Select learning space"}
              name="customer"
              isDisabled={selectedCustomer === undefined}
              value={
                selectedLearningSpace
                  ? {
                      value: selectedLearningSpace?.id,
                      label: selectedLearningSpace?.name,
                      data: { branch: selectedLearningSpace },
                    }
                  : null
              }
              options={selectedCustomer?.branches.map((branch) => ({
                value: branch.id,
                label: branch.name,
                data: {
                  branch,
                },
              }))}
              error={
                inputErrors.includes("learningSpace")
                  ? "Select a learning space"
                  : undefined
              }
            />
          )}
          <DatePickerField
            onChange={(date: Date) => {
              setSelectedValidTo(date);
              setInputErrors(
                [...inputErrors].filter(
                  (inputError) => inputError !== "validTo"
                )
              );
            }}
            label="Expiry Date"
            value={selectedValidTo}
            error={
              inputErrors.includes("validTo")
                ? "Select an expiry date"
                : undefined
            }
          />
          <HorizontalRow>
            {!editingVoucher && (
              <div>
                <TextField
                  type="number"
                  min="1"
                  name="maxDurationInHours"
                  label="Assigned Hours (Per User)"
                  value={selectedMaxDurationInHours ?? undefined}
                  onChange={(e: {
                    currentTarget: {
                      value: number;
                    };
                  }) =>
                    setSelectedMaxDurationInHours(Number(e.currentTarget.value))
                  }
                />
              </div>
            )}
            <div style={{ gridColumn: editingVoucher ? "1/3" : "2/2" }}>
              <TextField
                type="number"
                name="maxUsers"
                label="Maximum Users"
                min="1"
                max="100"
                value={selectedMaxUses ?? undefined}
                onChange={(e: {
                  currentTarget: {
                    value: number;
                  };
                }) => {
                  setSelectedMaxUses(Number(e.currentTarget.value));
                  setInputErrors(
                    [...inputErrors].filter(
                      (inputError) => inputError !== "maxUsers"
                    )
                  );
                }}
                error={
                  inputErrors.includes("maxUsers")
                    ? "Set a max amount of users"
                    : undefined
                }
              />
            </div>
          </HorizontalRow>
          {customerLearningSpaceAccessData &&
            !customerLearningSpaceAccessErrors &&
            !customerLearningSpaceAccessLoading &&
            availableCloudModules.length > 0 && (
              <VerticalRow>
                <LabelText disabled={false}>
                  Modules & Cases
                  <span>
                    {inputErrors.includes("modules")
                      ? " Select one or more cases"
                      : ""}
                  </span>
                </LabelText>
                {availableCloudModules.flatMap((cloudModule) =>
                  cloudModule.cases.map((cloudCase) => (
                    <Checkbox
                      key={`${cloudModule.moduleId}${cloudCase.caseId}`}
                      title={`${cloudModule.name}: ${cloudCase.name}`}
                      checkboxChecked={selectedCloudModuleInputs.some(
                        (selectedCloudModuleInput) =>
                          selectedCloudModuleInput.moduleId ===
                            cloudModule.moduleId &&
                          selectedCloudModuleInput.caseIds.some(
                            (selectedCloudCaseId) =>
                              selectedCloudCaseId === cloudCase.caseId
                          )
                      )}
                      onCheckboxClick={(e) => {
                        const newSelectedCloudModuleInputs = [
                          ...selectedCloudModuleInputs,
                        ];
                        const moduleToEdit = newSelectedCloudModuleInputs.find(
                          (newSelectedCloudModule) =>
                            newSelectedCloudModule.moduleId ===
                            cloudModule.moduleId
                        );
                        if (e.target.checked && moduleToEdit) {
                          setInputErrors([
                            ...inputErrors.filter(
                              (inputError) => inputError !== "modules"
                            ),
                          ]);
                          // Add to existing module
                          moduleToEdit.caseIds.push(cloudCase.caseId);
                        } else if (e.target.checked && !moduleToEdit) {
                          setInputErrors([
                            ...inputErrors.filter(
                              (inputError) => inputError !== "modules"
                            ),
                          ]);
                          // Add whole module and case
                          newSelectedCloudModuleInputs.push({
                            moduleId: cloudModule.moduleId,
                            caseIds: [cloudCase.caseId],
                          });
                        } else if (
                          moduleToEdit &&
                          moduleToEdit.caseIds.length >= 2
                        ) {
                          // Remove case from module (but other cases from said module remain)
                          moduleToEdit.caseIds.splice(
                            moduleToEdit.caseIds.indexOf(cloudCase.caseId),
                            1
                          );
                        } else if (
                          moduleToEdit &&
                          moduleToEdit.caseIds.length === 1
                        ) {
                          // Remove whole module since case was last case
                          newSelectedCloudModuleInputs.splice(
                            newSelectedCloudModuleInputs.findIndex(
                              (newSelectedCloudModuleInput) =>
                                newSelectedCloudModuleInput.moduleId ===
                                cloudModule.moduleId
                            ),
                            1
                          );
                        }
                        setSelectedCloudModuleInputs(
                          newSelectedCloudModuleInputs
                        );
                      }}
                    />
                  ))
                )}
              </VerticalRow>
            )}
        </>
      )}
      {customersLoading && <LoadingIndicator />}
      {customersErrors &&
        customersErrors.graphQLErrors.map((error: { message: string }) => (
          <Text key={error.message}>{error.message}</Text>
        ))}
      {customerLearningSpaceAccessErrors &&
        customerLearningSpaceAccessErrors.graphQLErrors.map(
          (error: { message: string }) => (
            <Text key={error.message}>{error.message}</Text>
          )
        )}
    </Dialog>
  );
};

export default CreateOrEditCloudModulesResourceAccessVoucherDialog;
