import { format } from "date-fns";

export const isDate = (date: any) => !isNaN(Date.parse(date));

// LOCALIZED DATES

const language = navigator.language;

export const localeDateFormat =
  language === "en-US"
    ? "MM/DD/YYYY"
    : language === "en-GB"
    ? "DD/MM/YYYY"
    : "YYYY-MM-DD";

export const getDatePickerLocalizedDatePickerFieldFormat = (
  showTime: boolean
) => {
  switch (language) {
    case "en-US":
      return showTime ? "MM/dd/yyyy h:mm aa" : "MM/dd/yyyy";
    case "en-GB":
      return showTime ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy";
    default:
      return showTime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd";
  }
};

export const getDatePickerLocalizedTimePickerFormat = () => {
  switch (language) {
    case "en-US":
      return "h:mm aa";
    case "en-GB":
      return "HH:mm";
    default:
      return "HH:mm";
  }
};

// dd Month YYYY, hh:mm
export const formatDateAndTime = (isoDate: string) => {
  if (isDate(isoDate)) {
    const date = new Date(isoDate);
    const time = new Date(isoDate).toLocaleTimeString(language, {
      hour: "2-digit",
      minute: "2-digit",
    });
    const formattedDate = date.toLocaleDateString(language, {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return formattedDate + ", " + time;
  } else {
    return;
  }
};

// hh:mm
export const formatTime = (isoDate: string) => {
  if (isDate(isoDate)) {
    return new Date(isoDate).toLocaleTimeString(language, {
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    return;
  }
};

// dd/mm/yyyy
export const formatDate = (dateString: string) => {
  if (isDate(dateString)) {
    const date = new Date(dateString).toLocaleDateString(language, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return date;
  } else {
    return;
  }
};

// dd/mm/yyyy | mm-dd-yyyy | yyyy-mm-dd ...
export const formatDatetoLocaleDateString = (date: Date) =>
  date.toLocaleDateString(language, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

// dd Mon YYYY
export const formatDateShortMonth = (date: Date) => {
  return date.toLocaleDateString(language, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

// dd Mon YYYY
export const formatDateStringShortMonth = (dateString: string) => {
  if (isDate(dateString)) {
    const date = new Date(dateString).toLocaleDateString(language, {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return date;
  } else {
    return;
  }
};

// dd Month, YYYY
export const formatDateLongMonth = (dateString: string) => {
  if (isDate(dateString)) {
    const date = new Date(dateString).toLocaleDateString(language, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return date;
  } else {
    return;
  }
};

// NON-LOCALIZED DATES

export const getUTCDate = (date: Date): Date => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const getUTCTimeFromISOString = (ISOString: Date): string => {
  const date = new Date(ISOString);
  const utcDate = getUTCDate(date);
  const readableUTCDate = utcDate.toLocaleTimeString(language, {
    hour: "2-digit",
    minute: "2-digit",
  });
  return readableUTCDate;
};

export const getUTCDateFromISOString = (ISOString: string): string => {
  const date = new Date(ISOString);
  const utcDate = getUTCDate(date);
  const readableUTCDate = utcDate.toLocaleDateString(language, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return readableUTCDate;
};

export const formatDurationToMinutesAndSeconds = (
  isoDurationString: string
) => {
  // Parse according to iso88601 duration
  // https://stackoverflow.com/questions/14934089/convert-iso-8601-duration-with-javascript/29153059
  const iso8601DurationRegex =
    /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;
  const durations = isoDurationString.match(iso8601DurationRegex);
  if (durations) {
    var minutes = durations[7] ?? "0";
    var seconds = durations[8] ?? "0";
    if (parseFloat(durations[8]) >= 60) {
      // Duration might be specified as 70s (= 1m10s)
      minutes = Math.floor(parseFloat(durations[8]) / 60).toString();
      seconds = (parseFloat(durations[8]) % 60).toString();
    }
    // Round to nearest int and format with leading zeroes
    minutes = Math.round(parseFloat(minutes)).toString().padStart(2, "0");
    seconds = Math.round(parseFloat(seconds)).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  } else {
    return;
  }
};

export const getTodaysDateTime = () => {
  const date = new Date();
  const dateMDY = `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()}-${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
  return dateMDY;
};

export const getDateOneWeekAgo = (date: Date) => {
  let newDate = new Date(date);
  let pastDate = newDate.getDate() - 7;
  newDate.setDate(pastDate);
  return newDate;
};

export const getLastHour = (date: Date) => {
  return new Date(new Date(date).setHours(23, 59, 59, 999));
};

export const getFirstHour = (date: Date) =>
  new Date(new Date(date).setHours(0, 0, 0, 0));

export const getDateString = (date: Date): string => {
  return format(date, "YYYY-MM-DD");
};

export const getLocalizedDateString = (date: Date): string => {
  return format(date, localeDateFormat);
};

export const getFormattedDurationStringFromSeconds = (
  duration: number,
  hasSeconds?: boolean
): string => {
  const days = Math.floor(duration / (24 * 60 * 60));
  const hours = Math.floor((duration % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((duration % (60 * 60)) / 60);
  const seconds = Math.floor(duration % 60);
  if (days > 0) {
    return `${days}d ${hours}h ${minutes}m ${hasSeconds ? seconds + "s" : ""}`;
  } else if (hours > 0) {
    return `${hours}h ${minutes}m ${hasSeconds ? seconds + "s" : ""}`;
  } else if (minutes > 0) {
    return `${minutes}m ${hasSeconds ? seconds + "s" : ""}`;
  } else {
    return `${hasSeconds ? seconds + "s" : ""}`;
  }
};

export const getLogbookStringFromDate = (date: Date): string => {
  // Get locale string with format 'YYYY-MM-DD'
  return date.toLocaleString("sv-SE").split(" ")[0];
};

export const getLogbookOffsetDate = (date: Date | string): Date =>
  typeof date === "string" ? offsetLocalDate(date) : date;

export const offsetLocalDate = (string: string): Date => {
  // Consider datestring without timezone to be Date at 00:00 in local timezone
  const offsetDate = new Date();
  const offset = offsetDate.getTimezoneOffset() / 60;

  let formatOffset = "";
  if (offset.toString().charAt(0) === "-") {
    const timeOnly = offset.toString().split("-")[1];
    if (timeOnly.length > 1) {
      formatOffset = `+${timeOnly}:00`;
    } else {
      formatOffset = `+0${timeOnly}:00`;
    }
  } else {
    if (offset.toString().length > 1) {
      formatOffset = `-${offset}:00`;
    } else {
      formatOffset = `-0${offset}:00`;
    }
  }
  /* format ex: 2020-04-13T00:00:00.000+08:00 */
  const UTCString = `${string}T00:00:00.000${formatOffset}`;
  return new Date(UTCString);
};
