import { gql, QueryResult, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { PageLayout } from "../../components/Page/PageLayout";
import { Card } from "../../components/shared/Card";
import {
  button3Style,
  H3,
  H4,
  Text,
  SpanNoWrap,
} from "../../components/shared/typography";
import { VistActivityItem } from "../../generated/graphql";
import LegacyReport from "./LegacyReport";
import {
  primaryButtonStyle,
  secondaryButtonStyle,
} from "../../components/shared/Button";
import LoadingIndicator, {
  Center,
} from "../../components/shared/LoadingIndicator";
import Imaging from "./Imaging";
import Results from "./Results";
import {
  formatDateAndTime,
  getFormattedDurationStringFromSeconds,
} from "../../lib/formatDateAndTime";

enum TabButtonTypes {
  CompactResult = "compact_result",
  ExpandedResult = "expanded_result",
  LegacyReport = "legacy_report",
  Imaging = "imaging",
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TabButtonGroup = styled.div`
  margin: ${(props) => props.theme.sizes.spacing4} 0;
`;

const TabButton = styled.button<{ active: boolean }>`
  height: 40px;
  padding: 0 ${(props) => props.theme.sizes.spacing2};
  margin: 0;
  ${button3Style}
  ${(props) => (props.active ? primaryButtonStyle : secondaryButtonStyle)};
  &:not(:first-child) {
    border-left: none;
  }
  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const VistActivityPage: React.FC = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState(TabButtonTypes.CompactResult);

  const {
    data: activityData,
    error: activityError,
    loading: activityLoading,
  }: QueryResult<{
    vistActivity: VistActivityItem;
  }> = useQuery(
    gql`
      query FetchOneVistActivity($id: String!) {
        vistActivity(id: $id) {
          id
          unitId
          courseName
          unitName
          benchmarkLink
          metricValuesLink
          groupsLink
          metricDescriptionsLink
          finalResultLink
          progress
          totalTimeInSeconds
          activityStart
          createdBy {
            first_name
            last_name
          }
          images {
            id
            name
            createdAt
            thumbnailLink
            link
          }
          recordings {
            id
            name
            createdAt
            duration
            thumbnailLink
            link
          }
        }
      }
    `,
    {
      variables: { id: id },
    }
  );

  return (
    <PageLayout>
      {/* Breadcrumbs */}
      {children}
      {!activityError && activityData && (
        <>
          <H3 style={{ marginTop: "32px" }}>
            {activityData.vistActivity.unitName}
          </H3>
          <H4 style={{ margin: "16px 0" }}>
            {activityData.vistActivity.courseName + " • VIST® Sim"}
          </H4>
          <SpanNoWrap>
            {formatDateAndTime(
              activityData.vistActivity.activityStart
            )}
          </SpanNoWrap>
          <SpanNoWrap
            style={{ marginLeft: "16px" }}
          >{`${activityData.vistActivity.createdBy?.first_name} ${activityData.vistActivity.createdBy?.last_name}`}</SpanNoWrap>
          <SpanNoWrap style={{ marginLeft: "16px" }}>
            Total time:{" "}
            {getFormattedDurationStringFromSeconds(
              activityData.vistActivity.totalTimeInSeconds,
              true
            )}
          </SpanNoWrap>
        </>
      )}
      <Row>
        <TabButtonGroup>
          {/* TODO MCS-864: Map over enum instead */}
          <TabButton
            active={activeTab === TabButtonTypes.CompactResult}
            onClick={() => setActiveTab(TabButtonTypes.CompactResult)}
          >
            Result
          </TabButton>
          <TabButton
            active={activeTab === TabButtonTypes.LegacyReport}
            onClick={() => setActiveTab(TabButtonTypes.LegacyReport)}
          >
            Legacy Report
          </TabButton>
          <TabButton
            active={activeTab === TabButtonTypes.Imaging}
            onClick={() => setActiveTab(TabButtonTypes.Imaging)}
          >
            Imaging
          </TabButton>
        </TabButtonGroup>
      </Row>
      <Card>
        {activityLoading && <LoadingIndicator />}
        {activityError &&
          activityError.graphQLErrors.map((error: { message: string }, i) => (
            <Center key={error.message + i}>
              <Text>{error.message}</Text>
            </Center>
          ))}
        {activityData &&
          (() => {
            switch (activeTab) {
              case TabButtonTypes.CompactResult:
                return (
                  <Results
                    progress={activityData.vistActivity.progress}
                    groupFileLink={activityData.vistActivity.groupsLink}
                    benchmarkFileLink={activityData.vistActivity.benchmarkLink}
                    metricValuesFileLink={
                      activityData.vistActivity.metricValuesLink
                    }
                    metricDescriptionsFileLink={
                      activityData.vistActivity.metricDescriptionsLink
                    }
                    unitId={activityData.vistActivity.unitId}
                  />
                );
              case TabButtonTypes.LegacyReport:
                return <LegacyReport id={activityData.vistActivity.id} />;
              case TabButtonTypes.Imaging:
                return (
                  <Imaging
                    images={activityData.vistActivity.images}
                    recordings={activityData.vistActivity.recordings}
                  />
                );
              default:
                break;
            }
          })()}
      </Card>
    </PageLayout>
  );
};
export default VistActivityPage;
