import React from "react";
import {
  H4NoWrap,
  SemiBoldText,
  SemiBoldTextNoWrap,
  SpanNoWrap,
} from "../../components/shared/typography";
import { EventResourceAccessVoucher } from "../../generated/graphql";
import {
  formatDateAndTime,
  formatDateShortMonth,
} from "../../lib/formatDateAndTime";
import CopyField from "../../components/shared/CopyField";
import { convertBase64ToImage, getDefaultLogo } from "../../lib/convertBase64";
import { ContextMenu } from "../../components/shared/context-menu/ContextMenu";
import { MenuItem } from "../../components/shared/context-menu/MenuItem";
import { gql, useMutation } from "@apollo/client";
import { eventResourceAccessVouchersFields } from "../../types/fragments/EventResourceAccessVoucherFragment";
import { ToastFormat, ToastType } from "../../shared/enums";
import { useToast } from "../../lib/useToast";
import { copyToClipboard } from "../../lib/copyToClipboard";
import {
  CreatedModifiedDate,
  LearningSpaceLogo,
  MiddleRowGrid,
  ResourceAccessVoucherCardWrapper,
  VR,
  TopRowGrid,
} from "./CloudCaseResourceAccessVoucherCard";
import UserAvatar from "../../components/icons/UserAvatar";
import {
  CardDivider,
  CardText,
  UserListRow,
} from "../../components/Groups/GroupCard";

interface Props {
  voucher: EventResourceAccessVoucher;
  setIsEditingVoucher: () => void;
  editable: boolean;
  archivable: boolean;
}

const EventResourceAccessVoucherCard: React.FC<Props> = ({
  voucher,
  setIsEditingVoucher,
  editable,
  archivable,
}) => {
  const [, createToast] = useToast();

  const [archiveVoucher] = useMutation(
    gql`
      mutation ArchiveEventResourceAccessVoucher(
        $eventResourceAccessVoucherId: Int!
      ) {
        archiveEventResourceAccessVoucher(
          eventResourceAccessVoucherId: $eventResourceAccessVoucherId
        ) {
          refetch {
            getEventResourceAccessVouchersAsAdmin {
              ...EventResourceAccessVouchersFields
            }
          }
        }
      }
      ${eventResourceAccessVouchersFields}
    `,
    {
      variables: {
        eventResourceAccessVoucherId: voucher.id,
      },
      onCompleted() {
        createToast({
          title: `Successfully archived event`,
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError() {
        createToast({
          title: "Failed to archived event. Please try again later.",
          type: ToastType.ERROR,
          format: ToastFormat.TOAST,
        });
      },
    }
  );

  const startDateAndTime = formatDateAndTime(voucher.eventAt);
  const endTime = new Date(
    new Date(voucher.eventAt).getTime() + voucher.durationInSeconds * 1000
  ).toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <>
      <ResourceAccessVoucherCardWrapper>
        <TopRowGrid
          middleAlignCreated={voucher.createdAt === voucher.modifiedAt}
        >
          <LearningSpaceLogo
            src={
              voucher.learningSpace.image
                ? convertBase64ToImage(voucher.learningSpace.image)
                : getDefaultLogo()
            }
            alt={voucher.learningSpace.name + " logo"}
          />

          <H4NoWrap>{`${voucher.name}: ${startDateAndTime}—${endTime}`}</H4NoWrap>
          <SpanNoWrap>{voucher.description}</SpanNoWrap>
          <CreatedModifiedDate>
            {`Created: ${formatDateShortMonth(new Date(voucher.createdAt))}`}
          </CreatedModifiedDate>
          {voucher.modifiedAt !== voucher.createdAt && (
            <CreatedModifiedDate>
              {`Modified: ${formatDateShortMonth(
                new Date(voucher.modifiedAt)
              )}`}
            </CreatedModifiedDate>
          )}
          <UserAvatar
            tooltip
            isAdmin={true}
            firstName={voucher.creator?.first_name ?? ""}
            lastName={voucher.creator?.last_name ?? ""}
          />
          {editable || archivable ? (
            <ContextMenu>
              {editable && (
                <MenuItem onClick={setIsEditingVoucher}>Edit voucher</MenuItem>
              )}
              {archivable && (
                <MenuItem onClick={archiveVoucher}>Archive voucher</MenuItem>
              )}
            </ContextMenu>
          ) : (
            <div />
          )}
        </TopRowGrid>
        <CardDivider />
        <MiddleRowGrid>
          <SemiBoldTextNoWrap>
            {`Invitation Link (Expires ${formatDateShortMonth(
              new Date(voucher.validTo)
            )})`}
          </SemiBoldTextNoWrap>
          <CopyField
            compact={true}
            value={`${window.location.origin}/?inviteCode=${voucher.code}`}
            onCopyClick={async () =>
              voucher.code &&
              (await copyToClipboard(
                `${window.location.origin}/?inviteCode=${voucher.code}`
              ))
                ? createToast({
                    title: "Invite link copied to clipboard",
                    type: ToastType.SUCCESS,
                    format: ToastFormat.TOAST,
                  })
                : createToast({
                    title: "Failed to copy invite link, please try again later",
                    type: ToastType.ERROR,
                    format: ToastFormat.TOAST,
                  })
            }
          />
          <VR />
          <SemiBoldText>
            {`Users ${
              voucher.maxUses ? `(${voucher.used} / ${voucher.maxUses})` : ""
            }`}
          </SemiBoldText>
          <UserListRow>
            {[...voucher.users].slice(0, 9).map((user, i) => (
              <UserAvatar
                isAdmin={false}
                key={`${user.keycloak_id}${i}`}
                tooltip
                firstName={user.first_name}
                lastName={user.last_name}
              />
            ))}
            {voucher.users.length - 9 > 0 && (
              <CardText style={{ marginBottom: "9px", marginLeft: "16px" }}>
                +{voucher.users.length - 9}
              </CardText>
            )}
          </UserListRow>
        </MiddleRowGrid>
      </ResourceAccessVoucherCardWrapper>
    </>
  );
};

export default EventResourceAccessVoucherCard;
