import { ApolloError, gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router";
import { PageLayout } from "../../components/Page/PageLayout";
import { Section } from "../../components/Page/Section";
import {
  RefetchPayload,
  TalentCourse,
  UnitProgress,
} from "../../generated/graphql";
import {
  LargeText,
  Text,
  H4,
  H6,
  HR,
  H3,
} from "../../components/shared/typography";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import styled, { ThemeContext } from "styled-components";
import { Card, OutlinedCard } from "../../components/shared/Card";
import { ProgressCircle } from "../../components/shared/ProgressCircle";
import { getIconURL } from "../../components/TalentCourses/CourseCard";
import {
  ButtonSizes,
  TalentCourseCategory,
  ToastFormat,
  ToastType,
} from "../../shared/enums";
import Button from "../../components/shared/Button";
import deprGql from "graphql-tag";
import { print } from "graphql";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import { renderLoadingText } from "../../components/shared/LoadingIndicator";
import Dialog from "../../components/shared/Dialog";
import { useToast } from "../../lib/useToast";

const CourseHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    flex-wrap: wrap;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    margin-bottom: ${(props) => props.theme.sizes.spacing3};
  }
`;

const ProgressInfoBox = styled(OutlinedCard)`
  padding: ${(props) =>
    `${props.theme.sizes.spacing1} ${props.theme.sizes.spacing3}`};
  width: ${(props) => props.theme.sizes.spacing32};
  height: ${(props) => props.theme.sizes.spacing14};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ImageContainer = styled(OutlinedCard)`
  width: ${(props) => props.theme.sizes.spacing14};
  height: ${(props) => props.theme.sizes.spacing14};
  display: flex;
  place-content: center;
  padding: 0;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    display: none;
  }
`;

const CourseCategoryImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  padding: ${(props) => props.theme.sizes.spacing2};
`;

const BookmarkImg = styled.img`
  width: ${(props) => props.theme.sizes.spacing3};
  height: ${(props) => props.theme.sizes.spacing3};
  margin-right: ${(props) => props.theme.sizes.spacing3};
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    display: none;
  }
`;

const CourseContentCard = styled(Card)`
  padding: ${(props) =>
    `${props.theme.sizes.spacing3} ${props.theme.sizes.spacing5}`};

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    padding: ${(props) =>
      `${props.theme.sizes.spacing2} ${props.theme.sizes.spacing3}`};
  }
`;

const ProgressText = styled(H6)<{ color?: string }>`
  margin: 0;
  color: ${(props) => props.color};
  white-space: noWrap;
`;

const Row = styled.div`
  display: flex;
  place-content: center space-between;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    flex-wrap: wrap;
  }
`;

const ButtonRow = styled(Row)`
  button:last-child {
    margin-left: ${(props) => props.theme.sizes.spacing2};
  }
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    margin-top: ${(props) => props.theme.sizes.spacing2};
    width: 100%;
    justify-content: flex-end;
  }
`;

const UnitRow = styled(Row)<{ heading?: boolean }>`
  ${(props) =>
    !props.heading &&
    `border-bottom: 1px solid ${props.theme.colors.lightGrey}; height: ${props.theme.sizes.spacing9}`};
  ${(props) => props.heading && `padding-bottom: ${props.theme.sizes.spacing2}`}
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    height: auto;
  }
`;

const UnitCell = styled.div<{
  flex: number;
  center?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  flex: ${(props) => (props.flex ? props.flex : 1)};
`;

const LearningSpaceCourse = () => {
  const { id, name, courseId } = useParams<{
    id: string;
    name: string;
    courseId: string;
  }>();
  const match = useRouteMatch();
  const theme = useContext(ThemeContext);
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [, createToast] = useToast();

  // Handles the click on a TalentCourse object and redirects to the goto course url
  const handleStartCourse = async () => {
    const endpoint =
      process.env.NODE_ENV === "development"
        ? `http://localhost:3001/api/graphql`
        : "/api/graphql";

    // Send user to Talent course
    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + keycloak.token || "", // TODO: Refactor to useGraphQL/Apollo
      },
      body: JSON.stringify({
        query: print(talentCourseQuery),
        variables: {
          emailAddress: user?.email || "",
          talentCourseId: Number(courseId) || null,
          talentBranchName: name,
        },
      }),
    })
      .then((courseRes) => courseRes.json())
      .then((course) => {
        if (course.errors)
          throw new Error(
            course.errors.map((error: { message: string }) => error.message)
          );

        const url: string = course.data.talentCourse.goto_url;
        if (url) window.location.href = url;
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  };

  const [fetchTalentCourse, { loading, error, data }] = useLazyQuery<{
    talentCourse: TalentCourse;
  }>(
    gql`
      query Course($courseId: Int!) {
        talentCourse(talentCourseId: $courseId) {
          id
          name
          code
          category
          description
          big_avatar
          units {
            id
            name
            type
          }
          progress {
            completion_percentage
            completion_status
            units {
              id
              completion_status
              total_time
              score
            }
          }
        }
      }
    `,
    {
      variables: {
        courseId: Number(courseId),
      },
    }
  );

  const [enrollUser, { loading: loadingEnrollUser }] = useMutation<{
    enrollTalentUserInCourse: TalentCourse;
  }>(
    gql`
      mutation EnrollTalentUserInCourse(
        $talentCourseId: Int!
        $emailAddress: String!
        $talentBranchName: String!
      ) {
        enrollTalentUserInCourse(
          talentCourseId: $talentCourseId
          emailAddress: $emailAddress
        ) {
          id
          role
          goto_url(
            emailAddress: $emailAddress
            talentBranchName: $talentBranchName
          )
        }
      }
    `,
    {
      variables: {
        talentCourseId: Number(courseId),
        talentBranchName: name,
        emailAddress: user?.email,
      },
      onCompleted: (data) => {
        createToast({
          title: "Course started. Redirecting shortly...",
          type: ToastType.SUCCESS,
          format: ToastFormat.BANNER,
        });
        const url = data.enrollTalentUserInCourse.goto_url;
        if (url) window.location.href = url;
      },
      onError: (err: ApolloError) => {
        console.log(JSON.stringify(err));
        createToast({
          title: "Failed to start course, please try again later",
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  const [resetProgress, { loading: loadingResetProgress }] = useMutation<{
    resetMyCourseProgress: RefetchPayload;
  }>(
    gql`
      mutation ResetMyCourseProgress(
        $courseId: String!
        $talentCourseId: Int!
        $talentBranchId: String!
        $emailAddress: String!
      ) {
        resetMyCourseProgress(courseId: $courseId) {
          refetch {
            talentCourse(talentCourseId: $talentCourseId) {
              id
              name
              code
              category
              description
              big_avatar
              units {
                id
                name
                type
              }
              progress {
                completion_percentage
                completion_status
                units {
                  id
                  completion_status
                  total_time
                  score
                }
              }
            }
            allTalentBranchCourses(
              talentBranchId: $talentBranchId
              emailAddress: $emailAddress
            ) {
              id
              category
              name
              completion_status
              completion_percentage
              big_avatar
              code
            }
          }
        }
      }
    `,
    {
      variables: {
        courseId: courseId,
        talentCourseId: Number(courseId),
        talentBranchId: id,
        emailAddress: user?.email,
      },
      onCompleted: () => {
        setShowResetDialog(false);
        createToast({
          title: "Successfully reset course progress.",
          type: ToastType.SUCCESS,
          format: ToastFormat.BANNER,
        });
      },
      onError: (err: ApolloError) => {
        console.log(JSON.stringify(err));
        createToast({
          title: "Failed to reset course progress, please try again later",
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  useEffect(() => {
    if (user) {
      fetchTalentCourse();
    }
  }, [user, fetchTalentCourse]);

  const handleEnrollUser = () => {
    enrollUser();
  };

  const getAmountOfUnitsWithStatus = (
    units: UnitProgress[],
    testString: string | null
  ) => {
    return units.filter((unit) => unit?.completion_status === testString)
      .length;
  };

  const getStatus = (statusObj: { [key: string]: string }, status?: string) => {
    return status ? statusObj[status] : statusObj["Default"];
  };

  return (
    <PageLayout title="Mentice Live | Learning Space Course">
      {error && (
        <>
          {error.graphQLErrors.map((error: { message: string }) => (
            <Text>{error.message}</Text>
          ))}
        </>
      )}
      {loading && <Text>Loading courses...</Text>}
      {data && !error && (
        <>
          <Breadcrumbs
            crumbs={[
              {
                path: "/",
                name: "Home",
              },
              {
                path: "/learning-spaces",
                name: "Learning Spaces",
              },
              {
                path: `/learning-spaces/${id}/${name}`,
                name: name,
              },
              {
                path: match.path,
                name: data.talentCourse.name ?? "",
              },
            ]}
          />
          <Row>
            <H3>{data.talentCourse.name}</H3>
            <ButtonRow>
              <Button
                size={ButtonSizes.Medium}
                onClick={
                  data.talentCourse.progress.completion_status ===
                  "not_attempted"
                    ? handleEnrollUser
                    : handleStartCourse
                }
              >
                {renderLoadingText(loadingEnrollUser, "Start", "Starting")}
              </Button>
              {data.talentCourse.progress.completion_status !==
                "not_attempted" && (
                <Button
                  secondary
                  size={ButtonSizes.Medium}
                  onClick={() => setShowResetDialog(true)}
                >
                  Reset
                </Button>
              )}
            </ButtonRow>
          </Row>

          <Section maxColumns={1}>
            <CourseHeader>
              <HeaderContent>
                {data.talentCourse.category && (
                  <ImageContainer>
                    <CourseCategoryImg
                      src={getIconURL(
                        data.talentCourse.category as TalentCourseCategory
                      )}
                    />
                  </ImageContainer>
                )}
                <LargeText
                  style={{
                    maxWidth: theme.sizes.spacing70,
                    margin: `0 ${theme.sizes.spacing3}`,
                  }}
                >
                  {data.talentCourse.description}
                </LargeText>
              </HeaderContent>
              <ProgressInfoBox>
                <div>
                  <H4>Progress</H4>
                  <div>
                    <ProgressText>
                      {data.talentCourse.progress.units &&
                        getAmountOfUnitsWithStatus(
                          data.talentCourse.progress.units,
                          "Completed"
                        )}{" "}
                      <span style={{ color: theme.colors.success500 }}>
                        Pass
                      </span>
                    </ProgressText>
                    <ProgressText>
                      {data.talentCourse.progress.units &&
                        getAmountOfUnitsWithStatus(
                          data.talentCourse.progress.units,
                          "In progress"
                        )}{" "}
                      <span style={{ color: theme.colors.primary500 }}>
                        In progress
                      </span>
                    </ProgressText>
                    <ProgressText>
                      {data.talentCourse.progress.units &&
                        getAmountOfUnitsWithStatus(
                          data.talentCourse.progress.units,
                          "Not attempted"
                        )}{" "}
                      Not attempted
                    </ProgressText>
                  </div>
                </div>

                <ProgressCircle
                  color={theme.colors.secondary500}
                  progress={
                    data.talentCourse.progress.completion_percentage ?? 0
                  }
                />
              </ProgressInfoBox>
            </CourseHeader>
            <HR style={{ margin: 0 }} />
            <>
              <H3>Curriculum</H3>
              <CourseContentCard>
                <UnitRow heading>
                  <UnitCell flex={5}>
                    <H4>Introduction</H4>
                  </UnitCell>
                  <UnitCell flex={1} center>
                    <H4>Score</H4>
                  </UnitCell>
                  <UnitCell flex={1} center>
                    <H4>Time</H4>
                  </UnitCell>
                  <UnitCell flex={1} center>
                    <H4>Status</H4>
                  </UnitCell>
                </UnitRow>
                {data.talentCourse.units && (
                  <>
                    {data.talentCourse.units.map((unit) => {
                      const unitProgress =
                        data.talentCourse.progress?.units?.find(
                          (progressUnit) => progressUnit?.id === unit?.id
                        );
                      return unit?.type === "Section" ? (
                        <H4
                          key={unit?.id}
                          style={{
                            marginTop: theme.sizes.spacing8,
                            marginBottom: theme.sizes.spacing2,
                          }}
                        >
                          {unit.name}
                        </H4>
                      ) : (
                        <UnitRow key={unit?.id}>
                          <UnitCell flex={5}>
                            <BookmarkImg
                              src="/static/assets/bookmark.svg"
                              alt="Bookmark icon"
                            />
                            <H6>{unit?.name}</H6>
                          </UnitCell>
                          <UnitCell flex={1} center>
                            {unitProgress?.score}
                          </UnitCell>
                          <UnitCell flex={1} center>
                            {unitProgress?.total_time}
                          </UnitCell>
                          <UnitCell flex={1} center>
                            <ProgressText
                              color={getStatus(
                                {
                                  Completed: theme.colors.success500,
                                  "Not attempted": theme.colors.darkGrey,
                                  "In progress": theme.colors.primary500,
                                  Default: theme.colors.darkGrey,
                                },
                                unitProgress?.completion_status
                              )}
                            >
                              {getStatus(
                                {
                                  Completed: "Pass",
                                  "Not attempted": "—",
                                  "In progress": "In progress",
                                  Default: "—",
                                },
                                unitProgress?.completion_status
                              )}
                            </ProgressText>
                          </UnitCell>
                        </UnitRow>
                      );
                    })}
                  </>
                )}
              </CourseContentCard>
            </>
          </Section>
        </>
      )}
      {showResetDialog && (
        <Dialog
          title="Reset Course Progress"
          onClose={() => setShowResetDialog(false)}
          buttons={
            <>
              <Button secondary onClick={() => setShowResetDialog(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => resetProgress()}
                disabled={loadingResetProgress}
              >
                {renderLoadingText(loadingResetProgress, "Confirm")}
              </Button>
            </>
          }
        >
          Are you sure you want to reset course progress? The update might not
          appear instantly.
        </Dialog>
      )}
    </PageLayout>
  );
};

export default LearningSpaceCourse;

const talentCourseQuery = deprGql`
  query getCourseURL(
    $emailAddress: String!
    $talentCourseId: Int!
    $talentBranchName: String!
  ) {
    talentCourse(talentCourseId: $talentCourseId) {
      goto_url(emailAddress: $emailAddress, talentBranchName: $talentBranchName)
    }
  }
`;
