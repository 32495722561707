import React from "react";
import styled from "styled-components";
import { Card } from "./Card";
import { HR } from "./typography";

const HoverableCard = styled(Card)<{ expanded: boolean }>`
  box-shadow: inset 5px 0 0 0 white,
    ${(props) => props.theme.shadows.cardShadow};
  ${(props) =>
    props.expanded &&
    `background-color: ${props.theme.colors.superLightGrey}; box-shadow: inset 5px 0 0 0 ${props.theme.colors.secondary300}, ${props.theme.shadows.cardShadow};`}
  transition: all ${(props) => props.theme.transitions.inOut};
  :hover {
    box-shadow: inset 5px 0 0 0 ${(props) => props.theme.colors.secondary300},
      ${(props) => props.theme.shadows.cardShadow};
    background-color: ${(props) => props.theme.colors.superLightGrey};
  }
`;

const Header = styled.div`
  cursor: pointer;
`;

const Content = styled.div``;

interface ExpandableCardProps {
  header: React.ReactNode;
  expanded: boolean;
}

const ExpandableCard: React.FC<ExpandableCardProps> = ({
  header,
  expanded,
  children,
}) => {
  return (
    <HoverableCard expanded={expanded}>
      <Header>{header}</Header>
      {expanded && (
        <Content>
          <HR></HR>
          {children}
        </Content>
      )}
    </HoverableCard>
  );
};

export default ExpandableCard;
