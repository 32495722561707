import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { TalentCourseCategory } from "../../shared/enums";
import { Card } from "../shared/Card";
import { H4, Span } from "../shared/typography";

export const CourseCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TalentCourseCard = styled(Card)<{ checked?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  // Border box doesn't seem to want to do what we want, so we retain the 1px border
  // But add/hack a inset box shadow to provide the 2px border
  ${(props) =>
    props.checked && `border: 1px solid ${props.theme.colors.primary300};`};
  ${(props) =>
    props.checked &&
    `box-shadow: 0 0 0 1px ${props.theme.colors.primary300}, ${props.theme.shadows.cardShadow};`};
  transition: none;
  @media screen and (min-width: ${(props) => props.theme.sizes.breakpointS}) {
    padding: ${(props) => props.theme.sizes.spacing3};
  }
`;

const TalentIcon = styled.img`
  width: ${(props) => props.theme.sizes.spacing8};
  height: ${(props) => props.theme.sizes.spacing8};
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    margin-right: 12px;
    width: ${(props) => props.theme.sizes.spacing4};
    height: ${(props) => props.theme.sizes.spacing4};
    position: absolute;
    left: 20px;
    top: 20px;
  }
`;

export const TextContainer = styled.div`
  flex-grow: 3;
  margin: 0 ${(props) => props.theme.sizes.spacing1} 0
    ${(props) => props.theme.sizes.spacing3};
  overflow: hidden;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    margin: 0px;
    padding-top: 36px;
  }
`;

export const CourseInfo = styled.div<{ progress: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props) => props.progress && "justify-content: space-between;"}
  white-space: nowrap;
  margin: ${(props) => (props.progress ? "6px 0 12px 0" : "8px 0 0")};
  @media screen and (min-width: ${(props) => props.theme.sizes.breakpointS}) {
    white-space: none;
    flex-wrap: wrap;
  }
`;

const Checkbox = styled.div`
  margin-right: 12px;
  input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    height: ${(props) => props.theme.sizes.spacing3};
    width: ${(props) => props.theme.sizes.spacing3};
    border: 2px solid ${(props) => props.theme.colors.lightGrey};
    border-radius: 4px;
    margin: 0px;
  }

  input[type="checkbox"]:after {
    content: "";
    position: relative;
    left: 40%;
    top: 20%;
    width: 15%;
    height: 40%;
    border: solid ${(props) => props.theme.colors.primary300};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
  }

  input[type="checkbox"]:checked {
    border: 2px solid ${(props) => props.theme.colors.primary300};
    background: white;
  }

  input[type="checkbox"]:checked:after {
    display: block;
  }
`;

export const getIconURL = (talentCourseCategory: TalentCourseCategory) => {
  switch (talentCourseCategory) {
    case TalentCourseCategory.CARDIOVASCULAR:
      return "/static/assets/heart.svg";
    case TalentCourseCategory.NEUROVASCULAR:
      return "/static/assets/brain.svg";
    case TalentCourseCategory.ENDOVASCULAR:
      return "/static/assets/bloodvessel.svg";
    case TalentCourseCategory.PERIPHERAL:
      return "/static/assets/body.svg";
    case TalentCourseCategory.OTHER:
      return "/static/assets/hat.svg";
    default:
      return "/static/assets/hat.svg";
  }
};

interface CourseCardProps {
  subtitle?: string | null;
  category: TalentCourseCategory;
  learningSpaceName?: string;
  code?: string | null;
  title?: string | null;
  onClick?: () => void;
  onCheckboxClick?: (event: React.FormEvent<HTMLInputElement>) => void;
  checkboxChecked?: boolean;
  progressBar?: React.ReactNode;
}

const CourseCard: React.FC<CourseCardProps> = ({
  children,
  subtitle,
  category,
  learningSpaceName,
  code,
  title,
  onClick,
  onCheckboxClick,
  checkboxChecked,
  progressBar,
}) => {
  const theme = useContext(ThemeContext);
  const getReadableStatus = (status: string) =>
    status.charAt(0).toUpperCase() + status.slice(1).split("_").join(" ");

  return (
    <CourseCheckboxWrapper>
      {onCheckboxClick && (
        <Checkbox>
          <input
            type="checkbox"
            checked={checkboxChecked}
            onChange={onCheckboxClick}
          ></input>
        </Checkbox>
      )}
      <TalentCourseCard onClick={onClick} checked={checkboxChecked}>
        <TalentIcon src={getIconURL(category)} alt={learningSpaceName} />
        <TextContainer>
          <H4>{title}</H4>
          {(subtitle || code) && (
            <CourseInfo progress={!!progressBar}>
              {subtitle && (
                <Span style={{ color: theme.colors.darkGrey }}>
                  {getReadableStatus(subtitle)}
                </Span>
              )}
              {code && <Span style={{ color: theme.colors.grey }}>{code}</Span>}
            </CourseInfo>
          )}
          {progressBar && progressBar}
        </TextContainer>
        {children}
      </TalentCourseCard>
    </CourseCheckboxWrapper>
  );
};

export default CourseCard;
