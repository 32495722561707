import React, { useState } from "react";
import styled from "styled-components";
import { SidebarCardSection } from "../../components/shared/sidebar-card/SidebarCardSection";
import { Card } from "../../components/shared/Card";
import { H3 } from "../../components/shared/typography";
import { gql, QueryResult, useQuery } from "@apollo/client";
import { useHistory, useRouteMatch } from "react-router";
import SelectField from "../../components/shared/SelectField";
import { Option } from "react-select/src/filters";
import Attending from "../../components/icons/Attending";
import Hospital from "../../components/icons/Hospital";
import { Customer } from "../../generated/graphql";
import { SidebarCardSearch } from "../../components/shared/sidebar-card/SidebarCard";

const TalentBranchCard = styled(Card)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: ${(props) => props.theme.sizes.spacing3};
  box-sizing: border-box;
  ${(props) => props.disabled && "pointer-events: none;"}
  > :first-child {
    margin-right: ${(props) => props.theme.sizes.spacing3};
  }
`;

const CustomerDropdown = styled.div`
  width: calc(100% * 2 / 3);
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    width: 100%;
  }
`;

interface AttendingsCustomer {
  name: string;
  id: number;
}

const Logbook = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const [selectedCustomer, setSelectedCustomer] = useState<Option | undefined>(
    undefined
  );

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: adminData,
  }: QueryResult<{
    customers: Customer[];
  }> = useQuery(
    gql`
      query customers {
        customers {
          id
          name
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data.customers.length > 0) {
          setSelectedCustomer({
            label: data.customers[0].name,
            value: `${data.customers[0].id}`,
            data: undefined,
          });
        }
      },
    }
  );
  const handleCardClick = (destination: "attendings" | "hospitals") => {
    history.push(`${match.path}/${destination}/${selectedCustomer?.value}`);
  };

  return (
    <SidebarCardSection title="Logbook" maxColumns={1}>
      <CustomerDropdown id="cy-customer-dropdown">
        {adminData?.customers && (
          <SidebarCardSearch>
            <SelectField
              label="Accounts"
              name="attendingCustomers"
              onChange={(option: Option) => setSelectedCustomer(option)}
              options={adminData?.customers.map(
                (customer: AttendingsCustomer) => ({
                  value: customer.id,
                  label: customer.name,
                })
              )}
              defaultValue={
                adminData.customers.length > 0
                  ? {
                      label: adminData.customers[0].name,
                      value: adminData.customers[0].id,
                    }
                  : undefined
              }
            />
          </SidebarCardSearch>
        )}
      </CustomerDropdown>
      <SidebarCardSection maxColumns={2}>
        <TalentBranchCard
          id="cy-attendings-card"
          onClick={() => handleCardClick("attendings")}
          disabled={!selectedCustomer}
        >
          <Attending />
          <H3>Attendings</H3>
        </TalentBranchCard>
        <TalentBranchCard
          onClick={() => handleCardClick("hospitals")}
          disabled={!selectedCustomer}
        >
          <Hospital />
          <H3>Hospitals</H3>
        </TalentBranchCard>
      </SidebarCardSection>
    </SidebarCardSection>
  );
};

export default Logbook;
