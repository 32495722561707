import {
  gql,
  QueryResult,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import React, { useState } from "react";
import GroupCard from "../../components/Groups/GroupCard";
import Button from "../../components/shared/Button";
import { MenuItem } from "../../components/shared/context-menu/MenuItem";
import Dialog from "../../components/shared/Dialog";
import LoadingIndicator, {
  renderLoadingText,
} from "../../components/shared/LoadingIndicator";
import { SidebarCardSection } from "../../components/shared/sidebar-card/SidebarCardSection";
import {
  CustomerGroup,
  GroupRole,
  RefetchPayload,
  TalentCourse,
  User,
} from "../../generated/graphql";
import { KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import { useToast } from "../../lib/useToast";
import { ToastFormat, ToastType } from "../../shared/enums";

const Groups: React.FC = () => {
  const { keycloak } = useKeycloak();
  const [, createToast] = useToast();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const [leaveGroupData, setLeaveGroupData] = useState<number | undefined>(
    undefined
  );
  const {
    data,
  }: QueryResult<{
    customerGroups: CustomerGroup[];
  }> = useQuery(
    gql`
      query FetchCustomerGroups($roles: [GroupRole!]) {
        customerGroups(roles: $roles) {
          id
          name
          description
          assignedCourses
          admins {
            first_name
            last_name
            email
          }
          members {
            first_name
            last_name
            email
          }
          customer {
            id
            name
          }
        }
      }
    `,
    {
      variables: {
        roles: [GroupRole.Learner],
      },
    }
  );

  const [
    fetchGroupCourses,
    { data: groupCoursesData, loading: loadingGroupCourses },
  ] = useLazyQuery<{ coursesByGroup: TalentCourse[] }>(
    gql`
      query GetCoursesByGroup($customerGroupId: Int!) {
        coursesByGroup(customerGroupId: $customerGroupId) {
          id
          name
        }
      }
    `,
    /* Make sure this list is always up-to-date */
    { fetchPolicy: "network-only" }
  );

  const [leaveGroup, { loading: loadingLeaveGroup }] = useMutation<{
    leaveGroup: RefetchPayload;
  }>(
    gql`
      mutation LeaveGroup($customerGroupId: Int!, $roles: [GroupRole!]) {
        leaveGroup(customerGroupId: $customerGroupId) {
          refetch {
            customerGroups(roles: $roles) {
              id
              name
              description
              assignedCourses
              admins {
                first_name
                last_name
                email
              }
              members {
                first_name
                last_name
                email
              }
              customer {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setLeaveGroupData(undefined);
        createToast({
          title: "Successfully left group.",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError: () => {
        createToast({
          title: "Something went wrong trying to leave group. Try again later.",
          type: ToastType.ERROR,
          format: ToastFormat.TOAST,
        });
      },
    }
  );

  const handleLeaveGroup = (groupId: number) => {
    setLeaveGroupData(groupId);
    fetchGroupCourses({ variables: { customerGroupId: groupId } });
  };

  const isUserMemberOfGroup = (members: User[], userEmail: string) =>
    members.map((member) => member.email).includes(userEmail);

  const getAssignedCoursesByGroup = (
    groupCoursesByGroup: TalentCourse[],
    customerGroups: CustomerGroup[],
    customerGroupId: number
  ): TalentCourse[] => {
    let assignedCourses: TalentCourse[] = [];
    // All courses on group
    groupCoursesByGroup.forEach((course) => {
      // All assigned learner groups
      customerGroups.forEach((assignedGroup) => {
        if (customerGroupId === assignedGroup.id) {
          assignedGroup?.assignedCourses?.forEach((assignedCourse) => {
            // Return course to unenroll if course is assigned course
            if (assignedCourse === course.id) {
              assignedCourses.push(course);
            }
          });
        }
      });
    });
    return assignedCourses;
  };

  const assignedCoursesToUnenroll =
    data?.customerGroups &&
    groupCoursesData?.coursesByGroup &&
    leaveGroupData &&
    getAssignedCoursesByGroup(
      groupCoursesData.coursesByGroup,
      data.customerGroups,
      leaveGroupData
    );

  return (
    <SidebarCardSection maxColumns={2} title="Your Groups">
      {data?.customerGroups.map((customerGroup) => (
        <GroupCard
          key={customerGroup.id}
          name={customerGroup.name}
          admin={customerGroup.admins?.[0]}
          members={customerGroup.members}
          customerName={customerGroup.customer?.name ?? ""}
          description={customerGroup.description}
        >
          <>
            <MenuItem
              onClick={() => {
                if (customerGroup.admins?.[0]?.email) {
                  document.location.href =
                    "mailto:" + customerGroup.admins?.[0]?.email;
                }
              }}
            >
              Contact Admin
            </MenuItem>
            {customerGroup.members &&
              user?.email &&
              isUserMemberOfGroup(customerGroup.members, user.email) && (
                <MenuItem onClick={() => handleLeaveGroup(customerGroup.id)}>
                  Leave Group
                </MenuItem>
              )}
          </>
        </GroupCard>
      ))}
      {leaveGroupData && (
        <Dialog
          title="Leave Group"
          onClose={() => setLeaveGroupData(undefined)}
          buttons={
            <>
              <Button
                secondary={true}
                onClick={() => setLeaveGroupData(undefined)}
              >
                Cancel
              </Button>
              <Button
                onClick={() =>
                  leaveGroup({
                    variables: {
                      customerGroupId: leaveGroupData,
                      roles: [GroupRole.Learner],
                    },
                  })
                }
                disabled={loadingGroupCourses || loadingLeaveGroup}
              >
                {renderLoadingText(loadingLeaveGroup, "Leave group", "Saving")}
              </Button>
            </>
          }
        >
          {loadingGroupCourses && <LoadingIndicator />}
          {!loadingGroupCourses && assignedCoursesToUnenroll && (
            <>
              {assignedCoursesToUnenroll.length !== 0 ? (
                <>
                  Leaving group will remove your membership access to the
                  following courses:
                  <ul>
                    {assignedCoursesToUnenroll.map((course) => (
                      <li key={course.id}>{course.name}</li>
                    ))}
                  </ul>
                  Are you sure you want to leave?
                </>
              ) : (
                <>Leaving group will not remove your access from any courses.</>
              )}
            </>
          )}
        </Dialog>
      )}
    </SidebarCardSection>
  );
};

export default Groups;
