import React, { useEffect } from "react";
import styled from "styled-components";
import { NavBar } from "./NavBar";
import { Text } from "../shared/typography";
import { getServiceTag } from "../../lib/getServiceTag";

const Background = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.colors.paleGrey};
`;

const Body = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: calc(1128px + 24px + 24px);
  padding: 0 24px 24px 24px;
  min-height: calc(100vh - 96px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const Content = styled.main`
  grid-area: content;
`;

const Footer = styled(Text)`
  opacity: 0.5;
  text-align: center;
  margin-top: 96px;
`;

interface IProps {
  title?: string;
  hideNavBar?: boolean;
}
export const PageLayout: React.FC<IProps> = ({
  title,
  hideNavBar,
  children
}) => {

  useEffect(() => {
    document.title = title ? title : ""
  }, [title]);

  return (
    <>
      <Background>
        {!hideNavBar && <NavBar></NavBar>}

        <Body>
          <Content>{children}</Content>
          <Footer>
            Copyright {new Date().getFullYear()} Mentice. All rights reserved.
            {getServiceTag() && " Service Tag: " + getServiceTag()}
          </Footer>
        </Body>
      </Background>
    </>
  );
};
