import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ButtonSizes } from "../../../shared/enums";
import Button from "../Button";
import { CloseButton } from "../Modal";
import { H3 } from "../typography";
import DropdownFilter from "./DropdownFilter";

const MenuWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;

  img {
    cursor: pointer;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
`;

const DropdownCloseButton = styled(CloseButton)`
  top: 0;
  right: 0;
  z-index: 0;
`;

interface DropdownMenuProps {
  children: React.ReactNode;
  title?: string;
  icon: string;
  isFilter?: boolean;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  children,
  title,
  icon,
  isFilter,
}) => {
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleClick = (e: MouseEvent) => {
    if (menuRef.current?.contains(e.target as Node)) {
      return;
    } else {
      setDropdownMenu(false);
    }
  };

  const handleMoreMenuClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setDropdownMenu(!dropdownMenu);
  };
  return (
    <MenuWrapper ref={menuRef}>
      <Button
        id="cy-filter-button"
        secondary
        icon={icon}
        onClick={handleMoreMenuClick}
        size={ButtonSizes.Medium}
      >
        {title}
      </Button>

      {dropdownMenu &&
        (isFilter ? (
          <DropdownFilter>
            <Header>
              <H3>{title}</H3>
              <DropdownCloseButton onClick={() => setDropdownMenu(false)} />
            </Header>

            {children}
          </DropdownFilter>
        ) : (
          children
        ))}
    </MenuWrapper>
  );
};
