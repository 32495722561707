import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { SortOrder, ToastFormat, ToastType } from "../../shared/enums";
import { Section } from "../Page/Section";
import ActionBar, { SelectFieldWidthProvider } from "../shared/ActionBar";
import CloudCaseDialog from "./CloudCaseDialog";
import {
  CloudCaseVirtualMachine,
  Maybe,
  SingleUserCloudModulesResourceAccessVoucher,
  TalentBranch,
} from "../../generated/graphql";
import { useToast } from "../../lib/useToast";
import { useHistory, useLocation } from "react-router";
import { getVoucherCodeErrorMessage } from "../../lib/getErrorMessage";
import SelectField from "../shared/SelectField";
import { Option } from "react-select/src/filters";
import {
  formatDateShortMonth,
  getFormattedDurationStringFromSeconds,
} from "../../lib/formatDateAndTime";
import { CloudCaseCard } from "./CloudCaseCard";
import { H3 } from "../shared/typography";
import { cloudCaseVMFields } from "../../types/fragments/CloudCaseVMFragment";

interface CloudCasesProps {
  showActionBar: boolean;
  setExistsCloudCases?: (existsCloudCases: boolean) => void;
}

type MergedVoucher = {
  durationLeftInSeconds: number;
  voucherId: number;
  validTo: string;
  createdAt: string;
  cases: {
    learningSpace: TalentBranch;
    moduleId: string;
    moduleName: string;
    caseId: string;
    caseName: string;
    caseDescription: string;
  }[];
  expired: boolean;
};

type MergedModule = {
  learningSpace: TalentBranch;
  moduleId: string;
  name: string;
  cases: {
    caseId: string;
    name: string;
    description: string;
    vouchers: {
      durationLeftInSeconds: number;
      voucherId: number;
      validTo: string;
    }[];
  }[];
};

export type MergedCloudCase = {
  learningSpace: TalentBranch;
  moduleId: string;
  moduleName: string;
  caseId: string;
  caseName: string;
  caseDescription: string;
  vouchers: {
    durationLeftInSeconds: number;
    voucherId: number;
    validTo: string;
  }[];
};

export const getCloudModulesResourceAccessVouchersAsTraineeFields = gql`
  fragment GetCloudModulesResourceAccessVouchersAsTraineeFields on SingleUserCloudModulesResourceAccessVoucher {
    voucherId
    customer {
      id
      name
    }
    learningSpace {
      id
      code
      name
      description
      image
    }
    modules {
      moduleId
      name
      description
      cases {
        caseId
        name
        description
      }
    }
    durationLeftInSeconds
    validTo
    createdAt
    expired
  }
`;

const CloudCases: React.FC<CloudCasesProps> = ({
  showActionBar,
  setExistsCloudCases,
}) => {
  const [, createToast] = useToast();
  const history = useHistory();
  const location = useLocation();

  const inviteCode = new URLSearchParams(location.search).get("inviteCode");

  const { data } = useQuery<{
    getVmAssignedToMe: Maybe<CloudCaseVirtualMachine>;
    getCloudModulesResourceAccessVouchersAsTrainee: SingleUserCloudModulesResourceAccessVoucher[];
  }>(
    gql`
      query GetCloudCaseVouchersAndVms {
        getVmAssignedToMe {
          ...CloudCaseVMFields
        }
        getCloudModulesResourceAccessVouchersAsTrainee {
          ...GetCloudModulesResourceAccessVouchersAsTraineeFields
        }
      }
      ${cloudCaseVMFields}
      ${getCloudModulesResourceAccessVouchersAsTraineeFields}
    `,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const hasVouchersThatAreNotExpired =
          data.getCloudModulesResourceAccessVouchersAsTrainee.some(
            (voucher) => !voucher.expired
          );

        setSelectedGroupOrderOption(
          hasVouchersThatAreNotExpired
            ? {
                value: "Module (A-Z)",
                label: "Module (A-Z)",
                data: {
                  sortOrder: SortOrder.AZ,
                },
              }
            : {
                value: "Voucher (Expires First)",
                label: "Voucher (Expires First)",
                data: {
                  sortOrder: SortOrder.AZ,
                },
              }
        );

        setMergedVouchersModulesAndCloudCases(
          data.getCloudModulesResourceAccessVouchersAsTrainee
        );

        if (
          data.getCloudModulesResourceAccessVouchersAsTrainee.length === 0 &&
          setExistsCloudCases
        ) {
          setExistsCloudCases(false);
        }
      },
    }
  );

  const hasVouchersThatAreNotExpired =
    data?.getCloudModulesResourceAccessVouchersAsTrainee.some(
      (voucher) => !voucher.expired
    );

  const groupOrderOptions = [
    ...(hasVouchersThatAreNotExpired
      ? [
          {
            value: "Module (A-Z)",
            label: "Module (A-Z)",
            data: {
              sortOrder: SortOrder.AZ,
            },
          },
          {
            value: "Module (Z-A)",
            label: "Module (Z-A)",
            data: {
              sortOrder: SortOrder.ZA,
            },
          },
        ]
      : []),
    ...(hasVouchersThatAreNotExpired
      ? [
          {
            value: "Case (A-Z)",
            label: "Case (A-Z)",
            data: {
              sortOrder: SortOrder.AZ,
            },
          },
          {
            value: "Case (Z-A)",
            label: "Case (Z-A)",
            data: {
              sortOrder: SortOrder.ZA,
            },
          },
        ]
      : []),
    {
      value: "Voucher (Expires First)",
      label: "Voucher (Expires First)",
      data: {
        sortOrder: SortOrder.AZ,
      },
    },
    {
      value: "Voucher (Expires Last)",
      label: "Voucher (Expires Last)",
      data: {
        sortOrder: SortOrder.ZA,
      },
    },
    {
      value: "Voucher (Recently Added)",
      label: "Voucher (Recently Added)",
      data: {
        sortOrder: SortOrder.ZA,
      },
    },
  ];

  const [selectedGroupOrderOption, setSelectedGroupOrderOption] =
    useState<Option>(groupOrderOptions[0]);
  const [selectedMergedCloudCase, setSelectedMergedCloudCase] =
    useState<MergedCloudCase>();
  const [mergedVouchers, setMergedVouchers] = useState<MergedVoucher[]>([]);
  const [mergedModules, setMergedModules] = useState<MergedModule[]>([]);
  const [mergedCloudCases, setMergedCloudCases] = useState<MergedCloudCase[]>(
    []
  );
  const setMergedVouchersModulesAndCloudCases = (
    vouchers: SingleUserCloudModulesResourceAccessVoucher[]
  ) => {
    setMergedVouchers(
      singleUserCloudModulesResourceAccessVouchersToMergedVouchers(vouchers)
    );
    setMergedModules(
      singleUserCloudModulesResourceAccessVouchersToMergedModules(vouchers)
    );
    setMergedCloudCases(
      singleUserCloudModulesResourceAccessVouchersToMergedCloudCases(vouchers)
    );
  };

  const [acceptResourceAccessVoucher] = useMutation(
    gql`
      mutation AcceptResourceAccessVoucher($code: String!) {
        acceptResourceAccessVoucher(code: $code) {
          refetch {
            # TODO: softcode duration
            singleUserCloudCaseVMAvailable(durationInSeconds: 3600)
            getVmAssignedToMe {
              ...CloudCaseVMFields
            }
            getCloudModulesResourceAccessVouchersAsTrainee {
              ...GetCloudModulesResourceAccessVouchersAsTraineeFields
            }
          }
        }
      }
      ${cloudCaseVMFields}
      ${getCloudModulesResourceAccessVouchersAsTraineeFields}
    `,
    {
      variables: { code: inviteCode },
      onCompleted(data) {
        setMergedVouchersModulesAndCloudCases(
          data.acceptResourceAccessVoucher.refetch
            .getCloudModulesResourceAccessVouchersAsTrainee
        );
        createToast({
          title: "Accepted voucher and gained access to cloud cases.",
          type: ToastType.SUCCESS,
          format: ToastFormat.BANNER,
        });

        if (
          data.acceptResourceAccessVoucher.refetch
            .getCloudModulesResourceAccessVouchersAsTrainee.length > 0 &&
          setExistsCloudCases
        ) {
          setExistsCloudCases(true);
        }
      },
      onError(err) {
        console.log(err);
        createToast({
          title: getVoucherCodeErrorMessage(
            err.graphQLErrors[0].extensions?.code
          ),
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  // Trigger accept voucher request when inviteCode entered into address bar
  useEffect(() => {
    if (inviteCode && inviteCode.startsWith("cmrav")) {
      acceptResourceAccessVoucher();
      history.push("/");
    }
  }, [inviteCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const singleUserCloudModulesResourceAccessVouchersToMergedVouchers = (
    singleUserCloudModulesResourceAccessVouchers: SingleUserCloudModulesResourceAccessVoucher[]
  ): MergedVoucher[] => {
    const mergedVouchers: MergedVoucher[] = [];
    singleUserCloudModulesResourceAccessVouchers.forEach((voucher) => {
      const cases: {
        learningSpace: TalentBranch;
        moduleId: string;
        moduleName: string;
        caseId: string;
        caseName: string;
        caseDescription: string;
      }[] = [];
      voucher.modules.forEach((module) => {
        module.cases.forEach((cloudCase) => {
          cases.push({
            learningSpace: voucher.learningSpace,
            moduleId: module.moduleId,
            moduleName: module.name,
            caseId: cloudCase.caseId,
            caseName: cloudCase.name,
            caseDescription: cloudCase.description,
          });
        });
      });
      mergedVouchers.push({
        durationLeftInSeconds: voucher.durationLeftInSeconds,
        voucherId: voucher.voucherId,
        validTo: voucher.validTo,
        createdAt: voucher.createdAt,
        expired: voucher.expired,
        cases,
      });
    });
    return mergedVouchers;
  };

  const singleUserCloudModulesResourceAccessVouchersToMergedModules = (
    vouchers: SingleUserCloudModulesResourceAccessVoucher[]
  ): MergedModule[] => {
    const mergedModules: MergedModule[] = [];

    vouchers.forEach((voucher) => {
      if (voucher.expired) {
        return;
      }
      voucher.modules.forEach((module) => {
        // Skip modules with no cases
        if (module.cases.length === 0) {
          return;
        }

        const existingMergedModule = mergedModules.find(
          (existingMergedModule) =>
            existingMergedModule.moduleId === module.moduleId
        );
        if (existingMergedModule) {
          module.cases.forEach((cloudCase) => {
            const existingCloudCase = existingMergedModule.cases.find(
              (existingCase) => existingCase.caseId === cloudCase.caseId
            );
            if (existingCloudCase) {
              existingCloudCase.vouchers.push({
                durationLeftInSeconds: voucher.durationLeftInSeconds,
                voucherId: voucher.voucherId,
                validTo: voucher.validTo,
              });
            } else {
              existingMergedModule.cases.push({
                caseId: cloudCase.caseId,
                name: cloudCase.name,
                description: cloudCase.description,
                vouchers: [
                  {
                    durationLeftInSeconds: voucher.durationLeftInSeconds,
                    voucherId: voucher.voucherId,
                    validTo: voucher.validTo,
                  },
                ],
              });
            }
          });
        } else {
          mergedModules.push({
            learningSpace: voucher.learningSpace,
            moduleId: module.moduleId,
            name: module.name,
            cases: module.cases.map((cloudCase) => ({
              caseId: cloudCase.caseId,
              name: cloudCase.name,
              description: cloudCase.description,
              vouchers: [
                {
                  durationLeftInSeconds: voucher.durationLeftInSeconds,
                  voucherId: voucher.voucherId,
                  validTo: voucher.validTo,
                },
              ],
            })),
          });
        }
      });
    });

    // Filter out modules with no cases
    return mergedModules.filter((module) => module.cases.length > 0);
  };

  const singleUserCloudModulesResourceAccessVouchersToMergedCloudCases = (
    vouchers: SingleUserCloudModulesResourceAccessVoucher[]
  ): MergedCloudCase[] => {
    const mergedCloudCases: MergedCloudCase[] = [];
    vouchers.forEach((voucher) => {
      if (voucher.expired) {
        return;
      }
      voucher.modules.forEach((module) => {
        module.cases.forEach((cloudCase) => {
          const existingMergedCloudCase = mergedCloudCases.find(
            (existingMergedCloudCase) =>
              existingMergedCloudCase.caseId === cloudCase.caseId
          );
          if (existingMergedCloudCase) {
            existingMergedCloudCase.vouchers.push({
              durationLeftInSeconds: voucher.durationLeftInSeconds,
              voucherId: voucher.voucherId,
              validTo: voucher.validTo,
            });
          } else {
            mergedCloudCases.push({
              learningSpace: voucher.learningSpace,
              moduleId: module.moduleId,
              moduleName: module.name,
              caseId: cloudCase.caseId,
              caseName: cloudCase.name,
              caseDescription: cloudCase.description,
              vouchers: [
                {
                  durationLeftInSeconds: voucher.durationLeftInSeconds,
                  voucherId: voucher.voucherId,
                  validTo: voucher.validTo,
                },
              ],
            });
          }
        });
      });
    });
    return mergedCloudCases;
  };

  const handleDeselectCloudCaseCard = () => {
    setSelectedMergedCloudCase(undefined);
  };

  const sortedMergedVouchers: MergedVoucher[] = mergedVouchers
    .map(
      // Tertiary sort case list alphabetically on module and case name
      (mergedVoucher) => {
        return {
          ...mergedVoucher,
          cases: mergedVoucher.cases.sort((case1, case2) => {
            return case1.moduleName > case2.moduleName ||
              (case1.moduleName === case2.moduleName &&
                case1.caseName > case2.caseName)
              ? 1
              : -1;
          }),
        };
      }
    )
    .sort((voucher1, voucher2) => {
      // Secondary sort vouchers by expiry date
      switch (selectedGroupOrderOption.value) {
        case "Voucher (Expires First)":
          return new Date(voucher1.validTo) > new Date(voucher2.validTo)
            ? -1
            : 1;
        case "Voucher (Expires Last)":
          return new Date(voucher1.validTo) > new Date(voucher2.validTo)
            ? 1
            : -1;
        case "Voucher (Recently Added)":
          return new Date(voucher1.createdAt) > new Date(voucher2.createdAt)
            ? -1
            : 1;
        default:
          return 0;
      }
    })
    .sort((voucher1, voucher2) =>
      voucher1.expired && !voucher2.expired ? 1 : -1
    );

  const voucherToShowOnHomePage: MergedVoucher | null =
    [...sortedMergedVouchers].sort((voucher1, voucher2) => {
      return new Date(voucher1.createdAt) > new Date(voucher2.createdAt)
        ? -1
        : 1;
    })[0] ?? null;

  const sortedMergedModules: MergedModule[] = mergedModules
    .map((mergedModule) => {
      // Secondary sort cases within each module
      return {
        ...mergedModule,
        cases: mergedModule.cases.sort((case1, case2) => {
          switch (selectedGroupOrderOption.value) {
            case "Module (A-Z)":
              return case1.name > case2.name ? 1 : -1;
            case "Module (Z-A)":
              return case1.name > case2.name ? -1 : 1;
            default:
              return 0;
          }
        }),
      };
    })
    .sort(
      // Primary sort by module
      (module1, module2) => {
        switch (selectedGroupOrderOption.value) {
          case "Module (A-Z)":
            return module1.name > module2.name ? 1 : -1;
          case "Module (Z-A)":
            return module1.name > module2.name ? -1 : 1;
          default:
            return 0;
        }
      }
    );

  const sortedMergedCases: MergedCloudCase[] = mergedCloudCases.sort(
    (case1, case2) => {
      switch (selectedGroupOrderOption.value) {
        case "Case (A-Z)":
          return case1.caseName > case2.caseName ? 1 : -1;
        case "Case (Z-A)":
          return case1.caseName > case2.caseName ? -1 : 1;
        default:
          return 0;
      }
    }
  );

  const shouldShowByCases =
    selectedGroupOrderOption.value === "Case (A-Z)" ||
    selectedGroupOrderOption.value === "Case (Z-A)";
  const shouldShowByModules =
    selectedGroupOrderOption.value === "Module (A-Z)" ||
    selectedGroupOrderOption.value === "Module (Z-A)";
  const shouldShowByVouchers =
    (selectedGroupOrderOption.value === "Voucher (Expires First)" ||
      selectedGroupOrderOption.value === "Voucher (Expires Last)" ||
      selectedGroupOrderOption.value === "Voucher (Recently Added)") &&
    showActionBar;

  // Check if there are _any_ vouchers to show
  // regardless of whether we're on the homepage or not
  // if not, return null
  return voucherToShowOnHomePage ? (
    <>
      {showActionBar && (
        <ActionBar
          backTo="/"
          title={"Cloud Cases"}
          buttonGroup={
            <>
              <SelectFieldWidthProvider>
                <SelectField
                  name="List by"
                  isActionBar={true}
                  onChange={(option: Option) =>
                    setSelectedGroupOrderOption(option)
                  }
                  value={{
                    value: selectedGroupOrderOption.value,
                    label: `Sort by: ${selectedGroupOrderOption.label}`,
                    data: {
                      sortField: selectedGroupOrderOption.data.sortField,
                      sortOrder: selectedGroupOrderOption.data.sortOrder,
                    },
                  }}
                  options={groupOrderOptions}
                />
              </SelectFieldWidthProvider>
            </>
          }
        />
      )}
      {showActionBar && shouldShowByCases && (
        <Section title="All Cases" maxColumns={4}>
          {sortedMergedCases.map((cloudCase) => (
            <CloudCaseCard
              caseName={cloudCase.caseName}
              moduleName={cloudCase.moduleName}
              learningSpace={cloudCase.learningSpace}
              key={`${cloudCase.moduleId}${cloudCase.caseId}`}
              onClick={() =>
                setSelectedMergedCloudCase({
                  learningSpace: cloudCase.learningSpace,
                  moduleId: cloudCase.moduleId,
                  moduleName: cloudCase.caseName,
                  caseId: cloudCase.caseId,
                  caseName: cloudCase.caseName,
                  caseDescription: cloudCase.caseDescription,
                  vouchers: cloudCase.vouchers,
                })
              }
            />
          ))}
        </Section>
      )}
      {showActionBar &&
        shouldShowByModules &&
        sortedMergedModules.map((mergedModule) => (
          <Section
            title={mergedModule.name}
            maxColumns={4}
            key={mergedModule.moduleId}
          >
            {mergedModule.cases.map((cloudCase) => (
              <CloudCaseCard
                key={`${mergedModule.moduleId}${cloudCase.caseId}`}
                caseName={cloudCase.name}
                moduleName={mergedModule.name}
                learningSpace={mergedModule.learningSpace}
                onClick={() =>
                  setSelectedMergedCloudCase({
                    learningSpace: mergedModule.learningSpace,
                    moduleId: mergedModule.moduleId,
                    moduleName: mergedModule.name,
                    caseId: cloudCase.caseId,
                    caseName: cloudCase.name,
                    caseDescription: cloudCase.description,
                    vouchers: cloudCase.vouchers,
                  })
                }
              />
            ))}
          </Section>
        ))}
      {showActionBar &&
        shouldShowByVouchers &&
        sortedMergedVouchers.map((voucher) => {
          const sectionTitle = (() => {
            switch (selectedGroupOrderOption.value) {
              case "Voucher (Recently Added)":
                // If max cases to show, then on homepage
                // Sort by recently created
                return voucher.expired
                  ? `Expired ${formatDateShortMonth(new Date(voucher.validTo))}`
                  : `Added ${formatDateShortMonth(
                      new Date(voucher.createdAt)
                    )} — ${
                      voucher.durationLeftInSeconds
                        ? getFormattedDurationStringFromSeconds(
                            voucher.durationLeftInSeconds
                          )
                        : "0m"
                    } left`;
              case "Voucher (Expires First)":
              case "Voucher (Expires Last)":
              default:
                return `${
                  voucher.expired
                    ? `Expired ${formatDateShortMonth(
                        new Date(voucher.validTo)
                      )}`
                    : `Expires ${formatDateShortMonth(
                        new Date(voucher.validTo)
                      )} — ${
                        voucher.durationLeftInSeconds
                          ? getFormattedDurationStringFromSeconds(
                              voucher.durationLeftInSeconds
                            )
                          : "0m"
                      } left`
                }`;
            }
          })();
          return (
            <Section
              title={sectionTitle}
              maxColumns={4}
              key={voucher.voucherId}
            >
              {voucher.cases.map((cloudCase) => (
                <CloudCaseCard
                  caseName={cloudCase.caseName}
                  moduleName={cloudCase.moduleName}
                  learningSpace={cloudCase.learningSpace}
                  key={`${cloudCase.moduleId}${cloudCase.caseId}`}
                  disabled={voucher.expired}
                  onClick={() =>
                    setSelectedMergedCloudCase({
                      learningSpace: cloudCase.learningSpace,
                      moduleId: cloudCase.moduleId,
                      moduleName: cloudCase.caseName,
                      caseId: cloudCase.caseId,
                      caseName: cloudCase.caseName,
                      caseDescription: cloudCase.caseDescription,
                      vouchers: [voucher],
                    })
                  }
                />
              ))}
            </Section>
          );
        })}
      {!showActionBar && voucherToShowOnHomePage && (
        <Section
          title={"Cloud Cases"}
          maxColumns={4}
          key={voucherToShowOnHomePage.voucherId}
          primaryActionText={"View All"}
          primaryActionLink={"/cloud-cases"}
        >
          {voucherToShowOnHomePage.cases.map((cloudCase) => (
            <CloudCaseCard
              caseName={cloudCase.caseName}
              moduleName={cloudCase.moduleName}
              learningSpace={cloudCase.learningSpace}
              key={`${cloudCase.moduleId}${cloudCase.caseId}`}
              disabled={voucherToShowOnHomePage.expired}
              onClick={() =>
                setSelectedMergedCloudCase({
                  learningSpace: cloudCase.learningSpace,
                  moduleId: cloudCase.moduleId,
                  moduleName: cloudCase.caseName,
                  caseId: cloudCase.caseId,
                  caseName: cloudCase.caseName,
                  caseDescription: cloudCase.caseDescription,
                  vouchers: [voucherToShowOnHomePage],
                })
              }
            />
          ))}
        </Section>
      )}
      {!showActionBar && !voucherToShowOnHomePage && (
        <H3>Cloud cases (None available)</H3>
      )}
      {data && selectedMergedCloudCase && (
        /* poll for status on cloudcaseVm with case id */
        <CloudCaseDialog
          onClose={handleDeselectCloudCaseCard}
          cloudCase={selectedMergedCloudCase}
          hasStarted={!!data.getVmAssignedToMe}
        />
      )}
    </>
  ) : null;
};

export default CloudCases;