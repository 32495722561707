import React, { useEffect, useState } from "react";
import Dialog from "../shared/Dialog";
import {
  H2,
  LargeText,
  PrimaryBoldSpan,
  SemiBoldText,
} from "../shared/typography";
import { MergedCloudCase } from "./CloudCases";
import {
  CloudCaseVmStatus,
  RefetchPayload,
  CloudCaseVirtualMachine,
  Maybe,
} from "../../generated/graphql";
import Button from "../shared/Button";
import { EventText, InfoMessageStartBtn, InfoRow } from "../Events/EventCard";
import { renderLoadingText } from "../shared/LoadingIndicator";
import {
  ApolloError,
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { useToast } from "../../lib/useToast";
import { ToastFormat, ToastType } from "../../shared/enums";
import { useHistory } from "react-router";
import {
  formatDateShortMonth,
  getFormattedDurationStringFromSeconds,
} from "../../lib/formatDateAndTime";
import SelectField from "../shared/SelectField";
import styled from "styled-components";
import { convertBase64ToImage, getDefaultLogo } from "../../lib/convertBase64";
import { cloudCaseVMFields } from "../../types/fragments/CloudCaseVMFragment";

const LogoWrapper = styled.div`
  margin-top: -${(props) => props.theme.sizes.spacing2};
  img {
    height: ${(props) => props.theme.sizes.spacing4};
    width: ${(props) => props.theme.sizes.spacing15};
    object-fit: contain;
    object-position: left center;
  }
`;

const DialogContent = styled.div`
  margin-top: ${(props) => props.theme.sizes.spacing3};
`;

const RowWrapper = styled.div`
  margin-top: ${(p) => p.theme.sizes.spacing4};
  margin-bottom: ${(p) => p.theme.sizes.spacing4};
`;
interface CloudCaseDialogProps {
  onClose: () => void;
  cloudCase: MergedCloudCase;
  hasStarted: boolean;
}

type VoucherOption = {
  value: string;
  label: string;
  data: {
    durationLeftInSeconds: number;
    voucherId: number;
  };
};

const CloudCaseDialog: React.FC<CloudCaseDialogProps> = ({
  onClose,
  cloudCase,
  hasStarted,
}) => {
  const [, createToast] = useToast();
  const history = useHistory();

  const voucherOptions = cloudCase.vouchers.map((voucher) => ({
    value: voucher.voucherId.toString(),
    label: `${
      voucher.durationLeftInSeconds
        ? getFormattedDurationStringFromSeconds(voucher.durationLeftInSeconds)
        : "0m"
    } left — expires ${formatDateShortMonth(new Date(voucher.validTo))}`,
    data: voucher,
  }));
  const [selectedVoucherOption, setSelectedVoucherOption] =
    useState<VoucherOption>(voucherOptions[0]);
  const [startedCaseFromThisDialog, setStartedCaseFromThisDialog] =
    useState<boolean>(false);

  const [startSingleUserCloudCase] = useMutation(
    gql`
      mutation StartSingleUserCloudCase(
        $moduleId: String!
        $caseId: String!
        $voucherId: Int!
      ) {
        startSingleUserCloudCase(
          moduleId: $moduleId
          caseId: $caseId
          voucherId: $voucherId
        )
      }
    `,
    {
      onCompleted() {
        createToast({
          title: "Cloud Case starting",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError(err: ApolloError) {
        createToast({
          title: ((error) => {
            switch (error) {
              case "All VMs are currently taken or reserved. Please try again later.":
                return error;
              case "You have another ongoing case or event.":
                return error;
              default:
                return "Failed to start cloud case, please try again later";
            }
          })(err.graphQLErrors[0].message),
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  // In case ending on close button fails user can end case from card too
  const [
    stopCloudCaseSessionWithVoucherId,
    { loading: loadingStopCloudCaseSessionWithVoucherId },
  ] = useMutation<{
    stopCloudCaseSessionWithVoucherId: RefetchPayload;
  }>(
    gql`
      mutation StopCloudCaseSessionWithVoucherId($voucherId: Int!) {
        stopCloudCaseSessionWithVoucherId(voucherId: $voucherId) {
          refetch {
            getVmAssignedToMe {
              ...CloudCaseVMFields
            }
          }
        }
      }
      ${cloudCaseVMFields}
    `,
    {
      onCompleted() {
        createToast({
          title: "Cloud case ended",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError(err: ApolloError) {
        console.log(JSON.stringify(err));
        createToast({
          title: "Failed to end cloud case, please try again later",
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  const { data: availabilityData } = useQuery<{
    singleUserCloudCaseVMAvailable: boolean;
  }>(gql`
    query SingleUserCloudCaseVMAvailable {
      # TODO: softcode duration
      singleUserCloudCaseVMAvailable(durationInSeconds: 3600)
    }
  `);

  const [getVmAssignedToMe, { data }] = useLazyQuery<{
    getVmAssignedToMe: Maybe<CloudCaseVirtualMachine>;
  }>(
    gql`
      query GetVmAssignedToMe {
        getVmAssignedToMe {
          ...CloudCaseVMFields
        }
      }
      ${cloudCaseVMFields}
    `,
    {
      pollInterval: 2000,
    }
  );

  // Immediately start polling if has started
  useEffect(() => {
    if (hasStarted) {
      getVmAssignedToMe();
    }
  }, [hasStarted, getVmAssignedToMe]);

  useEffect(() => {
    if (
      startedCaseFromThisDialog &&
      data?.getVmAssignedToMe?.status === CloudCaseVmStatus.Started &&
      data?.getVmAssignedToMe?.module_id === cloudCase.moduleId &&
      data?.getVmAssignedToMe?.case_id === cloudCase.caseId
    ) {
      history.push(`/cloud-case/`);
    }
  }, [data, history, cloudCase, startedCaseFromThisDialog]);

  /* TODO: Also use for EventCard dialog */
  return (
    <Dialog
      onClose={onClose}
      buttons={(() => {
        // Not enough time left (less than 5 minutes)
        if (selectedVoucherOption.data.durationLeftInSeconds < 5 * 60) {
          return (
            <>
              <Button disabled={true}>Start</Button>
              <InfoMessageStartBtn error={true}>
                Not enough simulation time left
              </InfoMessageStartBtn>
            </>
          );
        }

        // Machine is claimed for this case
        if (
          data?.getVmAssignedToMe &&
          data?.getVmAssignedToMe.module_id === cloudCase.moduleId &&
          data?.getVmAssignedToMe.case_id === cloudCase.caseId
        ) {
          switch (data.getVmAssignedToMe.status) {
            case CloudCaseVmStatus.Off:
              return (
                <>
                  <Button disabled={true}>
                    {renderLoadingText(true, "Start", "Starting")}
                  </Button>
                  <InfoMessageStartBtn>
                    Something went wrong
                  </InfoMessageStartBtn>
                </>
              );
            case CloudCaseVmStatus.Desired:
              return (
                <>
                  <Button disabled={true}>
                    {renderLoadingText(true, "Start", "Starting")}
                  </Button>
                  <InfoMessageStartBtn>
                    Looking for vacant simulator
                  </InfoMessageStartBtn>
                </>
              );
            case CloudCaseVmStatus.Idle:
              return (
                <>
                  <Button disabled={true}>
                    {renderLoadingText(true, "Start", "Initiating")}
                  </Button>
                  <InfoMessageStartBtn>
                    Case should start shortly
                  </InfoMessageStartBtn>
                </>
              );
            case CloudCaseVmStatus.Starting:
              return (
                <>
                  <Button disabled={true}>
                    {renderLoadingText(true, "Start", "Starting")}
                  </Button>
                  <InfoMessageStartBtn>
                    Start-up time is approx. 3 minutes
                  </InfoMessageStartBtn>
                </>
              );
            case CloudCaseVmStatus.Started:
              return (
                <>
                  <Button
                    onClick={() =>
                      stopCloudCaseSessionWithVoucherId({
                        variables: {
                          voucherId: data.getVmAssignedToMe?.voucher_id,
                        },
                      })
                    }
                  >
                    {renderLoadingText(
                      loadingStopCloudCaseSessionWithVoucherId,
                      "End",
                      "Ending"
                    )}
                  </Button>
                  <Button onClick={() => history.push(`/cloud-case/`)}>
                    {renderLoadingText(false, "Join", "Starting")}
                  </Button>
                </>
              );
          }
        }

        // A machine is not claimed available to start
        if (
          availabilityData &&
          availabilityData.singleUserCloudCaseVMAvailable
        ) {
          return (
            <Button
              onClick={() => {
                startSingleUserCloudCase({
                  variables: {
                    moduleId: cloudCase.moduleId,
                    caseId: cloudCase.caseId,
                    voucherId: selectedVoucherOption.data.voucherId,
                  },
                });
                setStartedCaseFromThisDialog(true);
                getVmAssignedToMe();
              }}
              disabled={
                // Either loading start call, or start call finished but no machine poll yet
                startedCaseFromThisDialog
              }
            >
              {renderLoadingText(
                startedCaseFromThisDialog,
                "Start",
                "Starting"
              )}
            </Button>
          );
        }

        // No machines available to start
        return (
          <>
            <Button disabled={true}>Start</Button>
            <InfoMessageStartBtn error={true}>
              No available simulators
            </InfoMessageStartBtn>
          </>
        );
      })()}
    >
      <LogoWrapper>
        <img
          src={
            cloudCase.learningSpace.image
              ? convertBase64ToImage(cloudCase.learningSpace.image)
              : getDefaultLogo()
          }
          alt={cloudCase.learningSpace.name + " logo"}
        />
      </LogoWrapper>
      <DialogContent>
        <H2>{cloudCase.caseName}</H2>
        {cloudCase.vouchers.length > 1 ? (
          <RowWrapper>
            <SelectField
              label="Select voucher to consume"
              onChange={(option: VoucherOption) =>
                setSelectedVoucherOption(option)
              }
              value={selectedVoucherOption}
              options={voucherOptions}
            />
          </RowWrapper>
        ) : (
          <RowWrapper>
            <InfoRow>
              <img
                style={{ display: "inline" }}
                src="/static/assets/calendar.svg"
                alt="Calendar icon"
              />{" "}
              <EventText>
                Total remaining time:{" "}
                <PrimaryBoldSpan>
                  {cloudCase.vouchers[0].durationLeftInSeconds
                    ? getFormattedDurationStringFromSeconds(
                        cloudCase.vouchers[0].durationLeftInSeconds
                      )
                    : "0m"}
                </PrimaryBoldSpan>
              </EventText>
            </InfoRow>
          </RowWrapper>
        )}
        <div>
          <SemiBoldText>Description</SemiBoldText>
          <LargeText>{cloudCase.caseDescription}</LargeText>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CloudCaseDialog;
