import React from "react";

const SelectAll: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g stroke="#3A3E42" strokeLinecap="square" strokeWidth="2">
              <path
                d="M0 10.3L3 13.5 13 0M18 8.5L12 8.5M18 13.5L8 13.5M18 3.5L16 3.5"
                transform="translate(-1184 -346) translate(1184 346) translate(3 4.5)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SelectAll;
