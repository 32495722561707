import React, { useContext, useEffect, useState } from "react";
import { Card } from "../shared/Card";
import styled, { ThemeContext } from "styled-components";
import {
  EventResourceAccessVoucher,
  CloudCaseVmStatus,
  RefetchPayload,
} from "../../generated/graphql";
import {
  H6,
  SemiBoldText,
  LargeText,
  h6Style,
  H2,
  semiBoldTextStyle,
} from "../shared/typography";
import {
  formatDateStringShortMonth,
  formatDateAndTime,
} from "../../lib/formatDateAndTime";
import { ellipsisSecondLine } from "../Groups/GroupCard";
import Dialog from "../shared/Dialog";
import { copyToClipboard } from "../../lib/copyToClipboard";
import { useToast } from "../../lib/useToast";
import { ToastFormat, ToastType } from "../../shared/enums";
import Button from "../shared/Button";
import { renderLoadingText } from "../shared/LoadingIndicator";
import { useHistory } from "react-router";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import { Option } from "react-select/src/filters";
import SelectField from "../shared/SelectField";
import CopyField from "../shared/CopyField";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { cloudCaseVMFields } from "../../types/fragments/CloudCaseVMFragment";
import { convertBase64ToImage, getDefaultLogo } from "../../lib/convertBase64";
import { getEventEndDate } from "./Events";
import { eventResourceAccessVouchersFields } from "../../types/fragments/EventResourceAccessVoucherFragment";

const CardWrapper = styled(Card)`
  padding: 0;
  cursor: pointer;
`;

const Thumbnail = styled.div<{ disabled?: boolean }>`
  background-color: ${(props) => props.theme.colors.superLightGrey};
  height: ${(props) => props.theme.sizes.spacing20};
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    ${(props) => props.disabled && "filter: grayscale(1);"}
  }
`;

const Info = styled.div`
  background-color: #fff;
  width: 100%;
  padding: ${(props) => props.theme.sizes.spacing2};
  position: relative;
`;

/* TODO: Move dialog and components to CloudCaseDialog */
export const InfoRow = styled.li<{ onClick?: () => void }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props) => props.onClick && "cursor: pointer;"};
`;

export const DialogInfoList = styled.ul`
  padding: 0;
  margin: ${(props) => props.theme.sizes.spacing3} 0;

  > :not(:last-child) {
    padding-bottom: ${(props) => props.theme.sizes.spacing2};
  }
`;

export const EventText = styled.div`
  ${semiBoldTextStyle}
  margin: 0;
  line-height: 20px;
  padding-left: ${(props) => props.theme.sizes.spacing1};
`;

const Title = styled(H6)`
  height: ${(props) => props.theme.sizes.spacing6};
  ${ellipsisSecondLine}
`;

const DialogContent = styled.div`
  position: relative;
  padding-top: ${(props) => props.theme.sizes.spacing3};
  margin-top: ${(props) => props.theme.sizes.spacing2};
`;

const ProgressFlagCard = styled(Card)<{
  isDialogFlag: boolean;
  isDisabled?: boolean;
}>`
  ${h6Style}
  max-width: fit-content;
  height: 32px;
  padding: 6px 20px;
  line-height: 1.2;
  color: ${(props) =>
    props.isDisabled
      ? props.theme.colors.darkGrey
      : props.theme.colors.success500};
  position: absolute;
  top: ${(props) => (props.isDialogFlag ? "-12px" : "-24px")};
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0 10px 15px -10px rgba(14, 15, 15, 0.24);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LogoWrapper = styled.div`
  margin-top: -${(props) => props.theme.sizes.spacing2};
`;

export const InfoMessageStartBtn = styled.span<{
  error?: boolean;
  center?: boolean;
}>`
  color: ${(props) =>
    props.error ? props.theme.colors.error500 : props.theme.colors.grey};
  font-weight: 500;
  font-size: 14px;
  margin-right: 8px;
  height: 28px;
  position: absolute;
  bottom: 2px;
  text-align: center;
  display: block;
`;

const ProgressFlag: React.FC<{
  isDialogFlag: boolean;
  isDisabled?: boolean;
}> = ({ children, isDialogFlag, isDisabled }) => (
  <ProgressFlagCard isDialogFlag={isDialogFlag} isDisabled={isDisabled}>
    {children}
  </ProgressFlagCard>
);
interface EventCardProps {
  eventVoucher: EventResourceAccessVoucher;
  showModal: boolean;
  onShowModal: (eventId: number | undefined) => void;
}

const EventCard: React.FC<EventCardProps> = ({
  eventVoucher,
  showModal,
  onShowModal,
}) => {
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const [, createToast] = useToast();

  const [activeSessionId, setActiveSessionId] = useState<string | undefined>(
    undefined
  );
  const [startedSessionLocally, setStartedSessionLocally] =
    useState<boolean>(false);

  const { data } = useQuery<{
    getEventResourceAccessVoucherById: EventResourceAccessVoucher;
  }>(
    gql`
      query GetEventResourceAccessVoucherById($voucherId: Int!) {
        getEventResourceAccessVoucherById(voucherId: $voucherId) {
          ...EventResourceAccessVouchersFields
          vm {
            ...CloudCaseVMFields
          }
        }
      }
      ${eventResourceAccessVouchersFields}
      ${cloudCaseVMFields}
    `,
    {
      variables: {
        voucherId: eventVoucher.id,
      },
      onCompleted: (data) => {
        setSelectedCase({
          value: `${data.getEventResourceAccessVoucherById.modules[0].name}: ${data.getEventResourceAccessVoucherById.modules[0].cases[0].name}`,
          label: `${data.getEventResourceAccessVoucherById.modules[0].name}: ${data.getEventResourceAccessVoucherById.modules[0].cases[0].name}`,
          data: {
            moduleId:
              data.getEventResourceAccessVoucherById.modules[0].moduleId,
            caseId:
              data.getEventResourceAccessVoucherById.modules[0].cases[0].caseId,
          },
        });
      },
      pollInterval: showModal ? 15000 : 0,
    }
  );

  const caseOptions = data?.getEventResourceAccessVoucherById?.modules.flatMap(
    (module) =>
      module.cases.map((cloudCase) => ({
        value: `${module.name}: ${cloudCase.name}`,
        label: `${module.name}: ${cloudCase.name}`,
        data: {
          moduleId: module.moduleId,
          caseId: cloudCase.caseId,
        },
      }))
  );

  const [selectedCase, setSelectedCase] = useState(caseOptions?.[0]);

  const [startCloudCaseEvent, { loading: loadingStartCloudCaseEvent }] =
    useMutation<{
      startCloudCaseEvent: string;
    }>(
      gql`
        mutation StartCloudCaseEvent(
          $moduleId: String!
          $caseId: String!
          $voucherId: Int!
        ) {
          startCloudCaseEvent(
            moduleId: $moduleId
            caseId: $caseId
            voucherId: $voucherId
          )
        }
      `,
      {
        onCompleted(data) {
          setActiveSessionId(data.startCloudCaseEvent);
          createToast({
            title: "Cloud Case starting",
            type: ToastType.SUCCESS,
            format: ToastFormat.TOAST,
          });
        },
        onError(err: ApolloError) {
          createToast({
            title: ((error) => {
              switch (error) {
                case "All VMs are currently taken or reserved. Please try again later.":
                  return error;
                case "You have another ongoing case or event.":
                  return error;
                default:
                  return "Failed to start cloud case, please try again later";
              }
            })(err.graphQLErrors[0].message),
            type: ToastType.ERROR,
            format: ToastFormat.BANNER,
          });
        },
      }
    );

  const [
    stopCloudCaseSessionWithVoucherId,
    { loading: loadingStopCloudCaseSessionWithVoucherId },
  ] = useMutation<{
    stopCloudCaseSessionWithVoucherId: RefetchPayload;
  }>(
    gql`
      mutation stopCloudCaseSessionWithVoucherId($voucherId: Int!) {
        stopCloudCaseSessionWithVoucherId(voucherId: $voucherId) {
          refetch {
            getEventResourceAccessVoucherById(voucherId: $voucherId) {
              vm {
                ...CloudCaseVMFields
              }
            }
          }
        }
      }
      ${cloudCaseVMFields}
    `,
    {
      onCompleted() {
        createToast({
          title: "Cloud case ended",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError(err: ApolloError) {
        console.log(JSON.stringify(err));
        createToast({
          title: "Failed to end cloud case, please try again later",
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  const isOrganizer =
    eventVoucher.organizers &&
    user &&
    eventVoucher.organizers.some(
      (organizer) => organizer?.email === user.email
    );

  const eventEndDate = getEventEndDate(
    new Date(eventVoucher.eventAt),
    eventVoucher.durationInSeconds
  );

  const eventIsStarting =
    data?.getEventResourceAccessVoucherById.vm?.status ===
    CloudCaseVmStatus.Starting;
  const eventHasStarted =
    data?.getEventResourceAccessVoucherById.vm?.status ===
    CloudCaseVmStatus.Started;

  // Allow event to start 10 minutes before scheduled time
  const eventIsAllowedToStart =
    new Date() >
      new Date(new Date(eventVoucher.eventAt).getTime() - 10 * 60 * 1000) &&
    eventEndDate > new Date();
  const eventHasPassed = eventEndDate < new Date() && !eventHasStarted;

  useEffect(() => {
    if (
      startedSessionLocally &&
      data?.getEventResourceAccessVoucherById.vm?.status ===
        CloudCaseVmStatus.Started
    ) {
      history.push(`/cloud-event/${eventVoucher.id}/`);
    }
  }, [data, history, startedSessionLocally, eventVoucher.id]);

  return (
    <>
      <CardWrapper
        onClick={() => eventVoucher.id && onShowModal(eventVoucher.id)}
      >
        <Thumbnail disabled={eventHasPassed}>
          <img
            height={theme.sizes.spacing6}
            src={
              eventVoucher.learningSpace.image
                ? convertBase64ToImage(eventVoucher.learningSpace.image)
                : getDefaultLogo()
            }
            alt={eventVoucher.learningSpace.name + " logo"}
          />
        </Thumbnail>
        <Info>
          {eventHasPassed && (
            <ProgressFlag isDialogFlag={false} isDisabled={true}>
              Ended
            </ProgressFlag>
          )}
          {eventIsStarting && (
            <ProgressFlag isDialogFlag={false}>Starting</ProgressFlag>
          )}
          {eventHasStarted && (
            <ProgressFlag isDialogFlag={false}>In progress</ProgressFlag>
          )}
          <Title>{eventVoucher.name}</Title>
          <InfoRow>
            <img src="/static/assets/calendar.svg" alt="Calendar icon" />
            <EventText>{formatDateAndTime(eventVoucher.eventAt)}</EventText>
          </InfoRow>
        </Info>
      </CardWrapper>
      {showModal && (
        <Dialog
          onClose={() => onShowModal(undefined)}
          buttons={(() => {
            const vm = data?.getEventResourceAccessVoucherById.vm;
            // Machine is claimed
            if (vm) {
              switch (vm.status) {
                case CloudCaseVmStatus.Off:
                  return (
                    <>
                      <Button disabled={true}>
                        {renderLoadingText(true, "Start", "Starting")}
                      </Button>
                      <InfoMessageStartBtn>
                        Something went wrong
                      </InfoMessageStartBtn>
                    </>
                  );
                case CloudCaseVmStatus.Desired:
                  return (
                    <>
                      <Button disabled={true}>
                        {renderLoadingText(true, "Start", "Starting")}
                      </Button>
                      <InfoMessageStartBtn>
                        Looking for vacant simulator
                      </InfoMessageStartBtn>
                    </>
                  );
                case CloudCaseVmStatus.Idle:
                  return (
                    <>
                      <Button disabled={true}>
                        {renderLoadingText(true, "Start", "Initiating")}
                      </Button>
                      <InfoMessageStartBtn>
                        Event should start shortly
                      </InfoMessageStartBtn>
                    </>
                  );
                case CloudCaseVmStatus.Starting:
                  return (
                    <>
                      <Button disabled={true}>
                        {renderLoadingText(true, "Start", "Starting")}
                      </Button>
                      <InfoMessageStartBtn>
                        Start-up time is approx. 3 minutes
                      </InfoMessageStartBtn>
                    </>
                  );
                case CloudCaseVmStatus.Started:
                  return (
                    <>
                      {isOrganizer && (
                        <Button
                          onClick={() => {
                            stopCloudCaseSessionWithVoucherId({
                              variables: {
                                voucherId: eventVoucher.id,
                              },
                            });
                          }}
                          disabled={
                            !data?.getEventResourceAccessVoucherById?.vm
                              ?.session_id
                          }
                        >
                          {renderLoadingText(
                            loadingStopCloudCaseSessionWithVoucherId,
                            "End",
                            "Ending"
                          )}
                        </Button>
                      )}
                      <Button
                        onClick={() =>
                          history.push(`/cloud-event/${eventVoucher.id}/`)
                        }
                      >
                        {renderLoadingText(false, "Join", "Starting")}
                      </Button>
                    </>
                  );
              }
            } else if (eventIsAllowedToStart && isOrganizer) {
              const loadingButNotPollingYet =
                startedSessionLocally ||
                loadingStartCloudCaseEvent ||
                (activeSessionId !== undefined && vm === undefined);
              return (
                <Button
                  onClick={() => {
                    setStartedSessionLocally(true);
                    startCloudCaseEvent({
                      variables: {
                        moduleId: selectedCase?.data.moduleId,
                        caseId: selectedCase?.data.caseId,
                        voucherId: eventVoucher.id,
                      },
                    });
                  }}
                  disabled={loadingButNotPollingYet}
                >
                  {renderLoadingText(
                    loadingButNotPollingYet,
                    "Start",
                    "Starting"
                  )}
                </Button>
              );
            }
          })()}
        >
          <>
            <LogoWrapper>
              <img
                height={theme.sizes.spacing4}
                src={
                  eventVoucher.learningSpace.image
                    ? convertBase64ToImage(eventVoucher.learningSpace.image)
                    : getDefaultLogo()
                }
                color={"#fff"}
                alt={eventVoucher.learningSpace.name + " logo"}
              />
            </LogoWrapper>
            <DialogContent>
              {eventIsStarting && (
                <ProgressFlag isDialogFlag={true}>
                  {`Starting ${selectedCase?.label}`}
                </ProgressFlag>
              )}
              {eventHasStarted && (
                <ProgressFlag isDialogFlag={true}>In progress</ProgressFlag>
              )}
              <H2>{eventVoucher.name}</H2>
              <DialogInfoList>
                <InfoRow>
                  <img src="/static/assets/calendar.svg" alt="Calendar icon" />
                  <EventText>
                    {formatDateAndTime(eventVoucher.eventAt)}
                  </EventText>
                </InfoRow>
                <InfoRow>
                  <img src="/static/assets/star.svg" alt="Calendar icon" />
                  <EventText>Cloud Simulation</EventText>
                </InfoRow>
                {eventIsAllowedToStart && isOrganizer && (
                  <>
                    <InfoRow>
                      <img src="/static/assets/link.svg" alt="Link icon" />
                      <EventText>
                        Invite link
                        {`(expires ${formatDateStringShortMonth(
                          eventVoucher.validTo
                        )})`}
                      </EventText>
                    </InfoRow>
                    <InfoRow>
                      <CopyField
                        compact={true}
                        value={`${window.location.origin}/?inviteCode=${eventVoucher.code}`}
                        onCopyClick={async () =>
                          (await copyToClipboard(
                            `${window.location.origin}/?inviteCode=${eventVoucher.code}`
                          ))
                            ? createToast({
                                title: "Invite link copied to clipboard",
                                type: ToastType.SUCCESS,
                                format: ToastFormat.TOAST,
                              })
                            : createToast({
                                title:
                                  "Failed to copy invite link, please try again later",
                                type: ToastType.ERROR,
                                format: ToastFormat.TOAST,
                              })
                        }
                      />
                    </InfoRow>
                  </>
                )}
              </DialogInfoList>
              <div>
                <SemiBoldText>Description</SemiBoldText>
                <LargeText>{eventVoucher.description}</LargeText>
              </div>
              {eventIsAllowedToStart && isOrganizer && (
                <div style={{ marginTop: "24px" }}>
                  <SemiBoldText>
                    {eventVoucher.vm?.status === CloudCaseVmStatus.Started
                      ? "End ongoing event to select case"
                      : "Select case to start"}
                  </SemiBoldText>
                  <SelectField
                    isDisabled={
                      eventVoucher.vm?.status === CloudCaseVmStatus.Started
                    }
                    onChange={(option: Option) => setSelectedCase(option)}
                    value={selectedCase}
                    options={caseOptions}
                  />
                </div>
              )}
            </DialogContent>
          </>
        </Dialog>
      )}
    </>
  );
};

export default EventCard;
