import React from "react";
import styled from "styled-components";
import { Span } from "../../shared/typography";

const Item = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  white-space: normal;
  border-bottom: 1px solid ${(props) => props.theme.colors.paleGrey};
  :hover {
    background-color: ${(props) => props.theme.colors.paleGrey};
  }
`;

interface MenuItemProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({ children, onClick }) => {
  return (
    <Span>
      <Item onClick={onClick}>{children}</Item>
    </Span>
  );
};
