import React from "react";
import styled from "styled-components";
import { H3, H4, TextButton } from "../shared/typography";
import { Link } from "react-router-dom";
import Button from "../shared/Button";
import BackButton from "../shared/BackButton";

export const SectionHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.div`
  grid-column: 1 / -1; /* span all columns */
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

const PrimaryActionLink = styled(Link)`
  grid-column: 2 / 2;
  align-items: flex-end;
  padding-top: 8px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.darkGrey};
  cursor: pointer;
`;

const PrimaryActionButton = styled.button`
  grid-column: 2 / 2;
  align-items: flex-end;
  padding-top: 8px;

  background: none;
  border: none;

  text-decoration: none;
  color: ${(props) => props.theme.colors.darkGrey};
  cursor: pointer;
`;

interface SectionContainerProps {
  maxColumns: number;
  title: string | undefined;
}

const SectionContainer = styled.div<SectionContainerProps>`
  width: 100%;
  /* If !title top margin should be placed on first section child */
  padding-top: ${(props) => props.title && `${props.theme.sizes.spacing3}`};
  padding-bottom: ${(props) => props.theme.sizes.spacing5};
  display: grid;
  /* Automatically determine amount of rows */
  grid-template-columns: ${(props) =>
    props.maxColumns === 1
      ? "1fr"
      : "repeat(auto-fill, minmax(" +
        (1128 - 24 * (props.maxColumns - 1)) / props.maxColumns +
        "px, 1fr))"};
  grid-gap: 24px 24px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

interface SectionProps {
  title?: string;
  smallerTitle?: string;
  back?: string;
  primaryActionLink?: string;
  primaryActionOnClick?: () => void;
  primaryActionText?: string;
  maxColumns: number;
  sectionButton?: { buttonText: string; onClick: () => void };
}

// General section -- a part of a page with a subheading
export const Section: React.FC<SectionProps> = ({
  title,
  smallerTitle,
  back,
  primaryActionLink,
  primaryActionOnClick,
  primaryActionText,
  sectionButton,
  maxColumns,
  children,
}) => {
  return (
    <SectionContainer
      maxColumns={maxColumns}
      title={title}
      id={`cy-${title?.replace(/\s/g, "")}`}
    >
      <Title>
        <SectionHeaderContainer>
          {back && <BackButton to={back} />}
          {title && !smallerTitle && <H3>{title}</H3>}
          {smallerTitle && !title && <H4>{smallerTitle}</H4>}
          {sectionButton?.buttonText && (
            <div style={{ marginTop: "28px" }}>
              <Button onClick={sectionButton.onClick}>
                {sectionButton.buttonText}
              </Button>
            </div>
          )}
        </SectionHeaderContainer>

        {primaryActionLink && (
          <PrimaryActionLink to={primaryActionLink} id={title}>
            <TextButton>{primaryActionText}</TextButton>
          </PrimaryActionLink>
        )}
        {primaryActionOnClick && (
          <PrimaryActionButton onClick={primaryActionOnClick}>
            <TextButton>{primaryActionText}</TextButton>
          </PrimaryActionButton>
        )}
      </Title>
      {children}
    </SectionContainer>
  );
};
