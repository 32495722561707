import React from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";
import BackButton from "./BackButton";
import { H2, H3 } from "./typography";

const ActionBarContainer = styled.div`
  position: relative;
`;

const BackButtonContainer = styled.div`
  position: absolute;
  left: -${(props) => props.theme.sizes.spacing5};
  top: 6px;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointXL}) {
    display: none;
  }
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    flex-wrap: wrap;
  }
`;

const TopContent = styled(BottomContent)<{ hasBottomContent: boolean }>`
  margin-bottom: ${(props) =>
    props.hasBottomContent
      ? props.theme.sizes.spacing3
      : props.theme.sizes.spacing2};
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    margin-bottom: ${(props) =>
      props.hasBottomContent
        ? props.theme.sizes.spacing3
        : props.theme.sizes.spacing2};
  }
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    min-width: 85%;
    margin-bottom: ${(props) => props.theme.sizes.spacing3};
  }
`;

const ButtonGroupContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  > *:not(:last-child) {
    margin-right: ${(props) => props.theme.sizes.spacing2};
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`;

const scalingTitleStyle = css`
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    margin-bottom: ${(props) => props.theme.sizes.spacing2};
  }
`;

export const SelectFieldWidthProvider = styled.div`
  width: 224px;
  height: 40px;
`;

const ScalingH2 = styled(H2)`
  ${scalingTitleStyle}
`;

const ScalingH3 = styled(H3)`
  ${scalingTitleStyle}
`;

interface ActionBarProps {
  title: string;
  button?: React.ReactNode;
  buttonGroup?: React.ReactNode;
  searchField?: React.ReactNode;
  backTo?: string;
}

const ActionBar: React.FC<ActionBarProps> = ({
  title,
  button,
  buttonGroup,
  searchField,
  backTo,
}) => {
  let history = useHistory();
  return (
    <>
      <ActionBarContainer>
        {backTo && (
          <BackButtonContainer>
            <BackButton onClick={() => history.push(backTo)} />
          </BackButtonContainer>
        )}
        <TopContent hasBottomContent={Boolean(searchField)}>
          {/* backTo link indicates first level action bar with larger title  */}
          {backTo ? (
            <ScalingH2>{title}</ScalingH2>
          ) : (
            <ScalingH3>{title}</ScalingH3>
          )}
          {button}
        </TopContent>

        <BottomContent>
          {searchField && <SearchContainer>{searchField}</SearchContainer>}
          {buttonGroup && (
            <ButtonGroupContainer>{buttonGroup}</ButtonGroupContainer>
          )}
        </BottomContent>
      </ActionBarContainer>
      <Divider />
    </>
  );
};

export default ActionBar;
