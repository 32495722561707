import React, { useEffect, useMemo, useReducer, useState } from "react";
import SelectField from "../../components/shared/SelectField";
import TextField from "../../components/shared/TextField";
import {
  PrimaryModality,
  Complication,
  OrganSystem,
  ProcedureCategory,
  ProcedureSubCategory,
  Procedure,
} from "../../generated/graphql";
import { GridRow, GridCol } from "../../components/shared/FormLayout";
import { DetailSectionProps } from "./record";
import RadioButton from "../../components/shared/RadioButton";
import { H6 } from "../../components/shared/typography";
import { Option } from "react-select/src/filters";
import { StringKeyObject } from "./types";

export const InterventionTypes = [
  "Arterial Interventions",
  "Biopsy/Drainage",
  "Dialysis Related Interventions",
  "Evaluation and Management (E/M)",
  "Nonvascular Interventions",
  "Other/Undefined",
  "Vascular Access Procedures",
  "Venous Interventions",
];

export const ModalityMap: StringKeyObject = {
  NoImaging: "No Imaging",
  CT: "CT",
  Fluoroscopy: "Fluoroscopy",
  MRI: "MRI",
  Ultrasound: "Ultrasound",
};

export const ComplicationsMap: StringKeyObject = {
  A: "A: No therapy, no consequence",
  B: "B: Nominal therapy, no consequence; includes overnight admission for observation only",
  C: "C: Require therapy, minor hospitalization (<48 hours)",
  D: "D: Require major therapy, unplanned increase in level of care, prolonged",
  E: "E: Permanent adverse sequelae",
  F: "F: Death",
  NONE: "None",
};

type State = {
  procedureValues?: {
    name?: string;
    organSystem?: string;
    category?: string;
    subCategory?: string;
    interventionType?: string;
    code?: string;
  } | null;
  organSystemOptions?: OrganSystem[];
  categoryOptions?: ProcedureCategory[];
  subCategoryOptions?: ProcedureSubCategory[];
};

type ProcedureAction =
  | { type: "name"; name: string }
  | { type: "interventionType"; interventionType: string }
  | { type: "organSystem"; organSystem: string }
  | { type: "category"; category: string }
  | { type: "subCategory"; subCategory: string };

const ProcedureDetailSection: React.FC<DetailSectionProps> = ({
  handleChange,
  values,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  interventionTypes,
}) => {
  const procedureFormValues = useMemo(
    () =>
      interventionTypes?.reduce<Array<Procedure>>((optionsList, procedure) => {
        const interventionType = procedure.name;
        procedure.organSystems.forEach((item) => {
          const organSystem = item.name;
          item.categories.forEach((item) => {
            const category = item.name;
            item.subCategories.forEach((item) => {
              optionsList.push({
                interventionType: interventionType,
                organSystem: organSystem,
                category: category,
                subCategory: item.subCategory,
                name: item.name,
                code: item.code,
              });
            });
          });
        });
        return optionsList;
      }, []),
    [interventionTypes]
  );

  const [textFieldValues, setTextFieldValues] = useState({
    procedureComments: values.procedureComments,
    successComments: values.successComments,
    procedureIndication: values.procedureIndication,
    doseTime: values.doseTime,
    doseCtdi: values.doseCtdi,
    doseDlp: values.doseDlp,
    doseSkin: values.doseSkin,
    doseDap: values.doseDap,
    complicationsComments: values.complicationsComments,
    closureDeviceComments: values.closureDeviceComments,
  });

  const initialState: State = {
    procedureValues: values.procedure,
    organSystemOptions: [],
    categoryOptions: [],
    subCategoryOptions: [],
  };

  // Reducer to handle the setting of the four dependent procedure values and their corresponding procedure name
  const reducer = (state: State, action: ProcedureAction): State => {
    switch (action.type) {
      // Each name is unique and sets all other values
      case "name":
        const selectedProcedure = procedureFormValues?.find(
          (item) => item.name === action.name
        );
        return {
          ...state,
          procedureValues: {
            ...state.procedureValues,
            name: action.name,
            interventionType: selectedProcedure?.interventionType,
            organSystem: selectedProcedure?.organSystem,
            category: selectedProcedure?.category,
            subCategory: selectedProcedure?.subCategory,
            code: selectedProcedure?.code,
          },
        };
      // interventionType is required as it sets organSystem, category, subCategory & name
      case "interventionType":
        const organSystemsFromIT = interventionTypes?.find(
          (item) => item.name === action.interventionType
        )?.organSystems;
        const organSystemFromIT = organSystemsFromIT?.map(
          (item) => item.name
        )[0];
        const categoriesFromIT = organSystemsFromIT?.find(
          (item) => item.name === organSystemFromIT
        )?.categories;
        const categoryFromIT = categoriesFromIT?.map((item) => item.name)[0];
        const subCategoriesFromIT = categoriesFromIT?.find(
          (item) => item.name === categoryFromIT
        )?.subCategories;
        const subCategoryFromIT = subCategoriesFromIT?.map(
          (items) => items.subCategory
        )[0];
        const nameFromIT = subCategoriesFromIT?.map((items) => items.name)[0];
        return {
          ...state,
          procedureValues: {
            ...state.procedureValues,
            interventionType: action.interventionType ?? "",
            organSystem: organSystemFromIT,
            category: categoryFromIT,
            subCategory: subCategoryFromIT,
            name: nameFromIT,
            code: subCategoriesFromIT?.map((items) => items.code)[0],
          },
          organSystemOptions: organSystemsFromIT,
          categoryOptions: categoriesFromIT,
          subCategoryOptions: subCategoriesFromIT,
        };
      // organSystem requires an interventiontype to set category, subCategory and name
      case "organSystem":
        const categoriesFromOS = state?.organSystemOptions?.find(
          (item) => item.name === action.organSystem
        )?.categories;
        const categoryFromOS = categoriesFromOS?.map((item) => item.name)[0];
        const subCategoriesFromOS = categoriesFromOS?.find(
          (item) => item.name === categoryFromOS
        )?.subCategories;
        const subCategoryFromOS = subCategoriesFromOS?.map(
          (items) => items.subCategory
        )[0];
        const nameFromOS = subCategoriesFromOS?.map((items) => items.name)[0];
        return {
          ...state,
          procedureValues: {
            ...state.procedureValues,
            organSystem: action.organSystem ?? "",
            category: categoryFromOS,
            subCategory: subCategoryFromOS,
            name: nameFromOS,
            code: subCategoriesFromOS?.map((items) => items.code)[0],
          },
          categoryOptions: categoriesFromOS,
          subCategoryOptions: subCategoriesFromOS,
        };
      // category requires an interventiontype and organSystem to set subCategory and name
      case "category":
        const subCategoriesFromC = state?.categoryOptions?.find(
          (item) => item.name === action.category
        )?.subCategories;
        const subCategoryFromC = subCategoriesFromC?.map(
          (item) => item.subCategory
        )[0];
        const nameFromC = subCategoriesFromC?.map((item) => item.name)[0];
        return {
          ...state,
          procedureValues: {
            ...state.procedureValues,
            category: action.category,
            subCategory: subCategoryFromC,
            name: nameFromC,
            code: subCategoriesFromC?.map((item) => item.code)[0],
          },
          subCategoryOptions: subCategoriesFromC,
        };
      // subCategory requires an interventiontype, organSystem and category to set name
      case "subCategory":
        const fromSC = state.subCategoryOptions?.find(
          (item) => item.subCategory === action.subCategory
        );
        const nameFromSC = fromSC?.name;
        const codeFromSC = fromSC?.code;
        return {
          ...state,
          procedureValues: {
            ...state.procedureValues,
            subCategory: action.subCategory,
            name: nameFromSC,
            code: codeFromSC,
          },
        };
      default:
        throw new Error("Unexpected action");
    }
  };
  const [currentProcedure, updateCurrentProcedure] = useReducer(
    reducer,
    initialState
  );

  // Sets formik values when reducer values update
  useEffect(() => {
    if (currentProcedure.procedureValues?.organSystem) {
      setFieldValue(
        "procedure.organSystem",
        currentProcedure.procedureValues?.organSystem
      );
    }
    if (currentProcedure.procedureValues?.category) {
      setFieldValue(
        "procedure.category",
        currentProcedure.procedureValues?.category
      );
    }
    if (currentProcedure.procedureValues?.subCategory) {
      setFieldValue(
        "procedure.subCategory",
        currentProcedure.procedureValues?.subCategory
      );
    }
    if (currentProcedure.procedureValues?.interventionType) {
      setFieldTouched && setFieldTouched("procedure.interventionType", true);
      setFieldValue(
        "procedure.interventionType",
        currentProcedure?.procedureValues?.interventionType ?? ""
      );
    }
    if (currentProcedure.procedureValues?.name) {
      setFieldValue("procedure.name", currentProcedure.procedureValues?.name);
    }
    if (currentProcedure.procedureValues?.code) {
      setFieldValue("procedure.code", currentProcedure.procedureValues?.code);
      setFieldValue(
        "procedure.interventionType",
        currentProcedure?.procedureValues?.interventionType ?? ""
      );
    }
  }, [currentProcedure, setFieldTouched, setFieldValue]);

  const resetFlouroscopyCT = (value: string) => {
    if (value === PrimaryModality.Fluoroscopy) {
      setTextFieldValues({
        ...textFieldValues,
        doseTime: null,
        doseDap: null,
        doseSkin: null,
      });
    }
    if (value === PrimaryModality.Ct) {
      setTextFieldValues({
        ...textFieldValues,
        doseCtdi: null,
        doseDlp: null,
      });
    }
  };

  return (
    <>
      <GridRow>
        <GridCol size={2}>
          <SelectField
            id="cy-record-procedure-name"
            label="Search Procedure Name"
            name="name"
            searchable
            value={{
              value: currentProcedure?.procedureValues?.name,
              label: currentProcedure?.procedureValues?.name,
            }}
            onChange={(option: Option) =>
              updateCurrentProcedure({
                type: "name",
                name: option.label,
              })
            }
            options={procedureFormValues?.map((item) => ({
              value: item.code,
              label: item.name,
            }))}
          />
        </GridCol>
        <GridCol size={1}>
          <SelectField
            label="Intervention Type *"
            name="interventionType"
            id="cy-record-procedure-intervention-type"
            error={
              touched?.procedure?.interventionType && errors?.interventionType
            }
            value={{
              value: currentProcedure?.procedureValues?.interventionType,
              label: currentProcedure?.procedureValues?.interventionType,
            }}
            onBlur={() => {
              setFieldTouched &&
                setFieldTouched("procedure.interventionType", true);
              setFieldValue(
                "procedure.interventionType",
                currentProcedure?.procedureValues?.interventionType ?? ""
              );
            }}
            onChange={(option: Option) =>
              updateCurrentProcedure({
                type: "interventionType",
                interventionType: option.value,
              })
            }
            options={InterventionTypes.map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </GridCol>
      </GridRow>
      {currentProcedure?.procedureValues?.interventionType && (
        <GridRow>
          <GridCol>
            <SelectField
              label="Organ System"
              name="organSystem"
              searchable
              value={{
                value: currentProcedure?.procedureValues?.organSystem,
                label: currentProcedure?.procedureValues?.organSystem,
              }}
              onChange={(option: Option) => {
                updateCurrentProcedure({
                  type: "organSystem",
                  organSystem: option.value,
                });
              }}
              options={currentProcedure.organSystemOptions?.map((item) => ({
                value: item.name,
                label: item.name,
              }))}
            />
          </GridCol>
          <GridCol>
            <SelectField
              label="Procedure Category"
              name="category"
              searchable
              value={{
                value: currentProcedure?.procedureValues?.category,
                label: currentProcedure?.procedureValues?.category,
              }}
              options={currentProcedure.categoryOptions?.map((item) => ({
                value: item.name,
                label: item.name,
              }))}
              onChange={(option: Option) => {
                updateCurrentProcedure({
                  type: "category",
                  category: option.value,
                });
                setFieldValue("procedure.category", option.value);
              }}
            />
          </GridCol>
          <GridCol>
            <SelectField
              label="Procedure"
              name="procedure"
              searchable
              options={currentProcedure.subCategoryOptions?.map((item) => ({
                value: item.subCategory,
                label: item.subCategory,
              }))}
              value={{
                value: currentProcedure?.procedureValues?.subCategory,
                label: currentProcedure?.procedureValues?.subCategory,
              }}
              onChange={(option: Option) => {
                updateCurrentProcedure({
                  type: "subCategory",
                  subCategory: option.value,
                });
              }}
            />
          </GridCol>
        </GridRow>
      )}
      <GridRow>
        <GridCol size={2}>
          <TextField
            id="cy-record-procedure-indication"
            multiline
            label="Procedure Indication *"
            name="procedureIndication"
            error={
              touched?.procedureIndication &&
              !textFieldValues.procedureIndication &&
              errors?.procedureIndication
            }
            value={textFieldValues.procedureIndication ?? ""}
            onChange={(e) =>
              setTextFieldValues({
                ...textFieldValues,
                procedureIndication: e.currentTarget.value,
              })
            }
            onBlur={(e: any) => {
              setFieldTouched && setFieldTouched("procedureIndication", true);
              handleChange(e);
            }}
            type="text"
          />
        </GridCol>
        <GridCol size={1}></GridCol>
      </GridRow>
      <GridRow>
        <GridCol size={2}>
          <TextField
            name="procedureComments"
            multiline
            value={textFieldValues.procedureComments ?? ""}
            onChange={(e) =>
              setTextFieldValues({
                ...textFieldValues,
                procedureComments: e.currentTarget.value,
              })
            }
            onBlur={handleChange}
            label="Procedure Comment"
            type="text"
          />
        </GridCol>
        <GridCol size={1}></GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          <SelectField
            label="Primary Modality"
            name="modalityPrimary"
            defaultValue={{
              value: values.modalityPrimary ?? "",
              label: ModalityMap[values?.modalityPrimary ?? ""],
            }}
            onChange={(option: Option) => {
              resetFlouroscopyCT(option.value);
              setFieldValue("modalityPrimary", option.value);
            }}
            options={Object.entries(ModalityMap).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </GridCol>
        <GridCol>
          {values.modalityPrimary &&
            values.modalityPrimary !== PrimaryModality.NoImaging && (
              <SelectField
                label="Secondary Modality"
                name="modalitySecondary"
                defaultValue={{
                  value: values.modalitySecondary ?? "",
                  label: ModalityMap[values.modalitySecondary ?? ""],
                }}
                onChange={(option: Option) => {
                  resetFlouroscopyCT(option.value);
                  setFieldValue("modalitySecondary", option.value);
                }}
                options={Object.entries(ModalityMap).map(([key, value]) => ({
                  value: key,
                  label: value,
                }))}
              />
            )}
        </GridCol>
        <GridCol>
          {(values.modalityPrimary === PrimaryModality.Fluoroscopy ||
            values.modalitySecondary === PrimaryModality.Fluoroscopy) && (
            <TextField
              name="doseTime"
              error={touched?.doseTime && errors?.doseTime}
              value={textFieldValues.doseTime ?? ""}
              onChange={(e) =>
                setTextFieldValues({
                  ...textFieldValues,
                  doseTime: e.currentTarget.value,
                })
              }
              onBlur={(e: React.FocusEvent) => {
                setFieldTouched && setFieldTouched("doseTime", true);
                handleChange(e);
              }}
              label="Flouro Time(min)"
              type="text"
            />
          )}
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          {(values.modalityPrimary === PrimaryModality.Fluoroscopy ||
            values.modalitySecondary === PrimaryModality.Fluoroscopy) && (
            <TextField
              name="doseSkin"
              value={textFieldValues.doseSkin ?? ""}
              onChange={(e) =>
                setTextFieldValues({
                  ...textFieldValues,
                  doseSkin: e.currentTarget.value,
                })
              }
              onBlur={handleChange}
              label="Dose Skin"
              type="text"
            />
          )}
        </GridCol>
        <GridCol>
          {(values.modalityPrimary === PrimaryModality.Fluoroscopy ||
            values.modalitySecondary === PrimaryModality.Fluoroscopy) && (
            <TextField
              name="doseDap"
              value={textFieldValues.doseDap ?? ""}
              onChange={(e) =>
                setTextFieldValues({
                  ...textFieldValues,
                  doseDap: e.currentTarget.value,
                })
              }
              onBlur={handleChange}
              label="DAP Fluoro Dose"
              type="text"
            />
          )}
        </GridCol>
        <GridCol></GridCol>
      </GridRow>
      <GridRow>
        {(ModalityMap[values.modalityPrimary ?? ""] === PrimaryModality.Ct ||
          ModalityMap[values.modalitySecondary ?? ""] ===
            PrimaryModality.Ct) && (
          <>
            <GridCol>
              <TextField
                name="doseCtdi"
                error={touched?.doseCtdi && errors?.doseCtdi}
                value={textFieldValues.doseCtdi ?? ""}
                onChange={(e) =>
                  setTextFieldValues({
                    ...textFieldValues,
                    doseCtdi: e.currentTarget.value,
                  })
                }
                onBlur={(e: React.FocusEvent) => {
                  setFieldTouched && setFieldTouched("doseCtdi", true);
                  handleChange(e);
                }}
                label="CTDI CT Dose (mGy-cm)"
                type="text"
              />
            </GridCol>
            <GridCol>
              <TextField
                name="doseDlp"
                error={touched?.doseDlp && errors?.doseDlp}
                value={textFieldValues.doseDlp ?? ""}
                onChange={(e) =>
                  setTextFieldValues({
                    ...textFieldValues,
                    doseDlp: e.currentTarget.value,
                  })
                }
                onBlur={(e: React.FocusEvent) => {
                  setFieldTouched && setFieldTouched("doseDlp", true);
                  handleChange(e);
                }}
                label="DLP CT Dose (myGy-cm)"
                type="text"
              />
            </GridCol>
            <GridCol></GridCol>
          </>
        )}
      </GridRow>
      <GridRow>
        <GridCol>
          <H6>Was procedure a technical success?</H6>
          <RadioButton
            name="success"
            value="Yes"
            checked={values.success === true}
            onChange={() => setFieldValue("success", true)}
            label="Yes"
          />
          <RadioButton
            name="success"
            value="No"
            checked={values.success === false}
            onChange={() => setFieldValue("success", false)}
            label="No"
          />
        </GridCol>
        <GridCol>
          <H6>On Call (Off Hour) case</H6>
          <RadioButton
            name="onCall"
            value="Yes"
            checked={values.onCall === true}
            onChange={() => setFieldValue("onCall", true)}
            label="Yes"
          />
          <RadioButton
            name="onCall"
            value="No"
            checked={values.onCall === false}
            onChange={() => setFieldValue("onCall", false)}
            label="No"
          />
        </GridCol>
        <GridCol>
          <H6>Did you dictate a report?</H6>
          <RadioButton
            name="dictated"
            value="Yes"
            checked={values.dictated === true}
            onChange={() => setFieldValue("dictated", true)}
            label="Yes"
          />
          <RadioButton
            name="dictated"
            value="No"
            checked={values.dictated === false}
            onChange={() => setFieldValue("dictated", false)}
            label="No"
          />
        </GridCol>
      </GridRow>
      {values.success === false && (
        <GridRow>
          <GridCol size={2}>
            <TextField
              name="successComments"
              multiline
              value={textFieldValues.successComments ?? ""}
              onChange={(e) =>
                setTextFieldValues({
                  ...textFieldValues,
                  successComments: e.currentTarget.value,
                })
              }
              onBlur={handleChange}
              label="Why was the procedure not successful?"
              type="text"
            />
          </GridCol>
          <GridCol size={1}></GridCol>
        </GridRow>
      )}
      <GridRow>
        <GridCol size={2}>
          <SelectField
            label="Adverse Event"
            name="complications"
            defaultValue={{
              value: values.complications ?? "",
              label: ComplicationsMap[values?.complications ?? ""],
            }}
            onChange={(option: Option) =>
              setFieldValue("complications", option.value)
            }
            options={Object.entries(ComplicationsMap).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
          {values.complications && values.complications !== Complication.None && (
            <TextField
              name="complicationsComments"
              multiline
              value={textFieldValues.complicationsComments ?? ""}
              onChange={(e) =>
                setTextFieldValues({
                  ...textFieldValues,
                  complicationsComments: e.currentTarget.value,
                })
              }
              onBlur={handleChange}
              label="Please describe the adverse event"
              type="text"
            />
          )}
        </GridCol>
        <GridCol size={1}></GridCol>
      </GridRow>
      <GridRow>
        <GridCol size={2}>
          <H6>Closure Device</H6>
          <RadioButton
            name="closureDevice"
            value="Yes"
            checked={values.closureDevice === true}
            onChange={() => setFieldValue("closureDevice", true)}
            label="Yes"
          />
          <RadioButton
            name="closureDevice"
            value="No"
            checked={values.closureDevice === false}
            onChange={() => setFieldValue("closureDevice", false)}
            label="No"
          />
        </GridCol>
        <GridCol size={1}></GridCol>
      </GridRow>
      {values.closureDevice && (
        <GridRow>
          <GridCol size={2}>
            <TextField
              name="closureDeviceComments"
              multiline
              value={textFieldValues.closureDeviceComments ?? ""}
              onChange={(e) =>
                setTextFieldValues({
                  ...textFieldValues,
                  closureDeviceComments: e.currentTarget.value,
                })
              }
              onBlur={handleChange}
              label="Closure Device Comments"
              type="text"
            />
          </GridCol>
          <GridCol size={1}></GridCol>
        </GridRow>
      )}
    </>
  );
};

export default ProcedureDetailSection;
