import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ThemeContext } from "styled-components";
import HospitalIcon, { HospitalAvatar } from "../../components/icons/Hospital";
import Button from "../../components/shared/Button";
import Dialog from "../../components/shared/Dialog";
import { List } from "../../components/shared/list/List";
import LoadingIndicator, {
  renderLoadingText,
} from "../../components/shared/LoadingIndicator";
import TextField from "../../components/shared/TextField";
import { H3, SemiBoldText, Text } from "../../components/shared/typography";
import { Hospital } from "../../generated/graphql";
import { ButtonSizes } from "../../shared/enums";
import {
  ActionButton,
  ItemGroup,
  ListItem,
} from "../../components/shared/list/List";
import { SidebarCardHeader } from "./Customers";

const Hospitals = () => {
  const { id } = useParams<{ id: string }>();
  const theme = useContext(ThemeContext);
  const [editingHospital, setEditingHospital] = useState<undefined | number>(
    undefined
  );
  const [editInputValue, setEditInputValue] = useState("");
  const [createHospitalName, setCreateHospitalName] = useState("");
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [duplicateHospitals, setDuplicateHospitals] = useState<
    { id: string; name: string } | undefined
  >(undefined);
  const [deleteHospitalIdName, setDeleteHospitalIdName] = useState({
    name: "",
    id: "",
  });
  const [hospitalErrorMessage, setHospitalErrorMessage] = useState("");

  const [fetchHospitals, { loading: hospitalLoading, data: hospitalData }] =
    useLazyQuery<{ hospitals: Hospital[] }>(
      gql`
        query FetchHospitals($customerId: Int!) {
          hospitals(customerId: $customerId) {
            name
            id
          }
        }
      `,
      { fetchPolicy: "network-only" }
    );

  useEffect(() => {
    if (id) {
      fetchHospitals({ variables: { customerId: Number(id) } });
    }
  }, [fetchHospitals, id]);

  const [deleteHospital, { loading: loadingDeleteHospital }] = useMutation(
    gql`
      mutation DeleteHospital($id: UUID!, $customerId: Int!) {
        deleteHospital(id: $id) {
          refetch {
            hospitals(customerId: $customerId) {
              name
              id
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        closeDeleteDialog();
        closeDuplicatesDialog();
      },
    }
  );

  const [updateHospital, { loading: loadingUpdateHospital }] = useMutation(
    gql`
      mutation UpdateHospital($id: UUID!, $name: String!, $customerId: Int!) {
        updateHospital(id: $id, name: $name) {
          refetch {
            hospitals(customerId: $customerId) {
              name
              id
            }
          }
        }
      }
    `
  );

  const [
    createHospital,
    { loading: loadingCreateHospital, error: createHospitalError },
  ] = useMutation(
    gql`
      mutation CreateHospital($name: String!, $customerId: Int!) {
        createHospital(customerId: $customerId, name: $name) {
          refetch {
            hospitals(customerId: $customerId) {
              name
              id
            }
          }
        }
      }
    `,
    { onCompleted: () => closeCreateDialog() }
  );

  const handleSaveEdit = (hospitalId: string, createAttendingName: string) => {
    const hasDuplicates = hospitalData?.hospitals?.some(
      (hospital: Hospital) =>
        hospital.name === editInputValue && hospital.id !== editingHospital
    );
    if (hasDuplicates) {
      setDuplicateHospitals({ id: hospitalId, name: createAttendingName });
    } else {
      saveEdit(hospitalId);
    }
  };

  const saveEdit = (hospitalId: string) => {
    if (editInputValue && hospitalId) {
      updateHospital({
        variables: {
          id: hospitalId,
          name: editInputValue,
          customerId: Number(id),
        },
      });
      setEditInputValue("");
      setEditingHospital(undefined);
    }
  };

  const handleEdit = (id: number, name: string) => {
    setEditInputValue(name);
    setEditingHospital(id);
  };

  const handleDeleteHospital = (hospitalId: string) => {
    deleteHospital({
      variables: {
        id: hospitalId,
        customerId: Number(id),
      },
    });
  };

  const handleDeleteDuplicates = (hospitalId: string) => {
    deleteHospital({
      variables: {
        id: hospitalId,
        customerId: Number(id),
      },
    });
  };

  const closeCreateDialog = () => {
    setShowCreateDialog(false);
    setCreateHospitalName("");
    setHospitalErrorMessage("");
  };

  const closeEditDialog = () => {
    setEditingHospital(undefined);
    setEditInputValue("");
    setHospitalErrorMessage("");
  };

  const closeDeleteDialog = () => {
    setShowDeleteDialog(false);
    setDeleteHospitalIdName({ id: "", name: "" });
  };

  const closeDuplicatesDialog = () => {
    setEditingHospital(undefined);
    setDuplicateHospitals(undefined);
  };

  const handleCancelDuplicatesDialog = async () => {
    await fetchHospitals({ variables: { customerId: Number(id) } });
    closeDuplicatesDialog();
  };

  const handleCreateHospital = () => {
    const hasDuplicates = hospitalData?.hospitals?.some(
      (hospital: Hospital) => hospital.name === createHospitalName
    );
    if (!createHospitalName) {
      setHospitalErrorMessage("Please enter hospital name");
      return;
    }
    if (hasDuplicates) {
      setHospitalErrorMessage("Hospital name already exists");
      return;
    }
    if (createHospitalError) {
      setHospitalErrorMessage(createHospitalError.message);
      return;
    }
    return createHospital({
      variables: { name: createHospitalName, customerId: Number(id) },
    });
  };

  return (
    <>
      <SidebarCardHeader>
        <div>
          <H3>
            Hospitals
            {hospitalData?.hospitals && ` (${hospitalData?.hospitals.length})`}
          </H3>
          <SemiBoldText>
            Add, edit or remove hospital names from the list of options in the
            logbook form.
          </SemiBoldText>
        </div>
        <Button
          onClick={() => setShowCreateDialog(true)}
          size={ButtonSizes.Medium}
        >
          Add Hospital
        </Button>
      </SidebarCardHeader>
      <List>
        {hospitalLoading && <LoadingIndicator />}
        {hospitalData &&
          hospitalData?.hospitals
            ?.slice()
            .sort((a: Hospital, b: Hospital) => a.name.localeCompare(b.name))
            .map((hospital: Hospital) => (
              <ListItem key={hospital.id}>
                <ItemGroup>
                  <HospitalAvatar isAdmin={false}>
                    <HospitalIcon color={theme.colors.darkGrey} small />
                  </HospitalAvatar>
                  <Text>{hospital.name}</Text>
                </ItemGroup>
                <ItemGroup>
                  <ActionButton>
                    <span
                      onClick={() => handleEdit(hospital.id, hospital.name)}
                    >
                      Edit
                    </span>
                  </ActionButton>
                  <ActionButton
                    onClick={() => {
                      setShowDeleteDialog(true);
                      setDeleteHospitalIdName({
                        name: hospital.name,
                        id: hospital.id,
                      });
                    }}
                  >
                    Remove
                  </ActionButton>
                </ItemGroup>
              </ListItem>
            ))}
      </List>
      {showCreateDialog && (
        <Dialog
          title={"Add Hospital"}
          onClose={() => closeCreateDialog()}
          buttons={
            <>
              <Button secondary onClick={() => closeCreateDialog()}>
                Cancel
              </Button>
              <Button onClick={handleCreateHospital}>
                {renderLoadingText(loadingCreateHospital, "Save")}
              </Button>
            </>
          }
        >
          <TextField
            label={"Hospital name"}
            placeholder={"Example: General Hospital"}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setHospitalErrorMessage("");
              setCreateHospitalName(e.currentTarget.value);
            }}
            value={createHospitalName}
            error={hospitalErrorMessage}
          />
        </Dialog>
      )}
      {editingHospital && (
        <Dialog
          title={"Edit Hospital"}
          onClose={() => closeEditDialog()}
          buttons={
            <>
              <Button secondary onClick={() => closeEditDialog()}>
                Cancel
              </Button>
              <Button
                onClick={() =>
                  handleSaveEdit(editingHospital.toString(), editInputValue)
                }
              >
                {renderLoadingText(loadingUpdateHospital, "Save")}
              </Button>
            </>
          }
        >
          <TextField
            label={"Hospital name"}
            placeholder={"Example: General Hospital"}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setHospitalErrorMessage("");
              setEditInputValue(e.currentTarget.value);
            }}
            value={editInputValue}
            error={hospitalErrorMessage}
          />
        </Dialog>
      )}
      {showDeleteDialog && (
        <Dialog
          title={"Remove Hospital"}
          onClose={() => closeDeleteDialog()}
          buttons={
            <>
              <Button secondary onClick={() => closeDeleteDialog()}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleDeleteHospital(deleteHospitalIdName.id);
                }}
              >
                {renderLoadingText(loadingDeleteHospital, "Remove")}
              </Button>
            </>
          }
        >
          Removing "{deleteHospitalIdName.name}" will not delete it from the
          records it is listed on. Do you wish to continue?
        </Dialog>
      )}
      {duplicateHospitals && (
        <Dialog
          title={"Save"}
          onClose={() => handleCancelDuplicatesDialog()}
          buttons={
            <>
              <Button secondary onClick={() => handleCancelDuplicatesDialog()}>
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteDuplicates(duplicateHospitals.id)}
              >
                {renderLoadingText(loadingDeleteHospital, "Save")}
              </Button>
            </>
          }
        >
          There is already a hospital with the name "{editInputValue}". Would
          you like to update "{duplicateHospitals.name}" to "{editInputValue}"?
        </Dialog>
      )}
    </>
  );
};

export default Hospitals;
