import React from "react";
import styled from "styled-components";
import { H3, H4 } from "./typography";
import { SectionHeaderContainer, Title } from "../Page/Section";

interface CardSectionContainerProps {
  maxColumns: number;
  title: string | undefined;
  parentHasSidebar: boolean;
}

const SectionContainer = styled.div<CardSectionContainerProps>`
  width: 100%;
  margin-top: ${(props) => (props.title ? "28px" : "16px")};
  display: grid;
  /* Automatically determine amount of rows */
  grid-template-columns: ${(props) =>
    props.maxColumns === 1
      ? "1fr"
      : "repeat(auto-fill, minmax(" +
        (1128 -
          24 -
          24 -
          (props.parentHasSidebar ? 256 : 0) -
          64 -
          64 * (props.maxColumns - 1)) /
          props.maxColumns +
        "px, 1fr))"};
  grid-gap: 24px 24px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

interface CardSectionProps {
  title?: string;
  smallerTitle?: string;
  maxColumns: number;
  parentHasSidebar: boolean;
}

export const CardSection: React.FC<CardSectionProps> = ({
  title,
  smallerTitle,
  maxColumns,
  children,
  parentHasSidebar = true,
}) => {
  return (
    <SectionContainer
      maxColumns={maxColumns}
      title={title}
      parentHasSidebar={parentHasSidebar}
    >
      <Title>
        <SectionHeaderContainer>
          {title && !smallerTitle && <H3>{title}</H3>}
          {!title && smallerTitle && <H4>{smallerTitle}</H4>}
        </SectionHeaderContainer>
      </Title>
      {children}
    </SectionContainer>
  );
};
