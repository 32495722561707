import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import LoadingIndicator from "../../components/shared/LoadingIndicator";
import {
  FloatingButton,
  FloatingButtonContainer,
  FullScreen,
  IFrame,
} from "../cloud-event";
import { H1, StyledLink } from "../../components/shared/typography";
import Close from "../../components/icons/Close";
import {
  ApolloError,
  gql,
  QueryResult,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  RefetchPayload,
  CloudCaseVirtualMachine,
} from "../../generated/graphql";
import { useToast } from "../../lib/useToast";
import { ToastFormat, ToastType } from "../../shared/enums";
import { cloudCaseVMFields } from "../../types/fragments/CloudCaseVMFragment";
import { useHistory } from "react-router";

type LocalVISTAPI = {
  setDebugUIVisibility: (isVisible: boolean) => void;
  shutdown: (msg: string) => void;
  setSessionNoticeTime: (t: number) => void;
};

enum LocalVISTAPICallbackNames {
  ON_WEBSOCKET_CLOSED = "onWebsocketClosed",
  ON_SHUTDOWN = "onShutdown",
  ON_SESSION_NOTICE_TIME = "onSessionNoticeTime",
  ON_REFRESH_TOKEN_REQUEST = "onRefreshTokenRequest",
}

type LocalVISTAPICallbacks = {
  [LocalVISTAPICallbackNames.ON_REFRESH_TOKEN_REQUEST]: () => void;
  [LocalVISTAPICallbackNames.ON_WEBSOCKET_CLOSED]: (paramList: any) => void;
  [LocalVISTAPICallbackNames.ON_SHUTDOWN]: (msg: string) => void;
  [LocalVISTAPICallbackNames.ON_SESSION_NOTICE_TIME]: () => void;
  [LocalVISTAPICallbackNames.ON_REFRESH_TOKEN_REQUEST]: () => void;
};

const CloudCases = () => {
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const [, createToast] = useToast();
  const history = useHistory();
  const [iframeWindow, setIFrameWindow] = useState<Window | undefined>(
    undefined
  );
  const iframeRef = useCallback((iframe: HTMLIFrameElement | null) => {
    if (iframe?.contentWindow) {
      setIFrameWindow(iframe.contentWindow);
    }
  }, []);

  const {
    data,
  }: QueryResult<{
    getVmAssignedToMe: CloudCaseVirtualMachine;
  }> = useQuery(
    gql`
      query getVmAssignedToMe {
        getVmAssignedToMe {
          ...CloudCaseVMFields
        }
      }
      ${cloudCaseVMFields}
    `
  );

  const [stopCloudCaseSessionWithVoucherId] = useMutation<{
    stopCloudCaseSessionWithVoucherId: RefetchPayload;
  }>(
    gql`
      mutation stopCloudCaseSessionWithVoucherId($voucherId: Int!) {
        stopCloudCaseSessionWithVoucherId(voucherId: $voucherId) {
          refetch {
            getVmAssignedToMe {
              ...CloudCaseVMFields
            }
          }
        }
      }
      ${cloudCaseVMFields}
    `,
    {
      variables: {
        voucherId: data?.getVmAssignedToMe?.voucher_id,
      },
      onCompleted() {
        createToast({
          title: "Cloud case ended",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError(err: ApolloError) {
        console.log(JSON.stringify(err));
        createToast({
          title: "Failed to end cloud case, please try again later",
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  const localVISTAPI: LocalVISTAPI = {
    setDebugUIVisibility: (visible) => {
      iframeWindow?.postMessage(
        JSON.stringify({
          function: "setDebugUIVisible",
          param: visible,
        }),
        "*"
      );
    },
    shutdown: (msg) => {
      iframeWindow?.postMessage(
        JSON.stringify({ function: "shutdown", param: msg }),
        "*"
      );
    },
    setSessionNoticeTime: (t) => {
      iframeWindow?.postMessage(
        JSON.stringify({ function: "setSessionNoticeTime", param: t }),
        "*"
      );
    },
  };

  useEffect(() => {
    localVISTAPI.setDebugUIVisibility(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeWindow]);

  useEffect(() => {
    const callbacks: LocalVISTAPICallbacks = {
      onWebsocketClosed: (paramObject: { code: number; reason: string }) => {
        /* TODO: Replace with onShutdown depending on parameter code */
        console.log("Websocket Closed:", paramObject);
        createToast({
          title: "Connection lost, trying to reconnect…",
          type: ToastType.ERROR,
          format: ToastFormat.TOAST,
        });
      },
      onSessionNoticeTime: () => {
        createToast({
          title: "Event will end automatically in",
          type: ToastType.SUCCESS,
          format: ToastFormat.COUNTDOWN,
          timeRemaining: 5 * 60,
        });
      },
      onShutdown: (msg) => {
        history.push(`/`);
        msg.includes(
          "No one joined session" &&
            "Event ended by time limit" &&
            "No one in session"
        )
          ? createToast({
              title: "Event has been ended by time limit.",
              type: ToastType.SUCCESS,
              format: ToastFormat.TOAST,
            })
          : createToast({
              title: "Event has been ended by organizer.",
              type: ToastType.SUCCESS,
              format: ToastFormat.TOAST,
            });
      },
      onRefreshTokenRequest: () => {
        console.log("Received request for new token");
        keycloak
          .updateToken(30)
          .then((refreshed) => {
            if (refreshed) {
              console.log("Refreshed token");
            } else {
              console.log("Current token still valid");
            }
            if (keycloak.token !== undefined) {
              iframeWindow?.postMessage(
                JSON.stringify({
                  function: "updateToken",
                  param: keycloak.token,
                }),
                "*"
              );
            } else {
              console.error("Updated to an undefined token");
            }
          })
          .catch((err) => {
            console.error("Failed to refresh token:", err);
          });
      },
    };

    const messageEventListenerCallback = (event: any) => {
      try {
        const data: { function: LocalVISTAPICallbackNames; param: any } =
          JSON.parse(event.data);
        if (data.function && callbacks.hasOwnProperty(data.function)) {
          callbacks[data.function](data.param);
        }
      } catch {}
    };
    window.addEventListener("message", messageEventListenerCallback);

    return () =>
      window.removeEventListener("message", messageEventListenerCallback);
  }, [keycloak, iframeWindow, createToast, history]);

  const isStarter = user?.email === data?.getVmAssignedToMe.started_by?.email;

  const [invariantKeycloakToken, initializeInvariantKeycloakToken] = useState<
    string | undefined
  >(undefined);
  useEffect(() => {
    if (invariantKeycloakToken === undefined && keycloak.token !== undefined) {
      initializeInvariantKeycloakToken(keycloak.token);
    }
  }, [keycloak.token, invariantKeycloakToken]);

  const memoizedIFrameOnLoad = useCallback(() => {
    console.log("Loaded Individual Cloud Case IFrame");
    iframeWindow?.focus();
  }, [iframeWindow]);

  const memoizedIFrameSrc = useMemo(() => {
    let baseUrl = data?.getVmAssignedToMe.url?.endsWith("/")
      ? data?.getVmAssignedToMe.url
      : data?.getVmAssignedToMe.url + "/";
    return `${baseUrl}proctor/?name=${user?.name}&userid=${user?.email}`;
  }, [data?.getVmAssignedToMe.url, user?.name, user?.email]);

  const isReadyToStartLoadingIFrame =
    typeof data?.getVmAssignedToMe.url === "string" &&
    typeof user?.name === "string" &&
    typeof user?.email === "string" &&
    typeof invariantKeycloakToken === "string";

  if (!isReadyToStartLoadingIFrame) {
    return <LoadingIndicator fullHeight />;
  } else if (!isStarter) {
    return <H1>Permisson denied</H1>;
  } else {
    return (
      <FullScreen>
        <meta name="viewport" content="viewport-fit=cover"></meta>
        <FloatingButtonContainer
          onClick={() => localVISTAPI.shutdown("SingleUser")}
        >
          <StyledLink to="/cloud-cases">
            <FloatingButton
              onClick={() => {
                stopCloudCaseSessionWithVoucherId();
              }}
            >
              <Close />
            </FloatingButton>
          </StyledLink>
        </FloatingButtonContainer>
        {isReadyToStartLoadingIFrame && (
          <MemoizedIFrame
            src={memoizedIFrameSrc}
            onLoad={memoizedIFrameOnLoad}
            refFromParent={iframeRef}
          />
        )}
      </FullScreen>
    );
  }
};

const MemoizedIFrame = memo<{
  src: string;
  onLoad: () => void;
  refFromParent: (iframe: HTMLIFrameElement) => void;
}>(({ src, onLoad, refFromParent }) => {
  console.log("Rendered memoized IFrame", src, onLoad, refFromParent);
  return (
    <IFrame
      onLoad={onLoad}
      ref={refFromParent}
      src={src}
      title="Cloud simulation"
      allowFullScreen
      id="vistFrame"
    />
  );
});

export default CloudCases;
