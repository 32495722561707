import React from "react";
import styled from "styled-components";
import { H4, StyledLink, Text } from "../shared/typography";
import { Card } from "../shared/Card";
import { convertBase64ToImage, getDefaultLogo } from "../../lib/convertBase64";

const TalentBranchCard = styled(Card)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 24px;
`;

const TalentLogo = styled.img`
  ${(props: { editable: boolean }) =>
    props.editable
      ? "height: auto; width: 48px;"
      : "height: 42px; width: auto;"}
  @media screen and (max-width: 768px) {
    ${(props: { editable: boolean }) =>
      props.editable && "height: auto; width: 42px;"}
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
  margin-left: 24px;

  h4 {
    margin: 8px 0;
    line-height: 1;
  }
`;

const Description = styled(Text)`
  padding-right: 24px;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface TalentBranchProps {
  talentBranchId: string | number;
  talentBranchName: string;
  code: string;
  title: string;
  description?: string | null;
  showEnrolmentModal?: () => void;
  image: string;
  onEditClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}

// Branch "card" for TalentLMS branches
export const TalentBranch: React.FC<TalentBranchProps> = ({
  talentBranchId,
  talentBranchName,
  title,
  code,
  description,
  onEditClick,
  image,
}) => {
  const editable = Boolean(onEditClick);

  return (
    <StyledLink to={`/learning-spaces/${talentBranchId}/${talentBranchName}`}>
      <TalentBranchCard>
        <div>
          <>
            <TalentLogo
              editable={editable}
              src={image ? convertBase64ToImage(image) : getDefaultLogo()}
              alt={talentBranchName + " logo"}
            />
          </>
        </div>
        <TextContainer>
          <ContentHeader>
            <H4>{title}</H4>
            {editable && (
              <img
                onClick={onEditClick}
                src={"/static/assets/edit.svg"}
                width="18px"
                height="18px"
                alt="edit-icon"
              />
            )}
          </ContentHeader>
          <Description>{description}</Description>
        </TextContainer>
      </TalentBranchCard>
    </StyledLink>
  );
};
