import React, { useEffect } from "react";
import { PageLayout } from "../../components/Page/PageLayout";
import { Section } from "../../components/Page/Section";
import { Text } from "../../components/shared/typography";
import gql from "graphql-tag";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import { TalentBranch } from "../../components/TalentBranches/TalentBranch";
import { TalentBranch as TalentBranchEntity } from "../../generated/graphql";
import { Branches } from "../../shared/enums";
import { useLazyQuery } from "@apollo/client";
import ActionBar from "../../components/shared/ActionBar";

const LearningSpaces: React.FC = ({ children }) => {
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;

  const [
    fetchTalentBranches,
    { data: talentBranchesData, error: talentBranchesErrors },
  ] = useLazyQuery(
    gql`
      query FetchTalentBranches($emailAddress: String!) {
        enrolledTalentBranches(emailAddress: $emailAddress) {
          id
          code
          name
          description
          image
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      variables: { emailAddress: user?.email ?? "" },
    }
  );

  // Trigger request after user data loads
  useEffect(() => {
    if (user) {
      fetchTalentBranches();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const shouldDisplayTalentBranches =
    !talentBranchesErrors && talentBranchesData;

  let sortedBranches = [];
  if (
    talentBranchesData &&
    talentBranchesData.enrolledTalentBranches &&
    talentBranchesData.enrolledTalentBranches.length > 0
  ) {
    sortedBranches = [...talentBranchesData.enrolledTalentBranches].sort(
      (a: TalentBranchEntity, b: TalentBranchEntity) => {
        if (
          a.code === Branches.goto ||
          a.code === Branches.staging ||
          b.code === Branches.free
        ) {
          return -1;
        } else if (
          a.code === Branches.free ||
          b.code === Branches.goto ||
          b.code === Branches.staging
        ) {
          return 1;
        } else {
          return 0;
        }
      }
    );
  }

  return (
    <PageLayout title="Mentice Live | Learning Spaces">
      {children}
      <ActionBar backTo="/" title={"Learning Spaces"} />
      <Section title="" maxColumns={2}>
        {/* List branches */}
        {sortedBranches &&
          sortedBranches.length > 0 &&
          sortedBranches.map((branch: TalentBranchEntity) => (
            <TalentBranch
              key={branch.id}
              talentBranchId={branch.id}
              talentBranchName={branch.code}
              code={branch.code}
              title={branch.name}
              description={branch.description}
              image={branch.image}
            />
          ))}
        {/* Show "enroll" prompt */}
        {shouldDisplayTalentBranches &&
          talentBranchesData.enrolledTalentBranches.length === 0 && (
            <Text>No learning spaces available</Text>
          )}
        {/* List errors */}
        {talentBranchesErrors &&
          talentBranchesErrors.graphQLErrors.map(
            (error: { message: string }, i: number) => (
              <Text key={i}>{error.message}</Text>
            )
          )}
        {/* Show loading message */}
        {!talentBranchesErrors && !talentBranchesData && (
          <Text>Loading Learning Spaces...</Text>
        )}
      </Section>
    </PageLayout>
  );
};

export default LearningSpaces;
