import React from "react";
import styled from "styled-components";

const AccordionWrapper = styled.span<{ isExpanded?: boolean }>`
  margin-right: 16px;
  width: 24px;
  height: 24px;
  transform: ${(props) =>
    props.isExpanded ? "rotate(180deg)" : "rotate(90deg)"};
  transition: transform ${(props) => props.theme.transitions.outSlow};
`;

const BigArrowUp: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ marginRight: 16 }}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g stroke="#3A3E42" strokeWidth="2">
              <path
                d="M0 0L10 8 20 0"
                transform="translate(-225 -2065) translate(225 2065) matrix(1 0 0 -1 2 16)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

interface AccordionIconProps {
  isExpanded?: boolean;
}

const AccordionIcon: React.FC<AccordionIconProps> = ({ isExpanded }) => (
  <AccordionWrapper isExpanded={isExpanded}>
    <BigArrowUp />
  </AccordionWrapper>
);

export default AccordionIcon;
