import React from "react";
import { Title, Center } from "./RunningVistCardComponents";
import { Text, textStyle } from "../../shared/typography";
import Button from "../../shared/Button";
import MetricGroup from "./MetricGroup";
import { BenchmarkValue, Group } from "../../../generated/graphql";

import VistTable from "../VistTable";
import styled from "styled-components";

const Warning = styled.p`
  ${textStyle}
  font-weight: normal;
  color: red;
`;

const Notice = styled.p`
  ${textStyle}
  font-weight: normal;
  color: #FF9A00;
`;

interface StartPageProps {
  title: string;
  description: string;
  benchmarks?: BenchmarkValue[];
  environmentOk: boolean;
  launch: () => void;
  group?: Group;
  license?:any;
}

const StartPage: React.FC<StartPageProps> = ({
  title,
  description,
  benchmarks,
  environmentOk,
  launch,
  group,
  license
}) => (
  <>
    <Center>
      <Title>{title}</Title>
      <Text>{description}</Text>
      {license && (!license?.valid || license?.expired || license?.remainingdays < 0) && <Warning>Your VIST simulator doesn't have a license to run this case</Warning> }
      {license && (license?.valid && !license?.expired && license?.remainingdays < 30) && <Notice>{`Your VIST simulator license for this case will run out in ${license.remainingdays} days`}</Notice> }
      <Button
        disabled={!environmentOk}
        onClick={() => {
          launch();
        }}
      >
        Start
      </Button>
      {!environmentOk && (
        <Text>This exercise needs to be run from a VIST® simulator.</Text>
      )}
    </Center>
    {/* If we have group and benchmark data, render benchmarks table */}
    {group && benchmarks && (
      <VistTable>
        {/* Explicitly unwrap the root group */}
        {group?.groups &&
          group.groups.map((group, i: number) => (
            <MetricGroup
              key={i}
              group={group}
              benchmarks={benchmarks}
              depth={0}
              isCompactView={false}
            />
          ))}
      </VistTable>
    )}
  </>
);

export default StartPage;
