import React from "react";
import styled from "styled-components";
import { H2 } from "../shared/typography";

const SectionHeaderContainer = styled.div`
  display: flex;
  flex-flow: "row nowrap";
  align-items: center;
`;

const Title = styled.div`
  grid-column: 1 / -1; /* span all columns */
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

const SectionContainer = styled.div`
  width: 100%;
  margin-top: ${(props) => props.theme.sizes.spacing8};

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

interface SectionProps {
  title: string;
}

export const SectionHeader: React.FC<SectionProps> = ({ title, children }) => {
  return (
    <SectionContainer>
      <Title>
        <SectionHeaderContainer>
          <H2 style={{ display: "inline-block" }}>{title}</H2>
        </SectionHeaderContainer>
      </Title>
      {children}
    </SectionContainer>
  );
};
