import React from "react";

interface DownloadProps {
  white?: boolean;
}

const Download: React.FC<DownloadProps> = ({ white = false }) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon/Export">
          <rect
            id="Rectangle"
            fill="#3A3E42"
            fillRule="nonzero"
            opacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          ></rect>
          <line
            x1="12"
            y1="2"
            x2="12.125"
            y2="15"
            id="Path"
            stroke={white ? "#ffffff" : "#3A3E42"}
            strokeWidth="2"
          ></line>
          <polyline
            id="Path"
            stroke={white ? "#ffffff" : "#3A3E42"}
            strokeWidth="2"
            strokeLinecap="square"
            points="17 10.125 12.125 15 7.25 10.125"
          ></polyline>
          <line
            x1="19"
            y1="20"
            x2="5"
            y2="20"
            id="Path"
            stroke={white ? "#ffffff" : "#3A3E42"}
            strokeWidth="2"
            strokeLinecap="square"
          ></line>
        </g>
      </g>
    </svg>
  );
};

export default Download;
