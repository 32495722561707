import { Route, Switch } from "react-router-dom";
import React from "react";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import GroupCoursesPage from "./group-admin";

function GroupAdminRouter() {
  return (
    <Switch>
      <Route
        exact
        path="/admin/group-courses/:customerGroupId/:customerGroupName"
      >
        <GroupCoursesPage>
          <Breadcrumbs
            crumbs={[
              {
                path: "/administration",
                name: "Administration",
              },
              {
                path: "/administration/groups",
                name: "Groups",
              },
              {
                path: "/",
                name: "Group Settings",
              },
            ]}
          />
        </GroupCoursesPage>
      </Route>
    </Switch>
  );
}

export default GroupAdminRouter;
