import React from "react";
import styled from "styled-components";
import { H4, Text } from "../shared/typography";
import { Card } from "../shared/Card";
import { TalentBranch } from "../../generated/graphql";
import { convertBase64ToImage, getDefaultLogo } from "../../lib/convertBase64";

const CourseCard = styled(Card)`
  display: flex;
  flex-direction: column;

  :hover {
    box-shadow: ${(props) => props.theme.shadows.cardShadowHover};
  }

  img {
    margin: ${(p) => p.theme.sizes.spacing2} 0;
    width: ${(p) => p.theme.sizes.spacing20};
    height: ${(p) => p.theme.sizes.spacing12};
    object-fit: contain;
    align-self: center;
    filter: grayscale(1);
    opacity: 0.6;
  }
`;

interface CloudCaseCardProps {
  caseName: string;
  moduleName: string;
  learningSpace: TalentBranch;
  disabled?: boolean;
  onClick?: () => void;
}

export const CloudCaseCard: React.FC<CloudCaseCardProps> = ({
  caseName,
  moduleName,
  learningSpace,
  disabled,
  onClick,
}) => {
  return (
    <CourseCard disabled={disabled} onClick={onClick}>
      <H4>{caseName}</H4>
      <Text>{moduleName}</Text>
      <img
        src={
          learningSpace.image
            ? convertBase64ToImage(learningSpace.image)
            : getDefaultLogo()
        }
        alt={learningSpace.name + " logo"}
      />
    </CourseCard>
  );
};
