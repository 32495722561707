import { gql, useLazyQuery } from "@apollo/client";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Download from "../../components/icons/Download";
import Loading from "../../components/icons/Loading";
import Button from "../../components/shared/Button";
import DateRangePicker from "../../components/shared/DateRangePicker";
import ExpandableCard from "../../components/shared/ExpandableCard";
import LoadingIndicator from "../../components/shared/LoadingIndicator";
import { CloseButton } from "../../components/shared/Modal";
import { SidebarCardSearch } from "../../components/shared/sidebar-card/SidebarCard";
import { SidebarCardSection } from "../../components/shared/sidebar-card/SidebarCardSection";
import TextField from "../../components/shared/TextField";
import {
  H4,
  H5,
  HR,
  LargeText,
  Text,
} from "../../components/shared/typography";
import { SystemsStats, VistActivityItem, VistSystem } from "../../generated/graphql";
import {
  getDateOneWeekAgo,
  getFirstHour,
  getLastHour,
  getUTCDateFromISOString,
  getUTCTimeFromISOString,
} from "../../lib/formatDateAndTime";
import { useToast } from "../../lib/useToast";
import { ButtonSizes, ToastFormat, ToastType } from "../../shared/enums";

const FlexibleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 1fr 1fr;
  }
`;

const SystemCardInfoSection = styled.div<{ rightAlign?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0;

  &:first-of-type {
    margin: 0 auto 0 0;
  }

  &:last-of-type {
    grid-column-start: 2;
    grid-column-end: 2;
  }

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    &:not(first-of-type) {
      align-items: flex-start;
    }
  }
`;

const VR = styled.div`
  width: 1px;
  height: ${(props) => props.theme.sizes.spacing6};
  background-color: #ebf0f5;
  margin: 0 16px;

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    display: none;
  }
`;

const ScrollableContent = styled.div`
  overflow-y: auto;
  max-height: ${(props) => props.theme.sizes.spacing90};
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.lightGrey};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.midGrey};
  }
`;

// TODO: clean up card containers
const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  height: 100%;
  justify-content: center;
  flex: 4;
  :not(:first-child) {
    margin-left: 16px;
  }
  h4:first-child {
    margin-bottom: 8px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

const SmallSection = styled.div`
  flex: 1;
  text-align: center;
  justify-content: center;
  white-space: nowrap;

  h6:first-child {
    margin-bottom: 8px;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
`;

const GreyLargeText = styled(LargeText)`
  color: #909090;
`;

const RightContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex: 1;
  align-items: center;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;
  margin-top: -12px;
  padding-right: ${(props) => props.theme.sizes.spacing3};

  > :last-child {
    margin-right: ${(props) => props.theme.sizes.spacing3};
  }
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    > :last-child {
      margin-right: 0;
    }
  }
`;

const InnerContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  > :not(:last-child) {
    margin-right: ${(props) => props.theme.sizes.spacing2};
  }
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    margin-top: 8px;
    width: 100%;
    justify-content: flex-start;
  }
`;

const IDRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  h6:last-child {
    margin-left: ${(props) => props.theme.sizes.spacing4};
  }
`;

const GreySpan = styled.span`
  color: #9c9ea0;
  margin-right: ${(props) => props.theme.sizes.spacing1};
`;

const StyledCloseButton = styled(CloseButton)`
  margin-top: 30px;
  top: 0;
  right: 8px;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    margin-top: 0px;
  }
`;

const PreText = styled(Text)`
  white-space: pre;
`

const Systems = () => {
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedServiceTag, setSelectedServiceTag] = useState<
    string | undefined
  >(undefined);
  const [downloadLoadingFor, setDownloadLoadingFor] = useState<
    string | undefined
  >(undefined);
  const [, createToast] = useToast();

  const [
    fetchVistSystems,
    {
      loading: vistSystemsLoading,
      data: vistSystemsData,
      error: vistSystemsError,
    },
  ] = useLazyQuery<{ vistSystemsByServiceTag: VistSystem[] }>(gql`
    query FetchVistSystems($serviceTag: String!) {
      vistSystemsByServiceTag(serviceTag: $serviceTag) {
        serviceTag
        simulationsDone
        lastSimulation
      }
    }
  `);

  const [fetchLogDownloadLink] = useLazyQuery<{
    logDownloadLink: URL;
  }>(
    gql`
      query FetchLogDownloadLink($activityIds: [String!]!) {
        logDownloadLink(activityIds: $activityIds)
      }
    `,
    {
      onCompleted: (data) => {
        window.open(`${data?.logDownloadLink}`, "_blank");
        setDownloadLoadingFor(undefined);
        createToast({
          title: "Successfully downloaded log files",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError: (error) => {
        setDownloadLoadingFor(undefined);
        createToast({
          title: "Something went wrong when downloading log files",
          type: ToastType.ERROR,
          format: ToastFormat.TOAST,
        });
      },
    }
  );

  const [
    fetchActivities,
    {
      loading: activitiesLoading,
      data: activitiesData,
      error: activitiesError,
    },
  ] = useLazyQuery<{
    vistActivitiesByServiceTag: VistActivityItem[];
  }>(
    gql`
      query FetchActivities(
        $serviceTag: String!
        $from: DateTime
        $to: DateTime
      ) {
        vistActivitiesByServiceTag(
          serviceTag: $serviceTag
          from: $from
          to: $to
        ) {
          id
          courseName
          unitName
          activityEnd
          moduleId
          exerciseId
        }
      }
    `
  );

  const [fetchSystemStats, {
    loading: statsLoading,
    data: statsData,
    error: statsError,
    }] = useLazyQuery<{vistSystemsStats: SystemsStats;}>(
    gql`
      query FetchSystemStats {
        vistSystemsStats {
          connected
          loggedTotal
          loggedLastWeek
          logsTotal
          logsLastWeek
        }
      }
    `,
    {
      onCompleted: (data) => {
        console.log(data);
      }
    }
  );

  useEffect(() => {
    fetchVistSystems({ variables: { serviceTag: searchText } });
    fetchSystemStats();
  }, [fetchVistSystems, searchText, fetchSystemStats]);

  const getStartEndDateWeekApart = (date: Date) => {
    const oneWeekBeforeDate = new Date(getFirstHour(getDateOneWeekAgo(date)));
    const dateMidnight = getLastHour(date);
    return [oneWeekBeforeDate, dateMidnight];
  };

  const handleDateRangeChange =
    (start: Date, end: Date) => (serviceTag: string) => {
      setStartDate(start);
      setEndDate(end);
      fetchActivities({
        variables: {
          serviceTag: serviceTag,
          from: getFirstHour(start),
          to: end && getLastHour(end),
        },
      });
    };

  const handleHeaderClick = (serviceTag: string, lastSimulation: Date) => {
    const [oneWeekBeforeLastSimulation, lastSimulationMidnight] =
      getStartEndDateWeekApart(lastSimulation);
    fetchActivities({
      variables: {
        serviceTag: serviceTag,
        from: oneWeekBeforeLastSimulation,
        to: lastSimulationMidnight,
      },
    });
    setStartDate(oneWeekBeforeLastSimulation);
    setEndDate(lastSimulationMidnight);
    serviceTag === selectedServiceTag
      ? setSelectedServiceTag(undefined)
      : setSelectedServiceTag(serviceTag);
  };

  const debounceRequest = useMemo(
    () =>
      debounce((value) => {
        setSearchText(value);
      }, 1000),
    [setSearchText]
  );

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    debounceRequest(e.currentTarget.value);
  };


  return (
    <SidebarCardSection title="Systems" maxColumns={1}>
      {!statsError && !statsLoading && <PreText>{`Connected: ${statsData?.vistSystemsStats.connected}\t\t\tLogged (last week / total): ${statsData?.vistSystemsStats.loggedLastWeek} / ${statsData?.vistSystemsStats.loggedTotal}\t\t\tLogs (last week / total): ${statsData?.vistSystemsStats.logsLastWeek} / ${statsData?.vistSystemsStats.logsTotal}`}
        </PreText> }
      <SidebarCardSearch>
        <TextField
          searchable
          onClearSearch={() => setSearchText("")}
          placeholder="Search by Service Tags"
          onChange={handleSearchChange}
        />
      </SidebarCardSearch>
      {(vistSystemsLoading || statsLoading) && <LoadingIndicator />}
      {vistSystemsError && <Text>No systems found</Text>}
      {vistSystemsData?.vistSystemsByServiceTag.map((system: VistSystem) => (
        <ExpandableCard
          expanded={selectedServiceTag === system.serviceTag}
          key={system.serviceTag}
          header={
            <FlexibleContainer
              className="cy-systems-card-header"
              onClick={() =>
                handleHeaderClick(system.serviceTag, system.lastSimulation)
              }
            >
              <SystemCardInfoSection>
                <H4>{system.serviceTag}</H4>
                <GreyLargeText>{/* Customer */}</GreyLargeText>
              </SystemCardInfoSection>
              <VR />
              <SystemCardInfoSection rightAlign>
                <GreyLargeText>Simulations Done</GreyLargeText>
                <LargeText>{system.simulationsDone}</LargeText>
              </SystemCardInfoSection>
              <VR />
              <SystemCardInfoSection rightAlign>
                <GreyLargeText>Last Simulation</GreyLargeText>
                <LargeText>
                  {system.lastSimulation &&
                    `${getUTCDateFromISOString(system.lastSimulation)},
                      ${getUTCTimeFromISOString(system.lastSimulation)}`}
                </LargeText>
              </SystemCardInfoSection>
            </FlexibleContainer>
          }
        >
          <ContentRow id="cy-session-log-header">
            <H5 style={{ paddingBottom: "8px" }}>Session logs</H5>
            <StyledCloseButton
              onClick={() => setSelectedServiceTag(undefined)}
            />
            <InnerContentRow>
              <div>
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(start: Date | null, end: Date | null) => {
                    const [newStart, newEnd] = getStartEndDateWeekApart(
                      system.lastSimulation
                    );
                    handleDateRangeChange(
                      start ? start : newStart,
                      end ? end : newEnd
                    )(system.serviceTag);
                  }}
                />
              </div>
              <Button
                size={ButtonSizes.Medium}
                disabled={
                  activitiesError ||
                  activitiesData?.vistActivitiesByServiceTag.length === 0
                    ? true
                    : false
                }
                icon={
                  downloadLoadingFor === "all" ? (
                    <Loading small noMargin />
                  ) : (
                    <Download />
                  )
                }
                onClick={() => {
                  fetchLogDownloadLink({
                    variables: {
                      activityIds:
                        activitiesData?.vistActivitiesByServiceTag.map(
                          (activity) => activity.id
                        ),
                    },
                  });
                  setDownloadLoadingFor("all");
                }}
                secondary
              />
            </InnerContentRow>
          </ContentRow>

          {(activitiesError ||
            activitiesData?.vistActivitiesByServiceTag.length) === 0 && (
            <GreyLargeText style={{ marginTop: "32px", marginBottom: "8px" }}>
              No activities found. Try changing the date range or refreshing the
              page.
            </GreyLargeText>
          )}
          <ScrollableContent>
            {activitiesLoading && <LoadingIndicator />}
            {activitiesData?.vistActivitiesByServiceTag.map((activity) => (
              <React.Fragment key={activity.id}>
                <HR />
                <RightContainer className="cy-session-log-activity">
                  <MainSection>
                    <H4 style={{ margin: 0 }}>{activity.unitName}</H4>
                    <GreyLargeText>{activity.courseName}</GreyLargeText>
                    <IDRow>
                      <LargeText>
                        <GreySpan>Module ID</GreySpan>
                        {activity.moduleId}
                      </LargeText>
                      <LargeText>
                        <GreySpan>Exercise ID</GreySpan>
                        {activity.exerciseId}
                      </LargeText>
                    </IDRow>
                  </MainSection>
                  <SmallSection>
                    <LargeText>
                      {activity.activityEnd &&
                        getUTCTimeFromISOString(activity.activityEnd)}
                    </LargeText>
                    <LargeText>
                      {activity.activityEnd &&
                        getUTCDateFromISOString(activity.activityEnd)}
                    </LargeText>
                  </SmallSection>
                  <Button
                    size={ButtonSizes.Medium}
                    icon={
                      downloadLoadingFor === activity.id ? (
                        <Loading small noMargin />
                      ) : (
                        <Download />
                      )
                    }
                    onClick={() => {
                      fetchLogDownloadLink({
                        variables: {
                          activityIds: [activity.id],
                        },
                      });
                      setDownloadLoadingFor(activity.id);
                    }}
                    secondary
                  />
                </RightContainer>
              </React.Fragment>
            ))}
          </ScrollableContent>
        </ExpandableCard>
      ))}
    </SidebarCardSection>
  );
};

export default Systems;
