import React from "react";

interface MoreIconProps {
  color?: string;
  scale?: number;
}

const MoreIcon: React.FC<MoreIconProps> = ({ color, scale }) => {
  const safeScale = scale ?? 1.5;
  return (
    <svg
      width={13 * safeScale}
      height={3 * safeScale}
      viewBox={`0 0 ${13 * safeScale} ${3 * safeScale}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={1.5 * safeScale}
        cy={1.5 * safeScale}
        r={1.5 * safeScale}
        fill={color ? color : "#ADB3B8"}
      />
      <circle
        cx={6.5 * safeScale}
        cy={1.5 * safeScale}
        r={1.5 * safeScale}
        fill={color ? color : "#ADB3B8"}
      />
      <circle
        cx={11.5 * safeScale}
        cy={1.5 * safeScale}
        r={1.5 * safeScale}
        fill={color ? color : "#ADB3B8"}
      />
    </svg>
  );
};

export default MoreIcon;
