import React from "react";
import { H3, H4 } from "../shared/typography";
import {
  Table,
  ThPrimary,
  ThSecondary,
  ThTertiary,
  Tr,
} from "./RunningVistCard/RunningVistCardComponents";

const VistTable: React.FC = ({ children }) => {
  return (
    <>
      <H3>Exercise requirements</H3>
      <Table>
        <thead>
          <Tr>
            <ThPrimary depth={0}>
              <H4>Metric</H4>
            </ThPrimary>
            <ThSecondary />
            <ThTertiary />
            <ThSecondary>
              <H4>Requirement</H4>
            </ThSecondary>
            <ThTertiary />
          </Tr>
        </thead>
        <tbody>{children}</tbody>
      </Table>
    </>
  );
};
export default VistTable;
