import { Operator } from "../../../shared/enums";

export const getOperatorSymbol = (operator: Operator) => {
  switch (operator) {
    case Operator.LT:
      return "\u003C";
    case Operator.LE:
      return "\u2264";
    case Operator.EQ:
      return "\u003D";
    case Operator.NE:
      return "\u2260";
    case Operator.GE:
      return "\u2265";
    case Operator.GT:
      return "\u003E";
    default:
      throw new Error("Unknown operator supplied: " + operator);
  }
};

export const parseValue = (val: string | number, roundOff: boolean) => {
  if (typeof val === "number") {
    // Don't need parsing
    if (val % 1 !== 0) {
      // If not int, round off if necessary and return
      return roundOff ? val.toFixed(2) : val;
    } else {
      // Val is int
      return val;
    }
  } else if (typeof val === "string" && !isNaN(parseFloat(val))) {
    // If string that represent number
    if (parseFloat(val) % 1 !== 0 && parseFloat(val).toString() === val) {
      // If not int and parsed float equal to string (fails for e.g. 2020.05.25)
      return roundOff ? parseFloat(val).toFixed(2) : parseFloat(val);
    } else if (parseInt(val).toString() === val) {
      // if int and parsed int not equal to string (fails for e.g. 2020.05.25)
      return parseInt(val);
    } else {
      // Return all other, non-parseable strings
      return val;
    }
  } else {
    // Returns everything else unparsed
    return val;
  }
};

export const compareByOperator = (
  operator: Operator,
  result: string | number,
  benchmark?: string | number
) => {
  // If one happens to compare something to a non-existing benchmark, consider it passed
  if (benchmark === undefined || benchmark === null) {
    return true;
  }

  var parsedBenchmark = parseValue(benchmark, false);
  var parsedResult = parseValue(result, false);

  // If one happens to compare something to a non-existing benchmark, consider it passed
  if (parsedBenchmark == null) {
    return true;
  }

  switch (operator) {
    case Operator.LT:
      return parsedResult < parsedBenchmark;
    case Operator.LE:
      return parsedResult <= parsedBenchmark;
    case Operator.EQ:
      return parsedResult === parsedBenchmark;
    case Operator.NE:
      return parsedResult !== parsedBenchmark;
    case Operator.GE:
      return parsedResult >= parsedBenchmark;
    case Operator.GT:
      return parsedResult > parsedBenchmark;
    default:
      return false;
  }
};
