import React from "react";
import styled from "styled-components";
import { H4, NewsDateHeader } from "../shared/typography";
import { Card } from "../shared/Card";

const NewsLinkWrapper = styled.a`
  color: unset;
  text-decoration: none;
`;

const NewsCard = styled(Card)`
  padding-bottom: 12px;
  overflow: hidden;

  :hover {
    box-shadow: ${props => props.theme.shadows.cardShadowHover};
  }
`;

const Image = styled.img`
  width: 100%;
  height: 256px;
  object-fit: cover;
  margin-bottom: 24px;
  border-radius: 2px;
`;

const PlaceholderImage = styled.img`
  width: 100%;
  height: 256px;
  object-fit: scale-down;
  padding: 96px;
  margin-bottom: 24px;
`;

interface NewsArticleProps {
  title: string;
  date: string;
  link: string;
  image?: string;
  shouldOpenInNewTab: boolean;
}

// News "card" for News Articles from Mentice News RSS Feed
export const NewsArticle: React.FC<NewsArticleProps> = ({
  title,
  date,
  link,
  image,
  shouldOpenInNewTab
}) => {
  const dateObj = new Date(date);
  const dateStr =
    dateObj.getDate() +
    " " +
    dateObj.toLocaleString("default", { month: "short" }) +
    ", " +
    dateObj.getFullYear();

  // Define props to spread in link, _if_ we want to open in a new tab
  const linkProps = shouldOpenInNewTab && {
    target: "_blank",
    rel: "noopener"
  };

  return (
    <NewsLinkWrapper href={link} {...linkProps}>
      <NewsCard className="newsCard">
        {image ? (
          <Image src={image} />
        ) : (
          <PlaceholderImage src="/static/assets/mentice-logo.png" />
        )}
        <NewsDateHeader>{dateStr}</NewsDateHeader>
        <H4>{title}</H4>
      </NewsCard>
    </NewsLinkWrapper>
  );
};
