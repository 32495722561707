/* eslint-disable react-hooks/exhaustive-deps */
import { useKeycloak } from "@react-keycloak/web";
import { debounce } from "lodash";
import React, { SetStateAction, useEffect, useReducer, useState } from "react";
import DateRangePicker from "../../components/shared/DateRangePicker";
import { DropdownMenu } from "../../components/shared/dropdown-menu/DropdownMenu";
import { FilterContainer } from "../../components/shared/dropdown-menu/FilterContainer";
import SelectField from "../../components/shared/SelectField";
import TextField from "../../components/shared/TextField";
import {
  IrLogFormData,
  OrganSystem,
  ProcedureCategory,
  ProcedureSubCategory,
} from "../../generated/graphql";
import {
  getDateString,
  getFirstHour,
  getLastHour,
} from "../../lib/formatDateAndTime";
import { haveRole, KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import {
  InterventionTypes,
  ModalityMap,
  ComplicationsMap,
} from "./ProcedureDetailSection";
import { FilterValues } from "./types";

interface OptionName {
  value: string;
  label: string;
  name: string;
}

interface RecordFilterProps {
  setFilterStartDate?: React.Dispatch<SetStateAction<string | undefined>>;
  setFilterEndDate?: React.Dispatch<SetStateAction<string | undefined>>;
  onClear: () => void;
  filterData: IrLogFormData;
  onChange: (event: React.ChangeEvent | OptionName) => void;
  values: FilterValues;
}

const RecordFilter: React.FC<RecordFilterProps> = ({
  setFilterStartDate,
  setFilterEndDate,
  onClear,
  onChange,
  filterData,
  values,
}) => {
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const isCustomerAdmin = haveRole(user, "customer_admin");
  const isGroupAdmin = haveRole(user, "group_admin");
  const isSysAdmin = haveRole(user, "sys_admin");
  const isAdmin = isSysAdmin || isGroupAdmin || isCustomerAdmin;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleDateRangeChange = (start: Date | null, end: Date | null) => {
    setFilterStartDate &&
      setFilterStartDate(start ? getDateString(start) : undefined);
    setFilterEndDate && setFilterEndDate(end ? getDateString(end) : undefined);
    setStartDate(start && getFirstHour(start));
    setEndDate(end && getLastHour(end));
  };

  const [textFieldValues, setTextFieldValues] = useState({
    participantName: "",
  });

  const debounceChange = debounce((value) => {
    onChange(value);
  }, 1000);

  type RecordProcedureFilterAction =
    | { type: "interventionType"; interventionType: string }
    | { type: "organSystem"; organSystem: string }
    | { type: "category"; category: string }
    | { type: "subCategory"; subCategory: string };

  type State = {
    organSystemOptions?: OrganSystem[];
    categoryOptions?: ProcedureCategory[];
    subCategoryOptions?: ProcedureSubCategory[];
  };

  const initialState: State = {
    organSystemOptions: [],
    categoryOptions: [],
    subCategoryOptions: [],
  };

  // Reducer to handle the setting of the four dependent filter value options
  const reducer = (
    state: State,
    action: RecordProcedureFilterAction
  ): State => {
    switch (action.type) {
      case "interventionType":
        const organSystemsFromIT = filterData.interventionTypes.find(
          (item) => item.name === action.interventionType
        )?.organSystems;
        const organSystemFromIT = organSystemsFromIT?.map(
          (item) => item.name
        )[0];
        const categoriesFromIT = organSystemsFromIT?.find(
          (item) => item.name === organSystemFromIT
        )?.categories;
        const categoryFromIT = categoriesFromIT?.map((item) => item.name)[0];
        const subCategoriesFromIT = categoriesFromIT?.find(
          (item) => item.name === categoryFromIT
        )?.subCategories;
        return {
          ...state,
          organSystemOptions: organSystemsFromIT,
          categoryOptions: categoriesFromIT,
          subCategoryOptions: subCategoriesFromIT,
        };
      case "organSystem":
        const categoriesFromOS = state?.organSystemOptions?.find(
          (item) => item.name === action.organSystem
        )?.categories;
        const categoryFromOS = categoriesFromOS?.map((item) => item.name)[0];
        const subCategoriesFromOS = categoriesFromOS?.find(
          (item) => item.name === categoryFromOS
        )?.subCategories;
        return {
          ...state,
          categoryOptions: categoriesFromOS,
          subCategoryOptions: subCategoriesFromOS,
        };
      case "category":
        const subCategoriesFromC = state?.categoryOptions?.find(
          (item) => item.name === action.category
        )?.subCategories;
        return {
          ...state,
          subCategoryOptions: subCategoriesFromC,
        };
      case "subCategory":
        return {
          ...state,
        };
      default:
        throw new Error("Unexpected action");
    }
  };
  const [recordProcedureFilter, updateRecordProcedureFilter] = useReducer(
    reducer,
    initialState
  );

  // Effects to reset values on change
  useEffect(() => {
    if (recordProcedureFilter.organSystemOptions) {
      onChange({ value: "", label: "", name: "organSystem" });
      onChange({ value: "", label: "", name: "category" });
      onChange({ value: "", label: "", name: "subCategory" });
    }
  }, [recordProcedureFilter.organSystemOptions]);

  useEffect(() => {
    if (recordProcedureFilter.categoryOptions) {
      onChange({ value: "", label: "", name: "category" });
      onChange({ value: "", label: "", name: "subCategory" });
    }
  }, [recordProcedureFilter.categoryOptions]);

  useEffect(() => {
    if (recordProcedureFilter.subCategoryOptions) {
      onChange({ value: "", label: "", name: "subCategory" });
    }
  }, [recordProcedureFilter.subCategoryOptions]);

  return (
    <DropdownMenu isFilter icon={"/static/assets/filter.svg"}>
      <FilterContainer
        onClear={() => {
          setTextFieldValues({ participantName: "" });
          handleDateRangeChange(null, null);
          onClear();
        }}
      >
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChange={(start: Date | null, end: Date | null) =>
            handleDateRangeChange(start, end)
          }
        />
        <SelectField
          label="Hospital"
          name="hospital"
          onChange={onChange}
          value={{
            value: values?.hospital,
            label: values?.hospital,
          }}
          options={filterData?.hospitals?.map((hospital) => ({
            value: hospital.name,
            label: hospital.name,
            name: "hospital",
          }))}
        />
        <SelectField
          label="Attending"
          name="attending"
          onChange={onChange}
          value={{
            value: values?.attending,
            label: values?.attending,
          }}
          options={filterData?.attending?.map((item) => ({
            value: `${item.lastName}, ${item.firstName}`,
            label: `${item.lastName}, ${item.firstName}`,
            name: "attending",
          }))}
        />
        {isAdmin && (
          <TextField
            name="participantName"
            value={textFieldValues.participantName}
            placeholder="Filter by participant name"
            onChange={(e) => {
              setTextFieldValues({
                ...textFieldValues,
                participantName: e.currentTarget.value,
              });
              debounceChange(e);
            }}
            label="Participant"
            type="text"
          />
        )}
        <SelectField
          label="Intervention Type"
          name="interventionType"
          onChange={(e: OptionName) => {
            updateRecordProcedureFilter({
              type: "interventionType",
              interventionType: e.value,
            });
            onChange(e);
          }}
          value={{
            value: values?.interventionType,
            label: values?.interventionType,
          }}
          options={InterventionTypes.map((item) => ({
            value: item,
            label: item,
            name: "interventionType",
          }))}
        />
        {values.interventionType && (
          <>
            <SelectField
              label="Organ System"
              name="organSystem"
              onChange={(e: OptionName) => {
                updateRecordProcedureFilter({
                  type: "organSystem",
                  organSystem: e.value,
                });
                onChange(e);
              }}
              value={{
                value: values?.organSystem,
                label: values?.organSystem,
              }}
              options={recordProcedureFilter.organSystemOptions?.map(
                (item) => ({
                  value: item.name,
                  label: item.name,
                  name: "organSystem",
                })
              )}
            />
            <SelectField
              label="Procedure category"
              name="category"
              onChange={(e: OptionName) => {
                updateRecordProcedureFilter({
                  type: "category",
                  category: e.value,
                });
                onChange(e);
              }}
              value={{
                value: values?.category,
                label: values?.category,
              }}
              options={recordProcedureFilter.categoryOptions?.map((item) => ({
                value: item.name,
                label: item.name,
                name: "category",
              }))}
            />
            <SelectField
              label="Procedure"
              name="subCategory"
              onChange={(e: OptionName) => {
                updateRecordProcedureFilter({
                  type: "subCategory",
                  subCategory: e.value,
                });
                onChange(e);
              }}
              value={{
                value: values?.subCategory,
                label: values?.subCategory,
              }}
              options={recordProcedureFilter.subCategoryOptions?.map(
                (item) => ({
                  value: item.name,
                  label: item.name,
                  name: "subCategory",
                })
              )}
            />
          </>
        )}
        <SelectField
          label="Primary modality"
          name="modalityPrimary"
          onChange={onChange}
          value={{
            value: values?.modalityPrimary,
            label: ModalityMap[values?.modalityPrimary ?? ""],
          }}
          options={Object.entries(ModalityMap).map(([key, value]) => ({
            value: key,
            label: value,
            name: "modalityPrimary",
          }))}
        />
        <SelectField
          label="Complications"
          name="complications"
          value={{
            value: values?.complications,
            label: ComplicationsMap[values?.complications ?? ""],
          }}
          onChange={onChange}
          options={Object.entries(ComplicationsMap).map(([key, value]) => ({
            value: key,
            label: value,
            name: "complications",
          }))}
        />
      </FilterContainer>
    </DropdownMenu>
  );
};

export default RecordFilter;
