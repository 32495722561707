import React, { useState } from "react";
import styled from "styled-components";
import LoadingIndicator from "../../components/shared/LoadingIndicator";
import {
  h3Style,
  h4Style,
  textStyle,
} from "../../components/shared/typography";
import { gql, useQuery } from "@apollo/client";

const Report = styled.div`
  padding: ${(props) =>
    `${props.theme.sizes.spacing4} ${props.theme.sizes.spacing5}`};
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    padding: ${(props) => props.theme.sizes.spacing2};
  }
  table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
  }
  table:first-of-type {
    td:first-of-type {
      width: 20%;
      @media screen and (max-width: ${(props) =>
          props.theme.sizes.breakpointM}) {
        width: auto;
      }
    }
  }
  table:not(:first-of-type) {
    tr:nth-child(odd) {
      background-color: ${(props) => props.theme.colors.paleGrey};
    }
    td {
      padding: ${(props) => props.theme.sizes.spacing0_5} 8px;
    }
  }
  table:not(:first-of-type):not(:last-of-type) {
    td:first-of-type {
      width: 60%;
    }
  }
  h1 {
    text-align: left;
    ${h3Style}
  }
  h2,
  .dataHeaderReport {
    ${h4Style}
  }
  .report,
  .reportBold {
    ${textStyle}
  }
`;

interface LegacyReportProps {
  id: string;
}

const LegacyReport: React.FC<LegacyReportProps> = ({ id }) => {
  const [finalResultFile, setFinalResultFile] = useState<string | undefined>(
    undefined
  );
  useQuery(
    gql`
      query FetchOneVistActivity($id: String!) {
        vistActivity(id: $id) {
          finalResultLink
        }
      }
    `,
    {
      variables: { id: id },
      onCompleted: (data) => {
        if (!data.vistActivity.finalResultLink) {
          setFinalResultFile("<p>No report found for the current activity</p>");
        } else {
          fetch(data.vistActivity.finalResultLink)
            .then((res) => res.text())
            .then((text) => {
              setFinalResultFile(text);
            })
            .catch(() => {
              setFinalResultFile(
                "<p>No report found for the current activity</p>"
              );
            });
        }
      },
    }
  );

  if (!finalResultFile) {
    return <LoadingIndicator />;
  } else {
    return (
      <Report
        dangerouslySetInnerHTML={{
          __html: finalResultFile,
        }}
      ></Report>
    );
  }
};

export default LegacyReport;
