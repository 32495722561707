import React, { useState } from "react";
import MetricGroupHeader from "./MetricGroupHeader";
import Metric from "./Metric";
import { Operator } from "../../../shared/enums";
import { compareByOperator } from "./helpers";
import { Result } from "./RunningVistCard";
import { BenchmarkValue, Group } from "../../../generated/graphql";
import { RelevantMetrics } from "./ResultsPage";

interface MetricGroupProps {
  group: Group;
  benchmarks: BenchmarkValue[];
  results?: Result[];
  depth: number;
  isCompactView: boolean;
  totalMetrics?: RelevantMetrics[];
}

const MetricGroup: React.FC<MetricGroupProps> = ({
  group,
  benchmarks,
  results,
  depth,
  isCompactView,
  totalMetrics,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    !!group.is_expanded_by_default
  );

  const matchResultBenchmark = (metric: string) => {
    const result = results?.find((result) => result.internal_id === metric);

    // Find the given benchmark for the current metric in this group
    const benchmark = benchmarks.find(
      (benchmark) => benchmark.metric.internal_id === metric
    );

    const completed =
      // Explicitly check against null or undefined, as 0 (a valid benchmark / result) is also falsy
      result?.value !== null &&
      result?.value !== undefined &&
      benchmark?.value !== null &&
      benchmark?.value !== undefined
        ? compareByOperator(
            benchmark.default_operator as Operator,
            result.value,
            benchmark.value
          )
        : null;

    return {
      result: result,
      benchmark: benchmark,
      completed: completed,
    };
  };

  // Get all metrics that we want to render in this group
  const relevantMetrics: RelevantMetrics[] = group.metrics
    ? group.metrics.map((metric) => matchResultBenchmark(metric))
    : [];

  // Calculate all group header metric results
  const accumulatedMetrics = group.groups
    ? group.groups.flatMap((group) =>
        group.metrics
          ? group.metrics.map((metric) => matchResultBenchmark(metric))
          : []
      )
    : [];

  const existsComparedBenchmarks: boolean =
    relevantMetrics.length > 0 &&
    !relevantMetrics?.find((metric) => metric.completed === null);

  const passedMetrics: number | undefined = existsComparedBenchmarks
    ? relevantMetrics.reduce(
        (acc: number, metric) => (metric.completed ? acc + 1 : acc),
        0
      )
    : undefined;

  const relevantMetricsWithResult = relevantMetrics.filter(
    (relevantMetric) =>
      relevantMetric.benchmark?.value !== null &&
      relevantMetric.benchmark?.value !== undefined
  );

  const passedAccumulatedMetrics = accumulatedMetrics?.filter(
    (metric) => metric?.completed === true
  );

  const totalPassedAccumulatedMetrics = totalMetrics?.filter(
    (metric) => metric?.completed === true
  );
  const hasGroupsOrRelevantMetrics =
    (group.groups?.length ?? 0) > 0 || relevantMetricsWithResult.length > 0;
  if (isCompactView && !hasGroupsOrRelevantMetrics) {
    return null;
  } else {
    return (
      <>
        <MetricGroupHeader
          depth={depth}
          close={() => setIsExpanded(!isExpanded)}
          isExpanded={isExpanded}
          label={group.label}
          description={group.description}
          passedMetrics={(() => {
            if (existsComparedBenchmarks) {
              if (passedAccumulatedMetrics && passedMetrics) {
                return passedAccumulatedMetrics.length + passedMetrics;
              } else {
                return passedMetrics;
              }
            } else {
              return totalPassedAccumulatedMetrics
                ? totalPassedAccumulatedMetrics.length
                : undefined;
            }
          })()}
          totalMetrics={(() => {
            if (existsComparedBenchmarks) {
              if (accumulatedMetrics) {
                return (
                  accumulatedMetrics.filter(
                    (metric) => metric.completed !== null
                  ).length + relevantMetrics.length
                );
              } else {
                return relevantMetrics.filter(
                  (metric) => metric.completed !== null
                ).length;
              }
            } else {
              return totalMetrics?.length ? totalMetrics?.length : undefined;
            }
          })()}
        />
        {isExpanded &&
          relevantMetrics?.map((metric, i: number) => {
            if (metric.benchmark) {
              return (
                <Metric
                  key={`${i}`}
                  depth={depth}
                  label={metric.benchmark.metric.label}
                  completed={!!metric.completed}
                  showResult={results !== undefined}
                  result={metric.result?.value}
                  operator={metric.benchmark.default_operator as Operator}
                  benchmark={metric.benchmark.value}
                  unit={metric.benchmark.metric.unit}
                />
              );
            } else {
              return null;
            }
          })}
        {isExpanded &&
          group.groups?.map((group, i: number) => (
            <MetricGroup
              depth={depth + 1}
              key={i}
              group={group}
              benchmarks={benchmarks}
              results={results}
              isCompactView={isCompactView}
            />
          ))}
      </>
    );
  }
};

export default MetricGroup;
