import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import {
  CardDivider,
  CardHeader,
  CardWrapper,
} from "../../components/Groups/GroupCard";
import { SidebarCardSection } from "../../components/shared/sidebar-card/SidebarCardSection";
import {
  BoldTextNoWrap,
  H4,
  SemiBoldTextNoWrap,
  Text,
  TextNoWrap,
} from "../../components/shared/typography";
import {
  CloudCaseVmStatus,
  CloudCaseVirtualMachine,
} from "../../generated/graphql";
import { ContextMenu } from "../../components/shared/context-menu/ContextMenu";
import { formatDateAndTime } from "../../lib/formatDateAndTime";
import ActionBar from "../../components/shared/ActionBar";
import Button from "../../components/shared/Button";
import { ButtonWrapper } from "../../components/shared/Dialog";
import { ButtonSizes, ToastFormat, ToastType } from "../../shared/enums";
import { useToast } from "../../lib/useToast";
import { MenuItem } from "../../components/shared/context-menu/MenuItem";
import {
  cloudCaseVMFields,
  cloudCaseVMHistoryFields,
} from "../../types/fragments/CloudCaseVMFragment";
import Tooltip from "../../components/Groups/Tooltip";

const InfoBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
`;

const HistoryBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  > *:not(:nth-child(1)) {
    padding-left: 6px;
  }
`;

const CloudCaseMachines: React.FC = () => {
  const { data, loading, error } = useQuery<{
    cloudCaseVMs: CloudCaseVirtualMachine[];
  }>(
    gql`
      query CloudCaseVMs {
        cloudCaseVMs {
          ...CloudCaseVMFields
          history {
            ...CloudCaseVMHistoryFields
          }
        }
      }
      ${cloudCaseVMFields}
      ${cloudCaseVMHistoryFields}
    `,
    {
      fetchPolicy: "network-only",
      pollInterval: 10000,
    }
  );

  return (
    <SidebarCardSection title="" maxColumns={1}>
      <ActionBar title={"Cloud Case VMs"} />
      {loading && !error && !data && (
        <SidebarCardSection title="Groups" maxColumns={1}>
          <Text>Loading cloud case VMs...</Text>
        </SidebarCardSection>
      )}
      {(error || !data) && !loading && (
        <SidebarCardSection title="Cloud Case VMs" maxColumns={1}>
          <Text>Something went wrong when fetching cloud case VMs...</Text>
          {error?.graphQLErrors?.map(
            (error: { message: string }, i: number) => (
              <Text key={i}>{error.message}</Text>
            )
          )}
        </SidebarCardSection>
      )}
      {!error && !loading && data && (
        <>
          {(() => {
            const startedVMs = [...data.cloudCaseVMs].filter(
              (cloudCaseVM) => cloudCaseVM.status === CloudCaseVmStatus.Started
            );
            return (
              <>
                {startedVMs.length > 0 && (
                  <>
                    <H4>Started VMs</H4>
                    <SidebarCardSection maxColumns={2}>
                      {startedVMs.map((cloudCaseVM) => (
                        <VMCard
                          key={cloudCaseVM.id}
                          cloudCaseVM={cloudCaseVM}
                        />
                      ))}
                    </SidebarCardSection>
                  </>
                )}
              </>
            );
          })()}
          {(() => {
            const startingVMs = [...data.cloudCaseVMs].filter(
              (cloudCaseVM) => cloudCaseVM.status === CloudCaseVmStatus.Starting
            );
            return (
              <>
                {startingVMs.length > 0 && (
                  <>
                    <H4>Starting VMs</H4>
                    <SidebarCardSection maxColumns={2}>
                      {startingVMs.map((cloudCaseVM) => (
                        <VMCard
                          key={cloudCaseVM.id}
                          cloudCaseVM={cloudCaseVM}
                        />
                      ))}
                    </SidebarCardSection>
                  </>
                )}
              </>
            );
          })()}
          {(() => {
            const idleVMs = [...data.cloudCaseVMs].filter(
              (cloudCaseVM) => cloudCaseVM.status === CloudCaseVmStatus.Idle
            );
            return (
              <>
                {idleVMs.length > 0 && (
                  <>
                    <H4>Idle VMs</H4>
                    <SidebarCardSection maxColumns={2}>
                      {idleVMs.map((cloudCaseVM) => (
                        <VMCard
                          key={cloudCaseVM.id}
                          cloudCaseVM={cloudCaseVM}
                        />
                      ))}
                    </SidebarCardSection>
                  </>
                )}
              </>
            );
          })()}
          {(() => {
            const desiredVMs = [...data.cloudCaseVMs].filter(
              (cloudCaseVM) => cloudCaseVM.status === CloudCaseVmStatus.Desired
            );
            return (
              <>
                {desiredVMs.length > 0 && (
                  <>
                    <H4>Claimed VMs</H4>
                    <SidebarCardSection maxColumns={2}>
                      {desiredVMs.map((cloudCaseVM) => (
                        <VMCard
                          key={cloudCaseVM.id}
                          cloudCaseVM={cloudCaseVM}
                        />
                      ))}
                    </SidebarCardSection>
                  </>
                )}
              </>
            );
          })()}
          {(() => {
            const offVMs = [...data.cloudCaseVMs].filter(
              (cloudCaseVM) => cloudCaseVM.status === CloudCaseVmStatus.Off
            );
            return (
              <>
                {offVMs.length > 0 && (
                  <>
                    <H4>Off VMs</H4>
                    <SidebarCardSection maxColumns={2}>
                      {offVMs.map((cloudCaseVM) => (
                        <VMCard
                          key={cloudCaseVM.id}
                          cloudCaseVM={cloudCaseVM}
                        />
                      ))}
                    </SidebarCardSection>
                  </>
                )}
              </>
            );
          })()}
          {(() => {
            const voidVMs = [...data.cloudCaseVMs].filter(
              (cloudCaseVM) => cloudCaseVM.status === CloudCaseVmStatus.Void
            );
            return (
              <>
                {voidVMs.length > 0 && (
                  <>
                    <H4>Claimed VMs</H4>
                    <SidebarCardSection maxColumns={2}>
                      {voidVMs.map((cloudCaseVM) => (
                        <VMCard
                          key={cloudCaseVM.id}
                          cloudCaseVM={cloudCaseVM}
                        />
                      ))}
                    </SidebarCardSection>
                  </>
                )}
              </>
            );
          })()}
          {(() => {
            const statuslessVMs = [...data.cloudCaseVMs].filter(
              (cloudCaseVM) =>
                cloudCaseVM.status !== CloudCaseVmStatus.Started &&
                cloudCaseVM.status !== CloudCaseVmStatus.Starting &&
                cloudCaseVM.status !== CloudCaseVmStatus.Idle &&
                cloudCaseVM.status !== CloudCaseVmStatus.Desired &&
                cloudCaseVM.status !== CloudCaseVmStatus.Off &&
                cloudCaseVM.status !== CloudCaseVmStatus.Void
            );
            return (
              <>
                {statuslessVMs.length > 0 && (
                  <>
                    <H4>Statusless VMs</H4>
                    <SidebarCardSection maxColumns={2}>
                      {statuslessVMs.map((cloudCaseVM) => (
                        <VMCard cloudCaseVM={cloudCaseVM} />
                      ))}
                    </SidebarCardSection>
                  </>
                )}
              </>
            );
          })()}
          {data.cloudCaseVMs.length === 0 && (
            <Text>No cloud case machines found.</Text>
          )}
        </>
      )}
    </SidebarCardSection>
  );
};

const VMCard: React.FC<{ cloudCaseVM: CloudCaseVirtualMachine }> = ({
  cloudCaseVM,
}) => {
  const [, createToast] = useToast();
  const [showToolTipForStateChangeDate, setShowToolTipForStateChangeDate] =
    useState<Date | undefined>(undefined);

  const [stopCloudCaseSessionWithVoucherId] = useMutation(
    gql`
      mutation StopCloudCaseSessionWithVoucherId($voucherId: Int!) {
        stopCloudCaseSessionWithVoucherId(voucherId: $voucherId) {
          refetch {
            cloudCaseVMs {
              ...CloudCaseVMFields
            }
          }
        }
      }
      ${cloudCaseVMFields}
    `,
    {
      variables: {
        voucherId: cloudCaseVM.voucher_id,
      },
      onCompleted() {
        createToast({
          title: "Cloud case ended",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError(err: ApolloError) {
        console.log(JSON.stringify(err));
        createToast({
          title: "Failed to end cloud case",
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  const [turnOffVM] = useMutation(
    gql`
      mutation TurnOffVM($url: String!) {
        turnOffVM(url: $url) {
          refetch {
            cloudCaseVMs {
              ...CloudCaseVMFields
            }
          }
        }
      }
      ${cloudCaseVMFields}
    `,
    {
      variables: {
        url: cloudCaseVM.url,
      },
      onCompleted() {
        createToast({
          title: "Turning off VM",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError(err: ApolloError) {
        console.log(JSON.stringify(err));
        createToast({
          title: "Failed to turn off VM",
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  const [turnOnVM] = useMutation(
    gql`
      mutation TurnOnVM($url: String!) {
        turnOnVM(url: $url) {
          refetch {
            cloudCaseVMs {
              ...CloudCaseVMFields
            }
          }
        }
      }
      ${cloudCaseVMFields}
    `,
    {
      variables: {
        url: cloudCaseVM.url,
      },
      onCompleted() {
        createToast({
          title: "Starting VM",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError(err: ApolloError) {
        console.log(JSON.stringify(err));
        createToast({
          title: "Failed to start VM",
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  const [resetVMToIdle] = useMutation(
    gql`
      mutation ResetVMToIdle($vmId: Int!) {
        resetVMToIdle(vmId: $vmId) {
          refetch {
            cloudCaseVMs {
              ...CloudCaseVMFields
            }
          }
        }
      }
      ${cloudCaseVMFields}
    `,
    {
      variables: {
        vmId: cloudCaseVM.id,
      },
      onCompleted() {
        createToast({
          title: "Reset VM to Idle",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError(err: ApolloError) {
        console.log(JSON.stringify(err));
        createToast({
          title: "Failed to reset VM to Idle",
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  const [resetVMToOff] = useMutation(
    gql`
      mutation ResetVMToOff($vmId: Int!) {
        resetVMToOff(vmId: $vmId) {
          refetch {
            cloudCaseVMs {
              ...CloudCaseVMFields
            }
          }
        }
      }
      ${cloudCaseVMFields}
    `,
    {
      variables: {
        vmId: cloudCaseVM.id,
      },
      onCompleted() {
        createToast({
          title: "Reset VM",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError(err: ApolloError) {
        console.log(JSON.stringify(err));
        createToast({
          title: "Failed to reset VM",
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  const [removeVM] = useMutation(
    gql`
      mutation RemoveVM($vmId: Int!) {
        removeVM(vmId: $vmId) {
          refetch {
            cloudCaseVMs {
              ...CloudCaseVMFields
            }
          }
        }
      }
      ${cloudCaseVMFields}
    `,
    {
      variables: {
        vmId: cloudCaseVM.id,
      },
      onCompleted() {
        createToast({
          title: "Removed VM",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError(err: ApolloError) {
        console.log(JSON.stringify(err));
        createToast({
          title: "Failed to remove VM",
          type: ToastType.ERROR,
          format: ToastFormat.BANNER,
        });
      },
    }
  );

  let vmTitle: string = "VM";
  if (cloudCaseVM.url) {
    let url: URL | undefined;
    try {
      url = new URL(cloudCaseVM.url);
    } catch {
      vmTitle = cloudCaseVM.url;
    }
    if (url && url.hostname.split(".").length > 2) {
      vmTitle = url.hostname.split(".")[0].toUpperCase();
    } else if (url) {
      vmTitle = url.hostname;
    } else {
      vmTitle = cloudCaseVM.url ?? "VM";
    }
  }

  return (
    <CardWrapper>
      <div>
        <CardHeader>
          <H4>{`${vmTitle}`}</H4>
          <ContextMenu>
            <MenuItem onClick={() => resetVMToOff()}>
              Reset without syncing
            </MenuItem>
            <MenuItem onClick={() => removeVM()}>Remove VM</MenuItem>
          </ContextMenu>
        </CardHeader>
        <Text>{cloudCaseVM.url}</Text>
        {(cloudCaseVM.module_id ||
          cloudCaseVM.case_id ||
          cloudCaseVM.voucher_id ||
          cloudCaseVM.started_by ||
          cloudCaseVM.session_started_at) && (
          <>
            <CardDivider />
            <InfoBox>
              <SemiBoldTextNoWrap>Session ID</SemiBoldTextNoWrap>
              <TextNoWrap>{cloudCaseVM.session_id?.slice(0, 15)}...</TextNoWrap>
              <SemiBoldTextNoWrap>Module ID</SemiBoldTextNoWrap>
              <TextNoWrap>{cloudCaseVM.module_id}</TextNoWrap>
              <SemiBoldTextNoWrap>Case ID</SemiBoldTextNoWrap>
              <TextNoWrap>{cloudCaseVM.case_id}</TextNoWrap>
              <SemiBoldTextNoWrap>Voucher ID</SemiBoldTextNoWrap>
              <TextNoWrap>{cloudCaseVM.voucher_id}</TextNoWrap>
              <SemiBoldTextNoWrap>User</SemiBoldTextNoWrap>
              <TextNoWrap>
                {cloudCaseVM.started_by?.first_name}{" "}
                {cloudCaseVM.started_by?.last_name}
              </TextNoWrap>
              <SemiBoldTextNoWrap>Started at</SemiBoldTextNoWrap>
              <TextNoWrap>
                {formatDateAndTime(cloudCaseVM.session_started_at)}
              </TextNoWrap>
            </InfoBox>
          </>
        )}
        {cloudCaseVM.history && (cloudCaseVM.history?.length ?? 0) > 0 && (
          <>
            <CardDivider />
            <HistoryBox>
              {cloudCaseVM.history &&
                cloudCaseVM.history.map((stateChange, i) => (
                  <Fragment>
                    <div
                      style={{ position: "relative" }}
                      key={stateChange.updated_at}
                    >
                      {cloudCaseVM.history &&
                      i !== cloudCaseVM.history?.length - 1 ? (
                        <TextNoWrap
                          onPointerEnter={() =>
                            setShowToolTipForStateChangeDate(
                              stateChange.updated_at
                            )
                          }
                          onPointerLeave={() =>
                            setShowToolTipForStateChangeDate(undefined)
                          }
                        >
                          {(stateChange.new_status === CloudCaseVmStatus.Desired
                            ? "Claimed"
                            : stateChange.new_status
                          ).toUpperCase()}
                        </TextNoWrap>
                      ) : (
                        <BoldTextNoWrap
                          onPointerEnter={() =>
                            setShowToolTipForStateChangeDate(
                              stateChange.updated_at
                            )
                          }
                          onPointerLeave={() =>
                            setShowToolTipForStateChangeDate(undefined)
                          }
                        >
                          {(stateChange.new_status === CloudCaseVmStatus.Desired
                            ? "Claimed"
                            : stateChange.new_status
                          ).toUpperCase()}
                        </BoldTextNoWrap>
                      )}
                      {showToolTipForStateChangeDate ===
                        stateChange.updated_at && (
                        <Tooltip
                          label={`${
                            stateChange.case_id &&
                            stateChange.module_id &&
                            stateChange.started_by
                              ? `${stateChange.started_by.first_name} ${
                                  stateChange.started_by.last_name
                                } started ${stateChange.module_id}: ${
                                  stateChange.case_id
                                } at ${formatDateAndTime(
                                  stateChange.updated_at
                                )}`
                              : `${formatDateAndTime(stateChange.updated_at)}`
                          }`}
                        />
                      )}
                    </div>
                    {cloudCaseVM.history &&
                      i !== cloudCaseVM.history.length - 1 && (
                        <TextNoWrap>→</TextNoWrap>
                      )}
                  </Fragment>
                ))}
            </HistoryBox>
            <CardDivider style={{ marginTop: "6px", marginBottom: "20px" }} />
          </>
        )}

        <ButtonWrapper>
          {cloudCaseVM.status === CloudCaseVmStatus.Desired && (
            <>
              <Button
                secondary
                size={ButtonSizes.Small}
                onClick={() => resetVMToIdle()}
              >
                Free VM
              </Button>
            </>
          )}
          {cloudCaseVM.status === CloudCaseVmStatus.Started && (
            <>
              <Button
                secondary
                size={ButtonSizes.Small}
                onClick={() => stopCloudCaseSessionWithVoucherId()}
              >
                End Session
              </Button>
            </>
          )}
          {(cloudCaseVM.status === CloudCaseVmStatus.Started ||
            cloudCaseVM.status === CloudCaseVmStatus.Starting ||
            cloudCaseVM.status === CloudCaseVmStatus.Desired ||
            cloudCaseVM.status === CloudCaseVmStatus.Idle) && (
            <>
              <Button size={ButtonSizes.Small} onClick={() => turnOffVM()}>
                Turn Off
              </Button>
            </>
          )}
          {cloudCaseVM.status === CloudCaseVmStatus.Off && (
            <>
              <Button
                secondary
                size={ButtonSizes.Small}
                onClick={() => resetVMToOff()}
              >
                Reset
              </Button>
              <Button size={ButtonSizes.Small} onClick={() => turnOnVM()}>
                Start In Idle
              </Button>
            </>
          )}
        </ButtonWrapper>
      </div>
    </CardWrapper>
  );
};

export default CloudCaseMachines;
