import React, { useEffect, useState } from "react";
import DatePickerField from "../../components/shared/DatePickerField";
import SelectField from "../../components/shared/SelectField";
import TextField from "../../components/shared/TextField";
import { GridRow, GridCol } from "../../components/shared/FormLayout";
import {
  Attending,
  CustomerGroup,
  Hospital,
  Participant,
  PatientSex,
  ProcedureSetting,
} from "../../generated/graphql";
import { DetailSectionProps } from "./record";
import { Option } from "react-select/src/filters";
import { User } from "../../components/Groups/Group";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import styled from "styled-components";
import { Text } from "../../components/shared/typography";
import { FieldArray, FieldArrayRenderProps } from "formik";
import { getFullName } from "./helpers";
import { StringKeyObject } from "./types";
import { getLogbookOffsetDate } from "../../lib/formatDateAndTime";
import { addDays, isDate, subDays } from "date-fns";
import { useToast } from "../../lib/useToast";
import { ToastFormat, ToastType } from "../../shared/enums";

const AgeCategoryMap: StringKeyObject = {
  Pediatric: "Pediatric (<18)",
  Adult: "Adult (18-64)",
  Geriatric: "Geriatric (65+)",
};

const ParticipantRoleMap: StringKeyObject = {
  Operator: "Operator",
  Assistant: "Assistant",
  Observer: "Observer",
};

const TechnologistRoleMap: StringKeyObject = {
  Circulator: "Circulator",
  Scrub: "Scrub",
};

const RoleMap: StringKeyObject = {
  ...ParticipantRoleMap,
  ...TechnologistRoleMap,
};

const LevelMap: StringKeyObject = {
  R1: "R1",
  R2: "R2",
  R3: "R3",
  R4: "R4",
  IR1: "IR1",
  IR2: "IR2",
  IR7: "IR7",
  PreRadiology: "Pre-radiology",
};

const RemoveButton = styled(Text)`
  position: absolute;
  font-style: italic;
  bottom: -16px;
  right: 34px;
  cursor: pointer;
`;

const GeneralDetailSection: React.FC<DetailSectionProps> = ({
  handleChange,
  values,
  setFieldValue,
  errors,
  touched,
  setFieldTouched,
  hospitals,
  attending,
  customerGroups,
  onCustomerChange,
  customerError,
  customerIds,
  id,
}) => {
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const [, createToast] = useToast();
  const [textFieldValues, setTextFieldValues] = useState(
    values.optionalIdentifier
  );
  let boundArrayHelpers: FieldArrayRenderProps;

  const bindArrayHelpers = (arrayHelpers: FieldArrayRenderProps) => {
    boundArrayHelpers = arrayHelpers;
  };
  // Selects groupId for users only belonging to one group
  // Users beloning to no or multiple groups defaults to undefined
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (customerGroups && customerGroups.length <= 1) {
      setSelectedGroupId(
        customerGroups[0] ? customerGroups[0].id.toString() : undefined
      );
    } else {
      setFieldValue("groupId", values?.groupId?.toString());
      setSelectedGroupId(values?.groupId?.toString() ?? undefined);
    }
  }, [customerGroups, setFieldValue, values?.groupId]);

  // Provides participants for the select field based on selected group
  const getParticipantOptions = (groups: CustomerGroup[]): (User | null)[] => {
    const members: User[] | undefined | null = groups.find(
      (group) => group.id.toString() === selectedGroupId
    )?.members;
    const membersIncludingUser =
      members &&
      [
        ...members,
        !members.some((member) => member?.keycloak_id === user?.sub)
          ? getUser()
          : null,
      ].filter(Boolean);
    return membersIncludingUser || [];
  };

  const getUser = () => ({
    first_name: user?.given_name ?? "",
    last_name: user?.family_name ?? "",
    email: user?.email ?? "",
    keycloak_id: user?.sub ?? "",
  });

  const resetParticipantValues = () => {
    values?.participants?.forEach((participant, i) => {
      if (i !== 0) {
        boundArrayHelpers.remove(i);
      } else {
        setFieldValue(`participants[${i}].user`, getUser());
      }
    });
  };

  const handleGroupChange = (option: Option) => {
    resetParticipantValues();
    setSelectedGroupId(option.value.split("-")[0]);
    onCustomerChange && onCustomerChange(option);
  };

  const getCustomerForSelectedGroup = (): CustomerGroup | undefined =>
    customerGroups?.find(
      (group) => group.id.toString() === values.groupId?.toString()
    );

  const hasMultipleCustomerGroups = customerGroups && customerGroups.length > 1;

  const hasMultipleCustomers = customerIds && customerIds.length > 1;

  return (
    <>
      <GridRow>
        <GridCol>
          <DatePickerField
            formikValue
            label="Date of procedure *"
            name="dateOfProcedure"
            value={
              values.dateOfProcedure
                ? getLogbookOffsetDate(values.dateOfProcedure)
                : undefined
            }
            onChange={(name: string, val: any) => {
              if (isDate(val) && val < subDays(new Date(), 11)) {
                createToast({
                  title:
                    "Selected date of procedure is more than 10 days ago. Is this correct?",
                  type: ToastType.ERROR,
                  format: ToastFormat.BANNER,
                });
              }
              setFieldValue(name, val);
            }}
            onBlur={() =>
              setFieldTouched && setFieldTouched("dateOfProcedure", true)
            }
            error={touched?.dateOfProcedure && errors?.dateOfProcedure}
            excludeDateIntervals={[
              {
                start: new Date(),
                end: addDays(new Date(), 9999999),
              },
            ]}
          />
          <SelectField
            id="cy-record-attending"
            // only users who belong to at least one customer can add attendings
            createable
            label="Attending *"
            name="attending"
            error={touched?.attendingName && errors?.attendingName}
            defaultValue={{
              value: values.attendingId ?? "",
              label: values.attendingName
                ? values.attendingName
                : "Last Name, First Name",
            }}
            onBlur={() =>
              setFieldTouched && setFieldTouched("attendingName", true)
            }
            onChange={(option: Option) => {
              setFieldValue("attendingName", option.label.split(" (")[0]);
              setFieldValue("attendingId", option.value);
            }}
            options={(() => {
              // if editing existing record
              if (id) {
                // and user belongs to several groups
                if (hasMultipleCustomerGroups) {
                  // list only attendings belonging to existing customer
                  return attending
                    ?.filter(
                      (item) =>
                        item.customerId ===
                        getCustomerForSelectedGroup()?.customer?.id
                    )
                    ?.map((item: Attending) => ({
                      value: item.id,
                      label: getFullName(item.lastName, item.firstName),
                    }));
                } else {
                  return attending?.map((item: Attending) => ({
                    value: item.id,
                    label: getFullName(item.lastName, item.firstName),
                  }));
                }
                // if creating new record
              } else {
                return attending?.map((item: Attending) => ({
                  value: item.id,
                  label: hasMultipleCustomers
                    ? `${getFullName(item.lastName, item.firstName)} (${
                        item.customerName
                      })`
                    : getFullName(item.lastName, item.firstName),
                }));
              }
            })()}
          />
          <SelectField
            label="Patient Age"
            name="ageCategory"
            defaultValue={{
              value: values.ageCategory ?? "",
              label: AgeCategoryMap[values.ageCategory ?? ""],
            }}
            onChange={(option: Option) =>
              setFieldValue("ageCategory", option.value)
            }
            options={Object.entries(AgeCategoryMap).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </GridCol>
        <GridCol>
          <TextField
            type="text"
            name="optionalIdentifier"
            id="cy-record-optional-identifier"
            label="Optional Identifier"
            onChange={(e) => setTextFieldValues(e.currentTarget.value)}
            onBlur={handleChange}
            value={textFieldValues ?? ""}
          />
          <SelectField
            id="cy-record-hospital"
            createable
            label="Hospital *"
            name="hospital"
            onBlur={() => setFieldTouched && setFieldTouched("hospital", true)}
            error={touched?.hospital && errors?.hospital}
            defaultValue={{
              value: values.hospital,
              label: values.hospital,
            }}
            onChange={(option: Option) => {
              setFieldValue("hospital", option.value.split("-")[0]);
            }}
            options={(() => {
              if (id) {
                if (hasMultipleCustomerGroups) {
                  return hospitals
                    ?.filter(
                      (item) =>
                        item.customerId ===
                        getCustomerForSelectedGroup()?.customer?.id
                    )
                    .map((hospital: Hospital) => ({
                      value: `${hospital.name}`,
                      label: `${hospital.name}`,
                    }));
                } else {
                  return hospitals?.map((hospital: Hospital) => ({
                    value: `${hospital.name}`,
                    label: `${hospital.name}`,
                  }));
                }
              } else {
                return hospitals?.map((hospital: Hospital) =>
                  hasMultipleCustomers
                    ? {
                        value: `${hospital.name}-${hospital.customerId ?? ""}`,
                        label: `${hospital.name} (${hospital.customerName})`,
                      }
                    : {
                        value: `${hospital.name}`,
                        label: `${hospital.name}`,
                      }
                );
              }
            })()}
          />
          <SelectField
            label="Patient Sex"
            name="sex"
            defaultValue={{
              value: values.sex ?? "",
              label: values.sex,
            }}
            onChange={(option: Option) => setFieldValue("sex", option.value)}
            options={Object.entries(PatientSex).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </GridCol>
        <GridCol>
          <SelectField
            label="Setting"
            name="setting"
            defaultValue={{
              value: values.setting ?? "",
              label: values.setting,
            }}
            onChange={(option: Option) =>
              setFieldValue("setting", option.value)
            }
            options={Object.entries(ProcedureSetting).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
          {hasMultipleCustomerGroups && (
            <SelectField
              id="cy-record-group-id"
              label="Group *"
              name="groupId"
              isDisabled={id}
              error={
                touched?.groupId && customerError !== undefined && customerError
              }
              defaultValue={
                id
                  ? {
                      value: values.groupId,
                      label: getCustomerForSelectedGroup()?.name,
                    }
                  : { value: "", label: "" }
              }
              onBlur={onCustomerChange}
              onChange={(option: Option) => handleGroupChange(option)}
              options={customerGroups?.map((group) =>
                hasMultipleCustomers
                  ? {
                      value: `${group.id}-${group?.customer?.id ?? ""}`,
                      label: `${group.name} (${group?.customer?.name ?? ""})`,
                    }
                  : {
                      value: `${group.id}-${group?.customer?.id ?? ""}`,
                      label: `${group.name}`,
                    }
              )}
            />
          )}
        </GridCol>
      </GridRow>
      <FieldArray
        name="participants"
        render={(arrayHelpers) => {
          bindArrayHelpers(arrayHelpers);
          return (
            values?.participants &&
            values?.participants.map((participant: Participant, i: number) => (
              <div style={{ position: "relative" }} key={i}>
                <GridRow>
                  <GridCol>
                    {participant.name && (
                      <TextField
                        disabled
                        onChange={() => {}}
                        label="Participant"
                        value={participant.name ?? ""}
                      />
                    )}
                    {!participant.name && (
                      <SelectField
                        label={`Participant`}
                        name={`participants[${i}].user`}
                        onChange={(option: Option) =>
                          setFieldValue(`participants[${i}].user`, option.value)
                        }
                        value={{
                          value:
                            values?.participants?.[i].user?.keycloak_id ?? "",

                          label: `${
                            values?.participants?.[i].user?.last_name
                              ? values?.participants?.[i].user?.last_name + ","
                              : ""
                          } ${
                            values?.participants?.[i].user?.first_name ?? ""
                          }`,
                        }}
                        options={
                          selectedGroupId &&
                          customerGroups &&
                          getParticipantOptions(customerGroups).map((user) => ({
                            value: user ?? "",
                            label: `${
                              user?.last_name ? user?.last_name + "," : ""
                            } ${user?.first_name ?? ""}`,
                          }))
                        }
                      />
                    )}
                  </GridCol>
                  <GridCol>
                    <SelectField
                      label={`Role`}
                      name={`participants[${i}].role`}
                      value={{
                        value: values?.participants?.[i].role ?? "",
                        label: RoleMap[values?.participants?.[i].role ?? ""],
                      }}
                      onChange={(option: Option) =>
                        setFieldValue(`participants[${i}].role`, option.value)
                      }
                      options={[
                        ...Object.entries(ParticipantRoleMap).map(
                          ([key, value]) => ({
                            value: key,
                            label: value,
                          })
                        ),
                        {
                          label: "Technologist",
                          options: Object.entries(TechnologistRoleMap).map(
                            ([key, value]) => ({
                              value: key,
                              label: value,
                            })
                          ),
                        },
                      ]}
                    />
                  </GridCol>
                  <GridCol>
                    <SelectField
                      label={`Level`}
                      name={`participants[${i}].level`}
                      value={{
                        value: values?.participants?.[i].level ?? "",

                        label: LevelMap[values?.participants?.[i].level ?? ""],
                      }}
                      onChange={(option: Option) => {
                        arrayHelpers.insert(i + 1, {});
                        setFieldValue(`participants[${i}].level`, option.value);
                      }}
                      options={Object.entries(LevelMap).map(([key, value]) => ({
                        value: key,
                        label: value,
                      }))}
                    />
                  </GridCol>
                  {i >= 1 && (
                    <RemoveButton onClick={() => arrayHelpers.remove(i)}>
                      Remove participant
                    </RemoveButton>
                  )}
                </GridRow>
              </div>
            ))
          );
        }}
      />
    </>
  );
};

export default GeneralDetailSection;
