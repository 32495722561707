import React, { useState } from "react";
import {
  H3,
  H4,
  h6Style,
  spanStyle,
  textStyle,
} from "./../../components/shared/typography";
import { Image, Recording } from "../../generated/graphql";
import { Card } from "../../components/shared/Card";
import {
  formatTime,
  formatDateStringShortMonth,
  formatDateAndTime,
  formatDurationToMinutesAndSeconds,
} from "../../lib/formatDateAndTime";
import { CardSection } from "../../components/shared/CardSection";
import styled from "styled-components";
import Clock from "../../components/icons/Clock";
import { CloseButton, Modal } from "../../components/shared/Modal";
import Play from "../../components/icons/Play";
import { Text } from "../../components/shared/typography";
import { getServiceTag } from "../../lib/getServiceTag";
import { getMP4Support } from "../../lib/getMP4Support";

const Thumbnail = styled.div`
  margin: -24px -24px 16px -24px;
  width: calc(100% + 2 * 24px);
  height: 0;
  padding-bottom: 80%;
  overflow: hidden;
  position: relative;

  /* Thumbnail img */
  > img {
    width: 105%;
    margin: -20% -2.5% 0 -2.5%;
  }

  /* Timestamp */
  > span {
    ${spanStyle};
    font-size: 12px;
    color: ${(props) => props.theme.colors.white};
    position: absolute;
    bottom: ${(props) => props.theme.sizes.spacing1};
    right: ${(props) => props.theme.sizes.spacing1};
    background: hsla(0, 0%, 0%, 0.5);
    padding: 0 6px;
    border-radius: 2px;
  }
`;

const Placeholder = styled.div`
  width: 100%;
  padding-bottom: 80%;
  background: ${(props) => props.theme.colors.lightGrey};
  position: relative;

  > span {
    ${spanStyle};
    display: block;
    text-align: center;
    font-size: 12px;
    position: absolute;
    top: 35%;
    width: 100%;
    color: ${(props) => props.theme.colors.darkDarkGrey};
  }
`;

const ImagingTitle = styled.h6`
  ${h6Style};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MetadataLabel = styled.span`
  ${textStyle};
  line-height: 0;

  > svg {
    position: relative;
    top: 3px;
  }
`;

const FullWidthTitleSpanner = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
`;

interface ImagingCardProps {
  imagingItem: Image | Recording;
  onClick: () => void;
}

const ImagingCard: React.FC<ImagingCardProps> = ({ imagingItem, onClick }) => (
  <Card
    onClick={
      imagingItem.thumbnailLink && imagingItem.link ? onClick : undefined
    }
  >
    <Thumbnail>
      {imagingItem.thumbnailLink && imagingItem.link ? (
        <>
          <img src={imagingItem.thumbnailLink} alt="Thumbnail" />
          {isRecording(imagingItem) && (
            <>
              <Play centeredInParent />
              <span>
                {formatDurationToMinutesAndSeconds(imagingItem.duration)}
              </span>
            </>
          )}
        </>
      ) : (
        <Placeholder>
          <span>
            {isRecording(imagingItem) ? "Recording" : "Image"} is being
            processed. <br />
            Please check back later.
          </span>
        </Placeholder>
      )}
    </Thumbnail>
    <ImagingTitle>{imagingItem.name}</ImagingTitle>
    <MetadataLabel>
      <Clock /> Created at {formatTime(imagingItem.createdAt)}
    </MetadataLabel>
  </Card>
);

const ImagingContainer = styled.div`
  padding: ${(props) =>
    `${props.theme.sizes.spacing4} ${props.theme.sizes.spacing5}`};
`;

const ModalContentContainer = styled.div`
  padding: ${(props) =>
    `${props.theme.sizes.spacing} ${props.theme.sizes.spacing3} ${props.theme.sizes.spacing3} ${props.theme.sizes.spacing3}`};
`;

const ModalImage = styled.img`
  width: 100%;
  max-width: 768px;
  max-height: 768px;
  display: block;
  margin-top: ${(props) => props.theme.sizes.spacing2};
`;

const ModalVideo = styled.video`
  width: 100%;
  max-width: 768px;
  max-height: 768px;
  display: block;
  margin-top: ${(props) => props.theme.sizes.spacing2};
`;

interface ImagingProps {
  images?: Image[] | null;
  recordings?: Recording[] | null;
}

const Imaging: React.FC<ImagingProps> = ({ images, recordings }) => {
  const serviceTag = getServiceTag();
  const clientHasMP4support = getMP4Support() === "1"
  const [showingPopupForImagingItem, setShowingPopupForImagingItem] = useState<
    Image | Recording | undefined
  >(undefined);

  var createdAtDate = undefined;
  if (recordings && recordings.length > 0) {
    createdAtDate = recordings[0].createdAt;
  } else if (images && images.length > 0) {
    createdAtDate = images[0].createdAt;
  }

  // Show .webm files on QT browser, show .mp4 files on other browsers
  // Show whatever is available if preferences are not met
  const webMAvailable = recordings?.some((recording) =>
    recording.name.endsWith(".webm")
  );
  const mp4Available = recordings?.some((recording) =>
    recording.name.endsWith(".mp4")
  );
  const aviAvailable = recordings?.some((recording) =>
    recording.name.endsWith(".avi")
  );

  const shouldShowWebM =
    (serviceTag && webMAvailable && !clientHasMP4support) ||
    (!mp4Available && !aviAvailable && webMAvailable);
  const shouldShowMp4AndAvi =
    ((!serviceTag || clientHasMP4support) && (mp4Available || aviAvailable)) || !webMAvailable;

  const recordingsToShow: Recording[] =
    (() => {
      if (shouldShowWebM) {
        return recordings?.filter((recording) =>
          recording.name.endsWith(".webm")
        );
      } else if (shouldShowMp4AndAvi) {
        return recordings?.filter(
          (recording) =>
            recording.name.endsWith(".mp4") || recording.name.endsWith(".avi")
        );
      } else {
        return recordings;
      }
    })() ?? [];

  if (
    (!images && !recordingsToShow) ||
    (images?.length === 0 && recordingsToShow?.length === 0)
  ) {
    return <Text>There are no images for this activity.</Text>;
  } else {
    return (
      <ImagingContainer>
        {showingPopupForImagingItem && (
          <Modal>
            <Card style={{ position: "relative" }}>
              <CloseButton
                onClick={() => setShowingPopupForImagingItem(undefined)}
              />
              <ModalContentContainer>
                <H4>{showingPopupForImagingItem.name}</H4>
                <MetadataLabel>
                  Created at{" "}
                  {formatDateAndTime(showingPopupForImagingItem.createdAt)}
                </MetadataLabel>
                {isRecording(showingPopupForImagingItem) ? (
                  <ModalVideo
                    controls
                    src={showingPopupForImagingItem.link}
                    autoPlay={true}
                  />
                ) : (
                  <ModalImage
                    src={showingPopupForImagingItem.link}
                    alt={showingPopupForImagingItem.name}
                  />
                )}
              </ModalContentContainer>
            </Card>
          </Modal>
        )}
        {createdAtDate && (
          <FullWidthTitleSpanner>
            <H3>Imaging</H3>
            <MetadataLabel>
              Created on {formatDateStringShortMonth(createdAtDate)}
            </MetadataLabel>
          </FullWidthTitleSpanner>
        )}
        {recordingsToShow && recordingsToShow.length > 0 && (
          <CardSection
            smallerTitle="Recordings"
            maxColumns={4}
            parentHasSidebar={false}
          >
            {recordingsToShow.map((recording) => (
              <ImagingCard
                imagingItem={recording}
                onClick={() => {
                  setShowingPopupForImagingItem(recording);
                }}
                key={recording.id}
              />
            ))}
          </CardSection>
        )}
        {images && images.length > 0 && (
          <CardSection
            smallerTitle="Images"
            maxColumns={4}
            parentHasSidebar={false}
          >
            {images.map((image) => (
              <ImagingCard
                imagingItem={image}
                onClick={() => {
                  setShowingPopupForImagingItem(image);
                }}
                key={image.id}
              />
            ))}
          </CardSection>
        )}
      </ImagingContainer>
    );
  }
};

export default Imaging;

const isRecording = (
  imagingItem: Image | Recording
): imagingItem is Recording => {
  return (imagingItem as Recording).duration !== undefined;
};
