import React from "react";
import styled from "styled-components";
import { spanStyle } from "../shared/typography";

const TooltipWrapper = styled.div`
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
`;

const TooltipBody = styled.div`
  ${spanStyle}
  border-radius: 2.8px;
  padding: 2px 8px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.grey};
  white-space: nowrap;
`;

const TooltipTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid ${(props) => props.theme.colors.grey};
`;

interface TooltipProps {
  label: string;
}

const Tooltip: React.FC<TooltipProps> = ({ label }) => {
  return (
    <TooltipWrapper>
      <TooltipTriangle></TooltipTriangle>
      <TooltipBody>{label}</TooltipBody>
    </TooltipWrapper>
  );
};

export default Tooltip;
