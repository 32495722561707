import React from "react";
import styled from "styled-components";
import { ButtonSizes } from "../../../shared/enums";
import Button from "../Button";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

interface FilterContainerProps {
  children: React.ReactNode;
  onClear: () => void;
}

export const FilterContainer: React.FC<FilterContainerProps> = ({
  children,
  onClear,
}) => {
  return (
    <>
      {children}
      <ButtonContainer>
        <Button onClick={onClear} size={ButtonSizes.Medium} secondary>
          Clear
        </Button>
      </ButtonContainer>
    </>
  );
};
