import { Route, Switch } from "react-router-dom";
import React from "react";
import LogbookPage from "./index";
import RecordPage from "./record";
import RecordsPage from "./records";
import Breadcrumbs from "../../components/shared/Breadcrumbs";

function LogbookRouter() {
  return (
    <Switch>
      <Route exact path="/logbook">
        <LogbookPage></LogbookPage>
      </Route>
      <Route exact path="/logbook/records/create">
        <RecordPage>
          <Breadcrumbs
            crumbs={[
              {
                path: "/logbook",
                name: "Logbook",
              },
              {
                path: "/logbook/records/create",
                name: "Enter New Record",
              },
            ]}
          />
        </RecordPage>
      </Route>
      <Route exact path="/logbook/records">
        <RecordsPage>
          <Breadcrumbs
            crumbs={[
              {
                path: "/logbook",
                name: "Logbook",
              },
              {
                path: "/logbook/records",
                name: "Existing Records",
              },
            ]}
          />
        </RecordsPage>
      </Route>
      <Route exact path="/logbook/records/:id">
        <RecordPage>
          <Breadcrumbs
            crumbs={[
              {
                path: "/logbook",
                name: "Logbook",
              },
              {
                path: "/logbook/records",
                name: "Existing Records",
              },
              {
                path: "/logbook/records/:id",
                name: "View Existing Record",
              },
            ]}
          />
        </RecordPage>
      </Route>
    </Switch>
  );
}

export default LogbookRouter;
