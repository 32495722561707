import React from "react";
import styled, { css } from "styled-components";
import { ButtonSizes, ButtonTypes } from "../../shared/enums";
import { Button2, Button3 } from "./typography";

interface StyledButtonProps {
  size: ButtonSizes;
  secondary: boolean;
  icon?: React.ReactElement | string;
  hasLabel: boolean;
}

export const buttonStyle = css`
  border-radius: 4px;
  border: 1px solid;
  color: ${(props) => props.theme.colors.darkGrey};
  white-space: nowrap;
  transition: box-shadow 0.25s ease-out;
`;

export const primaryButtonStyle = css`
  ${buttonStyle}
  background-color: ${(props) => props.theme.colors.primary25};
  border-color: ${(props) => props.theme.colors.primary500};
  :focus:enabled,
  :hover:enabled {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.primary100};
  }
  :active:enabled {
    background-color: ${(props) => props.theme.colors.primary500};
  }
  :disabled {
    opacity: 0.4;
    background-color: ${(props) => props.theme.colors.primary25};
  }
`;

export const secondaryButtonStyle = css`
  ${buttonStyle}
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  :focus:enabled,
  :hover:enabled {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.lightGrey};
  }
  :active:enabled {
    background-color: ${(props) => props.theme.colors.midGrey};
  }
  :disabled {
    opacity: 0.4;
    background-color: ${(props) => props.theme.colors.white};
  }
`;

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${(props) => (props.secondary ? secondaryButtonStyle : primaryButtonStyle)}
  padding: ${(props) => {
    switch (props.size) {
      case ButtonSizes.Large:
        return "0 40px";
      case ButtonSizes.Medium:
        return props.icon ? (props.hasLabel ? "8px 16px" : "8px") : "12px 40px";
      case ButtonSizes.Small:
        return "4px 12px";
    }
  }};
  height: ${(props: StyledButtonProps) => {
    switch (props.size) {
      case ButtonSizes.Large:
        return "48px";
      case ButtonSizes.Medium:
        return "40px";
      case ButtonSizes.Small:
        return "32px";
    }
  }};
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    padding: ${(props: StyledButtonProps) => props.icon && "8px"};
  }
`;

// Buttons with icons and label
const hideLabelOnBreakpointS = css<{ hasLabel: boolean }>`
  margin: ${(props) => (props.hasLabel ? "0 4px" : "0")};
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    display: none;
  }
`;

const IconButton2Label = styled(Button2)`
  ${hideLabelOnBreakpointS}
`;

const IconButton3Label = styled(Button3)`
  margin: ${(props) => (props.hasLabel ? "0 4px" : "0")};
  ${hideLabelOnBreakpointS}
`;

interface ButtonProps {
  size?: ButtonSizes;
  secondary?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  type?: ButtonTypes;
  id?: string;
  icon?: React.ReactElement | string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  secondary = false,
  size = ButtonSizes.Large,
  disabled = false,
  onClick,
  className,
  type,
  id = "cy-button-default",
  icon,
}) => {
  return (
    <StyledButton
      id={id}
      className={className}
      disabled={disabled}
      size={size}
      secondary={secondary}
      onClick={onClick}
      type={type}
      icon={icon}
      hasLabel={Boolean(children)}
    >
      {size === ButtonSizes.Large ? (
        icon ? (
          <IconButton2Label hasLabel={Boolean(children)}>
            {children}
          </IconButton2Label>
        ) : (
          <Button2>{children}</Button2>
        )
      ) : icon ? (
        <IconButton3Label hasLabel={Boolean(children)}>
          {children}
        </IconButton3Label>
      ) : (
        <Button3>{children}</Button3>
      )}
      {typeof icon === "string" ? <img src={icon} alt={`icon-button`} /> : icon}
    </StyledButton>
  );
};

export default Button;
