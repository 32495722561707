import { gql } from "@apollo/client";

export const cloudCaseVMFields = gql`
  fragment CloudCaseVMFields on CloudCaseVirtualMachine {
    id
    status
    url
    module_id
    case_id
    session_id
    voucher_id
    session_started_at
    started_by {
      first_name
      last_name
      email
      keycloak_id
    }
  }
`;

export const cloudCaseVMHistoryFields = gql`
  fragment CloudCaseVMHistoryFields on CloudCaseVirtualMachineStateChange {
    updated_at
    new_status
    prev_status
    started_by {
      first_name
      last_name
      email
      keycloak_id
    }
    case_id
    module_id
    voucher_id
  }
`;
