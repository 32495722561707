import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { PageLayout } from "../../components/Page/PageLayout";
import { Section } from "../../components/Page/Section";
import { Text } from "../../components/shared/typography";
import { gql, useLazyQuery } from "@apollo/client";
import { TalentCourse as TalentCourseEntity } from "../../generated/graphql";
import Breadcrumbs from "../../components/shared/Breadcrumbs";

// TODO: Remove this page

const UserCoursesPage = () => {
  let query = new URLSearchParams(useLocation().search);

  const [
    fetchGroupCourses,
    { data: groupCoursesData, error: groupCoursesErrors },
  ] = useLazyQuery(
    gql`
      query GetCoursesByGroup($customerGroupId: Int!) {
        coursesByGroup(customerGroupId: $customerGroupId) {
          id
          name
          category
          code
          big_avatar
          progress {
            completion_percentage
            completion_status
          }
        }
      }
    `,
    {
      variables: {
        customerGroupId: parseInt(query.get("group") ?? ""),
      },
    }
  );

  useEffect(() => {
    if (query.get("email") && query.get("group") && query.get("uid")) {
      fetchGroupCourses();
    }
  }, [query.get("email"), query.get("group"), query.get("uid")]); // eslint-disable-line react-hooks/exhaustive-deps

  const data = groupCoursesData && groupCoursesData.coursesByGroup;

  const allEnrolledTalentCourses: [TalentCourseEntity] =
    data &&
    data.filter(
      (course: TalentCourseEntity) => course.progress.completion_status !== ""
    );

  const allNotEnrolledTalentCourses: [TalentCourseEntity] =
    data &&
    data.filter(
      (course: TalentCourseEntity) => course.progress.completion_status === ""
    );

  const existsEnrolledTalentCourses =
    data && allEnrolledTalentCourses.length > 0;

  const existsNotEnrolledTalentCourses =
    data && allNotEnrolledTalentCourses.length > 0;

  return (
    <PageLayout title="Mentice Live | User Courses">
      <Breadcrumbs
        crumbs={[
          {
            path: "/admin",
            name: "Groups",
          },
          {
            path: "/",
            name: "User Courses",
          },
        ]}
      />

      {/* List enrolled courses */}
      <Section
        title={"Available Courses for " + query.get("email")}
        maxColumns={2}
      >
        {!existsEnrolledTalentCourses && <Text>This user has no courses.</Text>}

        {/* List group courses errors */}
        {groupCoursesErrors && (
          <>
            {groupCoursesErrors.graphQLErrors.map(
              (error: { message: string }, i: number) => (
                <Text key={i}>{error.message}</Text>
              )
            )}
          </>
        )}

        {/*  {existsEnrolledTalentCourses &&
          allEnrolledTalentCourses.map((course: TalentCourseEntity) => (
            <TalentCourse
              key={course.id}
              talentCourseId={course.id}
              category={course.category as TalentCourseCategory}
              title={course.name}
              subtitle={course.progress.completion_status}
              progress={course.progress.completion_percentage}
              code={course.code}
            />
          ))} */}
      </Section>

      {/* List not enrolled courses */}
      <Section
        title={"Make Course available for " + query.get("email")}
        maxColumns={2}
      >
        {!existsNotEnrolledTalentCourses && (
          <Text>This user has no more courses available for enrollment.</Text>
        )}

        {/* List group courses errors */}
        {groupCoursesErrors && (
          <>
            {groupCoursesErrors.graphQLErrors.map(
              (error: { message: string }, i: number) => (
                <Text key={i}>{error.message}</Text>
              )
            )}
          </>
        )}

        {/*  {existsNotEnrolledTalentCourses &&
          allNotEnrolledTalentCourses.map((course: TalentCourseEntity) => (
            <TalentCourse
              key={course.id}
              talentCourseId={course.id}
              category={course.category as TalentCourseCategory}
              title={course.name}
              subtitle={course.progress.completion_status}
              progress={course.progress.completion_percentage}
              code={course.code}
            />
          ))} */}
      </Section>
    </PageLayout>
  );
};

export default UserCoursesPage;
