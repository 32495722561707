import { gql } from "@apollo/client";

export const eventResourceAccessVouchersFields = gql`
  fragment EventResourceAccessVouchersFields on EventResourceAccessVoucher {
    id
    type
    code
    validFrom
    validTo
    customer {
      id
      name
    }
    learningSpace {
      id
      code
      name
      description
      image
    }
    creator {
      keycloak_id
      first_name
      last_name
      email
    }
    createdAt
    modifiedAt
    archivedAt
    archivedByUserId
    used
    maxUses
    users {
      keycloak_id
      first_name
      last_name
      email
    }
    modules {
      moduleId
      name
      description
      cases {
        caseId
        name
        description
      }
    }
    eventAt
    name
    description
    organizers {
      keycloak_id
      first_name
      last_name
      email
    }
    durationInSeconds
  }
`;
