import React, { useContext, useEffect, useState } from "react";
import LoadingIndicator from "../../components/shared/LoadingIndicator";
import ResultsPage from "../../components/VistCourses/RunningVistCard/ResultsPage";
import { BenchmarkValue, Group } from "../../generated/graphql";
import { Text } from "../../components/shared/typography";
import Checkbox from "../../components/shared/Checkbox";
import styled, { ThemeContext } from "styled-components";
import Loading from "../../components/icons/Loading";
import Download from "../../components/icons/Download";
import { ApolloError, gql, useLazyQuery } from "@apollo/client";
import { useToast } from "../../lib/useToast";
import { ButtonSizes, ToastFormat, ToastType } from "../../shared/enums";
import { useKeycloak } from "@react-keycloak/web";
import { haveRole, KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import Button from "../../components/shared/Button";
import { ProgressCircle } from "../../components/shared/ProgressCircle";
interface BenchmarkMetric {
  internal_id: string;
  operator: string;
  value?: string | number;
}

interface MetricValue {
  internal_id: string;
  value: number | string;
  operator?: string;
}
interface MetricDescription {
  internal_id: string;
  label: string;
  type: string;
  unit: string;
  value_format: string;
  enum_type: string | null;
}

export interface GroupFile {
  root_group: Group;
}

export interface BenchmarkFile {
  metrics: BenchmarkMetric[];
}

export interface MetricValuesFile {
  metric_values: MetricValue[];
}

export interface MetricDescriptionsFile {
  metric_descriptions: MetricDescription[];
}
interface ResultProps {
  groupFileLink?: string | null;
  benchmarkFileLink?: string | null;
  metricValuesFileLink?: string | null;
  metricDescriptionsFileLink?: string | null;
  unitId?: string | null;
  progress: number;
}

const benchmarkMetricToBenchmarkValue = (
  benchmarkMetric: BenchmarkMetric,
  metricDescriptions: MetricDescription[]
): BenchmarkValue | undefined => {
  const metricDescription = metricDescriptions.find(
    (metricDescription: any) =>
      metricDescription.internal_id === benchmarkMetric.internal_id
  );
  if (!metricDescription) return undefined;
  return {
    id: -1,
    value: benchmarkMetric.value?.toString(),
    default_operator: benchmarkMetric.operator,
    is_shown_as_graph: false,
    metric: {
      id: -1,
      internal_id: benchmarkMetric.internal_id,
      label: metricDescription.label,
      unit: metricDescription.unit,
      data_type: metricDescription.label,
      default_operator: benchmarkMetric.operator,
      value_format: "",
      is_comparable: false,
      order: 0,
    },
  };
};

const benchmarkMetricsToBenchmarkValues = (
  benchmarkMetrics: BenchmarkMetric[],
  metricDescriptions: MetricDescription[]
): BenchmarkValue[] => {
  var benchmarkValues: BenchmarkValue[] = [];
  benchmarkMetrics.forEach((benchmarkMetric) => {
    const benchmarkValue = benchmarkMetricToBenchmarkValue(
      benchmarkMetric,
      metricDescriptions
    );
    if (benchmarkValue !== undefined) {
      benchmarkValues.push(benchmarkValue);
    }
  });
  return benchmarkValues;
};

const metricValuesToBenchmarkValues = (
  metricValues: MetricValue[],
  metricDescriptions: MetricDescription[],
  benchmarkMetrics: BenchmarkMetric[]
): BenchmarkValue[] => {
  const benchmarkMetricsWithUndefinedValues = metricValues.map(
    (metricValue): BenchmarkMetric => {
      const benchmarkMetric = benchmarkMetrics.find(
        (benchmarkMetric) =>
          benchmarkMetric.internal_id === metricValue.internal_id
      );
      if (benchmarkMetric) {
        return benchmarkMetric;
      } else {
        return {
          internal_id: metricValue.internal_id,
          operator: "equal",
        };
      }
    }
  );
  return benchmarkMetricsToBenchmarkValues(
    benchmarkMetricsWithUndefinedValues,
    metricDescriptions
  );
};

const TopRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
`;

const TopRowSubcontainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: space-between;
  margin-right: 16px;

  > p {
    margin-right: 16px;
  }
`;

const CenterRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

// Component to turn fetched and parsed JSON files
// into format that ResultPage understands
const Results: React.FC<ResultProps> = ({
  groupFileLink,
  benchmarkFileLink,
  metricValuesFileLink,
  metricDescriptionsFileLink,
  progress,
  unitId,
}) => {
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const theme = useContext(ThemeContext);
  const isGroupAdmin = haveRole(user, "group_admin");
  const isCustomerAdmin = haveRole(user, "customer_admin");
  const isSysAdmin = haveRole(user, "sys_admin");

  const [isCompactView, setIsCompactView] = useState<boolean>(true);
  const [fetchCaseDownloadLink, { loading: downloadLoading }] = useLazyQuery<{
    caseDownloadLink: URL;
  }>(
    gql`
      query FetchLogDownloadLink($unitId: String!) {
        caseDownloadLink(unitId: $unitId)
      }
    `,
    {
      onCompleted: (data) => {
        window.location.assign(`${data?.caseDownloadLink}`);
        createToast({
          title: "Downloading all results of this case.",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError: (err: ApolloError) => {
        createToast({
          title:
            err.graphQLErrors[0].extensions?.code === "MISSING_FILE"
              ? `Could not download because results are missing or incomplete (Missing ${err.graphQLErrors[0].message}).`
              : "Something went wrong when downloading cases file",
          type: ToastType.ERROR,
          format: ToastFormat.TOAST,
        });
      },
    }
  );
  const [, createToast] = useToast();

  const [groupFile, setGroupFile] = useState<GroupFile | undefined>(undefined);
  const [benchmarkFile, setBenchmarkFile] = useState<BenchmarkFile | undefined>(
    undefined
  );
  const [metricValuesFile, setMetricValuesFile] = useState<
    MetricValuesFile | undefined
  >(undefined);
  const [metricDescriptionsFile, setMetricDescriptionsFile] = useState<
    MetricDescriptionsFile | undefined
  >(undefined);

  useEffect(() => {
    if (
      groupFileLink &&
      benchmarkFileLink &&
      metricValuesFileLink &&
      metricDescriptionsFileLink
    ) {
      fetch(groupFileLink)
        .then((res) => res.json())
        .then((json) => {
          setGroupFile(json);
        });
      fetch(benchmarkFileLink)
        .then((res) => res.json())
        .then((json) => {
          setBenchmarkFile(json);
        });
      fetch(metricValuesFileLink)
        .then((res) => res.json())
        .then((json) => {
          setMetricValuesFile(json);
        });
      fetch(metricDescriptionsFileLink)
        .then((res) => res.json())
        .then((json) => {
          setMetricDescriptionsFile(json);
        });
    }
  }, [
    groupFileLink,
    benchmarkFileLink,
    metricValuesFileLink,
    metricDescriptionsFileLink,
  ]);

  const missingFiles = [];
  if (!groupFileLink) missingFiles.push("groups.json");
  if (!benchmarkFileLink) missingFiles.push("benchmark.json");
  if (!metricValuesFileLink) missingFiles.push("metric_values.json");
  if (!metricDescriptionsFileLink)
    missingFiles.push("metric_descriptions.json");
  const missingFilesErrorMessage =
    "Results are missing or incomplete for this activity (missing " +
    missingFiles.join(", ") +
    ").";
  if (
    !groupFileLink ||
    !benchmarkFileLink ||
    !metricValuesFileLink ||
    !metricDescriptionsFileLink
  ) {
    return <Text>{missingFilesErrorMessage}</Text>;
  } else if (
    !groupFile ||
    !benchmarkFile ||
    !metricValuesFile ||
    !metricDescriptionsFile
  ) {
    return <LoadingIndicator />;
  } else {
    return (
      <>
        <TopRow id="cy-compact-view-checkbox">
          <CenterRow>
            <Checkbox
              checkboxChecked={isCompactView}
              onCheckboxClick={(e) => {
                setIsCompactView(e.currentTarget.checked);
              }}
            />
            <Text>Show metrics with requirements only</Text>
          </CenterRow>
          <TopRow>
            {(isGroupAdmin || isCustomerAdmin || isSysAdmin) && unitId && (
              <TopRowSubcontainer>
                <Text>Download all results</Text>
                <Button
                  id="download-case-button"
                  icon={
                    downloadLoading ? <Loading small noMargin /> : <Download />
                  }
                  onClick={() => {
                    fetchCaseDownloadLink({
                      variables: {
                        unitId,
                      },
                    });
                  }}
                  secondary
                  size={ButtonSizes.Medium}
                />
              </TopRowSubcontainer>
            )}
            <ProgressCircle
              color={theme.colors.grey}
              progress={progress ?? 0}
            />
          </TopRow>
        </TopRow>
        {benchmarkFile.metrics &&
          metricDescriptionsFile.metric_descriptions && (
            <ResultsPage
              group={groupFile.root_group}
              benchmarks={
                isCompactView
                  ? benchmarkMetricsToBenchmarkValues(
                      benchmarkFile.metrics,
                      metricDescriptionsFile.metric_descriptions
                    )
                  : metricValuesToBenchmarkValues(
                      metricValuesFile.metric_values,
                      metricDescriptionsFile.metric_descriptions,
                      benchmarkFile.metrics
                    )
              }
              results={metricValuesFile.metric_values}
              isCompactView={isCompactView}
            />
          )}
      </>
    );
  }
};

export default Results;
