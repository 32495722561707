import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import DataProtectionPolicyPage from "./data-protection-policy";
import MedicalDisclaimerPage from "./medical-disclaimer";
import ToSPage from "./terms-of-service";

function TermsRouter() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/data-protection-policy`}>
        <DataProtectionPolicyPage></DataProtectionPolicyPage>
      </Route>
      <Route path={`${match.path}/medical-discalimer`}>
        <MedicalDisclaimerPage></MedicalDisclaimerPage>
      </Route>
      <Route path={`${match.path}/terms-of-service`}>
        <ToSPage></ToSPage>
      </Route>
    </Switch>
  );
}

export default TermsRouter;
