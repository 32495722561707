import React from "react";
import styled from "styled-components";

const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="53"
    viewBox="0 0 53 53"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <circle
        cx="25.5"
        cy="25.5"
        r="25.5"
        fill="#000"
        fillOpacity=".3"
        stroke="#FFF"
        strokeWidth="2"
      />
      <path
        fill="#FFF"
        d="M19 35.167V16.833c0-1.01.85-1.833 1.895-1.833.364 0 .73.112 1.089.333l14.18 9.148c.525.342.836.91.836 1.519 0 .609-.31 1.176-.832 1.518l-14.177 9.144c-.365.226-.732.338-1.096.338C19.85 37 19 36.178 19 35.167z"
      />
    </g>
  </svg>
);

const CenteredInParent = styled.div`
  position: absolute;
  width: 48px;
  height: 48px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface PlayProps {
  centeredInParent?: boolean;
}

const Play: React.FC<PlayProps> = ({ centeredInParent = false }) =>
  centeredInParent ? (
    <CenteredInParent>
      <PlayIcon />
    </CenteredInParent>
  ) : (
    <PlayIcon />
  );

export default Play;
