import { isAfter } from "date-fns";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import DatePickerField from "./DatePickerField";

interface DateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (start: Date | null, end: Date | null) => void;
}

const DateRangePickerFields = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  &:not(:last-child) {
    margin-bottom: 28px;
  }

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    flex-wrap: wrap;
    margin-bottom: 28px;
    width: 100%;
  }
`;

const FieldMargin = styled.div`
  margin-bottom: 0;

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    width: 100%;
    margin-right: 0;
    &:not(:last-child) {
      margin-bottom: 28px;
    }
  }
  @media screen and (min-width: ${(props) => props.theme.sizes.breakpointS}) {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  onChange,
}) => {
  return (
    <DateRangePickerFields>
      <FieldMargin>
        <DatePickerField
          label="Start Date"
          range
          selected={startDate || undefined}
          onChange={(date: Date) => {
            onChange(date, endDate);
          }}
        />
      </FieldMargin>
      <FieldMargin>
        <DatePickerField
          label="End Date"
          range
          selected={
            endDate
              ? startDate && isAfter(endDate, startDate)
                ? endDate
                : startDate || undefined
              : undefined
          }
          onChange={(date: Date) => {
            onChange(startDate, date);
          }}
        />
      </FieldMargin>
    </DateRangePickerFields>
  );
};

export default DateRangePicker;
