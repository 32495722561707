export const theme = {
  colors: {
    white: "#ffffff",
    whiteRGB: "255,255,255",
    superLightGrey: "#fafbfd",
    paleGrey: "#ebf0f5",
    lightGrey: "#d5dade", // greys400
    midGrey: "#A6ACB1", // greys500
    grey: "#575c61", // greys600
    darkGrey: "#3a3e42", // greys700
    darkGreyRGB: "58, 62, 66",
    darkDarkGrey: "#242729",
    black: "#0e0f0f",
    primary25: "#ffe8d9",
    primary100: "#ff9752",
    primary300: "#ff8838",
    primary500: "#ff7214",
    primary700: "#cc4c0c",
    primary900: "#9e3500",
    secondary25: "#f5e0ff",
    secondary100: "#e0a3ff",
    secondary300: "#d88aff",
    secondary500: "#c158f5",
    secondary700: "#9336c2",
    secondary900: "#6d2b8f",
    success100: "#30c996",
    success300: "#00bf86",
    success500: "#009966",
    success700: "#007d51",
    success900: "#00613e",
    warning100: "#ffdb57",
    warning300: "#fad346",
    warning500: "#faca1e",
    warning700: "#876d03",
    warning900: "#635100",
    error25: "#fde5e5",
    error100: "#ff5959",
    error300: "#ff3b3b",
    error500: "#f52c2c",
    error700: "#c71818",
    error900: "#941212",
    primaryButtonHover: "#ed5f02",
    secondaryButtonHover: "#ebf0f5",
    disabledButtonColor: "#ffba8c",
    toastSuccess: "#ebfaf5",
    toastError: "#ffecec",
  },
  fonts: {
    heading: "'Muli', sans-serif;",
    body: "'Lato', sans-serif;",
  },
  shadows: {
    cardShadow: "0 20px 30px -20px rgba(10, 10, 10, 0.24)",
    cardShadowHover: "0 30px 40px -10px rgba(10, 10, 10, 0.24)",
    barShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    toastShadow:
      "0 6px 8px -20px rgba(10, 10, 10, 0.24), 0 6px 8px 0 rgba(0, 0, 0, 0.06);",
  },
  transitions: {
    inOut: "0.1s ease-in-out",
    out: "0.25 ease-out",
    outSlow: "0.5s ease-out",
  },
  sizes: {
    spacing0_5: "4px",
    spacing1: "8px",
    spacing2: "16px",
    spacing3: "24px",
    spacing4: "32px",
    spacing5: "40px",
    spacing6: "48px",
    spacing8: "64px",
    spacing9: "72px",
    spacing10: "80px",
    spacing12: "96px",
    spacing14: "112px",
    spacing15: "120px",
    spacing16: "128px",
    spacing19: "152px",
    spacing20: "160px",
    spacing24: "192px",
    spacing28: "224px",
    spacing32: "256px",
    spacing40: "320px",
    spacing56: "448px",
    spacing60: "480px",
    spacing70: "560px",
    spacing80: "640px",
    spacing90: "720px",
    spacing112: "896px",
    breakpointS: "576px",
    breakpointM: "768px",
    breakpointL: "992px",
    breakpointXL: "1200px",
  },
};
