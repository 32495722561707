import { Route, Switch, useRouteMatch } from "react-router-dom";
import React from "react";
import VistCoursesPage from "./index";
import SimulationPage from "./simulations";
import Breadcrumbs from "../../components/shared/Breadcrumbs";

function VistModulesRouter() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:courseCode/:courseVersion/simulations`}>
        <SimulationPage>
          <Breadcrumbs
            crumbs={[
              {
                path: "/",
                name: "Home",
              },
              {
                path: "/vist-modules",
                name: "VIST Modules",
              },
              {
                path: "",
                name: "VIST Cases",
              },
            ]}
          />
        </SimulationPage>
      </Route>
      <Route path={match.path}>
        <VistCoursesPage>
          <Breadcrumbs
            crumbs={[
              {
                path: "/",
                name: "Home",
              },
              {
                path: "/vist-modules",
                name: "VIST Modules",
              },
            ]}
          />
        </VistCoursesPage>
      </Route>
    </Switch>
  );
}

export default VistModulesRouter;
