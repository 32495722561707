import React, { useReducer, useEffect } from "react";
import { Card } from "../../shared/Card";
import { Text } from "../../shared/typography";
import { CloseButton } from "../../shared/Modal";
import { Center, Title } from "./RunningVistCardComponents";
import StartPage from "./StartPage";
import ResultsPage from "./ResultsPage";
import {
  Simulation as SimulationEntity,
  VistCourse as VistCourseEntity,
  Benchmark,
  Group,
} from "../../../generated/graphql";
import { useSimulator, SimulatorResult } from "../../../lib/useSimulator";
import Button from "../../shared/Button";
import { useGraphQL } from "../../../lib/useGraphQL";
import gql from "graphql-tag";

export interface Result {
  internal_id: string;
  value?: string | number;
}

interface IResponse {
  data?: {
    simulation: {
      benchmarks: Benchmark[];
      groups: Group;
    };
  };
  errors?: [Error];
}

const parseResultFile = (result: SimulatorResult) =>
  JSON.parse(result.files["metric_values.json"])?.metric_values;

type Action =
  | { type: "LAUNCH"; simulation: SimulationEntity }
  | { type: "DONE"; result: SimulatorResult }
  | { type: "ERROR"; reason: Error };

interface State {
  running?: SimulationEntity;
  result?: SimulatorResult;
}

const initialState: State = {};

const simulationReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "LAUNCH":
      console.log("LAUNCH", action);
      return {
        ...state,
        running: action.simulation,
        result: undefined,
      };
    case "DONE":
      console.info("DONE", action);
      return {
        ...state,
        running: undefined,
        result: action.result,
      };
    case "ERROR":
      console.error("ERROR", action);
      return {
        ...state,
        running: undefined,
        result: undefined,
      };
  }
};

interface RunningVistCardProps {
  simulation: SimulationEntity;
  vistCourse: VistCourseEntity;
  onClose?: () => void;
  license?: any;
}

const RunningVistCard: React.FC<RunningVistCardProps> = ({
  simulation,
  vistCourse,
  onClose,
  license
}) => {
  const [simulatorReady, launchSimulator] = useSimulator();
  const [state, dispatch] = useReducer(simulationReducer, initialState);

  const [apiResponse] = useGraphQL(
    gql`
      query Simulation($id: Int!) {
        simulation(id: $id) {
          groups {
            label
            description
            metrics
            is_expanded_by_default
            groups {
              label
              description
              metrics
              is_expanded_by_default
              groups {
                label
                description
                metrics
                is_expanded_by_default
                groups {
                  label
                  description
                  metrics
                  is_expanded_by_default
                  groups {
                    label
                    description
                    metrics
                    is_expanded_by_default
                    # TODO: Recursive group nesting, supported in GQL through fragments
                  }
                }
              }
            }
          }
          benchmarks {
            id
            label
            description
            values {
              id
              value
              default_operator
              metric {
                internal_id
                id
                label
                unit
                data_type
                default_operator
                value_format
                is_comparable
                order
              }
            }
          }
        }
      }
    `,
    {
      id: simulation?.id,
    }
  );

  const { data, errors }: IResponse = apiResponse;

  // Unwrap benchmarks from data - ASSUME that we only have one set of benchmarks at the root level
  const benchmarks = data?.simulation?.benchmarks[0]?.values;

  // If not running, received result, but no groups
  // then the course doesn't have benchmarks and we close after finishing exercise
  useEffect(() => {
    if (!state.running && !!state.result && !data?.simulation?.groups) {
      onClose && onClose();
    }
  }, [state, data, onClose]);

  if (!apiResponse) return null;

  return (
    <Card style={{ position: "relative" }}>
      {onClose && <CloseButton onClick={onClose} />}
      {/* Start exercise */}
      {!state.running && !state.result && data?.simulation && (
        <StartPage
          title={simulation.name}
          description={simulation.description}
          license={license}
          environmentOk={simulatorReady}
          group={data.simulation.groups} // Can be undefined / null, in which case no benchmark table will be rendered
          benchmarks={benchmarks}
          launch={() => {
            dispatch({ type: "LAUNCH", simulation: simulation });
            launchSimulator(vistCourse, simulation).then(
              (result) => dispatch({ type: "DONE", result }),
              (reason) => dispatch({ type: "ERROR", reason })
            );
          }}
        />
      )}
      {/* Running exercise */}
      {state.running && !state.result && (
        <Center>
          <Title>Exercise in progress...</Title>
          <Button
            onClick={() =>
              dispatch({
                type: "ERROR",
                reason: new Error("User quit exercise"),
              })
            }
          >
            Quit
          </Button>
        </Center>
      )}
      {/* Finished exercise */}
      {!state.running &&
        state.result &&
        data?.simulation?.groups &&
        benchmarks && (
          <ResultsPage
            group={data.simulation.groups}
            results={parseResultFile(state.result)}
            benchmarks={benchmarks}
            isCompactView={false}
          />
        )}
      {/* Display errors if we have them */}
      {errors && (
        <>
          {errors.map((error: Error, i: number) => (
            <Text key={i}>{error.message}</Text>
          ))}
        </>
      )}
    </Card>
  );
};

export default RunningVistCard;
