import React from "react";
import styled, { css } from "styled-components";
import { User } from "../../generated/graphql";
import { copyToClipboard } from "../../lib/copyToClipboard";
import { useToast } from "../../lib/useToast";
import { ToastFormat, ToastType } from "../../shared/enums";
import UserAvatar from "../icons/UserAvatar";
import { Card } from "../shared/Card";
import { ContextMenu } from "../shared/context-menu/ContextMenu";
import CopyField from "../shared/CopyField";
import { H4, H6, Span, Text } from "../shared/typography";

interface GroupCardProps {
  name: string;
  admin?: User;
  members?: User[] | null;
  customerName: string;
  description: string;
  invitationLink?: string;
}

export const CardWrapper = styled(Card)`
  padding: ${(props) =>
    `${props.theme.sizes.spacing2} ${props.theme.sizes.spacing3} ${props.theme.sizes.spacing3}`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const UserListRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  > div:not(:first-child) {
    margin-left: -4px;
  }
`;

const MembersInfo = styled.div`
  display: flex;
  flex-direction: row;
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:not(:first-child) {
    margin-left: ${(props) => props.theme.sizes.spacing4};
  }
`;

export const CardText = styled(H6)`
  color: ${(props) => props.theme.colors.darkdarkGrey};
  margin-bottom: 4px;
`;

export const ellipsisSecondLine = css`
  /* Ellipsis on second line: https://stackoverflow.com/a/50566101 */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const CardDescription = styled(Text)`
  margin-top: ${(props) => props.theme.sizes.spacing2};
  font-size: 12px;
  ${ellipsisSecondLine}
`;

export const CardDivider = styled.hr`
  border-bottom: 1px solid ${(props) => props.theme.colors.paleGrey};
  border-top: 0;
  border-left: 0;
  margin-top: 16px;
  margin-left: -24px;
  margin-right: -24px;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const GroupCard: React.FC<GroupCardProps> = ({
  name,
  admin,
  members,
  customerName,
  description,
  children,
  invitationLink,
}) => {
  const [, createToast] = useToast();
  return (
    <CardWrapper className="cy-group-card">
      <div>
        <CardHeader id="cy-group-header">
          <H4>{name}</H4>
          <ContextMenu>{children}</ContextMenu>
        </CardHeader>
        <Span>{customerName}</Span>
        <CardDescription>{description}</CardDescription>
      </div>
      {invitationLink && (
        <div>
          <CardDivider />
          <Text>Invitation Link</Text>
          <CopyField
            compact={true}
            value={`${window.location.origin}/?inviteCode=${invitationLink}`}
            onCopyClick={() =>
              invitationLink &&
              copyToClipboard(
                `${window.location.origin}/?inviteCode=${invitationLink}`
              )
                ? createToast({
                    title: "Invite link copied to clipboard",
                    type: ToastType.SUCCESS,
                    format: ToastFormat.TOAST,
                  })
                : createToast({
                    title: "Failed to copy invite link, please try again later",
                    type: ToastType.ERROR,
                    format: ToastFormat.TOAST,
                  })
            }
          />
        </div>
      )}
      <div>
        <CardDivider />
        <MembersInfo>
          <AvatarContainer>
            <CardText>Admin</CardText>
            {admin && (
              <UserAvatar
                isAdmin={true}
                tooltip
                firstName={admin?.first_name}
                lastName={admin?.last_name}
              />
            )}
          </AvatarContainer>
          <AvatarContainer>
            <CardText>Members</CardText>
            <UserListRow>
              {members?.slice(0, 3).map((member: User) => (
                <UserAvatar
                  isAdmin={false}
                  key={member.first_name + member.last_name}
                  tooltip
                  firstName={member.first_name}
                  lastName={member.last_name}
                />
              ))}
              {members?.slice(3, members?.length) &&
                members?.slice(3, members?.length).length > 0 && (
                  <CardText style={{ marginLeft: "16px" }}>
                    +{members?.slice(3, members.length).length}
                  </CardText>
                )}
            </UserListRow>
          </AvatarContainer>
        </MembersInfo>
      </div>
    </CardWrapper>
  );
};
export default GroupCard;
