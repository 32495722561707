import { Route, Switch } from "react-router-dom";
import React from "react";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import LearningSpace from "./LearningSpace";
import LearningSpaces from "./LearningSpaces";
import LearningSpaceCourse from "./LearningSpaceCourse";

function LearningSpacesRouter() {
  return (
    <Switch>
      <Route
        exact
        path="/learning-spaces/:id/:name"
        component={LearningSpace}
      />
      <Route exact path="/learning-spaces/:name" component={LearningSpace} />
      <Route
        path="/learning-spaces/:id/:name/:courseId"
        component={LearningSpaceCourse}
      />
      <LearningSpaces>
        <Breadcrumbs
          crumbs={[
            {
              path: "/",
              name: "Home",
            },
            {
              path: "/learning-spaces",
              name: "Learning Spaces",
            },
          ]}
        />
      </LearningSpaces>
    </Switch>
  );
}

export default LearningSpacesRouter;
