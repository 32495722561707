import { ProcedureActivity, VistActivityItem } from "../generated/graphql";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

export const exportToExcel = (
  formattedApiData: any[] | VistActivityItem[] | ProcedureActivity[],
  fileName: string
) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const jsonToSheet = XLSX.utils.json_to_sheet(formattedApiData);
  const sheets = { Sheets: { data: jsonToSheet }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(sheets, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
