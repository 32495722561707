import { gql, QueryResult, useQuery, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useParams } from "react-router";
import Button from "../../components/shared/Button";
import TextField from "../../components/shared/TextField";
import { H3, H4, SemiBoldText } from "../../components/shared/typography";
import { Attending } from "../../generated/graphql";
import { ButtonSizes } from "../../shared/enums";
import { SidebarCardHeader } from "./Customers";
import Dialog from "../../components/shared/Dialog";
import LoadingIndicator, {
  renderLoadingText,
} from "../../components/shared/LoadingIndicator";
import { getFullName, getNameFromFullName } from "../logbook/helpers";
import AttendingListItem from "./AttendingListItem";
import { List } from "../../components/shared/list/List";

const Attendings = () => {
  const { id } = useParams<{ id: string }>();
  const [editingAttending, setEditingAttending] = useState<undefined | number>(
    undefined
  );
  const [editInputName, setEditInputName] = useState("");
  const [editInputEmail, setEditInputEmail] = useState("");
  const [createAttendingName, setCreateAttendingName] = useState("");
  const [createAttendingEmail, setCreateAttendingEmail] = useState("");
  const [deleteAttendingIdName, setDeleteAttendingIdName] = useState({
    name: "",
    id: "",
  });
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [attendingErrorMessage, setAttendingErrorMessage] = useState("");
  const [attendingEmailErrorMessage, setAttendingEmailErrorMessage] =
    useState("");

  const {
    loading: attendingLoading,
    data: attendingData,
  }: QueryResult<{
    attendings: Attending[];
  }> = useQuery(
    gql`
      query FetchAttendings($customerId: Int!) {
        attendings(customerId: $customerId) {
          firstName
          lastName
          id
          email
        }
      }
    `,
    { variables: { customerId: Number(id) } }
  );

  const [deleteAttending, { loading: loadingDeleteAttending }] = useMutation(
    gql`
      mutation DeleteAttending($id: UUID!, $customerId: Int!) {
        deleteAttending(id: $id) {
          refetch {
            attendings(customerId: $customerId) {
              firstName
              lastName
              id
              email
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        closeDeleteDialog();
      },
    }
  );

  const [updateAttending, { loading: loadingEditAttending }] = useMutation(
    gql`
      mutation UpdateAttending(
        $id: UUID!
        $firstName: String!
        $lastName: String!
        $customerId: Int!
        $email: String
      ) {
        updateAttending(
          id: $id
          firstName: $firstName
          lastName: $lastName
          email: $email
        ) {
          refetch {
            attendings(customerId: $customerId) {
              firstName
              lastName
              id
              email
            }
          }
        }
      }
    `,
    { onCompleted: () => closeEditDialog() }
  );

  const [createAttending, { loading: loadingCreateAttending }] = useMutation(
    gql`
      mutation CreateAttending(
        $firstName: String!
        $lastName: String!
        $email: String
        $customerId: Int!
      ) {
        createAttending(
          customerId: $customerId
          firstName: $firstName
          email: $email
          lastName: $lastName
        ) {
          refetch {
            attendings(customerId: $customerId) {
              firstName
              lastName
              id
              email
            }
          }
        }
      }
    `,
    { onCompleted: () => closeCreateDialog() }
  );

  const handleEdit = (id: number, name: string, email?: string | null) => {
    setEditInputName(name);
    email && setEditInputEmail(email);
    setEditingAttending(id);
  };

  const handleRemove = (id: number, name: string) => {
    setShowDeleteDialog(true);
    setDeleteAttendingIdName({
      name: name,
      id: id.toString(),
    });
  };

  const handleSaveEdit = (attendingId: string, createAttendingName: string) => {
    const hasEmailDuplicates = attendingData?.attendings?.some(
      (attending: Attending) => {
        return attending.email === editInputEmail;
      }
    );
    if (!createAttendingName) {
      setAttendingErrorMessage("Please enter attending name");
      return;
    }
    if (!createAttendingName.includes(",")) {
      setAttendingErrorMessage(
        "Please separate first and last name with a comma"
      );
      return;
    }
    if (hasEmailDuplicates) {
      setAttendingEmailErrorMessage("Attending email already exists");
      return;
    }
    return updateAttending({
      variables: {
        id: attendingId,
        firstName: getNameFromFullName(editInputName, 1),
        lastName: getNameFromFullName(editInputName, 0),
        email: editInputEmail || null,
        customerId: Number(id),
      },
    }).catch((e) => setAttendingEmailErrorMessage(e.message));
  };

  const handleDeleteAttending = (attendingId: string) => {
    deleteAttending({
      variables: {
        id: attendingId,
        customerId: Number(id),
      },
    });
  };

  const closeCreateDialog = () => {
    setShowCreateDialog(false);
    setCreateAttendingName("");
    setCreateAttendingEmail("");
    setAttendingErrorMessage("");
    setAttendingEmailErrorMessage("");
  };

  const closeEditDialog = () => {
    setEditingAttending(undefined);
    setEditInputName("");
    setEditInputEmail("");
    setAttendingErrorMessage("");
    setAttendingEmailErrorMessage("");
  };

  const closeDeleteDialog = () => {
    setShowDeleteDialog(false);
    setDeleteAttendingIdName({ id: "", name: "" });
  };

  const handleCreateAttending = () => {
    const hasNameDuplicates = attendingData?.attendings?.some(
      (attending: Attending) => {
        return (
          getFullName(attending.lastName, attending.firstName) ===
          createAttendingName
        );
      }
    );
    const hasEmailDuplicates = attendingData?.attendings?.some(
      (attending: Attending) => {
        return attending.email === createAttendingEmail;
      }
    );
    if (!createAttendingName) {
      setAttendingErrorMessage("Please enter attending name");
      return;
    }
    if (!createAttendingName.includes(",")) {
      setAttendingErrorMessage(
        "Please separate first and last name with a comma"
      );
      return;
    }
    if (hasNameDuplicates) {
      setAttendingErrorMessage("Attending name already exists");
      return;
    }
    if (hasEmailDuplicates) {
      setAttendingEmailErrorMessage("Attending email already exists");
      return;
    }
    return createAttending({
      variables: {
        firstName: getNameFromFullName(createAttendingName, 1),
        lastName: getNameFromFullName(createAttendingName, 0),
        email: createAttendingEmail || null,
        customerId: Number(id),
      },
    }).catch((e) => setAttendingEmailErrorMessage(e.message));
  };

  return (
    <>
      <SidebarCardHeader>
        <div>
          <H3>
            Attendings
            {attendingData?.attendings &&
              ` (${attendingData?.attendings.length})`}
          </H3>
          <SemiBoldText>
            Add, edit or remove attendings' names from the list of options in
            the logbook form.
          </SemiBoldText>
        </div>
        <Button
          id="cy-add-attending"
          onClick={() => setShowCreateDialog(true)}
          size={ButtonSizes.Medium}
        >
          Add attending
        </Button>
      </SidebarCardHeader>
      <H4 style={{ marginTop: "32px" }}>Unverified</H4>
      <List id="cy-attendings-list-unverified">
        {attendingLoading && <LoadingIndicator />}
        {attendingData &&
          attendingData?.attendings
            ?.slice()
            .sort((a, b) => a.lastName.localeCompare(b.lastName))
            ?.filter((attending) => !attending.email)
            .map((attending: Attending) => (
              <AttendingListItem
                key={attending.id}
                attending={attending}
                onEdit={handleEdit}
                onRemove={handleRemove}
              />
            ))}
      </List>
      <H4 style={{ marginTop: "40px" }}>Verified</H4>
      <List id="cy-attendings-list-verified">
        {attendingLoading && <LoadingIndicator />}
        {attendingData &&
          attendingData?.attendings
            ?.slice()
            .sort((a, b) => a.lastName.localeCompare(b.lastName))
            .filter((attending) => attending.email)
            ?.map((attending: Attending) => (
              <AttendingListItem
                key={attending.id}
                attending={attending}
                onEdit={handleEdit}
                onRemove={handleRemove}
              />
            ))}
      </List>
      {showCreateDialog && (
        <Dialog
          title={"Add Attending"}
          onClose={() => closeCreateDialog()}
          buttons={
            <>
              <Button secondary onClick={() => closeCreateDialog()}>
                Cancel
              </Button>
              <Button
                onClick={handleCreateAttending}
                id="cy-save-add-attending"
              >
                {renderLoadingText(loadingCreateAttending, "Save")}
              </Button>
            </>
          }
        >
          <TextField
            id="cy-add-attending-name"
            label={"Attending name"}
            placeholder={"Last Name, First Name"}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setAttendingErrorMessage("");
              setAttendingEmailErrorMessage("");
              setCreateAttendingName(e.currentTarget.value);
            }}
            value={createAttendingName}
            error={attendingErrorMessage}
          />
          <TextField
            id="cy-add-attending-email"
            label={"Attending email"}
            placeholder={"attending@email.com"}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setCreateAttendingEmail(e.currentTarget.value);
            }}
            value={createAttendingEmail}
            error={attendingEmailErrorMessage}
          />
        </Dialog>
      )}
      {showDeleteDialog && (
        <Dialog
          title={"Remove Attending"}
          onClose={() => closeDeleteDialog()}
          buttons={
            <>
              <Button secondary onClick={() => closeDeleteDialog()}>
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteAttending(deleteAttendingIdName.id)}
              >
                {renderLoadingText(loadingDeleteAttending, "Remove")}
              </Button>
            </>
          }
        >
          Removing "{deleteAttendingIdName.name}" will not delete it from the
          records it is listed on. Do you wish to continue?
        </Dialog>
      )}
      {editingAttending && (
        <Dialog
          title={"Edit Attending"}
          onClose={() => closeEditDialog()}
          buttons={
            <>
              <Button secondary onClick={() => closeEditDialog()}>
                Cancel
              </Button>
              <Button
                id="cy-save-edit-attending"
                onClick={() =>
                  handleSaveEdit(editingAttending.toString(), editInputName)
                }
              >
                {renderLoadingText(loadingEditAttending, "Save")}
              </Button>
            </>
          }
        >
          <TextField
            label={"Attending name"}
            placeholder={"Last Name, First Name"}
            id="cy-edit-attending-name"
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setAttendingErrorMessage("");
              setEditInputName(e.currentTarget.value);
            }}
            value={editInputName}
            error={attendingErrorMessage}
          />
          <TextField
            label={"Attending email"}
            placeholder={"attending@email.com"}
            id="cy-edit-attending-email"
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setAttendingEmailErrorMessage("");
              setEditInputEmail(e.currentTarget.value);
            }}
            value={editInputEmail}
            error={attendingEmailErrorMessage}
          />
        </Dialog>
      )}
    </>
  );
};

export default Attendings;
