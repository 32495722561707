import React from "react";
import { theme } from "../../lib/theme";

interface ArrowRightProps {
  size: "small" | "medium";
  color?: string;
}

const ArrowRight: React.FC<ArrowRightProps> = ({ size, color }) => {
  const pxSize = (() => {
    switch (size) {
      case "small":
        return 24;
      case "medium":
        return 32;
    }
  })();
  const colorValue = color ?? theme.colors.midGrey;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={pxSize}
      height={pxSize}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={colorValue}>
          <g>
            <g>
              <g>
                <path
                  d="M9.272 15.872L12.936 12.2 9.272 8.528 10.4 7.4 15.2 12.2 10.4 17z"
                  transform="translate(-354 -184) translate(155 184) matrix(1 0 0 -1 199 24.4)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowRight;
