import React, { useRef } from "react";
import styled from "styled-components";
import Button from "../../components/shared/Button";
import { H6 } from "../../components/shared/typography";
import { ButtonSizes } from "../../shared/enums";

const FileInputWrapper = styled.div`
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 4px;
  margin-bottom: 24px;
  img {
    width: 81px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  button:first-child {
    margin-right: 16px;
  }
`;

interface FileInputProps {
  src: string;
  handleChange: (
    name: string
  ) => ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  handleResetImage: () => void;
}

const FileInput: React.FC<FileInputProps> = ({
  src,
  handleChange,
  handleResetImage,
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  return (
    <FileInputWrapper>
      <div>
        <H6>Logo</H6>
        <input
          type="file"
          onChange={handleChange("image")}
          accept="image/png"
          ref={fileInput}
          value=""
          hidden
        />
        <ButtonWrapper>
          <Button size={ButtonSizes.Small} onClick={handleResetImage} secondary>
            Reset
          </Button>
          <Button
            size={ButtonSizes.Small}
            onClick={() => fileInput.current?.click()}
          >
            Choose image
          </Button>
        </ButtonWrapper>
      </div>
      <img src={src} alt="current-logo"></img>
    </FileInputWrapper>
  );
};

export default FileInput;
