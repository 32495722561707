import React, { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useLocation } from "react-router";
import { getServiceTag } from "../../lib/getServiceTag";
import { isDebugVistMode } from "../../lib/isDebugVistMode";
import { SectionHeader } from "../Page/SectionHeader";
import { Section } from "../Page/Section";
import { VistCourseIcon } from "../../shared/enums";
import { VistCourseCard } from "../VistCourses/VistCourse";
import { VistCourse } from "../../generated/graphql";
import { Text } from "../../components/shared/typography";
import { KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import { useKeycloak } from "@react-keycloak/web";
import CloudCases from "./CloudCases";

const Applications = () => {
  const serviceTag = getServiceTag();
  const { keycloak } = useKeycloak();
  const debugVISTMode = isDebugVistMode(useLocation().search);
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;

  // Trigger fetch Talent User request if keycloak token succesfully decoded,
  // and serviceTag non-null (or in debug mode)
  useEffect(() => {
    if (user && (serviceTag || debugVISTMode)) {
      getVistCourses();
    }
  }, [serviceTag, user, debugVISTMode]); // eslint-disable-line react-hooks/exhaustive-deps
  const [getVistCourses, { data: vistCoursesData, error: vistCoursesErrors }] =
    useLazyQuery(
      gql`
        query FetchVistCourses($serviceTag: String!) {
          vistCourses(serviceTag: $serviceTag) {
            code
            version
            name
            id
            description
            hasCaseCreator
            license {
              checked
              simulations {
                valid
                expired
                remainingdays
              }
            }
          }
        }
      `,
      {
        variables: { serviceTag: serviceTag },
      }
    );

  const [existsCloudCases, setExistsCloudCases] = useState<boolean>(true);

  return (
    <>
      {(serviceTag || debugVISTMode || existsCloudCases) && (
        <SectionHeader title="Applications">
          <CloudCases
            showActionBar={false}
            setExistsCloudCases={setExistsCloudCases}
          />
          {(serviceTag || debugVISTMode) && (
            <Section
              title="VIST® Modules"
              maxColumns={4}
              primaryActionText="View All"
              primaryActionLink="/vist-modules"
            >
              {/* List VIST modules */}
              {!vistCoursesErrors &&
                vistCoursesData &&
                vistCoursesData?.vistCourses
                  ?.slice(0, 4)
                  .map((vistCourse: VistCourse) => {
                    return (
                      <a
                        href={`/vist-modules/${vistCourse.code}/${vistCourse.version}/simulations`}
                        style={{ height: "100%", textDecoration: "none" }}
                        key={vistCourse.id}
                      >
                        <VistCourseCard
                          title={vistCourse.name}
                          code={vistCourse.code}
                          version={vistCourse.version}
                          description={vistCourse.description}
                          vistCourseIcon={VistCourseIcon.Heart}
                          hasCaseCreator={vistCourse.hasCaseCreator}
                          license={vistCourse.license}
                        />
                      </a>
                    );
                  })}
              {/* List VIST errors */}
              {vistCoursesErrors && (
                <>
                  {vistCoursesErrors.graphQLErrors.map(
                    (error: { message: string }) => (
                      <Text>{error.message}</Text>
                    )
                  )}
                </>
              )}
              {/* Show loading message */}
              {!vistCoursesErrors && !vistCoursesData && (
                <Text>Loading VIST Modules...</Text>
              )}
            </Section>
          )}
        </SectionHeader>
      )}
    </>
  );
};

export default Applications;
