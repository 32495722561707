import React from "react";
import styled from "styled-components";
import { Span } from "./typography";

const RadioButtonWrapper = styled.div`
  margin-top: 12px;
  margin-right: 12px;
  margin-bottom: 28px;
  display: inline-block;
`;
const Item = styled.div`
  margin-right: 68px;
  position: relative;
  display: flex;
  flex-direction: row;
`;

const RadioButtonLabel = styled.label`
  margin-right: 34px;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 2px solid ${(props) => props.theme.colors.grey};
`;

const RadioInput = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  margin-right: 4px;
  cursor: pointer;
  &:hover ~ ${RadioButtonLabel} {
    border: 2px solid black;
  }
  ${(props: any) =>
    props.checked &&
    ` 
    &:checked + ${RadioButtonLabel} {
      background-color: ${props.theme.colors.white};
      border: 2px solid ${props.theme.colors.primary500};
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin: 4px;
        background: ${props.theme.colors.primary500};
      }
    }
  `}
`;

const RadioButtonSpan = styled(Span)`
  position: absolute;
  margin-left: 32px;
`;

interface RadioButtonProps {
  name: string;
  value: string;
  checked: boolean;
  label: string | React.ReactNode;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  name,
  value,
  checked,
  label,
  onChange,
}) => {
  return (
    <>
      <RadioButtonWrapper>
        <Item>
          <RadioInput
            type="radio"
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
          ></RadioInput>
          <RadioButtonLabel></RadioButtonLabel>
        </Item>
        <RadioButtonSpan>{label}</RadioButtonSpan>
      </RadioButtonWrapper>
    </>
  );
};

export default RadioButton;
