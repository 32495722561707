import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { PageLayout } from "../../components/Page/PageLayout";
import { Section } from "../../components/Page/Section";
import { VistCourseCard } from "../../components/VistCourses/VistCourse";
import { StyledLink, Text } from "../../components/shared/typography";
import { VistCourseSortField, SortOrder, VistCourse as VistCourseEntity } from "../../generated/graphql";
import { VistCourseIcon } from "../../shared/enums";
import { useLocation } from "react-router-dom";
import { getServiceTag } from "../../lib/getServiceTag";
import { gql, useLazyQuery } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakTokenParsed } from "keycloak-js";
import { isDebugVistMode } from "../../lib/isDebugVistMode";
import ActionBar, { SelectFieldWidthProvider } from "../../components/shared/ActionBar";
import TextField from "../../components/shared/TextField";
import SelectField from "../../components/shared/SelectField";
import { Option } from "react-select/src/filters";

const VistCoursesPage: React.FC = ({ children }) => {
  const serviceTag = getServiceTag();
  const { keycloak } = useKeycloak();
  const tokenParsed: KeycloakTokenParsed | undefined = keycloak?.idTokenParsed;
  const [searchText, setSearchText] = useState("");
  const sortOrderOptions: Option[] = [
    {
      value: "Module (A-Z)",
      label: "Module (A-Z)",
      data: {
        sortField: VistCourseSortField.Name,
        sortOrder: SortOrder.Asc,
      },
    },
    {
      value: "Module (Z-A)",
      label: "Module (Z-A)",
      data: {
        sortField: VistCourseSortField.Name,
        sortOrder: SortOrder.Desc,
      },
    },
    {
      value: "Newest",
      label: "Newest",
      data: {
        sortField: VistCourseSortField.Date,
        sortOrder: SortOrder.Desc,
      },
    },
    {
      value: "Oldest",
      label: "Oldest",
      data: {
        sortField: VistCourseSortField.Date,
        sortOrder: SortOrder.Asc,
      },
    },
  ];
  const [selectedSortOption, setSelectedSortOption] = useState<Option>(
    sortOrderOptions[0]
  );
  const [courseState, setCourseState] = useState<
    VistCourseEntity[] | undefined
  >(undefined);

  const [fetchVistCases, { error : VistCourseErrors }] = useLazyQuery<{vistCases:VistCourseEntity[];}>(
    gql`
      query FetchVistCases(
        $serviceTag: String!
        $searchParams: VISTCourseSearchParams
        ) {
        vistCases(serviceTag: $serviceTag, searchParams: $searchParams) {
          code
          version
          name
          description
          hasCaseCreator
          license {
            checked
            simulations {
              valid
              expired
              remainingdays
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      onCompleted: (data:any) => {
        setCourseState(data.vistCases);
      },
    }
  );
  const debugVISTMode = isDebugVistMode(useLocation().search);

  useEffect(() => {
    if (tokenParsed && (serviceTag || debugVISTMode)) {
      fetchVistCases({
        variables: {
          serviceTag: serviceTag,
          searchParams: {
            sortField: selectedSortOption.data.sortField,
            sortOrder: selectedSortOption.data.sortOrder,
            searchText: searchText,
          },
        }
    });
    }
  }, [tokenParsed, serviceTag, debugVISTMode, selectedSortOption, searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const request = debounce((value) => {
    setSearchText(value);
  }, 300);

  const debouceRequest = useCallback((value) => request(value), []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    debouceRequest(e.currentTarget.value);
  };

  return (
    <PageLayout title="Mentice Live | VIST® Modules">
      {children}
      <ActionBar
        backTo="/"
        title={"VIST® Modules"}
        searchField={
          <TextField
            searchable
            onClearSearch={() => {
              setSearchText("");
            }}
            placeholder="Search by course name, code or description"
            onChange={onChange}
            id="cy-vist-search-field"
          />
        }
        button={<></>}
        buttonGroup={
          <>
            <SelectFieldWidthProvider>
              <SelectField
                name="Sort by"
                isActionBar={true}
                onChange={(option: Option) => setSelectedSortOption(option)}
                value={{
                  value: selectedSortOption.value,
                  label: `Sort: ${selectedSortOption.label}`,
                  data: {
                    sortField: selectedSortOption.data.sortField,
                    sortOrder: selectedSortOption.data.sortOrder,
                  },
                }}
                options={sortOrderOptions}
              />
            </SelectFieldWidthProvider>
          </>
        }
      />
      {serviceTag || debugVISTMode ? (
        
        <Section maxColumns={4}>
          {!VistCourseErrors &&
            courseState &&
            courseState.map((vistCourse: VistCourseEntity, i: number) => {
              return (
                <StyledLink
                  to={`/vist-modules/${vistCourse.code}/${vistCourse.version}/simulations`}
                  key={i}
                >
                  <VistCourseCard
                    title={vistCourse.name}
                    description={vistCourse.description}
                    code={vistCourse.code}
                    version={vistCourse.version}
                    vistCourseIcon={VistCourseIcon.Heart}
                    hasCaseCreator={vistCourse.hasCaseCreator}
                    license={vistCourse.license}
                  />
                </StyledLink>
              );
            })}
          {VistCourseErrors && (
            <>
              <Text>{VistCourseErrors.message}</Text>
            </>
          )}
        </Section>
      ) : (
        <Text>
          Could not determine service tag. This probably means VIST is not
          installed.
        </Text>
      )}
    </PageLayout>
  );
};

export default VistCoursesPage;
