import React, { useContext } from "react";
import styled, { css, ThemeContext } from "styled-components";

interface ProgressBarProps {
  progress: number;
}

const ProgressBarWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
`;

const barStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  border-radius: 1px;
  height: 100%;
`;

const Background = styled.div`
  ${barStyle}
  width: 100%;
  background-color: ${(props) => props.theme.colors.paleGrey};
`;

const Progress = styled.div<{ color: string; width: number }>`
  ${barStyle}
  width: ${(props) => `${props.width}%`};
  background-color: ${(props) => props.color};
`;

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  const theme = useContext(ThemeContext);
  return (
    <ProgressBarWrapper>
      <Background />
      <Progress
        color={
          progress < 100 ? theme.colors.secondary500 : theme.colors.success500
        }
        width={progress}
      />
    </ProgressBarWrapper>
  );
};
