export const convertImageToBase64 = (
  file: Blob
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const convertBase64ToImage = (imgsrc: string): string => {
  const image = new Image();
  return (image.src = imgsrc);
};

export const getDefaultLogo = () =>
  "/static/assets/menticelearningcenter@2x.png";
