import React from "react";

const Attending: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 56 56"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="#FF8838">
          <g>
            <g>
              <g strokeWidth="2">
                <path
                  strokeLinecap="square"
                  d="M48.125 41.767L32.667 35c-4.148 3.111-9.852 3.111-14 0L3.208 41.767C1.33 42.704.104 44.58 0 46.677v4.656h51.333v-4.656c-.103-2.096-1.33-3.973-3.208-4.91z"
                  transform="translate(-128.000000, -1244.000000) translate(96.000000, 1223.000000) translate(32.000000, 21.000000) translate(2.333333, 2.333333)"
                />
                <path
                  d="M18.667 35L18.667 28.258M32.667 35L32.667 28.258"
                  transform="translate(-128.000000, -1244.000000) translate(96.000000, 1223.000000) translate(32.000000, 21.000000) translate(2.333333, 2.333333)"
                />
                <path
                  strokeLinecap="square"
                  d="M38.5 11.667V17.5c0 7.088-5.746 12.833-12.833 12.833h0c-7.088 0-12.834-5.745-12.834-12.833v-5.833"
                  transform="translate(-128.000000, -1244.000000) translate(96.000000, 1223.000000) translate(32.000000, 21.000000) translate(2.333333, 2.333333)"
                />
                <path
                  d="M37.683 23.155c-3.593-1.186-8.072-2.334-12.016-2.334-3.945 0-8.424 1.148-12.011 2.334"
                  transform="translate(-128.000000, -1244.000000) translate(96.000000, 1223.000000) translate(32.000000, 21.000000) translate(2.333333, 2.333333)"
                />
                <path
                  strokeLinecap="square"
                  d="M38.5 2.567S33.833 0 25.667 0C17.5 0 12.833 2.567 12.833 2.567v10.266s4.667-2.566 12.834-2.566c8.166 0 12.833 2.566 12.833 2.566V2.567z"
                  transform="translate(-128.000000, -1244.000000) translate(96.000000, 1223.000000) translate(32.000000, 21.000000) translate(2.333333, 2.333333)"
                />
              </g>
              <path
                strokeLinecap="square"
                d="M28.583 5.25L28.583 9.917M30.917 7.583L26.25 7.583"
                transform="translate(-128.000000, -1244.000000) translate(96.000000, 1223.000000) translate(32.000000, 21.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Attending;
