import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Section } from "../../components/Page/Section";
import LoadingIndicator from "../../components/shared/LoadingIndicator";
import CourseCard from "../../components/TalentCourses/CourseCard";
import { TalentCourseCategory } from "../../shared/enums";
import { CustomerLearningSpace } from "./Customers";

const InfoIcon = styled.img`
  width: 20px !important;
  height: 20px !important;
  top: 15px;
  right: 15px;
  align-self: flex-start !important;
  position: absolute;
  :hover {
    filter: brightness(80%) contrast(100%);
  }
`;

interface LearningSpaceCoursesProps {
  customerLearningSpace: CustomerLearningSpace;
  selectAllCourses?: boolean;
}

const LearningSpaceCourses: React.FC<LearningSpaceCoursesProps> = ({
  customerLearningSpace,
  selectAllCourses,
}) => {
  const { loading, data } = useQuery(
    gql`
      query CustomerLearningSpaceAccess(
        $customerId: Int!
        $learningSpaceId: String!
      ) {
        customerLearningSpaceAccess(
          customerId: $customerId
          learningSpaceId: $learningSpaceId
        ) {
          courses {
            course {
              name
              id
              code
            }
            allowed
          }
        }
      }
    `,
    {
      variables: {
        customerId: customerLearningSpace.customer.id,
        learningSpaceId: customerLearningSpace.learningSpace.id.toString(),
      },
    }
  );

  const handleCheckboxChange = (id: number) => {
    const checkedCourses = data.customerLearningSpaceAccess.courses
      .filter((item: any) => item.allowed)
      .map((item: any) => item.course.id);
    const courses = checkedCourses.includes(id)
      ? checkedCourses.filter((item: any) => item !== id)
      : [...checkedCourses, id];
    saveLearningSpaceAccess({
      variables: {
        customerId: customerLearningSpace.customer.id,
        learningSpaceId: customerLearningSpace.learningSpace.id.toString(),
        courses: courses,
        fullAccess: false,
      },
    });
  };

  const [saveLearningSpaceAccess] = useMutation(
    gql`
      mutation SaveLearningSpaceAccess(
        $customerId: Int!
        $learningSpaceId: String!
        $courses: [Int!]!
        $fullAccess: Boolean!
      ) {
        saveLearningSpaceAccess(
          customerId: $customerId
          learningSpaceId: $learningSpaceId
          courses: $courses
          fullAccess: $fullAccess
        ) {
          refetch {
            customerLearningSpaceAccess(
              customerId: $customerId
              learningSpaceId: $learningSpaceId
            ) {
              courses {
                course {
                  name
                  id
                }
                allowed
              }
            }
          }
        }
      }
    `,
    {
      update: (cache) => {
        // Evict getCustomerGroup query from cache
        // We cannot refetch because we don't know which customer group variable the last query had
        // By evicting we just refetch next time
        cache.evict({ id: "ROOT_QUERY", fieldName: "getCustomerGroup" });
        cache.gc();
      },
    }
  );

  useEffect(() => {
    if (selectAllCourses === true) {
      const checkedCourses = data.customerLearningSpaceAccess.courses.map(
        (item: any) => item.course.id
      );
      saveLearningSpaceAccess({
        variables: {
          customerId: customerLearningSpace.customer.id,
          learningSpaceId: customerLearningSpace.learningSpace.id.toString(),
          courses: checkedCourses,
          fullAccess: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAllCourses]);

  return (
    <div>
      <Section title="" maxColumns={1}>
        {loading && <LoadingIndicator />}
        {data?.customerLearningSpaceAccess?.courses &&
          data.customerLearningSpaceAccess.courses.map(
            (item: any, i: number) => (
              <CourseCard
                key={i}
                category={TalentCourseCategory.OTHER}
                title={item.course.name}
                code={item.course.code}
                checkboxChecked={item.allowed}
                onCheckboxClick={() => handleCheckboxChange(item.course.id)}
              >
                <InfoIcon
                  className="infoIcon"
                  src="/static/assets/Information-circle.svg"
                  alt=""
                  onClick={(e) => console.log(e)}
                />
              </CourseCard>
            )
          )}
      </Section>
    </div>
  );
};

export default LearningSpaceCourses;
