import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import {
  H4,
  LargeText,
  LargeTextNoWrap,
  Span,
  SpanNoWrap,
} from "../shared/typography";
import { Card } from "../shared/Card";
import { ProgressCircle } from "../shared/ProgressCircle";
import {
  getFormattedDurationStringFromSeconds,
  formatDateAndTime,
} from "../../lib/formatDateAndTime";
import { VistActivityItem as VistActivityItemEntity } from "../../generated/graphql";
import { haveRole, KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import { useKeycloak } from "@react-keycloak/web";

const VistActivityCard = styled(Card)`
  display: flex;
  align-items: center;
  padding: 18px ${(props) => props.theme.sizes.spacing3};
  min-height: ${(props) => props.theme.sizes.spacing14};
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 85px;
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  flex: 10;
  height: 100%;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 16px;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    margin-bottom: 16px;
    margin-left: 8px;
  }
`;

const ResultSection = styled.div<{ last?: boolean }>`
  ${(props) => (props.last ? "display: none" : "display: flex")};
  align-content: center;
  justify-content: center;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    ${(props) => (props.last ? "display: flex" : "display: none")};
  }
`;

const LeftContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex: 7;
  align-items: flex-start;
  min-width: ${(props) => props.theme.sizes.spacing24};

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    flex-direction: column;
  }
`;

const FlexibleRightContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1;
  height: 100%;

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    flex-direction: row;
    height: auto;
    width: 100%;
    justify-content: space-between;
  }
`;

const DateContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
`;

const SemiBoldTextNoWrap = styled(SpanNoWrap)<{ color: string }>`
  font-weight: 600;
  color: ${(props) => props.color};
`;
interface VistActivityItemProps {
  activity: VistActivityItemEntity;
}

export const VistActivityItem: React.FC<VistActivityItemProps> = ({
  activity,
}) => {
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const isGroupAdmin = haveRole(user, "group_admin");
  const isCustomerAdmin = haveRole(user, "customer_admin");
  const isSysAdmin = haveRole(user, "sys_admin");
  const theme = useContext(ThemeContext);

  const {
    activityStart,
    unitName,
    courseName,
    totalTimeInSeconds,
    result,
    progress,
  } = activity;
  const color =
    result === "Did not meet all criteria"
      ? theme.colors.secondary500
      : result === "Succesfully met all criteria"
      ? theme.colors.success500
      : theme.colors.darkGrey;

  return (
    <VistActivityCard className="cy-vist-activity-item">
      <LeftContainer>
        <ResultSection
          className={progress ? "cy-activity-result-section-progress" : ""}
        >
          <ProgressCircle color={color} progress={progress ?? 0} />
        </ResultSection>
        <MainSection>
          <H4>{unitName}</H4>
          <LargeText style={{ margin: "4px 0" }}>
            {courseName}
            {" • VIST® Sim"}
          </LargeText>
          <Span>
            {getFormattedDurationStringFromSeconds(totalTimeInSeconds, true)} -{" "}
            <SemiBoldTextNoWrap color={color}>{result}</SemiBoldTextNoWrap>
          </Span>
        </MainSection>
      </LeftContainer>
      <FlexibleRightContainer>
        <ResultSection last={true}>
          <ProgressCircle color={color} progress={progress ?? 0} />
        </ResultSection>
        {activity.activityStart && (
          <DateContainer>
            <LargeTextNoWrap>
              {formatDateAndTime(activityStart)}
            </LargeTextNoWrap>
            {(isSysAdmin || isCustomerAdmin || isGroupAdmin) && (
              <SpanNoWrap>{`${activity.createdBy?.first_name} ${activity.createdBy?.last_name}`}</SpanNoWrap>
            )}
          </DateContainer>
        )}
      </FlexibleRightContainer>
    </VistActivityCard>
  );
};
