import React from "react";

const Add: React.FC<{ color?: string }> = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g stroke={color} strokeLinecap="square" strokeWidth="2">
              <path
                d="M6 0L6 12M12 6L0 6"
                transform="translate(-349 -326) translate(349 326) translate(6 6)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Add;
