import React from "react";
import { PageLayout } from "../../components/Page/PageLayout";
import Breadcrumbs from "../shared/Breadcrumbs";
import Events from "./Events";

const EventsPage: React.FC = () => {
  return (
    <PageLayout title="Mentice Live | Events">
      <Breadcrumbs
        crumbs={[
          {
            path: "/",
            name: "Home",
          },
          {
            path: "/events",
            name: "Events",
          },
        ]}
      />
      <Events showViewAllLink={false} showActionBar={true} />
    </PageLayout>
  );
};

export default EventsPage;
