import styled from "styled-components";
import { H2, DeprecatedH6 } from "../../shared/typography";

export const Table = styled.table`
  width: 100%;
  margin: 16px 0;
  border-collapse: collapse;
`;

export const Tr = styled.tr<{ failed?: boolean }>`
  height: 24px;
  background: ${props =>
    !!props.failed ? props.theme.colors.warning100 : props.theme.colors.white};

  &:nth-child(even) {
    background: ${props =>
      !!props.failed
        ? props.theme.colors.warning500
        : props.theme.colors.paleGrey};
    border: none;
  }
`;

export const ThPrimary = styled.th<{ depth: number }>`
  width: 40%;
  text-align: left;
  padding-left: ${props => props.depth * 24 + "px"};

  > * {
    display: inline-block;
  }
`;

export const ThSecondary = styled.th`
  width: 15%;
  text-align: right;
  padding-right: 8px;
`;

export const ThTertiary = styled.th`
  width: 15%;
  text-align: left;
`;

export const TdPrimary = styled.td<{ depth: number }>`
  width: 40%;
  text-align: left;
  padding-left: ${props => props.depth * 24 + 34 + "px"};

  > * {
    display: inline-block;
  }
`;

export const TdSecondary = styled.td`
  width: 15%;
  text-align: right;
  padding-right: 8px;
`;

export const TdTertiary = styled.td`
  width: 15%;
`;

export const Title = styled(H2)`
  text-align: center;
  margin-bottom: 32px;
`;

export const HeaderTitle = styled(DeprecatedH6)`
  color: ${props => props.theme.colors.black};
`;

export const Center = styled.div`
  text-align: center;
`;
