import React, { useCallback, useEffect, useState } from "react";
import { PageLayout } from "../../components/Page/PageLayout";
import { H4, StyledLink } from "../../components/shared/typography";
import styled from "styled-components";
import { Section } from "../../components/Page/Section";
import { VistActivityItem } from "../../components/VistActivity/VistActivityItem";
import { gql, useLazyQuery } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import { haveRole, KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import {
  ActivitySortField,
  CustomerGroup as CustomerGroupEntity,
  SortOrder,
  VistActivityItem as VistActivityItemEntity,
} from "../../generated/graphql";
import { DropdownMenu } from "../../components/shared/dropdown-menu/DropdownMenu";
import { debounce } from "lodash";
import { FilterContainer } from "../../components/shared/dropdown-menu/FilterContainer";
import Checkbox from "../../components/shared/Checkbox";
import TextField from "../../components/shared/TextField";
import { exportToExcel } from "../../lib/exportToExcel";
import { useToast } from "../../lib/useToast";
import { ButtonSizes, ToastFormat, ToastType } from "../../shared/enums";
import { useRouteMatch } from "react-router-dom";
import {
  formatDateAndTime,
  getTodaysDateTime,
  getFormattedDurationStringFromSeconds,
} from "../../lib/formatDateAndTime";
import Download from "../../components/icons/Download";
import ActionBar, {
  SelectFieldWidthProvider,
} from "../../components/shared/ActionBar";
import Button from "../../components/shared/Button";
import SelectField from "../../components/shared/SelectField";
import { Option } from "react-select/src/filters";

const ActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span > p {
    margin: 2px 0 0 0;
    text-align: right;
    padding: 0;
    opacity: 0.75;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    line-height: 2;
    letter-spacing: 0.17px;
    color: ${(props) => props.theme.colors.grey};
  }
`;

export const CheckboxSpacer = styled.div`
  margin-bottom: ${(props) => props.theme.sizes.spacing4};
  > div {
    margin-bottom: ${(props) => props.theme.sizes.spacing2};
    white-space: nowrap;
  }
`;

const ActivityLogPage: React.FC = ({ children }) => {
  const { keycloak } = useKeycloak();
  const match = useRouteMatch();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const [, createToast] = useToast();

  const sortOrderOptions: Option[] = [
    {
      value: "Date (Newest to oldest)",
      label: "Date (Newest to oldest)",
      data: {
        sortField: ActivitySortField.ActivityStart,
        sortOrder: SortOrder.Desc,
      },
    },
    {
      value: "Date (Oldest to newest)",
      label: "Date (Oldest to newest)",
      data: {
        sortField: ActivitySortField.ActivityStart,
        sortOrder: SortOrder.Asc,
      },
    },
    {
      value: "Course (A-Z)",
      label: "Course (A-Z)",
      data: {
        sortField: ActivitySortField.CourseName,
        sortOrder: SortOrder.Asc,
      },
    },
    {
      value: "Course (Z-A)",
      label: "Course (Z-A)",
      data: {
        sortField: ActivitySortField.CourseName,
        sortOrder: SortOrder.Desc,
      },
    },
    {
      value: "Exercise (A-Z)",
      label: "Exercise (A-Z)",
      data: {
        sortField: ActivitySortField.ExerciseName,
        sortOrder: SortOrder.Asc,
      },
    },
    {
      value: "Exercise (Z-A)",
      label: "Exercise (Z-A)",
      data: {
        sortField: ActivitySortField.ExerciseName,
        sortOrder: SortOrder.Desc,
      },
    },
    {
      value: "Score (Highest to lowest)",
      label: "Score (Highest to lowest)",
      data: {
        sortField: ActivitySortField.Score,
        sortOrder: SortOrder.Asc,
      },
    },
    {
      value: "Score (Lowest to highest)",
      label: "Score (Lowest to highest)",
      data: {
        sortField: ActivitySortField.Score,
        sortOrder: SortOrder.Desc,
      },
    },
  ];
  const [selectedSortOption, setSelectedSortOption] = useState<Option>(
    sortOrderOptions[0]
  );

  const [searchText, setSearchText] = useState("");

  const [activityState, setActivityState] = useState<
    VistActivityItemEntity[] | undefined
  >(undefined);
  const [customerGroups, setCustomerGroups] = useState<CustomerGroupEntity[]>(
    []
  );

  const isGroupAdmin = haveRole(user, "group_admin");
  const isCustomerAdmin = haveRole(user, "customer_admin");

  // Vist activities if user is group admin
  // Last fields used for Export
  const [fetchVistActivities, { error: vistActivitiesErrors }] = useLazyQuery<{
    customerGroups: CustomerGroupEntity[];
    vistActivities: VistActivityItemEntity[];
  }>(
    gql`
      query FetchVistActivity(
        $groups: [Int!]!
        $searchParams: ActivitySearchParams
      ) {
        vistActivities(groups: $groups, searchParams: $searchParams) {
          activityStart
          activityEnd
          createdBy {
            first_name
            last_name
            email
          }
          id
          unitName
          courseName
          totalTimeInSeconds
          result
          progress
          sourceUrl
          exerciseId
          exerciseName
          moduleId
          moduleName
          moduleVersion
          serviceTag
          ipAddress
        }
        customerGroups {
          id
          name
        }
      }
    `,
    {
      onCompleted: (data) => {
        setActivityState(data.vistActivities);
        setCustomerGroups(data.customerGroups);
      },
    }
  );

  const request = debounce((value) => {
    setSearchText(value);
  }, 300);

  const debouceRequest = useCallback((value) => request(value), []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    debouceRequest(e.currentTarget.value);
  };

  const [checkedGroups, setCheckedGroups] = useState<number[]>([]);

  useEffect(() => {
    if (user) {
      fetchVistActivities({
        variables: {
          groups: checkedGroups,
          searchParams: {
            sortField: selectedSortOption.data.sortField,
            sortOrder: selectedSortOption.data.sortOrder,
            searchText: searchText,
          },
        },
      });
    }
  }, [user, checkedGroups, selectedSortOption, searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const fileName = "activity-log-" + getTodaysDateTime();

  const handleDownloadActivities = () => {
    if (activityState?.length) {
      try {
        exportToExcel(
          activityState.map((activity: VistActivityItemEntity) => {
            return {
              createdBy: activity.createdBy?.email,
              courseName: activity.courseName,
              exerciseName: activity.unitName,
              activityStart: formatDateAndTime(activity.activityStart),
              activityEnd: formatDateAndTime(activity.activityEnd),
              exerciseId: activity.exerciseId,
              id: activity.id,
              ipAddress: activity.ipAddress,
              moduleId: activity.moduleId,
              moduleName: activity.moduleName,
              moduleVersion: activity.moduleVersion,
              score: activity.progress,
              result: activity.result,
              serviceTag: activity.serviceTag,
              totalTime: getFormattedDurationStringFromSeconds(
                activity.totalTimeInSeconds,
                true
              ),
              totalTimeSeconds: activity.totalTimeInSeconds,
              sourceUrl: activity.sourceUrl,
            };
          }),
          fileName
        );
        createToast({
          title: "Successfully exported activities",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      } catch (error) {
        console.log(error);
        createToast({
          title: "Something went wrong while exporting the activities.",
          type: ToastType.ERROR,
          format: ToastFormat.TOAST,
        });
      }
    } else {
      createToast({
        title: "You have no activities to export",
        type: ToastType.ERROR,
        format: ToastFormat.TOAST,
      });
    }
  };

  return (
    <PageLayout title="Mentice Live | Group Courses">
      {children}
      <ActionBar
        backTo="/"
        title={"Activity Log"}
        searchField={
          <TextField
            searchable
            onClearSearch={() => {
              setSearchText("");
            }}
            placeholder="Search by Course or Exercise Name"
            onChange={onChange}
            id="cy-vist-search-field"
          />
        }
        button={<></>}
        buttonGroup={
          <>
            {(isGroupAdmin || isCustomerAdmin) && (
              <Button
                icon={<Download />}
                secondary
                onClick={handleDownloadActivities}
                size={ButtonSizes.Medium}
              />
            )}
            <SelectFieldWidthProvider>
              <SelectField
                name="Sort by"
                isActionBar={true}
                onChange={(option: Option) => setSelectedSortOption(option)}
                value={{
                  value: selectedSortOption.value,
                  label: `Sort: ${selectedSortOption.label}`,
                  data: {
                    sortField: selectedSortOption.data.sortField,
                    sortOrder: selectedSortOption.data.sortOrder,
                  },
                }}
                options={sortOrderOptions}
              />
            </SelectFieldWidthProvider>
            {isGroupAdmin && (
              <DropdownMenu isFilter icon={"/static/assets/filter.svg"}>
                <FilterContainer onClear={() => setCheckedGroups([])}>
                  <H4 style={{ marginBottom: "24px" }}>Groups</H4>
                  <CheckboxSpacer>
                    {customerGroups.map(
                      (group: CustomerGroupEntity, i: number) => (
                        <Checkbox
                          key={group.id}
                          title={group.name}
                          checkboxChecked={checkedGroups.includes(group.id)}
                          onCheckboxClick={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            e.currentTarget.checked
                              ? setCheckedGroups([...checkedGroups, group.id])
                              : setCheckedGroups(
                                  checkedGroups.filter((id) => id !== group.id)
                                )
                          }
                        />
                      )
                    )}
                  </CheckboxSpacer>
                </FilterContainer>
              </DropdownMenu>
            )}
          </>
        }
      />
      <Section maxColumns={1}>
        {/* List activity log items */}
        {!vistActivitiesErrors &&
          activityState &&
          activityState.map(
            (vistActivityItem: VistActivityItemEntity, i: number) => (
              <ActivityWrapper key={vistActivityItem.id}>
                <StyledLink
                  to={`${match.path}/vist-activity/${vistActivityItem.id}`}
                >
                  <VistActivityItem activity={vistActivityItem} />
                </StyledLink>
              </ActivityWrapper>
            )
          )}
      </Section>
    </PageLayout>
  );
};

export default ActivityLogPage;
