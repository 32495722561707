import React from "react";
import UserAvatar from "../../components/icons/UserAvatar";
import {
  ActionButton,
  ItemGroup,
  List,
  ListItem,
} from "../../components/shared/list/List";
import { Text, H4 } from "../../components/shared/typography";
import styled from "styled-components";

export interface RemoteVISTUser {
  id: string;
  role: string;
  name: string;
}
interface ParticipantListProps {
  participants: RemoteVISTUser[];
  title?: string;
  onActionClick?: (userId: string) => void;
}

const ListWrapper = styled.div`
  &:not(:first-of-type) {
    margin-top: 20px;
  }
`;

const ParticipantList: React.FC<ParticipantListProps> = ({
  participants,
  title,
  onActionClick,
}) => {
  return (
    <ListWrapper>
      {title && <H4>{title}</H4>}
      <List>
        {participants.map((participant: RemoteVISTUser) => (
          <ListItem key={participant.id}>
            <ItemGroup>
              <UserAvatar
                isAdmin={false}
                firstName={participant.name}
                lastName={participant.name.split(" ")[1] || participant.name}
              />
              <Text>{`${participant.name}`}</Text>
            </ItemGroup>
            {onActionClick && (
              <ItemGroup>
                <ActionButton
                  onClick={() => onActionClick && onActionClick(participant.id)}
                >
                  Assign control
                </ActionButton>
              </ItemGroup>
            )}
          </ListItem>
        ))}
      </List>
    </ListWrapper>
  );
};

export default ParticipantList;
