import React from "react";
import styled from "styled-components";
import { Card } from "../shared/Card";
import { Modal, CloseButton } from "../shared/Modal";

const VideoCard = styled(Card)`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
`;

interface VideoModalProps {
  setSelectedVideo: (id: undefined) => void;
  selectedVideo: string;
}

const VideoModal: React.FC<VideoModalProps> = ({
  setSelectedVideo,
  selectedVideo
}) => {
  const url = selectedVideo.replace("watch?v=", "embed/");

  return (
    <Modal big>
      <VideoCard>
        <CloseButton onClick={() => setSelectedVideo(undefined)} />
        <iframe
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            padding: "60px"
          }}
          src={url}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen={true}
          title="video"
        />
      </VideoCard>
    </Modal>
  );
};

export default VideoModal;
