import React, { useContext } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";
import styled, { ThemeContext } from "styled-components";
import { H6 } from "./typography";
import { theme as menticeTheme } from "../../lib/theme";

const SelectLabel = styled(H6)<{ disabled: boolean }>`
  color: ${(props: any) => props.theme.colors.grey};
  margin: 0 0 8px 0;
  font-size: 14px;
  line-height: 1;
  padding: 0;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  color: ${(props: any) => props.theme.colors.darkGrey};
`;

const SelectContainer = styled.div`
  &:last-child {
    margin-bottom: -28px;
  }
`;

export const SelectErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.error500};
  font-weight: 500;
  height: 28px;
  font-size: 14px;
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

const DropdownIcon = styled.img`
  padding: 4px;
  margin-right: 8px;
  width: 24px;
  height: 24px;
`;

const ClearIcon = styled.img`
  margin-right: 8px;
  width: 24px;
  height: 24px;
  &:hover {
    cursor: pointer;
  }
`;

const SelectField = (
  props: any /* isMulti incorrectly typed on Props in react-select 4.3.0 */,
  id: string = "cy-select-undefined"
) => {
  const theme = useContext(ThemeContext);

  const selectStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      fontFamily: theme.fonts.heading,
      opacity: state.isDisabled ? 0.4 : 1,
    }),
    control: (provided: any, state: any) => {
      const hasValue =
        state.hasValue &&
        !!state.getValue()[0].value &&
        !!state.getValue()[0].label;

      return {
        ...provided,
        padding: "1px 8px",
        minHeight: "38px",
        borderColor: props.error
          ? theme.colors.error500
          : state.isFocused
          ? theme.colors.primary500
          : hasValue
          ? theme.colors.darkGrey
          : theme.colors.midGrey,
        boxShadow: "none",
        background: props.error ? theme.colors.error25 : "white",
      };
    },
    multiValue: (provided: any) => ({
      ...provided,
      borderRadius: "4px",
    }),
    input: (provided: any) => ({
      ...provided,
      fontFamily: theme.fonts.heading,
      "& input": {
        font: "inherit",
      },
    }),
  };

  const selectTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: menticeTheme.colors.paleGrey,
      primary: menticeTheme.colors.primary500,
      dangerLight: menticeTheme.colors.paleGrey,
      danger: menticeTheme.colors.primary700,
      neutral10: menticeTheme.colors.paleGrey,
      neutral20: menticeTheme.colors.lightGrey,
      neutral30: menticeTheme.colors.lightGrey,
      neutral50: menticeTheme.colors.grey,
    },
  });

  const components = {
    IndicatorSeparator: () => null,
    DropdownIndicator: () => (
      <DropdownIcon
        src={"/static/assets/arrow-down.svg"}
        alt="Dropdown icon"
      ></DropdownIcon>
    ),
    ClearIndicator: ({ clearValue }: { clearValue: () => void }) => {
      return (
        <ClearIcon
          onClick={clearValue}
          src={"/static/assets/clear-icon.svg"}
          alt="Dropdown icon"
        ></ClearIcon>
      );
    },
  };

  return (
    <SelectContainer id={id}>
      {!props.isActionBar && (
        <SelectLabel disabled={props.isDisabled}>{props.label}</SelectLabel>
      )}
      {props.createable ? (
        <Creatable
          components={components}
          styles={selectStyles}
          {...props}
          theme={selectTheme}
        />
      ) : (
        <Select
          placeholder=""
          components={components}
          styles={selectStyles}
          {...props}
          theme={selectTheme}
        />
      )}
      {!props.isActionBar && (
        <SelectErrorMessage>{props.error}</SelectErrorMessage>
      )}
    </SelectContainer>
  );
};

export default SelectField;
