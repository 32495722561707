import { useEffect, useState } from "react";
import { ToastFormat, ToastType } from "../shared/enums";
import { generateUUID } from "./generateUUID";

export const toastIsCountdownToast = (toast: Toast): toast is CountdownToast =>
  (toast as CountdownToast).timeRemaining !== undefined &&
  typeof (toast as CountdownToast).timeRemaining === "number";

export type NormalToastData = {
  title: string;
  type: ToastType;
  format: ToastFormat.TOAST | ToastFormat.BANNER;
};
export type CountdownToastData = {
  title: string;
  type: ToastType;
  format: ToastFormat.COUNTDOWN;
  timeRemaining: number; // seconds
};
export type ToastData = NormalToastData | CountdownToastData;
export type NormalToast = NormalToastData & { id: string };
export type CountdownToast = CountdownToastData & { id: string };
export type Toast = NormalToast | CountdownToast;

export function createToastStore() {
  let toasts: Toast[] = [];
  const storeSetters = new Set();

  const createToast = (toastData: ToastData) => {
    const toast: Toast = {
      ...toastData,
      id: generateUUID(),
    };

    toasts = [...toasts, toast];
    storeSetters.forEach((setter: any) => setter(toasts));
    if (toast.format === ToastFormat.TOAST)
      setTimeout(() => removeToast(toast), 12000);
  };

  const removeToast = (toast: Toast) => {
    toasts = toasts.filter((existingToast) => existingToast.id !== toast.id);
    storeSetters.forEach((setter: any) => setter(toasts));
  };

  function useStore(): [Toast[], typeof createToast, typeof removeToast] {
    const [state, setState] = useState(toasts);
    useEffect(
      () => () => {
        storeSetters.delete(setState);
      },
      []
    );
    storeSetters.add(setState);
    return [state, createToast, removeToast];
  }

  return useStore;
}
