import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface BackButtonProps {
  to?: string;
  onClick?: () => void;
}

const BackIcon = styled.img`
  display: inline-block;
  vertical-align: bottom;
  width: 24px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 48px;
`;

const BackButton: React.FC<BackButtonProps> = ({ to, onClick }) =>
  to ? (
    <Link to={to}>
      <BackIcon src="/static/assets/back.svg" alt="Back button" />
    </Link>
  ) : (
    <BackIcon
      src="/static/assets/back.svg"
      alt="Back button"
      onClick={onClick}
    />
  );

export default BackButton;
