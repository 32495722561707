import React from "react";
import {
  Tr,
  TdPrimary,
  TdSecondary,
  TdTertiary,
} from "./RunningVistCardComponents";
import { Span } from "../../shared/typography";
import { Operator } from "../../../shared/enums";
import { getOperatorSymbol } from "./helpers";
import { parseValue } from "./helpers";

interface MetricProps {
  completed: boolean;
  label: string;
  result?: string | number;
  benchmark?: string | null;
  unit: string;
  operator: Operator;
  showResult: boolean;
  depth: number;
}

const Metric: React.FC<MetricProps> = ({
  completed,
  depth,
  label,
  result,
  showResult,
  operator,
  benchmark,
  unit,
}) => (
  <Tr failed={showResult && !!benchmark && !completed}>
    <TdPrimary depth={depth}>
      <Span>{label}</Span>
    </TdPrimary>
    <TdSecondary></TdSecondary>
    <TdTertiary style={{ textAlign: "right" }}>
      {result !== undefined && result !== null && showResult && (
        <Span>{parseValue(result, true)}</Span>
      )}
      {(result === undefined || result === null) && showResult && (
        <Span>No Value</Span>
      )}
    </TdTertiary>
    <TdSecondary>
      <Span>
        {benchmark !== undefined &&
          benchmark !== null &&
          getOperatorSymbol(operator)}{" "}
        {benchmark !== undefined &&
          benchmark !== null &&
          parseValue(benchmark, true)}
      </Span>
    </TdSecondary>
    <TdTertiary>
      <Span>{unit}</Span>
    </TdTertiary>
  </Tr>
);

export default Metric;
