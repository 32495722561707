import React from "react";
import styled from "styled-components";
import { OutlinedCard } from "../Card";

export const SidebarCardSearch = styled.div`
  width: calc(100% * 3 / 4);
  margin: ${(props) => props.theme.sizes.spacing2} 0;
`;

const WrapperCard = styled(OutlinedCard)`
  margin: 16px auto;
  display: flex;
  flex-flow: row nowrap;
  padding: ${(props) => `${props.theme.sizes.spacing3} 0`};
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    flex-wrap: wrap;
    margin: ${(props) => props.theme.sizes.spacing12} auto;
  }
`;

const Sidebar = styled.div`
  padding: ${(props) =>
    `${props.theme.sizes.spacing2} ${props.theme.sizes.spacing5}`};
  z-index: 20;
  flex: 1;
  border-right: 1px solid ${(props) => props.theme.colors.midGrey};
  min-width: ${(props) => props.theme.sizes.spacing28};
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    border: none;
    padding: ${(props) =>
      `${props.theme.sizes.spacing2} ${props.theme.sizes.spacing4} ${props.theme.sizes.spacing3}`};
  }
`;

const SidebarList = styled.ul`
  padding: 0;
  list-style: none;
  margin-top: 0;
`;

const Content = styled.div`
  flex: 5;
  background: unset;
  border: none;
  padding: ${(props) =>
    `${props.theme.sizes.spacing2} ${props.theme.sizes.spacing5}`};

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    min-width: ${(props) => props.theme.sizes.spacing60};
    padding: ${(props) =>
      `0 ${props.theme.sizes.spacing4} ${props.theme.sizes.spacing3}`};
  }
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    min-width: ${(props) => props.theme.sizes.spacing40};
  }
`;

interface SidebarCardProps {
  sidebarList: React.ReactNode[];
}

const SidebarCard: React.FC<SidebarCardProps> = ({ sidebarList, children }) => {
  return (
    <WrapperCard>
      <Sidebar>
        <SidebarList id="cy-sidebar-card-list">
          {sidebarList.map((sidebarListItem) => sidebarListItem)}
        </SidebarList>
      </Sidebar>
      <Content id="cy-sidebar-card-content">{children}</Content>
    </WrapperCard>
  );
};

export default SidebarCard;
