import {
  ApolloError,
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import React, { useContext, useMemo, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { Customer, TalentBranch } from "../../generated/graphql";
import Button from "../../components/shared/Button";
import { Card } from "../../components/shared/Card";
import { ContextMenu } from "../../components/shared/context-menu/ContextMenu";
import { MenuItem } from "../../components/shared/context-menu/MenuItem";
import Dialog from "../../components/shared/Dialog";
import TextField from "../../components/shared/TextField";
import {
  H3,
  H4,
  h6Style,
  LargeText,
  SemiBoldTextNoWrap,
  Span,
  Text,
} from "../../components/shared/typography";
import { getDefaultLogo } from "../../lib/convertBase64";
import { ButtonSizes, SortOrder } from "../../shared/enums";
import SelectField from "../../components/shared/SelectField";
import LearningSpaceCourses from "./LearningSpaceCourses";
import BackButton from "../../components/shared/BackButton";
import LoadingIndicator, {
  renderLoadingText,
} from "../../components/shared/LoadingIndicator";
import { useKeycloak } from "@react-keycloak/web";
import { haveRole, KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import ActionBar, {
  SelectFieldWidthProvider,
} from "../../components/shared/ActionBar";
import { debounce } from "lodash";
import { Option } from "react-select/src/filters";
import Tooltip from "../../components/Groups/Tooltip";

export const SidebarCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const CustomerCard = styled(Card)`
  margin: 24px 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: flex-start;
`;

const LearningSpaceTag = styled.div`
  background-color: ${(props) => props.theme.colors.paleGrey};
  padding: 8px;
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  img {
    height: 20px;
    margin-right: 8px;
  }
  margin-right: 16px;
  flex-shrink: 0;
`;

const TagContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: ${(p) => p.theme.sizes.spacing4};
`;

const CustomerAdminTextfieldsWrapper = styled.div`
  position: relative;
`;

const TextFieldWrapper = styled.div`
  position: relative;
  height: 90px;
`;

const TextFieldRemoveButton = styled(Text)`
  font-style: italic;
  position: absolute;
  bottom: -5px;
  right: 0px;
  cursor: pointer;
`;

const TextFieldAddButton = styled(Text)<{ offsetRight: boolean }>`
  font-style: italic;
  position: absolute;
  bottom: -5px;
  right: ${(p) => (p.offsetRight ? "120px" : "0px")};
  cursor: pointer;
`;

const QuotaWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: ${(p) => p.theme.sizes.spacing4} ${(p) =>
      p.theme.sizes.spacing2};
  grid-column-gap: ${(p) => p.theme.sizes.spacing2};
  grid-row-gap: ${(p) => p.theme.sizes.spacing1};
  grid-auto-flow: column;
  justify-items: center;
`;

const QuotaNumberH6 = styled.h6`
  ${h6Style}
  font-weight: 700;
  position: relative;
  margin-top: -${(p) => p.theme.sizes.spacing1};
`;

interface Options {
  value: string;
  label: string;
  id: string;
}

interface InputValues {
  customerName: string;
  customerAdminEmails: string[];
  customerBranches: null | string[];
}

export interface CustomerLearningSpace {
  customer: Customer;
  learningSpace: TalentBranch;
}

export const customerFields = gql`
  fragment CustomerFields on Customer {
    id
    name
    branches {
      name
      id
      image
    }
    cloudModuleTimeBalance {
      consumedTimeInMs
      reservedTimeInMs
      spendableTimeInMs
    }
  }
`;

const Customers = () => {
  const theme = useContext(ThemeContext);
  const [showAddCustomerDialogAsSysAdmin, setShowAddCustomerDialogAsSysAdmin] =
    useState(false);
  const [editCustomer, setEditCustomer] = useState<undefined | Customer>(
    undefined
  );
  const [editLearningSpace, setEditLearningSpace] = useState<
    TalentBranch[] | undefined
  >(undefined);
  const [
    showEditCustomerDialogAsSysAdmin,
    setShowEditCustomerDialogAsSysAdmin,
  ] = useState(false);
  const [
    showEditCustomerDialogAsCustomerAdmin,
    setShowEditCustomerDialogAsCustomerAdmin,
  ] = useState(false);
  const [selectAllCourses, setSelectAllCourses] = useState<undefined | boolean>(
    undefined
  );
  const [selectedCustomer, setSelectedCustomer] = useState<
    CustomerLearningSpace | undefined
  >(undefined);
  const [customerNameError, setCustomerNameError] = useState<
    string | undefined
  >(undefined);
  const [customerAdminError, setCustomerAdminError] = useState<
    { error: string; index: number } | undefined
  >(undefined);
  const [customerBranchesError, setCustomerBranchesError] = useState<
    string | undefined
  >(undefined);
  const sortOrderOptions = [
    {
      value: "Name (A-Z)",
      label: "Name (A-Z)",
      data: {
        sortOrder: SortOrder.AZ,
      },
    },
    {
      value: "Name (Z-A)",
      label: "Name (Z-A)",
      data: {
        sortOrder: SortOrder.ZA,
      },
    },
  ];
  const [selectedSortOption, setSelectedSortOption] = useState<Option>(
    sortOrderOptions[0]
  );
  const [searchText, setSearchText] = useState("");

  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const isCustomerAdmin = haveRole(user, "customer_admin");
  const isSysAdmin = haveRole(user, "sys_admin");

  const [inputValues, setInputValues] = useState<InputValues>({
    customerName: "",
    customerAdminEmails: [""],
    customerBranches: null,
  });

  const [toppingUpCustomerId, setToppingUpCustomerId] = useState<
    undefined | number
  >(undefined);
  const [timeQuotumDifferenceInHours, setTimeQuotumDifferenceInHours] =
    useState<number | undefined>(undefined);

  const {
    loading: customersLoading,
    data: customersData,
    error: customersErrors,
  } = useQuery(
    gql`
      query Customers {
        customers {
          ...CustomerFields
        }
      }
      ${customerFields}
    `,
    {
      fetchPolicy: "network-only",
    }
  );
  const { customers } = !customersLoading && !customersErrors && customersData;

  const [fetchCustomerAdmins, { loading: loadingCustomerAdmins }] =
    useLazyQuery<{ customer: Customer }>(
      gql`
        query FetchCustomerAdmins($customerId: Int!) {
          customer(customerId: $customerId) {
            admins {
              keycloak_id
              email
            }
          }
        }
      `,
      {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
          setInputValues({
            customerName: editCustomer?.name ?? "",
            customerAdminEmails:
              data.customer?.admins.length === 0
                ? [""]
                : data.customer?.admins?.map((admin) => admin.email),
            customerBranches: editCustomer?.branches?.map((item) =>
              item.id.toString()
            ) ?? [""],
          });
        },
      }
    );

  const { data: learningSpacesData, error: learningSpacesErrors } = useQuery(
    gql`
      query TalentBranches {
        talentBranches {
          id
          code
          name
          description
          image
        }
      }
    `
  );

  const onSaveError = (err: ApolloError) => {
    if (err.graphQLErrors[0]?.extensions?.code === "unique_customer_names") {
      setCustomerNameError("An account with this name already exists");
    } else if (err.message === "Error: The user does not exist") {
      setCustomerAdminError({ error: "The user does not exist", index: 0 });
    } else if (
      err.message.includes("Error: The following user does not exist: ")
    ) {
      const incorrectEmailAddress = err.message.replace(
        "Error: The following user does not exist: ",
        ""
      );
      const indexOfIncorrectEmailAddress =
        inputValues.customerAdminEmails.indexOf(incorrectEmailAddress);
      setCustomerAdminError({
        error: "The user does not exist",
        index: indexOfIncorrectEmailAddress,
      });
    } else if (err.message === "Error: admin cannot be empty string") {
      const indexOfEmptyEmailAddress =
        inputValues.customerAdminEmails.indexOf("");
      setCustomerAdminError({
        error: "Assign an admin",
        index: indexOfEmptyEmailAddress,
      });
    } else if (err.message === "Error: name cannot be empty") {
      setCustomerNameError("Assign an account name");
    } else if (err.message.includes("Error: Incorrect admin email address: ")) {
      const incorrectEmailAddress = err.message.replace(
        "Error: Incorrect admin email address: ",
        ""
      );
      setCustomerAdminError({
        error: "Incorrect email address",
        index: inputValues.customerAdminEmails.indexOf(incorrectEmailAddress),
      });
    }
  };

  const [saveCustomerAsSysAdmin, { loading: loadingSaveCustomerAsSysAdmin }] =
    useMutation(
      gql`
        mutation SaveCustomer($customer: SaveCustomerInput!) {
          saveCustomer(customer: $customer) {
            refetch {
              customers {
                ...CustomerFields
              }
            }
          }
        }
        ${customerFields}
      `,
      {
        onCompleted: () => handleClose(),
        onError: onSaveError,
      }
    );
  const [
    saveCustomerAsCustomerAdmin,
    { loading: loadingSaveCustomerAsCustomerAdmin },
  ] = useMutation(
    gql`
      mutation SaveCustomer($customer: SaveCustomerInput!) {
        saveCustomer(customer: $customer) {
          refetch {
            getCustomersForAdmin {
              id
              name
            }
          }
        }
      }
    `,
    {
      onCompleted: () => handleClose(),
      onError: onSaveError,
    }
  );
  const [topUpCustomer] = useMutation(
    gql`
      mutation AddCustomerCloudModuleQuotaTransaction(
        $customerId: Int!
        $timeQuotumDifferenceInMs: Int!
      ) {
        addCustomerCloudModuleQuotaTransaction(
          customerId: $customerId
          timeQuotumDifferenceInMs: $timeQuotumDifferenceInMs
        ) {
          refetch {
            customers {
              ...CustomerFields
            }
          }
        }
      }
      ${customerFields}
    `,
    {
      onCompleted: () => {
        setTimeQuotumDifferenceInHours(undefined);
        setToppingUpCustomerId(undefined);
      },
      onError: () => {
        setTimeQuotumDifferenceInHours(undefined);
        setToppingUpCustomerId(undefined);
      },
      variables: {
        customerId: toppingUpCustomerId,
        timeQuotumDifferenceInMs:
          (timeQuotumDifferenceInHours ?? 0) * 3600 * 1000,
      },
    }
  );

  const handleChange =
    (
      changeType: "customerName" | "customerAdminEmailsEdit" | "learningSpaces",
      index?: number
    ) =>
    (event: any) => {
      setCustomerNameError(undefined);
      setCustomerAdminError(undefined);
      setCustomerBranchesError(undefined);

      switch (changeType) {
        case "customerName":
          setInputValues({
            ...inputValues,
            customerName: event.currentTarget.value,
          });
          break;
        case "learningSpaces":
          const customerBranches = event
            .map((option: Options) => option.id)
            .map((branchId: string | number) => `${branchId}`);
          setInputValues({ ...inputValues, customerBranches });
          break;
        case "customerAdminEmailsEdit":
          if (typeof index === "number") {
            const customerAdminEmails = inputValues.customerAdminEmails;
            customerAdminEmails[index] = event.currentTarget.value;
            setInputValues({
              ...inputValues,
              customerAdminEmails,
            });
          }
          break;
      }
    };

  const handleClose = () => {
    setCustomerNameError(undefined);
    setCustomerAdminError(undefined);
    setCustomerBranchesError(undefined);
    setInputValues({
      customerName: "",
      customerAdminEmails: [""],
      customerBranches: null,
    });
    setShowAddCustomerDialogAsSysAdmin(false);
    setShowEditCustomerDialogAsSysAdmin(false);
    setShowEditCustomerDialogAsCustomerAdmin(false);
  };

  const hasInputError = (): boolean => {
    let inputError = false;
    if (isSysAdmin) {
      if (
        inputValues.customerName === null ||
        inputValues.customerName === undefined ||
        inputValues.customerName === ""
      ) {
        setCustomerNameError("Assign an account name");
        inputError = true;
      }
      if (inputValues.customerAdminEmails.length === 0) {
        setCustomerAdminError({ error: "Assign an admin", index: 0 });
        inputError = true;
      }
      if (
        inputValues.customerBranches === null ||
        inputValues.customerBranches === undefined ||
        inputValues.customerBranches.length === 0
      ) {
        setCustomerBranchesError("Assign learning spaces to this account");
        inputError = true;
      }
    } else if (isCustomerAdmin) {
      if (inputValues.customerAdminEmails.length === 0) {
        setCustomerAdminError({ error: "Assign an admin", index: 0 });
        inputError = true;
      }
    }
    return inputError;
  };

  const handleAddCustomer = () => {
    if (hasInputError()) return;

    if (isSysAdmin) {
      saveCustomerAsSysAdmin({
        variables: {
          customer: {
            name: inputValues.customerName,
            admins: inputValues.customerAdminEmails.join(","),
            branches: inputValues.customerBranches,
          },
        },
      });
    }
  };

  const handleEditSave = () => {
    if (hasInputError()) return;

    if (isSysAdmin) {
      saveCustomerAsSysAdmin({
        variables: {
          customer: {
            id: editCustomer?.id,
            name: inputValues.customerName,
            admins: inputValues.customerAdminEmails,
            branches: inputValues.customerBranches,
          },
        },
      });
    } else if (isCustomerAdmin) {
      saveCustomerAsCustomerAdmin({
        variables: {
          customer: {
            id: editCustomer?.id,
            name: inputValues.customerName,
            admins: inputValues.customerAdminEmails,
          },
        },
      });
    }
  };

  const handleEditCustomer = (c: Customer) => {
    setEditCustomer(c);
    setEditLearningSpace(c.branches);
    fetchCustomerAdmins({
      variables: {
        customerId: c.id,
      },
    });
    if (isSysAdmin) {
      setShowEditCustomerDialogAsSysAdmin(true);
    } else if (isCustomerAdmin) {
      setShowEditCustomerDialogAsCustomerAdmin(true);
    }
  };

  const addNewCustomerAdminTextfield = () => {
    setInputValues({
      ...inputValues,
      customerAdminEmails: [...inputValues.customerAdminEmails, ""],
    });
  };

  const removeCustomerAdminTextfield = (i: number) => {
    setInputValues({
      ...inputValues,
      customerAdminEmails: inputValues.customerAdminEmails.filter(
        (_, j) => i !== j
      ),
    });
    setCustomerAdminError(undefined);
  };

  const debounceRequest = useMemo(
    () =>
      debounce((value) => {
        setSearchText(value);
      }, 300),
    [setSearchText]
  );

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    debounceRequest(e.currentTarget.value);
  };

  const [quotaTooltip, setQuotaTooltip] = useState<{
    quotum: "spendable" | "reserved" | "consumed";
    customerId: number;
  } | null>(null);

  return selectedCustomer ? (
    <div>
      <BackButton onClick={() => setSelectedCustomer(undefined)}></BackButton>
      <SidebarCardHeader>
        <div>
          <H3>Mentice Learning Space Courses</H3>
          <LargeText>
            Select course(s) to make available to{" "}
            <span style={{ fontWeight: "bold" }}>
              {selectedCustomer.customer.name}
            </span>
          </LargeText>
        </div>

        <Button
          onClick={() => setSelectAllCourses(true)}
          size={ButtonSizes.Medium}
        >
          Select all
        </Button>
      </SidebarCardHeader>
      <LearningSpaceCourses
        customerLearningSpace={selectedCustomer}
        selectAllCourses={selectAllCourses}
      />
    </div>
  ) : (
    <div>
      <ActionBar
        title={"Accounts"}
        searchField={
          <TextField
            searchable
            onClearSearch={() => {
              setSearchText("");
            }}
            placeholder="Search by account name"
            onChange={handleSearchChange}
          />
        }
        button={
          isSysAdmin ? (
            <Button
              onClick={() => {
                setShowAddCustomerDialogAsSysAdmin(true);
                setInputValues({
                  customerName: "",
                  customerAdminEmails: [""],
                  customerBranches: null,
                });
              }}
              size={ButtonSizes.Medium}
            >
              Add account
            </Button>
          ) : (
            <></>
          )
        }
        buttonGroup={
          <>
            <SelectFieldWidthProvider>
              <SelectField
                name="Sort by"
                isActionBar={true}
                onChange={(option: Option) => setSelectedSortOption(option)}
                value={{
                  value: selectedSortOption.value,
                  label: `Sort: ${selectedSortOption.label}`,
                  data: {
                    sortField: selectedSortOption.data.sortField,
                    sortOrder: selectedSortOption.data.sortOrder,
                  },
                }}
                options={sortOrderOptions}
              />
            </SelectFieldWidthProvider>
          </>
        }
      />
      {customersLoading && <LoadingIndicator />}
      {!customersErrors &&
        customers &&
        customers.length > 0 &&
        [...customers]
          .sort((customer1, customer2) =>
            selectedSortOption.data.sortOrder === SortOrder.AZ
              ? customer1.name.toLowerCase() > customer2.name.toLowerCase()
                ? 1
                : -1
              : customer1.name.toLowerCase() < customer2.name.toLowerCase()
              ? 1
              : -1
          )
          .filter((customer) => {
            if (searchText === "") {
              return true;
            } else {
              return customer.name
                .toLowerCase()
                .includes(searchText.toLowerCase());
            }
          })
          .map((customer: Customer) => {
            return (
              <CustomerCard key={customer.id} id="cy-customer-card">
                <div>
                  <H4>{customer.name}</H4>
                  {isSysAdmin && (
                    <>
                      <SemiBoldTextNoWrap>Learning Spaces</SemiBoldTextNoWrap>
                      <TagContainer>
                        {customer.branches.length > 0 &&
                          customer.branches.map((branch: any) => (
                            <LearningSpaceTag
                              key={branch.id}
                              onClick={() =>
                                setSelectedCustomer({
                                  customer: customer,
                                  learningSpace: branch,
                                })
                              }
                            >
                              <img
                                src={
                                  branch.image ? branch.image : getDefaultLogo()
                                }
                                alt="Learning Space Logo"
                              ></img>
                              <Span>{branch.name}</Span>
                            </LearningSpaceTag>
                          ))}
                      </TagContainer>
                    </>
                  )}
                  <SemiBoldTextNoWrap>
                    Cloud Cases Time Quota
                  </SemiBoldTextNoWrap>
                  <QuotaWrapper>
                    <Text
                      onPointerEnter={() =>
                        setQuotaTooltip({
                          quotum: "spendable",
                          customerId: customer.id,
                        })
                      }
                      onPointerLeave={() => setQuotaTooltip(null)}
                    >
                      Spendable
                    </Text>
                    <QuotaNumberH6
                      style={{ color: theme.colors.success700 }}
                      onPointerEnter={() =>
                        setQuotaTooltip({
                          quotum: "spendable",
                          customerId: customer.id,
                        })
                      }
                      onPointerLeave={() => setQuotaTooltip(null)}
                    >
                      {Math.floor(
                        customer.cloudModuleTimeBalance.spendableTimeInMs /
                          3600000
                      )}
                      h
                      {quotaTooltip &&
                        quotaTooltip.customerId === customer.id &&
                        quotaTooltip.quotum === "spendable" && (
                          <Tooltip
                            label={
                              "Spendable hours will become reserved by creating vouchers, and consumed by accepting vouchers."
                            }
                          />
                        )}
                    </QuotaNumberH6>
                    <Text
                      onPointerEnter={() =>
                        setQuotaTooltip({
                          quotum: "reserved",
                          customerId: customer.id,
                        })
                      }
                      onPointerLeave={() => setQuotaTooltip(null)}
                    >
                      Reserved
                    </Text>
                    <QuotaNumberH6
                      onPointerEnter={() =>
                        setQuotaTooltip({
                          quotum: "reserved",
                          customerId: customer.id,
                        })
                      }
                      onPointerLeave={() => setQuotaTooltip(null)}
                      style={{ color: theme.colors.secondary900 }}
                    >
                      {Math.floor(
                        customer.cloudModuleTimeBalance.reservedTimeInMs /
                          3600000
                      )}
                      h
                      {quotaTooltip &&
                        quotaTooltip.customerId === customer.id &&
                        quotaTooltip.quotum === "reserved" && (
                          <Tooltip
                            label={
                              "Reserved hours for outstanding vouchers, returned to spendable if not consumed before voucher expiry."
                            }
                          />
                        )}
                    </QuotaNumberH6>
                    <Text
                      onPointerEnter={() =>
                        setQuotaTooltip({
                          quotum: "consumed",
                          customerId: customer.id,
                        })
                      }
                      onPointerLeave={() => setQuotaTooltip(null)}
                    >
                      Consumed
                    </Text>
                    <QuotaNumberH6
                      onPointerEnter={() =>
                        setQuotaTooltip({
                          quotum: "consumed",
                          customerId: customer.id,
                        })
                      }
                      onPointerLeave={() => setQuotaTooltip(null)}
                      style={{ color: theme.colors.midGrey }}
                    >
                      {Math.floor(
                        customer.cloudModuleTimeBalance.consumedTimeInMs /
                          3600000
                      )}
                      h
                      {quotaTooltip &&
                        quotaTooltip.customerId === customer.id &&
                        quotaTooltip.quotum === "consumed" && (
                          <Tooltip
                            label={
                              "Hours that have been used by accepting vouchers."
                            }
                          />
                        )}
                    </QuotaNumberH6>
                  </QuotaWrapper>
                </div>
                <ContextMenu>
                  <MenuItem
                    onClick={() => {
                      handleEditCustomer(customer);
                    }}
                  >
                    Edit Account
                  </MenuItem>
                  {isSysAdmin && (
                    <MenuItem
                      onClick={() => {
                        setToppingUpCustomerId(customer.id);
                      }}
                    >
                      Top Up Quota
                    </MenuItem>
                  )}
                </ContextMenu>
              </CustomerCard>
            );
          })}
      {showEditCustomerDialogAsSysAdmin && isSysAdmin && (
        <Dialog
          title="Edit Account"
          onClose={handleClose}
          buttons={
            <>
              <Button secondary={true} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                onClick={handleEditSave}
                disabled={
                  loadingSaveCustomerAsSysAdmin || loadingCustomerAdmins
                }
              >
                {renderLoadingText(
                  loadingSaveCustomerAsSysAdmin,
                  "Save changes"
                )}
              </Button>
            </>
          }
        >
          {loadingCustomerAdmins && <LoadingIndicator />}
          {!loadingCustomerAdmins && (
            <div>
              <TextField
                label="Account Name"
                value={inputValues.customerName}
                onChange={handleChange("customerName")}
                placeholder="Example - Abc"
                error={customerNameError}
              />
              <CustomerAdminTextfieldsWrapper>
                {inputValues.customerAdminEmails.map(
                  (customerAdminEmail, i) => (
                    <TextFieldWrapper key={i}>
                      <TextField
                        label={
                          inputValues.customerAdminEmails.length === 1
                            ? "Admin Email"
                            : `Admin Email ${i + 1}`
                        }
                        key={i}
                        value={customerAdminEmail}
                        onChange={handleChange("customerAdminEmailsEdit", i)}
                        placeholder="Enter Email Address"
                        disabled={loadingCustomerAdmins}
                        error={
                          i === customerAdminError?.index &&
                          customerAdminError?.error
                        }
                      />
                      {inputValues.customerAdminEmails.length > 1 && (
                        <TextFieldRemoveButton
                          onClick={() => removeCustomerAdminTextfield(i)}
                        >
                          Remove admin
                        </TextFieldRemoveButton>
                      )}
                    </TextFieldWrapper>
                  )
                )}
                <TextFieldAddButton
                  offsetRight={inputValues.customerAdminEmails.length > 1}
                  onClick={() => addNewCustomerAdminTextfield()}
                >
                  Add another admin
                </TextFieldAddButton>
              </CustomerAdminTextfieldsWrapper>
              {!learningSpacesErrors &&
              learningSpacesData &&
              learningSpacesData.talentBranches.length > 0 ? (
                <SelectField
                  label="Learning Spaces"
                  isMulti
                  isSearchable
                  name="learningSpaces"
                  defaultValue={editLearningSpace?.map(
                    (item: TalentBranch) => ({
                      value: item.name,
                      label: item.name,
                      id: item.id,
                    })
                  )}
                  onChange={handleChange("learningSpaces")}
                  options={learningSpacesData.talentBranches.map(
                    (item: TalentBranch) => ({
                      value: item.name,
                      label: item.name,
                      id: item.id,
                    })
                  )}
                  error={customerBranchesError}
                />
              ) : (
                <Text>No learning spaces were found</Text>
              )}
            </div>
          )}
        </Dialog>
      )}
      {showEditCustomerDialogAsCustomerAdmin && isCustomerAdmin && (
        <Dialog
          title="Edit Account"
          onClose={handleClose}
          buttons={
            <>
              <Button secondary={true} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                onClick={handleEditSave}
                disabled={
                  loadingSaveCustomerAsCustomerAdmin || loadingCustomerAdmins
                }
              >
                {renderLoadingText(
                  loadingSaveCustomerAsCustomerAdmin,
                  "Save changes"
                )}
              </Button>
            </>
          }
        >
          {loadingCustomerAdmins && <LoadingIndicator />}
          {!loadingCustomerAdmins && (
            <CustomerAdminTextfieldsWrapper>
              {inputValues.customerAdminEmails.map((customerAdminEmail, i) => (
                <TextFieldWrapper key={i}>
                  <TextField
                    label={
                      inputValues.customerAdminEmails.length === 1
                        ? "Admin Email"
                        : `Admin Email ${i + 1}`
                    }
                    value={customerAdminEmail}
                    onChange={handleChange("customerAdminEmailsEdit", i)}
                    placeholder="Enter Email Address"
                    disabled={loadingCustomerAdmins}
                    error={
                      i === customerAdminError?.index &&
                      customerAdminError?.error
                    }
                  />
                  {i > 0 && (
                    <TextFieldRemoveButton
                      onClick={() => removeCustomerAdminTextfield(i)}
                    >
                      Remove admin
                    </TextFieldRemoveButton>
                  )}
                </TextFieldWrapper>
              ))}
              <TextFieldAddButton
                offsetRight={inputValues.customerAdminEmails.length > 1}
                onClick={() => addNewCustomerAdminTextfield()}
              >
                Add another admin
              </TextFieldAddButton>
            </CustomerAdminTextfieldsWrapper>
          )}
        </Dialog>
      )}
      {showAddCustomerDialogAsSysAdmin && isSysAdmin && (
        <Dialog
          title="Add account"
          onClose={handleClose}
          buttons={
            <>
              <Button secondary={true} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                disabled={loadingSaveCustomerAsSysAdmin}
                onClick={handleAddCustomer}
                id="cy-customer-save"
              >
                {renderLoadingText(
                  loadingSaveCustomerAsSysAdmin,
                  "Save changes"
                )}
              </Button>
            </>
          }
        >
          <div>
            <TextField
              label="Account Name"
              value={inputValues.customerName}
              onChange={handleChange("customerName")}
              placeholder="Example - Abc"
              id="cy-customer-name"
              error={customerNameError}
            />
            <CustomerAdminTextfieldsWrapper>
              {inputValues.customerAdminEmails.map((customerAdminEmail, i) => (
                <TextFieldWrapper key={i}>
                  <TextField
                    label={
                      inputValues.customerAdminEmails.length === 1
                        ? "Admin Email"
                        : `Admin Email ${i + 1}`
                    }
                    key={i}
                    id="cy-customer-admin-email"
                    value={customerAdminEmail}
                    onChange={handleChange("customerAdminEmailsEdit", i)}
                    placeholder="Enter Email Address"
                    disabled={loadingCustomerAdmins}
                    error={
                      i === customerAdminError?.index &&
                      customerAdminError?.error
                    }
                  />
                  {i > 0 && (
                    <TextFieldRemoveButton
                      onClick={() => removeCustomerAdminTextfield(i)}
                    >
                      Remove admin
                    </TextFieldRemoveButton>
                  )}
                </TextFieldWrapper>
              ))}
              <TextFieldAddButton
                offsetRight={inputValues.customerAdminEmails.length > 1}
                onClick={() => addNewCustomerAdminTextfield()}
              >
                Add another admin
              </TextFieldAddButton>
            </CustomerAdminTextfieldsWrapper>
            {!learningSpacesErrors &&
            learningSpacesData &&
            learningSpacesData.talentBranches.length > 0 ? (
              <SelectField
                isMulti
                isSearchable
                label="Learning Spaces"
                name="learningSpaces"
                onChange={handleChange("learningSpaces")}
                options={learningSpacesData.talentBranches.map(
                  (item: TalentBranch) => ({
                    value: item.name,
                    label: item.name,
                    id: item.id,
                  })
                )}
                id="cy-customer-select-field"
                error={customerBranchesError}
              />
            ) : (
              <Text>No learning spaces were found</Text>
            )}
          </div>
        </Dialog>
      )}
      {toppingUpCustomerId && (
        <Dialog
          title="Top Up Cloud Case Quota"
          onClose={() => {
            setTimeQuotumDifferenceInHours(undefined);
            setToppingUpCustomerId(undefined);
          }}
          buttons={
            <>
              <Button
                secondary={true}
                onClick={() => {
                  setTimeQuotumDifferenceInHours(undefined);
                  setToppingUpCustomerId(undefined);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => topUpCustomer()}
                disabled={
                  loadingSaveCustomerAsCustomerAdmin ||
                  loadingCustomerAdmins ||
                  timeQuotumDifferenceInHours === undefined
                }
              >
                {renderLoadingText(
                  loadingSaveCustomerAsCustomerAdmin,
                  "Top Up"
                )}
              </Button>
            </>
          }
        >
          <CustomerAdminTextfieldsWrapper>
            <TextField
              type="number"
              name="topUpQuotaAmount"
              label="Hours to adjust quota with"
              value={timeQuotumDifferenceInHours}
              onChange={(e: {
                currentTarget: {
                  value: number;
                };
              }) =>
                setTimeQuotumDifferenceInHours(Number(e.currentTarget.value))
              }
            />
          </CustomerAdminTextfieldsWrapper>
        </Dialog>
      )}
    </div>
  );
};

export default Customers;
