import { QueryResult, useMutation, useQuery } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import { Formik, FormikErrors } from "formik";
import gql from "graphql-tag";
import React, { FormEvent, useState } from "react";
import LoadingIndicator, {
  renderLoadingText,
} from "../../components/shared/LoadingIndicator";
import { SidebarCardSection } from "../../components/shared/sidebar-card/SidebarCardSection";
import TextField, { LabelText } from "../../components/shared/TextField";
import { User, UserInput } from "../../generated/graphql";
import { KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import { H3, Text } from "../../components/shared/typography";
import Button from "../../components/shared/Button";
import {
  ButtonSizes,
  ButtonTypes,
  ToastFormat,
  ToastType,
} from "../../shared/enums";
import { GridCol, GridRow } from "../../components/shared/FormLayout";
import styled from "styled-components";
import { useToast } from "../../lib/useToast";
import { Option } from "react-select/src/filters";
import SelectField from "../../components/shared/SelectField";
import { StringKeyObject } from "../logbook/types";
import Checkbox from "../../components/shared/Checkbox";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export const GenderOptionsMap: StringKeyObject = {
  female: "Female",
  male: "Male",
  neutral: "Neutral",
};

const SectionHeader = styled(H3)`
  margin: ${(props) => props.theme.sizes.spacing8} 0
    ${(props) => props.theme.sizes.spacing1} 0;
`;

const SectionTwoColInput = styled.div`
  padding-right: 20%;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointXL}) {
    padding-right: 0;
  }
`;

const SectionButtonWrapper = styled(H3)`
  width: 100%;
  margin-top: ${(props) => `${props.theme.sizes.spacing10}`};
  display: flex;
  justify-content: flex-end;
  button:first-child {
    margin-right: ${(props) => props.theme.sizes.spacing2};
  }
`;

const PhoneInputStyles = {
  margin: "10px 0"
}

interface AccountProps {}

const Account: React.FC<AccountProps> = () => {
  const { keycloak } = useKeycloak();
  const [, createToast] = useToast();
  const user: KeycloakAccessToken | undefined = keycloak.tokenParsed;
  const [userInputValues, setUserInputValues] = useState<UserInput>({
    keycloak_id: "",
    email: "",
    firstName: "",
    lastName: "",
    gender: "",
    hospital_university: "",
    number_of_real_procedures_performed: "",
    number_of_simulated_procedures_performed: "",
    specialty_interventional_cardiology_years_experience: "",
    specialty_interventional_neuroradiology_years_experience: "",
    specialty_interventional_radiology_years_experience: "",
    specialty_other: "",
    specialty_other_years_experience: "",
    specialty_vascular_surgery_years_experience: "",
    years_as_first_operator: "",
    years_as_second_operator: "",
    secondary_email: "",
    phone_no: "",
    logbook: ""
  });

  const {
    data: userData,
    error: userErrors,
    loading: userLoading,
  }: QueryResult<{
    user: User;
  }> = useQuery(
    gql`
      query GetUser($emailAddress: String!) {
        user(emailAddress: $emailAddress) {
          keycloak_id
          email
          first_name
          last_name
          gender
          hospital_university
          number_of_real_procedures_performed
          number_of_simulated_procedures_performed
          specialty_interventional_cardiology_years_experience
          specialty_interventional_neuroradiology_years_experience
          specialty_interventional_radiology_years_experience
          specialty_other
          specialty_other_years_experience
          specialty_vascular_surgery_years_experience
          years_as_first_operator
          years_as_second_operator
          secondary_email
          phone_no
          logbook
        }
      }
    `,
    {
      variables: { emailAddress: user?.email ?? "" },
      onCompleted: (data) => {
        fillUserInputValues(data);
      },
    }
  );

  const fillUserInputValues = (data: { user: User }) => {
    setUserInputValues({
      keycloak_id: data.user.keycloak_id,
      email: data.user.email,
      firstName: data.user.first_name,
      lastName: data.user.last_name,
      gender: data.user.gender,
      hospital_university: data.user.hospital_university,
      number_of_real_procedures_performed:
        data.user.number_of_real_procedures_performed,
      number_of_simulated_procedures_performed:
        data.user.number_of_simulated_procedures_performed,
      specialty_interventional_cardiology_years_experience:
        data.user.specialty_interventional_cardiology_years_experience,
      specialty_interventional_neuroradiology_years_experience:
        data.user.specialty_interventional_neuroradiology_years_experience,
      specialty_interventional_radiology_years_experience:
        data.user.specialty_interventional_radiology_years_experience,
      specialty_other: data.user.specialty_other,
      specialty_other_years_experience:
        data.user.specialty_other_years_experience,
      specialty_vascular_surgery_years_experience:
        data.user.specialty_vascular_surgery_years_experience,
      years_as_first_operator: data.user.years_as_first_operator,
      years_as_second_operator: data.user.years_as_second_operator,
      secondary_email: data.user.secondary_email,
      phone_no: data.user.phone_no,
      logbook: data.user.logbook
    });
  };

  const [updateUserData, { loading: userUpdateLoading }] = useMutation(
    gql`
      mutation UpdateUser($user: UserInput!, $emailAddress: String!) {
        updateUser(user: $user, emailAddress: $emailAddress) {
          refetch {
            user(emailAddress: $emailAddress) {
              keycloak_id
              email
              first_name
              last_name
              gender
              hospital_university
              number_of_real_procedures_performed
              number_of_simulated_procedures_performed
              specialty_interventional_cardiology_years_experience
              specialty_interventional_neuroradiology_years_experience
              specialty_interventional_radiology_years_experience
              specialty_other
              specialty_other_years_experience
              specialty_vascular_surgery_years_experience
              years_as_first_operator
              years_as_second_operator
              secondary_email
              phone_no
              logbook
            }
          }
        }
      }
    `,
    {
      variables: { user: userInputValues, emailAddress: user?.email ?? "" },
      onCompleted: () => {
        createToast({
          title: "Successfully updated your profile settings",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError: (err) => {
        console.log(JSON.stringify(err));
        createToast({
          title: "Something went wrong saving your settings. Try again later",
          type: ToastType.ERROR,
          format: ToastFormat.TOAST,
        });
      },
    }
  );

  const positiveNumber = (value: number): string =>
    value ? Math.abs(value).toString() : "";

  return (
    <>
      <SidebarCardSection maxColumns={1}>
        {userLoading && <LoadingIndicator />}
        {userErrors && <Text>{userErrors.message}</Text>}
        {userData && userInputValues && (
          <Formik
            initialValues={{
              email: userData?.user.email ?? "",
              firstName: userData.user.first_name,
              lastName: userData.user.last_name,
              secondary_email: userData.user.secondary_email ?? ""
            }}
            onSubmit={(_, { setSubmitting }) => {
              updateUserData();
              setTimeout(() => {
                setSubmitting(false);
              }, 400);
            }}
            validate={(values) => {
              const errors: FormikErrors<{
                [field: string]: string;
              }> = {};
              if (!values.firstName) {
                errors.firstName = "Required";
              }
              if (!values.lastName) {
                errors.lastName = "Required";
              }
              if (!values.email || !/.+@.+/.test(values.email)) {
                errors.email = "Invalid email address"
              }
              if (values.secondary_email && !/.+@.+/.test(values.secondary_email)) {
                errors.secondary_email = "Invalid email address"
              }
              return errors;
            }}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                <H3 style={{ marginBottom: "20px" }}>General Detail</H3>
                <GridRow>
                  <GridCol sidebarCard>
                    <TextField
                      id="cy-account-email"
                      type="text"
                      name="email"
                      label="Email"
                      error={
                        touched?.email &&
                        errors?.email
                      }
                      value={userInputValues.email}
                      onBlur={(e: FormEvent<HTMLInputElement>) => {
                        setFieldTouched && setFieldTouched("email", true);
                        handleChange(e);
                      }}
                      onChange={(e) =>
                        setUserInputValues({
                          ...userInputValues,
                          email: e.currentTarget.value,
                        })
                      }
                    />
                    <TextField
                      id="cy-account-first-name"
                      type="text"
                      name="firstName"
                      label="First name *"
                      error={
                        touched?.firstName &&
                        !userInputValues.firstName &&
                        errors?.firstName
                      }
                      value={userInputValues.firstName ?? "Placeholder text"}
                      onBlur={(e: FormEvent<HTMLInputElement>) => {
                        setFieldTouched && setFieldTouched("firstName", true);
                        handleChange(e);
                      }}
                      onChange={(e) =>
                        setUserInputValues({
                          ...userInputValues,
                          firstName: e.currentTarget.value,
                        })
                      }
                    />
                    <TextField
                      type="text"
                      name="lastName"
                      label="Last name *"
                      id="cy-account-last-name"
                      value={userInputValues.lastName ?? "Placeholder text"}
                      onBlur={(e: FormEvent<HTMLInputElement>) => {
                        setFieldTouched && setFieldTouched("lastName", true);
                        handleChange(e);
                      }}
                      error={
                        touched?.lastName &&
                        !userInputValues.lastName &&
                        errors?.lastName
                      }
                      onChange={(e) =>
                        setUserInputValues({
                          ...userInputValues,
                          lastName: e.currentTarget.value,
                        })
                      }
                    />
                    <SelectField
                      id="cy-account-gender"
                      label="Gender"
                      name="gender"
                      searchable
                      value={{
                        value: userInputValues.gender,
                        label: GenderOptionsMap[userInputValues.gender ?? ""],
                      }}
                      onChange={(option: Option) =>
                        setUserInputValues({
                          ...userInputValues,
                          gender: option.value,
                        })
                      }
                      options={Object.entries(GenderOptionsMap).map(
                        ([key, value]) => ({
                          value: key,
                          label: value,
                        })
                      )}
                    />
                    <TextField
                      type="text"
                      id="cy-account-hospital"
                      name="hospital"
                      label="Hospital"
                      placeholder={
                        userInputValues.hospital_university
                          ? undefined
                          : "Example: General Hospital"
                      }
                      value={userInputValues.hospital_university ?? undefined}
                      onBlur={handleChange}
                      onChange={(e) =>
                        setUserInputValues({
                          ...userInputValues,
                          hospital_university: e.currentTarget.value,
                        })
                      }
                    />
                    <LabelText disabled={false}>Cell phone (optional for SMS Notifications)</LabelText>
                    <PhoneInput
                      placeholder="Example: +1 555 123456"
                      value={userInputValues.phone_no ?? undefined}
                      onChange={(e) =>
                        setUserInputValues({
                          ...userInputValues,
                          phone_no: e,
                        })}
                      containerStyle={PhoneInputStyles}
                      inputStyle={{borderRadius:"4px", width:"100%", height: "40px"}}
                      masks={{se:'.. ... .. ..'}}
                    />
                    <br/>
                    <TextField
                      type="text"
                      id="cy-account-secondary-email"
                      name="secondary_email"
                      label="Secondary email"
                      error={
                        errors?.secondary_email
                      }
                      placeholder={
                        userInputValues.secondary_email
                          ? undefined
                          : "Secondary email (optional)"
                      }
                      value={userInputValues.secondary_email ?? undefined}
                      onBlur={handleChange}
                      onChange={(e) =>
                        setUserInputValues({
                          ...userInputValues,
                          secondary_email: e.currentTarget.value,
                        })
                      }
                    />
                  </GridCol>
                  <GridCol>
                  <Checkbox
                      testId="cy-account-use-logbook"
                      title="Use Logbook"
                      checkboxChecked={userInputValues.logbook === "true"}
                      onCheckboxClick={(e) =>
                        setUserInputValues({
                          ...userInputValues,
                          logbook: e.currentTarget.checked ? "true" : "false",
                        })
                      }
                    />
                  </GridCol>
                </GridRow>
                <SectionHeader>Experience</SectionHeader>
                <SectionTwoColInput>
                  <GridRow>
                    <GridCol sidebarCard>
                      <TextField
                        type="number"
                        min="0"
                        name="specialty_interventional_radiology_years_experience"
                        label="Interventional Radiology"
                        value={
                          userInputValues.specialty_interventional_radiology_years_experience ??
                          undefined
                        }
                        placeholder={
                          userInputValues.specialty_interventional_radiology_years_experience
                            ? undefined
                            : "0"
                        }
                        onBlur={handleChange}
                        onChange={(e) =>
                          setUserInputValues({
                            ...userInputValues,
                            specialty_interventional_radiology_years_experience:
                              positiveNumber(e.currentTarget.value),
                          })
                        }
                      />
                      <TextField
                        type="number"
                        min="0"
                        name="specialty_interventional_neuroradiology_years_experience"
                        label="Interventional Neurology"
                        value={
                          userInputValues.specialty_interventional_neuroradiology_years_experience ??
                          undefined
                        }
                        placeholder={
                          userInputValues.specialty_interventional_neuroradiology_years_experience
                            ? undefined
                            : "0"
                        }
                        onBlur={handleChange}
                        onChange={(e) =>
                          setUserInputValues({
                            ...userInputValues,
                            specialty_interventional_neuroradiology_years_experience:
                              positiveNumber(e.currentTarget.value),
                          })
                        }
                      />
                      <TextField
                        type="text"
                        id="cy-account-specialty-other"
                        name="specialty_other"
                        label="Other experience"
                        placeholder={
                          userInputValues.specialty_other
                            ? undefined
                            : "Example: Paramedic"
                        }
                        value={userInputValues.specialty_other ?? undefined}
                        onBlur={handleChange}
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                          setUserInputValues({
                            ...userInputValues,
                            specialty_other: e.currentTarget.value,
                          })
                        }
                      />
                    </GridCol>
                    <GridCol sidebarCard>
                      <TextField
                        type="number"
                        min="0"
                        name="specialty_interventional_cardiology_years_experience"
                        label="Interventional Cardiology"
                        value={
                          userInputValues.specialty_interventional_cardiology_years_experience ??
                          undefined
                        }
                        placeholder={
                          userInputValues.specialty_interventional_cardiology_years_experience
                            ? undefined
                            : "0"
                        }
                        onBlur={handleChange}
                        onChange={(e) =>
                          setUserInputValues({
                            ...userInputValues,
                            specialty_interventional_cardiology_years_experience:
                              positiveNumber(e.currentTarget.value),
                          })
                        }
                      />
                      <TextField
                        type="number"
                        min="0"
                        name="specialty_vascular_surgery_years_experience"
                        label="Vascular Surgery"
                        value={
                          userInputValues.specialty_vascular_surgery_years_experience ??
                          undefined
                        }
                        placeholder={
                          userInputValues.specialty_vascular_surgery_years_experience
                            ? undefined
                            : "0"
                        }
                        onBlur={handleChange}
                        onChange={(e) =>
                          setUserInputValues({
                            ...userInputValues,
                            specialty_vascular_surgery_years_experience:
                              positiveNumber(e.currentTarget.value),
                          })
                        }
                      />
                      <TextField
                        type="number"
                        min="0"
                        id="cy-account-specialty-other-years-experience"
                        name="specialty_other_years_experience"
                        label="Years of other experience"
                        value={
                          userInputValues.specialty_other_years_experience ??
                          undefined
                        }
                        placeholder={
                          userInputValues.specialty_other_years_experience
                            ? undefined
                            : "0"
                        }
                        onBlur={handleChange}
                        onChange={(e) =>
                          setUserInputValues({
                            ...userInputValues,
                            specialty_other_years_experience: positiveNumber(
                              e.currentTarget.value
                            ),
                          })
                        }
                      />
                    </GridCol>
                  </GridRow>
                </SectionTwoColInput>
                <SectionHeader>Other information</SectionHeader>
                <SectionTwoColInput>
                  <GridRow>
                    <GridCol sidebarCard>
                      <TextField
                        type="number"
                        min="0"
                        name="years_as_first_operator"
                        label="Years as first operator"
                        value={
                          userInputValues.years_as_first_operator ?? undefined
                        }
                        placeholder={
                          userInputValues.years_as_first_operator
                            ? undefined
                            : "0"
                        }
                        onBlur={handleChange}
                        onChange={(e) =>
                          setUserInputValues({
                            ...userInputValues,
                            years_as_first_operator: positiveNumber(
                              e.currentTarget.value
                            ),
                          })
                        }
                      />
                      <TextField
                        type="number"
                        min="0"
                        name="number_of_real_procedures_performed"
                        label="Number of real procedures performed"
                        value={
                          userInputValues.number_of_real_procedures_performed ??
                          undefined
                        }
                        placeholder={
                          userInputValues.number_of_real_procedures_performed
                            ? undefined
                            : "0"
                        }
                        onBlur={handleChange}
                        onChange={(e) =>
                          setUserInputValues({
                            ...userInputValues,
                            number_of_real_procedures_performed: positiveNumber(
                              e.currentTarget.value
                            ),
                          })
                        }
                      />
                    </GridCol>
                    <GridCol sidebarCard>
                      <TextField
                        type="number"
                        min="0"
                        name="years_as_second_operator"
                        label="Years as second operator"
                        value={
                          userInputValues.years_as_second_operator ?? undefined
                        }
                        placeholder={
                          userInputValues.years_as_second_operator
                            ? undefined
                            : "0"
                        }
                        onBlur={handleChange}
                        onChange={(e) =>
                          setUserInputValues({
                            ...userInputValues,
                            years_as_second_operator: positiveNumber(
                              e.currentTarget.value
                            ),
                          })
                        }
                      />

                      <TextField
                        type="number"
                        min="0"
                        name="number_of_simulated_procedures_performed"
                        label="No. of simulated procedures performed"
                        value={
                          userInputValues.number_of_simulated_procedures_performed ??
                          undefined
                        }
                        placeholder={
                          userInputValues.number_of_simulated_procedures_performed
                            ? undefined
                            : "0"
                        }
                        onBlur={handleChange}
                        onChange={(e) =>
                          setUserInputValues({
                            ...userInputValues,
                            number_of_simulated_procedures_performed:
                              positiveNumber(e.currentTarget.value),
                          })
                        }
                      />
                    </GridCol>
                  </GridRow>
                </SectionTwoColInput>
                <GridRow>
                  <SectionButtonWrapper>
                    <Button
                      size={ButtonSizes.Medium}
                      id="cy-account-cancel"
                      type={ButtonTypes.Button}
                      disabled={isSubmitting}
                      secondary
                      onClick={() => fillUserInputValues(userData)}
                    >
                      Cancel
                    </Button>
                    <Button
                      size={ButtonSizes.Medium}
                      id="cy-account-submit"
                      type={ButtonTypes.Submit}
                      disabled={isSubmitting}
                      onClick={() => {
                        Object.keys(errors).length !== 0 &&
                          createToast({
                            title:
                              "Some input values are not valid. Please fill in all required fields correctly.",
                            type: ToastType.ERROR,
                            format: ToastFormat.TOAST,
                          });
                      }}
                    >
                      {renderLoadingText(userUpdateLoading, "Save")}
                    </Button>
                  </SectionButtonWrapper>
                </GridRow>
              </form>
            )}
          </Formik>
        )}
      </SidebarCardSection>
    </>
  );
};
export default Account;