import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

interface ProgressCircleProps {
  progress: number;
  color: string;
}

export const ProgressCircle: React.FC<ProgressCircleProps> = ({
  progress,
  color,
}) => {
  const theme = useContext(ThemeContext);
  const progressFraction = progress / 100;
  const circumference = 32;

  return (
    <svg
      id="svg"
      width="80"
      height="80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ overflow: 'inherit' }}
    >
      <text
        x="50%"
        y="50%"
        style={{ letterSpacing: "0.4", fontFamily: "Muli" }}
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="16"
        fill={theme.colors.darkGrey}
        fontWeight="700"
      >
        {progress}%
      </text>
      {/* Full circle as background */}
      <circle
        r={circumference}
        cx="40"
        cy="40"
        transform="rotate(-90, 40, 40)"
        fill="transparent"
        stroke={theme.colors.paleGrey}
        strokeWidth="4"
        strokeDasharray={2 * Math.PI * circumference}
      />
      {/* Progress circle */}
      {progressFraction && (
        <circle
          r={circumference}
          cx="40"
          cy="40"
          transform="rotate(-90, 40, 40)"
          fill="transparent"
          stroke={color}
          strokeWidth="4"
          strokeDasharray={2 * Math.PI * circumference}
          strokeDashoffset={
            (1 - progressFraction) * 2 * Math.PI * circumference
          } // 1 - Progress * Circumference
        />
      )}
    </svg>
  );
};
