import { Route, Switch } from "react-router-dom";
import React from "react";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import ActivityLogPage from "./index";
import VistActivityPage from "./vist-activity";

function ActivityLogRouter() {
  return (
    <Switch>
      <Route exact path="/activity-log">
        <ActivityLogPage>
          <Breadcrumbs
            crumbs={[
              {
                path: "/",
                name: "Home",
              },
              {
                path: "/activity-log",
                name: "Activity Log",
              },
            ]}
          />
        </ActivityLogPage>
      </Route>
      <Route exact path="/activity-log/vist-activity/:id">
        <VistActivityPage>
          <Breadcrumbs
            crumbs={[
              {
                path: "/",
                name: "Home",
              },
              {
                path: "/activity-log",
                name: "Activity Log",
              },
              {
                path: "/activity-log/vist-activity/:id",
                name: "Vist Activity",
              },
            ]}
          />
        </VistActivityPage>
      </Route>
    </Switch>
  );
}

export default ActivityLogRouter;
