import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { TalentCourseCategory } from "../../shared/enums";
import { Card } from "../shared/Card";
import { H4, Span } from "../shared/typography";
import { CourseInfo, getIconURL, TextContainer } from "./CourseCard";

const TalentCourseListingCard = styled(Card)`
  display: flex;
  align-items: center;
  position: relative;
  transition: none;
  @media screen and (min-width: ${(props) => props.theme.sizes.breakpointS}) {
    padding: ${(props) => props.theme.sizes.spacing3};
  }
  height: auto;
  margin-top: ${(p) => p.theme.sizes.spacing3};
`;

const TalentIcon = styled.img`
  width: ${(props) => props.theme.sizes.spacing5};
  height: ${(props) => props.theme.sizes.spacing5};
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    margin-right: 12px;
    width: ${(props) => props.theme.sizes.spacing4};
    height: ${(props) => props.theme.sizes.spacing4};
    position: absolute;
    left: 20px;
    top: 20px;
  }
`;

interface CourseListingCardProps {
  title: string;
  category: TalentCourseCategory;
  code?: string;
}

const CourseListingCard: React.FC<CourseListingCardProps> = ({
  category,
  code,
  title,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <TalentCourseListingCard className="cy-talent-course-listing-card">
      <TalentIcon src={getIconURL(category)} />
      <TextContainer>
        <H4>{title}</H4>
        {code && (
          <CourseInfo progress={false}>
            <Span style={{ color: theme.colors.grey }}>{code}</Span>
          </CourseInfo>
        )}
      </TextContainer>
    </TalentCourseListingCard>
  );
};

export default CourseListingCard;
