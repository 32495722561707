import React from "react";
import { PageLayout } from "../Page/PageLayout";
import Breadcrumbs from "../shared/Breadcrumbs";
import CloudCases from "./CloudCases";

const CloudCasesPage: React.FC = () => {
  return (
    <PageLayout title="Mentice Live | Cloud Cases">
      <Breadcrumbs
        crumbs={[
          {
            path: "/",
            name: "Home",
          },
          {
            path: "/cloud-cases",
            name: "Cloud Cases",
          },
        ]}
      />
      <CloudCases showActionBar={true} />
    </PageLayout>
  );
};

export default CloudCasesPage;
