import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ArrowRight from "../icons/ArrowRight";
import { Span } from "./typography";

const BreadcrumbList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${(props) =>
    `${props.theme.sizes.spacing5} 0 ${props.theme.sizes.spacing1} 0`};
  font-family: ${(props) => props.theme.fonts.heading};
  color: ${(props) => props.theme.colors.midGrey};
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  a {
    color: ${(props) => props.theme.colors.midGrey};
  }
`;

const HoverableLink = styled(Link)`
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const ActiveText = styled(Span)`
  color: ${(props) => props.theme.colors.darkDarkGrey};
`;

const Breadcrumbs = ({
  crumbs,
}: {
  crumbs: (
    | {
        path: string;
        name: string;
      }
    | {
        path: string;
        name: string;
      }
  )[];
}) => {
  if (crumbs.length <= 1) {
    return null;
  }
  return (
    <BreadcrumbList>
      {crumbs.map(
        ({ name, path }: { name: string; path: string }, key: number) =>
          key + 1 === crumbs.length ? (
            <ActiveText key={key}>{name}</ActiveText>
          ) : (
            <React.Fragment key={key}>
              <HoverableLink to={path}>{name}</HoverableLink>
              <ArrowRight size="small" />
            </React.Fragment>
          )
      )}
    </BreadcrumbList>
  );
};
export default Breadcrumbs;
