import React from "react";
import styled from "styled-components";
import { Card } from "./Card";
import { CloseButton, Modal } from "./Modal";
import { H3, largeTextStyle } from "./typography";

const ModalCard = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 32px;
  cursor: default;
  width: 576px;
  @media screen and (max-width: 576px) {
    width: 90vw;
    padding: 32px;
    min-width: 320px;
  }
`;

const ModalTitle = styled(H3)`
  color: ${(props) => props.theme.colors.darkDarkGrey};
  text-align: left;
  padding: 8px;
`;

const ModalContent = styled.div`
  ${largeTextStyle}
  color: ${(props) => props.theme.colors.darkDarkGrey};
  text-align: left;
  padding: 20px 8px 40px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  button {
    margin: 0 8px;
    pointer-events: auto;
    @media screen and (max-width: 576px) {
      width: 100%;
      margin: 8px 0;
    }
  }
  @media screen and (max-width: 576px) {
    flex-wrap: wrap;
    padding: 0 8px;
  }
`;

export const DialogCloseButton = styled(CloseButton)`
  top: 32px;
  right: 32px;
`;

export const ButtonCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.sizes.spacing2};
`;

interface DialogProps {
  children: React.ReactNode;
  title?: string;
  buttons?: React.ReactNode;
  onClose: () => void;
}

const Dialog: React.FC<DialogProps> = ({
  children,
  title,
  buttons,
  onClose,
}) => {
  return (
    <Modal>
      <ModalCard id="cy-modal-card">
        <DialogCloseButton onClick={onClose} />
        {title && <ModalTitle>{title}</ModalTitle>}
        <ModalContent>{children}</ModalContent>
        {buttons && <ButtonWrapper>{buttons}</ButtonWrapper>}
      </ModalCard>
    </Modal>
  );
};

export default Dialog;
