import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PageLayout } from "../../components/Page/PageLayout";
import { Section } from "../../components/Page/Section";
import { Card } from "../../components/shared/Card";
import { Formik, FormikErrors, FormikValues } from "formik";
import {
  ActivityType,
  ProcedureActivity,
  InterventionType,
  CustomerGroup,
  Hospital,
  Attending,
  SortOrder,
  GroupRole,
  Participant,
} from "../../generated/graphql";
import { H3, StyledLink, Text } from "../../components/shared/typography";
import {
  gql,
  QueryResult,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import GeneralDetailSection from "./GeneralDetailSection";
import ProcedureDetailSection from "./ProcedureDetailSection";
import ClinicalDetailSection from "./ClinicalDetailSection";
import Button from "../../components/shared/Button";
import { useHistory, useParams } from "react-router-dom";
import { ButtonTypes, ToastFormat, ToastType } from "../../shared/enums";
import { KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import { useKeycloak } from "@react-keycloak/web";
import { useToast } from "../../lib/useToast";
import { generateUUID } from "../../lib/generateUUID";
import { Option } from "react-select/src/filters";
import LoadingIndicator, {
  renderLoadingText,
} from "../../components/shared/LoadingIndicator";
import { getLogbookStringFromDate } from "../../lib/formatDateAndTime";
import { User } from "../../components/Groups/Group";
import { getFullName, getUniqueCustomerIdsFromCustomerGroups } from "./helpers";

export interface DetailSectionProps {
  values: FormValues;
  setFieldValue: (
    name: string,
    option: string | undefined | User | boolean
  ) => void;
  setFieldTouched?: (name: string, touched: boolean) => void;
  handleChange: (e: React.ChangeEvent<any>) => React.ChangeEvent<any> | void;
  errors?: FormikErrors<FormikValues>;
  touched?: any;
  interventionTypes?: InterventionType[];
  hospitals?: Hospital[];
  attending?: Attending[];
  customerGroups?: CustomerGroup[];
  onCustomerChange?: (option: Option) => void;
  customerError?: "Required" | false;
  customerIds?: (number | undefined)[];
  id?: string;
}

interface FormValues extends ProcedureActivity {
  attendingId: string;
  attendingName: string | null | undefined;
}

const FormSectionHeading = styled(H3)`
  padding: 34px 34px 14px;
`;

const FormCard = styled(Card)`
  padding: 30px 30px 34px;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    padding: 0;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  padding: 48px 34px 24px;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  button:first-child {
    margin-right: ${(props) => props.theme.sizes.spacing3};
  }
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    button:first-child {
      margin-bottom: ${(props) => props.theme.sizes.spacing3};
      margin-right: 0;
    }
    & * {
      width: 100%;
    }
  }
`;

const RecordPage: React.FC = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const [, createToast] = useToast();
  const [toastError, setToastError] = useState(false);
  const [customerError, setCustomerError] = useState<
    undefined | "Required" | false
  >(undefined);
  const [customerIds, setCustomerIds] = useState<
    (number | undefined)[] | undefined
  >(undefined);
  const [selectedCustomerId, setSelectedCustomerId] = useState<
    number | undefined
  >(undefined);
  const history = useHistory();

  const { data: formData } = useQuery(
    gql`
      query FetchFormData {
        IRLogFormData {
          interventionTypes {
            name
            organSystems {
              name
              categories {
                name
                subCategories {
                  name
                  subCategory
                  code
                }
              }
            }
          }
          attending {
            id
            firstName
            lastName
            customerName
            customerId
          }
          hospitals {
            id
            name
            customerName
            customerId
          }
        }
      }
    `
  );

  const {
    data: customerGroupsData,
  }: QueryResult<{
    customerGroups: CustomerGroup[];
  }> = useQuery(
    gql`
      query FetchCustomerGroups($roles: [GroupRole!]) {
        customerGroups(roles: $roles) {
          id
          name
          customer {
            id
            name
          }
          members {
            first_name
            last_name
            email
            keycloak_id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setCustomerIds(
          getUniqueCustomerIdsFromCustomerGroups(data.customerGroups)
        );
      },
      variables: {
        roles: [GroupRole.Learner, GroupRole.Admin],
      },
    }
  );

  const [
    fetchRecord,
    { data: recordData, loading: loadingRecordData, error: recordError },
  ] = useLazyQuery<{ activity: ProcedureActivity }>(
    gql`
      query FetchRecord($id: ID!) {
        activity(id: $id) {
          dateOfProcedure
          groupId
          optionalIdentifier
          hospital
          sex
          ageCategory
          setting
          attending {
            firstName
            lastName
            id
          }
          participants {
            user {
              first_name
              last_name
              keycloak_id
            }
            name
            role
            level
          }
          procedure {
            name
            interventionType
            organSystem
            category
            subCategory
            code
          }
          procedureIndication
          procedureComments
          modalityPrimary
          modalitySecondary
          doseTime
          doseCtdi
          doseDlp
          doseDap
          doseSkin
          onCall
          success
          successComments
          dictated
          complications
          complicationsComments
          ebl
          sedation
          platelet
          inr
          inrReversal
          closureDevice
          closureDeviceComments
          imageFollowup
          imageFollowupNotes
          consultFollowup
          consultFollowupNotes
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      variables: {
        id: id,
      },
    }
  );

  const [
    saveActivity,
    {
      loading: loadingSaveActivity,
      data: mutationResponse,
      error: mutationError,
    },
  ] = useMutation(
    gql`
      mutation SaveActivity(
        $activity: ProcedureActivityInput!
        $customerId: Int
        $attendingName: String
        $searchParams: ProcedureActivitySearchParams!
      ) {
        saveActivity(
          activity: $activity
          customerId: $customerId
          attendingName: $attendingName
        ) {
          refetch {
            activities(searchParams: $searchParams) {
              totalCount
              activities {
                id
                dateOfProcedure
                optionalIdentifier
                hospital
                attending {
                  firstName
                  lastName
                  id
                }
                participants {
                  user {
                    first_name
                    last_name
                  }
                  name
                }
                procedure {
                  name
                }
              }
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    if (toastError) {
      createToast({
        title:
          "Some input values are not valid. Please fill in all required fields correctly.",
        type: ToastType.ERROR,
        format: ToastFormat.BANNER,
      });
      setToastError(false);
    }
  }, [toastError, createToast]);

  useEffect(() => {
    if (mutationResponse) {
      createToast({
        title: "Record successfully submitted.",
        type: ToastType.SUCCESS,
        format: ToastFormat.TOAST,
      });
      if (id) {
        // If ID then user was editing existing record -> navigate back to all records.
        // (This was MyIRLog's behaviour)
        history.push(`/logbook/records`);
      } else {
        // If no ID then user was creating new record -> navigate to new empty record.
        // Set a small timeout to show "record successfully submitted" toast.
        // Use history.go(0) because:
        // - history.push(`/logbook/records/create`) does not reload page (as we're already on that route)
        // - window.location.reload() is not recommended
        // - manually resetting the component state seems bug-prone (easy to forget or do wrong)
        window.setTimeout(() => history.go(0), 1200);
      }
    }
    if (mutationError) {
      createToast({
        title: "Record submission failed. Try again later",
        type: ToastType.ERROR,
        format: ToastFormat.TOAST,
      });
    }
  }, [createToast, mutationError, mutationResponse, history, id]);

  useEffect(() => {
    if (id) {
      fetchRecord();
    }
  }, [id, fetchRecord]);

  const handleCustomerChange = (option: any) => {
    if (option?.value) {
      setSelectedCustomerId(Number(option?.value?.split("-")[1]));
      setCustomerError(false);
    } else if (!option?.value && customerIds && customerIds?.length > 1) {
      setCustomerError("Required");
    }
  };

  return (
    <PageLayout title="Mentice Live | Logbook Record">
      {/* Breadcrumbs */}
      {children}
      <Section
        title={id ? "View Existing Record" : "Enter New Record"}
        maxColumns={1}
      >
        <FormCard>
          {loadingRecordData && <LoadingIndicator />}
          {recordError && <Text>{recordError.message}</Text>}
          {(recordData || !id) && (
            <Formik
              initialValues={
                recordData
                  ? {
                      id: id,
                      groupId: recordData.activity.groupId,
                      type: recordData.activity.type,
                      dateOfProcedure: recordData.activity.dateOfProcedure,
                      optionalIdentifier:
                        recordData.activity.optionalIdentifier,
                      hospital: recordData.activity.hospital,
                      ageCategory: recordData.activity.ageCategory,
                      sex: recordData.activity.sex,
                      setting: recordData.activity.setting,
                      attendingName:
                        recordData.activity.attending &&
                        getFullName(
                          recordData.activity.attending.lastName,
                          recordData.activity.attending.firstName
                        ),
                      attendingId:
                        recordData.activity.attending &&
                        recordData.activity.attending.id,
                      participants: recordData.activity.participants,
                      procedureComments: recordData.activity.procedureComments,
                      modalityPrimary: recordData.activity.modalityPrimary,
                      modalitySecondary: recordData.activity.modalitySecondary,
                      onCall: recordData.activity.onCall,
                      success: recordData.activity.success,
                      successComments: recordData.activity.successComments,
                      procedure: recordData.activity.procedure,
                      doseTime: recordData.activity.doseTime,
                      doseCtdi: recordData.activity.doseCtdi,
                      doseDlp: recordData.activity.doseDlp,
                      doseSkin: recordData.activity.doseSkin,
                      doseDap: recordData.activity.doseDap,
                      procedureIndication:
                        recordData.activity.procedureIndication,
                      dictated: recordData.activity.dictated,
                      complications: recordData.activity.complications,
                      complicationsComments:
                        recordData.activity.complicationsComments,
                      ebl: recordData.activity.ebl,
                      sedation: recordData.activity.sedation,
                      platelet: recordData.activity.platelet,
                      inr: recordData.activity.inr,
                      inrReversal: recordData.activity.inrReversal,
                      closureDevice: recordData.activity.closureDevice,
                      closureDeviceComments:
                        recordData.activity.closureDeviceComments,
                      imageFollowup: recordData.activity.imageFollowup,
                      imageFollowupNotes:
                        recordData.activity.imageFollowupNotes,
                      consultFollowup: recordData.activity.consultFollowup,
                      consultFollowupNotes:
                        recordData.activity.consultFollowupNotes,
                    }
                  : {
                      id: generateUUID(),
                      groupId: undefined,
                      type: ActivityType.Irlog,
                      dateOfProcedure: new Date(),
                      optionalIdentifier: "",
                      procedure: {
                        name: "",
                        code: "",
                        interventionType: "",
                        organSystem: "",
                        category: "",
                        subCategory: "",
                      },
                      hospital: "",
                      ageCategory: undefined,
                      sex: undefined,
                      setting: undefined,
                      attendingName: "",
                      attendingId: undefined,
                      participants: [
                        {
                          user: {
                            first_name: user?.given_name ?? "",
                            last_name: user?.family_name ?? "",
                            keycloak_id: user?.sub ?? "",
                            email: user?.email ?? "",
                          },
                          role: undefined,
                          level: undefined,
                        },
                      ],
                      procedureComments: "",
                      modalityPrimary: undefined,
                      modalitySecondary: undefined,
                      onCall: undefined,
                      success: undefined,
                      successComments: "",
                      doseTime: undefined,
                      doseCtdi: undefined,
                      doseDlp: undefined,
                      doseSkin: undefined,
                      doseDap: undefined,
                      procedureIndication: "",
                      dictated: undefined,
                      complications: undefined,
                      complicationsComments: "",
                      ebl: undefined,
                      sedation: undefined,
                      platelet: undefined,
                      inr: undefined,
                      inrReversal: undefined,
                      closureDevice: undefined,
                      closureDeviceComments: "",
                      imageFollowup: undefined,
                      imageFollowupNotes: "",
                      consultFollowup: undefined,
                      consultFollowupNotes: "",
                    }
              }
              validate={(values) => {
                const errors: FormikErrors<{
                  [field: string]: string;
                }> = {};
                if (!values.dateOfProcedure) {
                  errors.dateOfProcedure = "Required";
                }
                if (!values.attendingName) {
                  errors.attendingName = "Required";
                }
                if (
                  values &&
                  values.attendingName &&
                  !values.attendingName.includes(",")
                ) {
                  errors.attendingName =
                    "Separate first and last name with comma";
                }
                if (!values.hospital) {
                  errors.hospital = "Required";
                }
                if (!values?.procedure?.interventionType) {
                  errors.interventionType = "Required";
                }
                if (!values.procedureIndication) {
                  errors.procedureIndication = "Required";
                }
                if (
                  values.doseTime &&
                  !/^(\d*|\d+\.\d{0,3}|\d*\.\d{1,3})$/.test(
                    values.doseTime.toString()
                  )
                ) {
                  errors.doseTime = "Value should be a number";
                }
                if (
                  values.doseCtdi &&
                  !/^(\d*|\d+\.\d{0,3}|\d*\.\d{1,3})$/.test(
                    values.doseCtdi.toString()
                  )
                ) {
                  errors.doseCtdi = "Value should be a number";
                }
                if (
                  values.doseDlp &&
                  !/^(\d*|\d+\.\d{0,3}|\d*\.\d{1,3})$/.test(
                    values.doseDlp.toString()
                  )
                ) {
                  errors.doseDlp = "Value should be a number";
                }
                // if creating new record for user belonging to multiple groups and no customer group is selected
                if (
                  !id &&
                  (!customerIds || customerIds.length > 1) &&
                  !selectedCustomerId
                ) {
                  setCustomerError("Required");
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                if (
                  !id &&
                  (!customerIds || customerIds.length > 1) &&
                  !selectedCustomerId
                ) {
                  setCustomerError("Required");
                  setToastError(true);
                } else {
                  saveActivity({
                    variables: {
                      customerId: selectedCustomerId,
                      attendingName:
                        values.attendingId === values.attendingName
                          ? values.attendingName
                          : undefined,
                      activity: {
                        ...values,
                        attendingName: undefined,
                        attendingId:
                          values.attendingId === values.attendingName
                            ? undefined
                            : values.attendingId,
                        id: values.id && values.id.toString(),
                        groupId: Number(values.groupId),
                        type: undefined,
                        participants: Object.values({
                          ...values?.participants
                            // filter out invalid participants
                            ?.filter(
                              (value: Participant) => value.user || value.name
                            )
                            .map((item: FormikValues) => {
                              return {
                                role: item?.role,
                                level: item?.level,
                                userId: item?.user?.keycloak_id,
                                name: item?.name,
                              };
                            }),
                        }),
                        modalityPrimary: values.modalityPrimary?.replace(
                          / /g,
                          ""
                        ),
                        modalitySecondary: values.modalitySecondary?.replace(
                          / /g,
                          ""
                        ),
                        doseTime:
                          values.doseTime && parseFloat(`${values.doseTime}`),
                        doseSkin:
                          values.doseSkin && parseFloat(`${values.doseSkin}`),
                        doseCtdi:
                          values.doseCtdi && parseFloat(`${values.doseCtdi}`),
                        doseDlp:
                          values.doseDlp && parseFloat(`${values.doseDlp}`),
                        doseDap:
                          values.doseDap && parseFloat(`${values.doseDap}`),
                        procedure: {
                          ...values.procedure,
                          ...{
                            interventionType: undefined,
                            organSystem: undefined,
                            category: undefined,
                            subCategory: undefined,
                            name: undefined,
                            __typename: undefined,
                          },
                        },
                        dateOfProcedure: getLogbookStringFromDate(
                          values.dateOfProcedure
                        ),
                        imageFollowup:
                          values.imageFollowup &&
                          getLogbookStringFromDate(values.imageFollowup),
                        consultFollowup:
                          values.consultFollowup &&
                          getLogbookStringFromDate(values.consultFollowup),
                      },
                      searchParams: {
                        limit: 1000,
                        sortOrder: SortOrder.Desc,
                      },
                    },
                  });
                }
                setTimeout(() => {
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                touched,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <FormSectionHeading>General Detail</FormSectionHeading>
                  <GeneralDetailSection
                    customerIds={customerIds}
                    customerError={customerError}
                    onCustomerChange={(option: any) => {
                      setFieldTouched("groupId", true);
                      if (option.value) {
                        setFieldValue("groupId", option.value.split("-")[0]);
                        handleCustomerChange(option);
                      }
                    }}
                    customerGroups={
                      customerGroupsData && customerGroupsData.customerGroups
                    }
                    handleChange={handleChange}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    errors={errors}
                    touched={touched}
                    attending={formData && formData.IRLogFormData.attending}
                    hospitals={formData && formData.IRLogFormData.hospitals}
                    id={id ? id : undefined}
                  />
                  <FormSectionHeading>Procedure Detail</FormSectionHeading>
                  <ProcedureDetailSection
                    handleChange={handleChange}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    errors={errors}
                    touched={touched}
                    interventionTypes={
                      formData && formData.IRLogFormData.interventionTypes
                    }
                  />
                  <ClinicalDetailSection
                    handleChange={handleChange}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <ButtonWrapper>
                    <StyledLink to="/logbook">
                      <Button disabled={isSubmitting} secondary>
                        Cancel
                      </Button>
                    </StyledLink>
                    <Button
                      id="cy-record-create-button"
                      type={ButtonTypes.Submit}
                      disabled={isSubmitting}
                      onClick={() => {
                        setToastError(Object.keys(errors).length !== 0);
                      }}
                    >
                      {renderLoadingText(loadingSaveActivity, "Submit Log")}
                    </Button>
                  </ButtonWrapper>
                </StyledForm>
              )}
            </Formik>
          )}
        </FormCard>
      </Section>
    </PageLayout>
  );
};

export default RecordPage;
