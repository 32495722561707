import { useParams } from "react-router-dom";
import React, { useState, useReducer } from "react";
import { PageLayout } from "../../components/Page/PageLayout";
import { Section } from "../../components/Page/Section";
import {
  ButtonSizes,
  TalentCourseCategory,
  ToastFormat,
  ToastType,
} from "../../shared/enums";
import { h4Style, H3, H4, Bold } from "../../components/shared/typography";
import { gql, QueryResult, useMutation, useQuery } from "@apollo/client";
import { CustomerGroup, TalentCourse } from "../../generated/graphql";
import Dialog, { ButtonCol } from "../../components/shared/Dialog";
import { Text } from "../../components/shared/typography";
import { copyToClipboard } from "../../lib/copyToClipboard";
import CopyField from "../../components/shared/CopyField";
import styled from "styled-components";
import { useToast } from "../../lib/useToast";
import SidebarCard from "../../components/shared/sidebar-card/SidebarCard";
import CourseCard from "../../components/TalentCourses/CourseCard";
import { ContextMenu } from "../../components/shared/context-menu/ContextMenu";
import { MenuItem } from "../../components/shared/context-menu/MenuItem";
import Button from "../../components/shared/Button";
import SelectAll from "../../components/icons/SelectAll";
import RadioButton from "../../components/shared/RadioButton";
import { renderLoadingText } from "../../components/shared/LoadingIndicator";
import LoadingIndicator from "../../components/shared/LoadingIndicator";
import AccordionIcon from "../../components/icons/AccordionIcon";

const DialogText = styled.div`
  margin-bottom: 40px;
`;

const DialogContent = styled.div`
  margin-bottom: -32px;
`;

const AssignedIcon = styled.img`
  width: 24px;
  height: 24px;
  vertical-align: bottom;
  margin-right: 4px;
`;

const AssignedLabel = styled.span`
  ${h4Style}
  font-size: 14px;
  font-weight: normal;
  color: ${(props) => props.theme.colors.success300};
  margin-right: ${(props) => props.theme.sizes.spacing2};
  flex-shrink: 0;
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointL}) {
    display: none;
  }
`;

const ActionContainer = styled.div`
  margin-top: ${(props) => props.theme.sizes.spacing4};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

// In order for the transform & animation to be applied to the > caret,
// it needs to be wrapped in a flex parent. Unclear why.
const AccordionClickableTitle = styled.span`
  display: flex;
`;

const ButtonSubcontainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: ${(props) => props.theme.sizes.spacing1};
`;

const LoadingButtonWrapper = styled.div`
  & > button {
    width: 144px;
    padding: ${(props) =>
      `${props.theme.sizes.spacing0_5} ${props.theme.sizes.spacing2}`};
  }
`;
interface CoursesState {
  selectedAssigned: number[];
  selectedAvailable: number[];
}
interface ReducerAction {
  type:
    | "clickAssigned"
    | "clickAllAssigned"
    | "clickAvailable"
    | "clickAllAvailable"
    | "clearSelection";
  payload?: number;
}

export const AccordionSection = ({
  children,
  title,
  buttons,
  startsCollapsed = false,
}: {
  children: React.ReactNode;
  title: string;
  buttons: React.ReactNode[];
  startsCollapsed?: boolean;
}) => {
  const [isCollapsed, setIsCollaped] = useState<boolean>(startsCollapsed);
  return (
    <>
      <ActionContainer>
        <AccordionClickableTitle onClick={() => setIsCollaped(!isCollapsed)}>
          <AccordionIcon isExpanded={!isCollapsed} />
          <H4>{title}</H4>
        </AccordionClickableTitle>
        <ButtonSubcontainer>{buttons}</ButtonSubcontainer>
      </ActionContainer>
      {!isCollapsed && children}
    </>
  );
};

const GroupCoursesPage: React.FC = ({ children }) => {
  let {
    customerGroupId,
    customerGroupName,
  }: { customerGroupId: number | string; customerGroupName: string } =
    useParams<{ customerGroupId: string; customerGroupName: string }>();
  customerGroupId = parseInt(customerGroupId ?? "");

  const today = new Date();
  const days = 30;
  const endDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + days
  );
  const maxUses = 20;

  const [, createToast] = useToast();

  const {
    data: coursesData,
    error: coursesError,
    loading: coursesLoading,
  }: QueryResult<{
    getCustomerGroup: CustomerGroup;
  }> = useQuery(
    gql`
      query GetCustomerGroup($customerGroupId: Int!) {
        getCustomerGroup(customerGroupId: $customerGroupId) {
          courses {
            course {
              id
              name
              category
              code
            }
            assigned
          }
        }
      }
    `,
    {
      variables: {
        customerGroupId,
      },
    }
  );

  const assignedCourses =
    coursesData?.getCustomerGroup?.courses?.filter(
      (course) => course.assigned
    ) ?? [];
  const availableCourses =
    coursesData?.getCustomerGroup?.courses?.filter(
      (course) => !course.assigned
    ) ?? [];

  const [
    setCustomerGroupCourseAccess,
    { loading: loadingSetCustomerGroupAccess },
  ] = useMutation(
    gql`
      mutation CustomerGroupCourseAccess(
        $customerGroupId: Int!
        $courseIds: [Int!]!
        $fullRemoval: Boolean!
      ) {
        customerGroupCourseAccess(
          customerGroupId: $customerGroupId
          courseIds: $courseIds
          fullRemoval: $fullRemoval
        ) {
          refetch {
            getCustomerGroup(customerGroupId: $customerGroupId) {
              courses {
                course {
                  id
                  name
                  category
                  code
                }
                assigned
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        createToast({
          title: "Successfully updated courses",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
        dispatch({ type: "clearSelection" });
        setUnassigningCourses(undefined);
        setFullRemoval(false);
        setAssigningCourse(false);
      },
      onError: () => {
        createToast({
          title: "Something went wrong when updating courses",
          type: ToastType.ERROR,
          format: ToastFormat.TOAST,
        });
        dispatch({ type: "clearSelection" });
      },
    }
  );

  const [showInviteLinkModalForCourse, setShowInviteLinkModalForCourse] =
    useState<TalentCourse | undefined>(undefined);

  const [fetchVoucherURL, { data: voucherData, error: voucherError }] =
    useMutation(
      gql`
        mutation CreateCourseVoucher($voucherInput: VoucherInput!) {
          createCourseVoucher(voucher: $voucherInput) {
            code
          }
        }
      `
    );

  const voucherCodeURL = `${window.location.origin}/?voucherCode=${voucherData?.createCourseVoucher?.code}`;
  const handleSendCourseInvite = (course: TalentCourse) => {
    setShowInviteLinkModalForCourse(course);
    fetchVoucherURL({
      variables: {
        voucherInput: {
          customerGroupId,
          talentCourseId: course.id,
          maxUses,
          endDate,
        },
      },
    });
  };

  const coursesReducer = (
    coursesState: CoursesState,
    action: ReducerAction
  ): CoursesState => {
    switch (action.type) {
      case "clickAssigned":
        if (!action.payload) {
          throw Error("No course chosen");
        }
        if (coursesState.selectedAssigned.includes(action.payload)) {
          // Select assigned course
          return {
            ...coursesState,
            selectedAssigned: coursesState.selectedAssigned.filter(
              (selectedAssignedId) => selectedAssignedId !== action.payload
            ),
          };
        } else {
          // Unselect assigned course
          return {
            ...coursesState,
            selectedAssigned: [
              action.payload,
              ...coursesState.selectedAssigned,
            ],
          };
        }
      case "clickAllAssigned":
        if (
          coursesState.selectedAssigned.length <
          (coursesData?.getCustomerGroup?.courses?.length ?? 0)
        ) {
          // Select all
          return {
            ...coursesState,
            selectedAssigned: [
              ...(coursesData?.getCustomerGroup?.courses ?? []),
            ].map((course) => course.course.id),
          };
        } else {
          // Unselect all
          return {
            ...coursesState,
            selectedAssigned: [],
          };
        }
      case "clickAvailable":
        if (!action.payload) {
          throw Error("No course chosen");
        }
        if (coursesState.selectedAvailable.includes(action.payload)) {
          // Remove
          return {
            ...coursesState,
            selectedAvailable: coursesState.selectedAvailable.filter(
              (selectedAvailableId) => selectedAvailableId !== action.payload
            ),
          };
        } else {
          // Add
          return {
            ...coursesState,
            selectedAvailable: [
              action.payload,
              ...coursesState.selectedAvailable,
            ],
          };
        }
      case "clickAllAvailable":
        if (
          coursesState.selectedAvailable.length <
          (coursesData?.getCustomerGroup?.courses?.length ?? 0)
        ) {
          // Select all
          return {
            ...coursesState,
            selectedAvailable: [
              ...(coursesData?.getCustomerGroup?.courses ?? []),
            ].map((course) => course.course.id),
          };
        } else {
          // Unselect all
          return {
            ...coursesState,
            selectedAvailable: [],
          };
        }
      case "clearSelection":
        return {
          ...coursesState,
          selectedAvailable: [],
          selectedAssigned: [],
        };
    }
  };

  const [coursesState, dispatch] = useReducer(coursesReducer, {
    selectedAssigned: [],
    selectedAvailable: [],
  });

  const sidebarList = [<div key={"courses"}>Courses</div>];

  let errorMessage: string = "";
  if (!customerGroupName) {
    errorMessage = "could not determine group name.";
  } else if (!customerGroupId) {
    errorMessage = "could not determine group ID.";
  } else if (coursesError) {
    errorMessage = coursesError.message;
  }

  const [unassigningCourses, setUnassigningCourses] = useState<
    undefined | number[]
  >(undefined);
  const [assigningCourse, setAssigningCourse] = useState(false);
  // Full removal limits access to the course for existing users
  // And not only for new users joining the group
  const [fullRemoval, setFullRemoval] = useState(false);

  const unassignCourses = (selectedAssigned: number[]) => {
    const courseIds = assignedCourses
      .map(({ course }) => course.id)
      .filter(
        (courseToSetAsAssignedId) =>
          !selectedAssigned.includes(courseToSetAsAssignedId)
      );

    setCustomerGroupCourseAccess({
      variables: {
        fullRemoval,
        customerGroupId,
        courseIds,
      },
    });
  };

  const assignCourses = (selectedAvailable: number[]) => {
    setAssigningCourse(true);
    const courseIds = [
      ...assignedCourses.map(({ course }) => course.id),
      ...selectedAvailable,
    ];

    setCustomerGroupCourseAccess({
      variables: {
        fullRemoval: false,
        customerGroupId,
        courseIds,
      },
    });
  };

  return (
    <PageLayout title="Mentice Live | Group Admin">
      {/* Breadcrumbs */}
      {children}
      {showInviteLinkModalForCourse && voucherData && !voucherError && (
        <Dialog
          title="Send Course Invite"
          onClose={() => setShowInviteLinkModalForCourse(undefined)}
        >
          <DialogContent>
            <DialogText>
              {`Invite users from ${customerGroupName} to the course 
                  ${showInviteLinkModalForCourse.name} by sharing this
                  link with them. This invite can be used by a maximum
                  of ${maxUses} users within the next ${days} days.`}
            </DialogText>
            <CopyField
              value={voucherCodeURL}
              onCopyClick={async () =>
                voucherCodeURL && (await copyToClipboard(voucherCodeURL))
                  ? createToast({
                      title: "Invite link copied to clipboard",
                      type: ToastType.SUCCESS,
                      format: ToastFormat.TOAST,
                    })
                  : createToast({
                      title:
                        "Failed to copy invite link, please try again later",
                      type: ToastType.ERROR,
                      format: ToastFormat.TOAST,
                    })
              }
            />
          </DialogContent>
        </Dialog>
      )}
      <Section title={`${customerGroupName} — Group Admin`} maxColumns={1}>
        <SidebarCard sidebarList={sidebarList}>
          <H3>Assign Courses to {customerGroupName}</H3>
          {errorMessage ? (
            <Text>An error occured loading group courses: {errorMessage}</Text>
          ) : (
            <>
              <AccordionSection
                title={"Assigned Courses"}
                buttons={[
                  <Button
                    icon={<SelectAll />}
                    size={ButtonSizes.Small}
                    secondary
                    key="SelectAllAssignedCourses"
                    onClick={() => dispatch({ type: "clickAllAssigned" })}
                  />,
                  <LoadingButtonWrapper key="UnassignCourses">
                    <Button
                      size={ButtonSizes.Small}
                      disabled={
                        coursesState.selectedAssigned.length === 0 ||
                        loadingSetCustomerGroupAccess
                      }
                      onClick={() => {
                        setUnassigningCourses(coursesState.selectedAssigned);
                      }}
                    >
                      {renderLoadingText(
                        loadingSetCustomerGroupAccess && !assigningCourse,
                        "Unassign"
                      )}
                    </Button>
                  </LoadingButtonWrapper>,
                ]}
              >
                {coursesLoading ? (
                  <LoadingIndicator />
                ) : (
                  <Section title="" maxColumns={1}>
                    {assignedCourses.map(({ course }) => (
                      <CourseCard
                        key={course.id}
                        category={course.category as TalentCourseCategory}
                        code={course.code}
                        title={course.name}
                        checkboxChecked={coursesState.selectedAssigned.includes(
                          course.id
                        )}
                        onCheckboxClick={() =>
                          dispatch({
                            type: "clickAssigned",
                            payload: course.id,
                          })
                        }
                      >
                        <AssignedLabel>
                          <AssignedIcon src="/static/assets/successIcon.svg" />
                          Assigned
                        </AssignedLabel>
                        <ContextMenu>
                          <MenuItem
                            onClick={() => setUnassigningCourses([course.id])}
                          >
                            Unassign Course
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleSendCourseInvite(course)}
                          >
                            Send Course Invite
                          </MenuItem>
                        </ContextMenu>
                      </CourseCard>
                    ))}
                  </Section>
                )}
              </AccordionSection>
              <AccordionSection
                title={"Available Courses"}
                buttons={[
                  <Button
                    icon={<SelectAll />}
                    size={ButtonSizes.Small}
                    secondary
                    key="SelectAllAvailableCourses"
                    onClick={() => dispatch({ type: "clickAllAvailable" })}
                  />,
                  <LoadingButtonWrapper key="AssignCourses">
                    <Button
                      size={ButtonSizes.Small}
                      disabled={
                        coursesState.selectedAvailable.length === 0 ||
                        loadingSetCustomerGroupAccess
                      }
                      onClick={() =>
                        assignCourses(coursesState.selectedAvailable)
                      }
                    >
                      {renderLoadingText(
                        loadingSetCustomerGroupAccess && assigningCourse,
                        "Assign"
                      )}
                    </Button>
                  </LoadingButtonWrapper>,
                ]}
              >
                {coursesLoading ? (
                  <LoadingIndicator />
                ) : (
                  <Section title="" maxColumns={1}>
                    {availableCourses.map(({ course }) => (
                      <CourseCard
                        key={course.id}
                        category={course.category as TalentCourseCategory}
                        code={course.code}
                        title={course.name}
                        checkboxChecked={coursesState.selectedAvailable.includes(
                          course.id
                        )}
                        onCheckboxClick={() =>
                          dispatch({
                            type: "clickAvailable",
                            payload: course.id,
                          })
                        }
                      >
                        <ContextMenu>
                          <MenuItem onClick={() => assignCourses([course.id])}>
                            Assign Course
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleSendCourseInvite(course)}
                          >
                            Send Course Invite
                          </MenuItem>
                        </ContextMenu>
                      </CourseCard>
                    ))}
                    {unassigningCourses && (
                      <Dialog
                        title={"Unassign Course"}
                        onClose={() => setUnassigningCourses(undefined)}
                        buttons={
                          <>
                            <Button
                              secondary
                              size={ButtonSizes.Medium}
                              onClick={() => setUnassigningCourses(undefined)}
                            >
                              Cancel
                            </Button>
                            <Button
                              size={ButtonSizes.Medium}
                              onClick={() => {
                                unassignCourses(unassigningCourses);
                              }}
                            >
                              {renderLoadingText(
                                loadingSetCustomerGroupAccess,
                                "Save"
                              )}
                            </Button>
                          </>
                        }
                      >
                        When unassigning a course from {customerGroupName}, you
                        can choose to limit access for new members only
                        (existing members already enrolled can continue using
                        the course) or for all.
                        <ButtonCol>
                          <RadioButton
                            name="new"
                            value="new"
                            checked={!fullRemoval}
                            onChange={() => setFullRemoval(false)}
                            label={
                              <>
                                Limit access for
                                <Bold> new members only</Bold>
                              </>
                            }
                          />
                          <RadioButton
                            name="all"
                            value="all"
                            checked={fullRemoval}
                            onChange={() => setFullRemoval(true)}
                            label={
                              <>
                                Limit access for
                                <Bold> all</Bold>
                              </>
                            }
                          />
                        </ButtonCol>
                      </Dialog>
                    )}
                  </Section>
                )}
              </AccordionSection>
            </>
          )}
        </SidebarCard>
      </Section>
    </PageLayout>
  );
};

export default GroupCoursesPage;
