import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import Dialog from "../../components/shared/Dialog";
import LoadingIndicator, {
  renderLoadingText,
} from "../../components/shared/LoadingIndicator";
import SelectField from "../../components/shared/SelectField";
import TextField from "../../components/shared/TextField";
import {
  EventResourceAccessVoucher,
  CreateEventResourceAccessVoucherInput,
  Customer,
  TalentBranch,
  CustomerLearningSpaceAccess,
  CloudModuleInput,
  EditEventResourceAccessVoucherInput,
} from "../../generated/graphql";
import {
  CustomerOption,
  HorizontalRow,
  LearningSpaceOption,
} from "./CreateOrEditCloudModulesResourceAccessVoucherDialog";
import { customerFields } from "./Customers";
import { Text } from "../../components/shared/typography";
import { useToast } from "../../lib/useToast";
import { ToastFormat, ToastType } from "../../shared/enums";
import DatePickerField from "../../components/shared/DatePickerField";
import { eventResourceAccessVouchersFields } from "../../types/fragments/EventResourceAccessVoucherFragment";

interface Props {
  onClose: () => void;
  editingEvent?: EventResourceAccessVoucher;
}

const CreateOrEditEventResourceAccessVoucherDialog: React.FC<Props> = ({
  editingEvent,
  onClose,
}) => {
  const [, createToast] = useToast();
  const [inputErrors, setInputErrors] = useState<inputError[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<
    Customer | undefined
  >(editingEvent ? editingEvent.customer : undefined);
  const [selectedLearningSpace, setSelectedLearningSpace] = useState<
    TalentBranch | undefined
  >(editingEvent ? editingEvent.learningSpace : undefined);
  const [availableCloudModuleInputs, setAvailableCloudModuleInputs] = useState<
    CloudModuleInput[]
  >([]);
  const [inputName, setInputName] = useState<string | undefined>(
    editingEvent ? editingEvent.name : undefined
  );
  const [inputDescription, setInputDescription] = useState<string | undefined>(
    editingEvent ? editingEvent.description : undefined
  );
  const [selectedEventAt, setSelectedEventAt] = useState<Date | undefined>(
    editingEvent ? new Date(editingEvent.eventAt) : undefined
  );
  const [selectedDurationInHours, setSelectedDurationInHours] =
    useState<number>(
      editingEvent ? Math.floor(editingEvent.durationInSeconds / 3600) : 1
    );
  const [selectedMaxUses, setSelectedMaxUses] = useState<number | undefined>(
    editingEvent && editingEvent.maxUses ? editingEvent.maxUses : undefined
  );

  const {
    loading: customersLoading,
    data: customersData,
    error: customersErrors,
  } = useQuery<{
    customers: Customer[];
  }>(
    gql`
      query Customers {
        customers {
          ...CustomerFields
        }
      }
      ${customerFields}
    `
  );

  // Will default to all available modules and cases where user has access...
  // ... so unless used for showing which cases will be available for event, this could be moved to resourceService...
  // ... as soon as customerLearningSpaceAccess is not used to limit access for some users during development
  const [
    fetchCustomerLearningSpaceAccess,
    { error: customerLearningSpaceAccessErrors },
  ] = useLazyQuery<{
    customerLearningSpaceAccess: CustomerLearningSpaceAccess;
  }>(
    gql`
      query CustomerLearningSpaceAccess(
        $customerId: Int!
        $learningSpaceId: String!
      ) {
        customerLearningSpaceAccess(
          customerId: $customerId
          learningSpaceId: $learningSpaceId
        ) {
          cloudModules {
            moduleId
            name
            cases {
              caseId
              name
            }
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        const newSelectedCloudModuleInputs: CloudModuleInput[] =
          data.customerLearningSpaceAccess.cloudModules.map((cloudModule) => {
            const cases = cloudModule.cases.map(
              (cloudCase) => cloudCase.caseId
            );
            return {
              moduleId: cloudModule.moduleId,
              caseIds: cases,
            };
          });
        setAvailableCloudModuleInputs(newSelectedCloudModuleInputs);
      },
    }
  );

  const [createEventVoucher, { loading: loadingCreateEventVoucher }] =
    useMutation<{
      createEventResourceAccessVoucher: CreateEventResourceAccessVoucherInput;
    }>(
      gql`
        mutation CreateEventResourceAccessVoucher(
          $createEventResourceAccessVoucherInput: CreateEventResourceAccessVoucherInput!
        ) {
          createEventResourceAccessVoucher(
            createEventResourceAccessVoucherInput: $createEventResourceAccessVoucherInput
          ) {
            refetch {
              getEventResourceAccessVouchersAsAdmin {
                ...EventResourceAccessVouchersFields
              }
            }
          }
        }
        ${eventResourceAccessVouchersFields}
      `,
      {
        onCompleted: () => {
          createToast({
            title: "Created new event",
            type: ToastType.SUCCESS,
            format: ToastFormat.TOAST,
          });
          onClose();
        },
        onError: (e) => {
          console.log(e.graphQLErrors[0].message);
          if (
            e.graphQLErrors[0].message ===
            "Too many events scheduled for this time."
          ) {
            createToast({
              title:
                "There are already too many events scheduled at this time. Please select a different time.",
              type: ToastType.ERROR,
              format: ToastFormat.TOAST,
            });
          } else if (
            e.graphQLErrors[0].message ===
            "Too little spendable time in cloud cases time quota."
          ) {
            createToast({
              title:
                "Too little spendable time in cloud cases time quota to create event.",
              type: ToastType.ERROR,
              format: ToastFormat.TOAST,
            });
          } else {
            createToast({
              title: "Something went wrong creating the event",
              type: ToastType.ERROR,
              format: ToastFormat.TOAST,
            });
          }
        },
      }
    );

  const [editEventVoucher, { loading: loadingEditEventVoucher }] = useMutation<{
    editEventResourceAccessVoucher: EditEventResourceAccessVoucherInput;
  }>(
    gql`
      mutation EditResourceAccessVoucher(
        $editEventResourceAccessVoucherInput: EditEventResourceAccessVoucherInput!
      ) {
        editEventResourceAccessVoucher(
          editEventResourceAccessVoucherInput: $editEventResourceAccessVoucherInput
        ) {
          refetch {
            getEventResourceAccessVouchersAsAdmin {
              ...EventResourceAccessVouchersFields
            }
          }
        }
      }
      ${eventResourceAccessVouchersFields}
    `,
    {
      onCompleted: () => {
        createToast({
          title: "Successfully updated event",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
        onClose();
      },
      onError: (e) => {
        console.log(e);
        if (
          e.graphQLErrors[0].message ===
          "Too many events scheduled for this time."
        ) {
          createToast({
            title:
              "There are already too many events scheduled at this time. Please select a different time.",
            type: ToastType.ERROR,
            format: ToastFormat.TOAST,
          });
        } else {
          createToast({
            title: "Something went wrong creating the event",
            type: ToastType.ERROR,
            format: ToastFormat.TOAST,
          });
        }
      },
    }
  );

  useEffect(() => {
    // Choose one and only customer if only one available
    if (
      !editingEvent &&
      customersData?.customers &&
      customersData?.customers?.length === 1
    ) {
      setSelectedCustomer(customersData?.customers[0]);
    }
  }, [customersData, setSelectedCustomer, editingEvent]);

  useEffect(() => {
    if (selectedCustomer && selectedLearningSpace) {
      fetchCustomerLearningSpaceAccess({
        variables: {
          customerId: selectedCustomer.id,
          learningSpaceId: selectedLearningSpace.id.toString(),
        },
      });
    } else {
      setAvailableCloudModuleInputs([]);
    }
  }, [
    selectedCustomer,
    selectedLearningSpace,
    fetchCustomerLearningSpaceAccess,
    setAvailableCloudModuleInputs,
  ]);

  type inputError =
    | "customer"
    | "learningSpace"
    | "description"
    | "name"
    | "modules"
    | "eventAt"
    | "maxUses"
    | "durationInHours";

  return (
    <Dialog
      onClose={() => onClose()}
      title={editingEvent ? "Edit Event" : "Create Event"}
      buttons={
        <>
          <Button secondary onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            id="cy-create-group-dialog-button"
            onClick={() => {
              const newInputErrors: inputError[] = [];
              if (!selectedCustomer) {
                newInputErrors.push("customer");
              }
              if (!selectedLearningSpace) {
                newInputErrors.push("learningSpace");
              }
              if (!inputDescription) {
                newInputErrors.push("description");
              }
              if (!inputName) {
                newInputErrors.push("name");
              }
              if (!selectedEventAt) {
                newInputErrors.push("eventAt");
              }
              if (!selectedMaxUses) {
                newInputErrors.push("maxUses");
              }
              if (!selectedDurationInHours) {
                newInputErrors.push("durationInHours");
              }
              if (
                !(
                  availableCloudModuleInputs.length > 0 &&
                  availableCloudModuleInputs[0].caseIds.length > 0
                )
              ) {
                newInputErrors.push("modules");
                createToast({
                  title:
                    "Selected customer and learning space has no available modules and cases.",
                  type: ToastType.ERROR,
                  format: ToastFormat.TOAST,
                });
              }
              if (
                selectedCustomer &&
                selectedLearningSpace &&
                inputName &&
                inputDescription &&
                availableCloudModuleInputs &&
                selectedEventAt &&
                selectedDurationInHours &&
                selectedMaxUses &&
                availableCloudModuleInputs.length > 0 &&
                availableCloudModuleInputs[0].caseIds.length > 0
              ) {
                setInputErrors([]);
                if (editingEvent) {
                  editEventVoucher({
                    variables: {
                      editEventResourceAccessVoucherInput: {
                        voucherId: editingEvent.id,
                        name: inputName,
                        description: inputDescription,
                        eventAt: selectedEventAt,
                        durationInSeconds: selectedDurationInHours * 60 * 60,
                      },
                    },
                  });
                } else {
                  createEventVoucher({
                    variables: {
                      createEventResourceAccessVoucherInput: {
                        name: inputName,
                        description: inputDescription,
                        customerId: selectedCustomer?.id,
                        learningSpaceId: selectedLearningSpace?.id,
                        modules: availableCloudModuleInputs,
                        eventAt: selectedEventAt,
                        durationInSeconds: selectedDurationInHours * 60 * 60,
                        maxUses: selectedMaxUses,
                      },
                    },
                  });
                }
              } else {
                setInputErrors([...inputErrors, ...newInputErrors]);
              }
            }}
          >
            {editingEvent
              ? renderLoadingText(
                  loadingEditEventVoucher,
                  "Save Event",
                  "Saving Event"
                )
              : renderLoadingText(
                  loadingCreateEventVoucher,
                  "Create Event",
                  "Creating Event"
                )}
          </Button>
        </>
      }
    >
      {!customersLoading && !customersErrors && customersData?.customers && (
        <>
          {!editingEvent && customersData.customers.length > 1 && (
            <SelectField
              label={"Customer"}
              onChange={(option: CustomerOption) => {
                setSelectedCustomer(option.data.customer);
                setSelectedLearningSpace(undefined);
                setInputErrors(
                  [...inputErrors].filter(
                    (inputError) => inputError !== "customer"
                  )
                );
              }}
              placeholder={"Select customer"}
              name="customer"
              options={customersData.customers.map((customer) => ({
                value: customer.id,
                label: customer.name,
                data: {
                  customer,
                },
              }))}
              error={
                inputErrors.includes("customer")
                  ? "Select a customer"
                  : undefined
              }
              value={
                selectedCustomer
                  ? {
                      value: selectedCustomer?.id,
                      label: selectedCustomer?.name,
                      data: {
                        customer: selectedCustomer,
                      },
                    }
                  : null
              }
            />
          )}
          {!editingEvent && (
            <SelectField
              label={"Learning Space"}
              onChange={(option: LearningSpaceOption) => {
                setSelectedLearningSpace(option.data.branch);
                setInputErrors(
                  [...inputErrors].filter(
                    (inputError) => inputError !== "learningSpace"
                  )
                );
              }}
              placeholder={"Select learning space"}
              name="customer"
              isDisabled={selectedCustomer === undefined}
              value={
                selectedLearningSpace
                  ? {
                      value: selectedLearningSpace?.id,
                      label: selectedLearningSpace?.name,
                      data: { branch: selectedLearningSpace },
                    }
                  : null
              }
              options={selectedCustomer?.branches.map((branch) => ({
                value: branch.id,
                label: branch.name,
                data: {
                  branch,
                },
              }))}
              error={
                inputErrors.includes("learningSpace")
                  ? "Select a learning space"
                  : undefined
              }
            />
          )}
          <TextField
            label="Title"
            value={inputName}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setInputName(e.currentTarget.value);
              setInputErrors(
                [...inputErrors].filter((inputError) => inputError !== "name")
              );
            }}
            error={
              inputErrors.includes("name")
                ? "Give your event a title"
                : undefined
            }
          />
          <TextField
            label="Description"
            value={inputDescription}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setInputDescription(e.currentTarget.value);
              setInputErrors(
                [...inputErrors].filter(
                  (inputError) => inputError !== "description"
                )
              );
            }}
            error={
              inputErrors.includes("description")
                ? "Give your event a description"
                : undefined
            }
          />
          <DatePickerField
            showTimeSelect
            onChange={(date: Date) => {
              setSelectedEventAt(date);
              setInputErrors(
                [...inputErrors].filter(
                  (inputError) => inputError !== "eventAt"
                )
              );
            }}
            label="Date and time"
            value={selectedEventAt}
            error={
              inputErrors.includes("eventAt")
                ? "Select an event date and time"
                : undefined
            }
          />
          <HorizontalRow>
            {!editingEvent && (
              <div>
                <TextField
                  type="number"
                  min="1"
                  max="4"
                  name="durationInHours"
                  label="Duration (Hours)"
                  value={selectedDurationInHours ?? undefined}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setSelectedDurationInHours(Number(e.currentTarget.value));
                  }}
                />
              </div>
            )}
            {!editingEvent && (
              <div>
                <TextField
                  type="number"
                  name="maxUses"
                  label="Maximum Users"
                  min="1"
                  max="20"
                  value={selectedMaxUses ?? undefined}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setSelectedMaxUses(Number(e.currentTarget.value));
                    setInputErrors(
                      [...inputErrors].filter(
                        (inputError) => inputError !== "maxUses"
                      )
                    );
                  }}
                  error={
                    inputErrors.includes("maxUses")
                      ? "Set a max amount of users"
                      : undefined
                  }
                />
              </div>
            )}
          </HorizontalRow>
        </>
      )}
      {customersLoading && <LoadingIndicator />}
      {customersErrors &&
        customersErrors.graphQLErrors.map((error: { message: string }) => (
          <Text key={error.message}>{error.message}</Text>
        ))}
      {customerLearningSpaceAccessErrors &&
        customerLearningSpaceAccessErrors.graphQLErrors.map(
          (error: { message: string }) => (
            <Text key={error.message}>{error.message}</Text>
          )
        )}
    </Dialog>
  );
};

export default CreateOrEditEventResourceAccessVoucherDialog;
