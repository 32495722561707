import React from "react";
import type { RouteProps } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
  Switch,
  Redirect,
} from "react-router-dom";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import keycloak from "./keycloak";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import { theme } from "./lib/theme";
import TermsRouter from "./pages/terms/router";
import Home from "./Home";
import VideosPage from "./pages/videos";
import VistModulesRouter from "./pages/vist-modules/router";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import ProfilePage from "./pages/profile";
import UserCoursesPage from "./pages/user-courses";
import LogbookRouter from "./pages/logbook/router";
import Administration from "./pages/administration";
import ActivityLogRouter from "./pages/activity-log/router";
import GroupAdminRouter from "./pages/admin/router";
import LoadingIndicator from "./components/shared/LoadingIndicator";
import Toaster from "./components/shared/Toaster";
import LearningSpacesRouter from "./pages/learning-spaces/LearningSpacesRouter";
import CloudEvent from "./pages/cloud-event";
import EventsPage from "./components/Events/EventsPage";
import CloudCasesPage from "./components/Applications/CloudCasesPage";
import CloudCases from "./pages/cloud-cases";

const GlobalStyle = createGlobalStyle`
  html,
  body {
    min-height: 100vh;
    margin: 0;
  }

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  * {
    box-sizing: inherit;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  font-family: ${(props) => props.theme.fonts.body};
`;

const eventLogger = (event: unknown, error: unknown) => {
  // console.log("onKeycloakEvent", event, error);
};

const tokenLogger = (tokens: unknown) => {
  // console.log("onKeycloakTokens", tokens);
};

const GRAPHQL_URL =
  process.env.NODE_ENV === "development"
    ? `http://localhost:3001/api/graphql`
    : "/api/graphql";

const ApolloProviderWrapper = ({ children }: any) => {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingIndicator fullHeight />
      </ThemeProvider>
    );
  }

  const httpLink = new HttpLink({ uri: GRAPHQL_URL });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: "Bearer " + keycloak.token,
      },
    }));

    return forward(operation);
  });

  const client = new ApolloClient({
    // Useful to leave this here, uncomment to use Apollo Client Devtools:
    // connectToDevTools: true,
    uri: GRAPHQL_URL,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            talentCourse: {
              merge: false,
            },
            hospitals: {
              merge: false,
            },
            attendings: {
              merge: false,
            },
            IRLogFormData: {
              merge: false,
            },
          },
        },
        CustomerGroup: {
          fields: {
            members: {
              merge: false,
            },
          },
        },
      },
    }),
    link: from([authMiddleware, httpLink]),
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      <ApolloProviderWrapper>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Container>
            <Toaster />
            <RouterComponent />
          </Container>
        </ThemeProvider>
      </ApolloProviderWrapper>
    </ReactKeycloakProvider>
  );
}

function RouterComponent() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/terms">
            <TermsRouter />
          </Route>
          <PrivateRoute path="/administration" component={Administration} />
          <PrivateRoute path="/cloud-event/:voucherId" component={CloudEvent} />
          <PrivateRoute path="/cloud-case/" component={CloudCases} />
          <PrivateRoute path="/logbook" component={LogbookRouter} />
          <Redirect from="/myirlog" to="/logbook" />
          <Redirect from="/ir-log" to="/logbook" />
          <PrivateRoute path="/vist-modules" component={VistModulesRouter} />
          <PrivateRoute
            path="/learning-spaces"
            component={LearningSpacesRouter}
          />
          <PrivateRoute path="/videos" component={VideosPage} />
          <PrivateRoute path="/profile" component={ProfilePage} />
          <PrivateRoute path="/events" component={EventsPage} />
          <PrivateRoute path="/cloud-cases" component={CloudCasesPage} />
          <PrivateRoute path="/user-courses" component={UserCoursesPage} />
          <PrivateRoute path="/activity-log" component={ActivityLogRouter} />
          <PrivateRoute path="/admin" component={GroupAdminRouter} />
          <PrivateRoute path="/" component={Home} />
        </Switch>
      </div>
    </Router>
  );
}

interface PrivateRouteParams extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export function PrivateRoute({
  component: Component,
  ...rest
}: PrivateRouteParams) {
  const { keycloak, initialized } = useKeycloak();
  if (!initialized) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingIndicator fullHeight />
      </ThemeProvider>
    );
  }
  if (!keycloak?.authenticated) {
    keycloak.login();
    return (
      <ThemeProvider theme={theme}>
        <LoadingIndicator fullHeight />
      </ThemeProvider>
    );
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

export default App;
