import React, { useCallback, useState } from "react";
import { PageLayout } from "../../components/Page/PageLayout";
import { Section } from "../../components/Page/Section";
import {
  VistCourse as VistCourseEntity,
  Simulation as SimulationEntity,
  VistCourseSortField,
  SortOrder,
} from "../../generated/graphql";
import { getServiceTag } from "../../lib/getServiceTag";
import { useSimulator } from "../../lib/useSimulator";
import { Modal } from "../../components/shared/Modal";
import { ButtonSizes, VistCourseIcon } from "../../shared/enums";
import RunningVistCard from "../../components/VistCourses/RunningVistCard/RunningVistCard";
import { useParams } from "react-router";
import { gql, QueryResult, useQuery } from "@apollo/client";
import { VistCourseCard } from "../../components/VistCourses/VistCourse";
import { Option } from "react-select/src/filters";
import ActionBar, { SelectFieldWidthProvider } from "../../components/shared/ActionBar";
import TextField from "../../components/shared/TextField";
import { debounce } from "lodash";
import SelectField from "../../components/shared/SelectField";
import Button from "../../components/shared/Button";

interface SimulationsPageParams {
  courseCode: string;
  courseVersion: string;
}

const SimulationPage: React.FC = ({ children }) => {
  const { courseCode, courseVersion } = useParams<SimulationsPageParams>();
  const serviceTag = getServiceTag();
  const [simulatorReady, launchSimulator] = useSimulator();
  const [selectedSimulation, setSelectedSimulation] = useState<
    SimulationEntity | undefined
  >(undefined);

  const { data }: QueryResult<{ vistCourse: VistCourseEntity }> = useQuery(
    gql`
      query VistCourse($code: String!, $version: String!, $serviceTag: String!) {
        vistCourse(code: $code, version: $version, serviceTag: $serviceTag) {
          code
          description
          version
          resultFormatVersion
          name
          hasCaseCreator
          license {
            checked
            simulations {
              id
              valid
              expired
              remainingdays
            }
          }
          simulations {
            id
            code
            name
            description
            options
            isCaseCreator
          }
        }
      }
    `,
    {
      variables: {
        code: courseCode,
        version: courseVersion,
        serviceTag: serviceTag,
      },
    }
  );

  const sortOrderOptions: Option[] = [
    {
      value: "Case (A-Z)",
      label: "Case (A-Z)",
      data: {
        sortField: VistCourseSortField.Name,
        sortOrder: SortOrder.Asc,
      },
    },
    {
      value: "Case (Z-A)",
      label: "Case (Z-A)",
      data: {
        sortField: VistCourseSortField.Name,
        sortOrder: SortOrder.Desc,
      },
    },
    {
      value: "ID-asc",
      label: "ID 🠉",
      data: {
        sortField: VistCourseSortField.Date,
        sortOrder: SortOrder.Asc,
      },
    },
    {
      value: "ID-desc",
      label: "ID 🠋",
      data: {
        sortField: VistCourseSortField.Date,
        sortOrder: SortOrder.Desc,
      },
    },
  ];
  const [selectedSortOption, setSelectedSortOption] = useState<Option>(
    sortOrderOptions[2]
  );

  const [searchText, setSearchText] = useState("");

  const startCaseCreator = data?.vistCourse?.simulations.find(
    (simulation) => simulation.isCaseCreator
  );

  const request = debounce((value) => {
    setSearchText(value);
  }, 300);

  const debouceRequest = useCallback((value) => request(value), []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    debouceRequest(e.currentTarget.value);
  };

  const searchFilter = (c:SimulationEntity) => {
    return (c.name + '|' + c.description + '|' + c.code).toLowerCase().indexOf(searchText.toLowerCase()) >= 0
  }

  const licenseMap:any = {}
  for (let s of data?.vistCourse?.license?.simulations || [])
  {
    licenseMap[s.id] = {checked: data?.vistCourse.license?.checked, ...s}
  }

  return (
    <PageLayout title={"Mentice Live | " + data?.vistCourse.name || ""}>
      {children} {/* Breadcrumbs */}
      <ActionBar
        backTo="/vist-modules"
        title={data?.vistCourse?.name || "Module"}
        searchField={
          <TextField
            searchable
            onClearSearch={() => {
              setSearchText("");
            }}
            placeholder="Search by exercise name, id or description"
            onChange={onChange}
            id="cy-vist-search-field"
          />
        }
        button={
          startCaseCreator && simulatorReady && data
          ?
          <Button
            size={ButtonSizes.Medium}
            onClick={() => { launchSimulator(data.vistCourse, startCaseCreator); }}
          >
            Start Case Creator
          </Button>
          :
        <></>}
        buttonGroup={
          <>
            <SelectFieldWidthProvider>
              <SelectField
                name="Sort by"
                isActionBar={true}
                onChange={(option: Option) => setSelectedSortOption(option)}
                value={{
                  value: selectedSortOption.value,
                  label: `Sort: ${selectedSortOption.label}`,
                  data: {
                    sortField: selectedSortOption.data.sortField,
                    sortOrder: selectedSortOption.data.sortOrder,
                  },
                }}
                options={sortOrderOptions}
              />
            </SelectFieldWidthProvider>
          </>
        }
      />

      {selectedSimulation && data?.vistCourse && (
        <Modal big>
          <RunningVistCard
            simulation={selectedSimulation}
            vistCourse={data?.vistCourse}
            onClose={() => setSelectedSimulation(undefined)}
            license={licenseMap[selectedSimulation.code]}
          />
        </Modal>
      )}
      {data && (
        <Section
          maxColumns={4}
        >
          {data &&
            data.vistCourse.simulations &&
            [...data?.vistCourse?.simulations]
              .filter(searchFilter)
              .sort((a, b) =>
                (selectedSortOption.data.sortField === VistCourseSortField.Name ?
                  a.name.localeCompare(b.name, "en", { sensitivity: 'base' }) :
                  a.code.localeCompare(b.code, "en", { numeric: true })) *
                (selectedSortOption.data.sortOrder === SortOrder.Asc ? 1 : -1)
              )
              .map((simulation, ix) => {
                return (
                  !simulation.isCaseCreator && (
                    <VistCourseCard
                      title={simulation.name}
                      description={simulation.description}
                      vistCourseIcon={VistCourseIcon.Patient}
                      key={ix}
                      onClick={() => setSelectedSimulation(simulation)}
                      simulationId={simulation.code}
                      license={licenseMap[simulation.code]}
                    />
                  )
                );
              })}
        </Section>
      )}
    </PageLayout>
  );
};

export default SimulationPage;
