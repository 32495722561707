import React from "react";
import styled from "styled-components";

const Backdrop = styled.div`
  /* Cover entire screen */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;

  z-index: 100;

  /* Center the content */
  display: grid;
  justify-items: center;
  align-items: center;

  /* Blur the background */
  /* TODO: Not supported as of Chrome 68 which the Qt client currently runs */
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(213, 218, 222, 0.5);
`;

interface ModalProps {
  big?: boolean;
}

export const CloseButton = styled.button`
  cursor: pointer;
  z-index: 99;
  width: 32px;
  height: 32px;
  background: url("../../../static/assets/close.svg");
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0;
  margin: 0;
  border: none;
  position: absolute;
  top: 12px;
  right: 12px;
`;

const ModalContainer = styled.div<ModalProps>`
  display: ${(props) => (props.big ? "grid" : "flex")};
  width: ${(props) => (props.big ? "100%" : "auto")};
  padding: 24px;
  grid-row-gap: 8px;
  justify-items: center;
  max-width: ${(props) => (props.big ? `calc(1128px + 24px + 24px)` : "")};
`;

export const Modal: React.FC<ModalProps> = ({ big, children }) => {
  return (
    <Backdrop>
      <ModalContainer big={big || false}>{children}</ModalContainer>
    </Backdrop>
  );
};
