import { formatDateAndTime } from "./formatDateAndTime";

export const getVoucherCodeErrorMessage = (
  errorCode: string,
  oldVoucherExpireTime?: string
): string => {
  switch (errorCode) {
    case "GROUP_JOIN_ONCE":
      return "You have already joined this group";
    case "VOUCHER_NO_MORE_USES":
      return "The voucher has no more uses";
    case "VOUCHER_ALREADY_USED":
      return "You have already used this voucher";
    case "INVALID_VOUCHER":
      return "Invitation is invalid, try another link.";
    case "OLD_VOUCHER":
      return `The voucher has expired at ${formatDateAndTime(
        oldVoucherExpireTime || ""
      )}`;
    case "VOUCHER_EXPIRED":
      return "The voucher has expired";
    default:
      return "Something went wrong while accepting the voucher";
  }
};

export const getEventCodeErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case "INVITE_OLD":
      return "Event invitation has expired!";
    case "INVITE_INVALID":
      return "Event invitation is invalid, try another link.";
    default:
      return "Failed to accept event invitation, please try again later.";
  }
};
