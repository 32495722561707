import React from "react";
import { PageLayout } from "../../components/Page/PageLayout";
import { Card } from "../../components/shared/Card";
import { Section } from "../../components/Page/Section";
import { H4, Text } from "../../components/shared/typography";

// TODO: figure out neat way to reuse colors / components / typography,
// while at the same time having them wrapped in a pagelayout so they can be viewed individually
function MedicalDisclaimerPage() {
  return (
    <PageLayout title="Mentice Live | Medical Disclaimer" hideNavBar={true}>
      <Section title="Medical Disclaimer" maxColumns={1}>
        <Card>
          <H4>
            LEGAL DISCLAIMER ON USE OF MENTICE PRODUCTS, SOFTWARE AND EQUIPMENT
          </H4>

          <Text>
            Mentice AB (“Mentice,” “we,” “our,” or “us”) provides certain
            medical related software and hardware simulation solutions and
            systems (“Systems”). Our Systems are used by healthcare
            professionals as a training tool to acquire, retain, and enhance
            their procedural skills.
          </Text>
          <Text>
            By purchasing, licensing, viewing, using and/or accessing the
            System, you acknowledge and agree that the Systems provided by
            Mentice are (i) specific educational and medical training products
            and tools (ii) not certified as a medical device; (iii) not intended
            for any clinical or diagnostic use and (iv) intended to be used
            solely for non-commercial, medical training and performance
            improvement purposes. You further acknowledge and agree that at all
            times you will use and access the System (x) solely in connection
            with such medical training and performance improvement purposes;
            (xi) in compliance will all applicable laws and regulations; and
            (xii) in accordance with any user documentation, instruction
            manuals, guides and/or requirements we provide to you. You that at
            no time shall any System be used to (xii) diagnose, treat or cure a
            human being’s condition or in a life-saving situation; (xiii)
            support professional medical decisions, diagnosis or treatments, or
            (ix) replace any diagnosis, recommendation, advice, treatment or
            decision by an appropriately trained and licensed physician. USE OF
            THE SYSTEM WITH REAL SUBJECTS COULD CAUSE SERIOUS INJURY OR DEATH.
          </Text>

          <Text>DISCLAIMERS.</Text>
          <Text>
            MENTICE MAKES NO REPRESENTATIONS OR WARRANTIES AND SHALL NOT BE
            RESPONSIBLE FOR THE COMPETENCY OF ANY PERSON WHO MAY RECEIVE
            EDUCATIONAL INFORMATION AND/OR MEDICAL TRAINING PROVIDED THROUGH OR
            BASED ON THE SYSTEM OR FOR THE EXERCISE OF HIS OR HER SKILLS BY SUCH
            PERSON AFTER COMPLETION OF ANY TRAINING, COURSES OR CURRICULUM USING
            THE SYSTEM. SUPPLIER DOES NOT GUARANTEE THAT ANY PERSON RECEIVING
            EDUCATIONAL INFORMATION AND/OR MEDICAL TRAINING FROM THE SYSTEM WILL
            ACHIEVE ANY PARTICULAR SKILL LEVEL OR THE NECESSARY PROFICIENCY TO
            QUALIFY FOR ANY LICENSE, CERTIFICATES OR RATINGS ISSUED BY ANY
            REGULATORY AGENCY OR GOVERNMENT AUTHORITY.
          </Text>
          <Text>
            MENTICE MAKES NO REPRESENTATION OR WARRANTIES THAT THE SYSTEM AND
            THE MEDICAL INFORMATION AND TRAINING PROVIDED ON THE SYSTEM: (A)
            WILL BE CONSTANTLY AVAILABLE, OR AVAILABLE AT ALL; OR (B) IS
            ERROR-FREE, COMPLETE, TRUE, ACCURATE, UP TO DATE AND/OR
            NON-MISLEADING. YOUR USE OF THE SYSTEM IS WITH FULL KNOWLEDGE AND
            AWARENESS THAT YOU WAIVE ANY CLAIM YOU MAY HAVE AGAINST MENTICE FOR
            RELIANCE ON ANY INFORMATION OR TRAINING PRESENTED THROUGH THE
            SYSTEM.
          </Text>

          <Text>LIMITATIONS ON LIABILITY.</Text>
          <Text>
            IN NO EVENT WILL MENTICE BE LIABLE FOR ANY PERSONAL INJURY LOSS OF
            USE, LOSS OF PROFIT, INTERRUPTION OF BUSINESS, OR ANY INDIRECT,
            SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND
            (INCLUDING LOST PROFITS), REGARDLESS OF THE FORM OF ACTION, WHETHER
            IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT PRODUCT LIABILITY,
            OR OTHERWISE, EVEN IF MENTICE HAS BEEN ADVISED OR SHOULD HAVE BEEN
            AWARE OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL MENTICE’S
            LIABILITY TO YOU FOR ANY CLAIM, WHETHER IN CONTRACT, TORT OR ANY
            OTHER THEORY OF LIABILITY, EXCEED THE PURCHASE PRICE OF THE SYSTEM
            PAID BY YOU.
          </Text>
          <Text>
            IT IS FURTHER UNDERSTOOD THAT MENTICE SHALL ALSO HAVE NO LIABILITY
            WHATSOEVER CONCERNING DAMAGES ARISING OUT OF OR RELATING TO
            UNAUTHORISED ACCESS, USE AND/OR ALTERATION OR DESTRUCTION, BY ANY
            THIRD PARTY, INCLUDING ANY USERS, OF THE SYSTEM OR ANY DATA
            CONTAINED THEREON OR CREATED BY THE SYSTEM OR YOUR COMPUTER SYSTEM.
          </Text>
        </Card>
      </Section>
    </PageLayout>
  );
}

MedicalDisclaimerPage.getInitialProps = () => {
  return { public: true };
};

export default MedicalDisclaimerPage;
