import React from "react";
import styled from "styled-components";
import { AvatarStyle } from "./UserAvatar";

interface HospitalIconProps {
  color?: string;
  small?: boolean;
}

export const HospitalAvatar = styled.div`
  ${AvatarStyle}
`;

const Hospital: React.FC<HospitalIconProps> = ({
  color = "#FF9752",
  small = false,
}) =>
  small ? (
    <svg
      width="24px"
      height="23px"
      viewBox="0 0 24 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group@1x</title>
      <g
        id="Components"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <g
          id="Icons"
          transform="translate(-436.000000, -991.000000)"
          stroke={color}
          strokeWidth="1.5"
        >
          <g id="Hospital-Icon" transform="translate(432.000000, 986.000000)">
            <g id="Path" transform="translate(5.000000, 6.000000)">
              <polyline points="4 21 0 21 0 11 4 11"></polyline>
              <line x1="9" y1="16" x2="9" y2="12"></line>
              <polyline points="12 4 12 0 22 3 22 21 4 21 4 7 14 7 14 21"></polyline>
              <line x1="18" y1="17" x2="18" y2="7"></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 56 56"
    >
      <g fill="none" fillRule="evenodd" strokeLinecap="square">
        <g stroke={color} strokeWidth="2">
          <g>
            <g>
              <path
                d="M22 26L22 32M25 29L19 29"
                transform="translate(-276.000000, -1245.000000) translate(244.000000, 1223.000000) translate(32.000000, 22.000000)"
              />
              <g>
                <path
                  d="M9 50L0 50 0 26 3.375 26M23.471 10.714L23.471 0 50 7.143 50 50 9 50 9 16 29 16 29 50M35.5 41L35.5 15M43.5 40L43.5 16"
                  transform="translate(-276.000000, -1245.000000) translate(244.000000, 1223.000000) translate(32.000000, 22.000000) translate(3.000000, 3.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
export default Hospital;
