import React, { useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { PageLayout } from "../../components/Page/PageLayout";
import SidebarCard from "../../components/shared/sidebar-card/SidebarCard";
import { SectionHeader } from "../../components/Page/SectionHeader";
import { useKeycloak } from "@react-keycloak/web";
import { haveRole, KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import LearningSpaces from "./LearningSpaces";
import Customers from "./Customers";
import { StyledLink } from "../../components/shared/typography";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import Logbook from "./Logbook";
import Attendings from "./Attendings";
import Hospitals from "./Hospitals";
import Systems from "./Systems";
import SyncTalent from "./SyncTalent";
import SidebarListItem from "../../components/shared/sidebar-card/SidebarListItem";
import Groups from "./Groups";
import { gql, QueryResult, useQuery } from "@apollo/client";
import { Customer } from "../../generated/graphql";
import irLogEnabledForCustomers from "../../shared/irLogEnabledForCustomers.json";
import CourseListings from "./CourseListings";
import CloudCaseResourceAccessVouchers from "./CloudCaseResourceAccessVouchers";
import CloudCaseMachines from "./CloudCaseMachines";
import Events from "./Events";

export interface RouteObj {
  path: string;
  exact: boolean;
  name: string;
  main: React.JSXElementConstructor<unknown>;
}

const Administration = () => {
  const location = useLocation();
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const isGroupAdmin = haveRole(user, "group_admin");
  const isCustomerAdmin = haveRole(user, "customer_admin");
  const isSysAdmin = haveRole(user, "sys_admin");
  const match = useRouteMatch();

  const {
    data: customerData,
  }: QueryResult<{
    customers: Customer[];
  }> = useQuery(
    gql`
      query FetchCustomers {
        customers {
          name
        }
      }
    `
  );

  const routes: RouteObj[] = [];
  if (isCustomerAdmin || isSysAdmin) {
    routes.push({
      path: `${match.path}/accounts`,
      exact: true,
      name: "Accounts",
      main: () => <Customers />,
    });
  }
  if (isSysAdmin) {
    routes.push({
      path: `${match.path}/learning-spaces`,
      exact: true,
      name: "Learning Spaces",
      main: () => <LearningSpaces />,
    });
    routes.push({
      path: `${match.path}/cloud-case-machines`,
      exact: true,
      name: "Cloud Case VMs",
      main: () => <CloudCaseMachines />,
    });
  }
  if (isGroupAdmin || isCustomerAdmin || isSysAdmin) {
    routes.push({
      path: `${match.path}/groups`,
      exact: true,
      name: "Groups",
      main: () => <Groups />,
    });
  }
  // TODO this should probably be switched for IR Log feature flag
  const shouldShowLogbook = customerData?.customers.some((customer) =>
    irLogEnabledForCustomers.names.includes(customer.name)
  );
  const shouldShowCloudCases = customerData?.customers.some(
    (customer) =>
      customer.name === "Db-seed customer" || customer.name === "Medtronic"
  );
  if ((shouldShowLogbook && isCustomerAdmin) || isSysAdmin) {
    routes.push(
      {
        path: `${match.path}/logbook`,
        exact: true,
        name: "Logbook",
        main: () => <Logbook />,
      },
      {
        path: `${match.path}/logbook/attendings/:id`,
        exact: true,
        name: "Attendings",
        main: () => <Attendings />,
      },
      {
        path: `${match.path}/logbook/hospitals/:id`,
        exact: true,
        name: "Hospitals",
        main: () => <Hospitals />,
      }
    );
  }
  if (shouldShowCloudCases && !isSysAdmin) {
    routes.push({
      path: `${match.path}/cloud-case-vouchers`,
      exact: true,
      name: "Cloud Cases",
      main: () => <CloudCaseResourceAccessVouchers />,
    });
  }
  // Is group admin of customer group (or member, but members cannot access admin panel) or customer admin
  if (shouldShowCloudCases && !isSysAdmin) {
    routes.push({
      path: `${match.path}/events`,
      exact: true,
      name: "Events",
      main: () => <Events />,
    });
  }
  if (isSysAdmin) {
    routes.push(
      {
        path: `${match.path}/courses`,
        exact: true,
        name: "Courses",
        main: () => <CourseListings />,
      },
      {
        path: `${match.path}/cloud-case-vouchers`,
        exact: true,
        name: "Cloud Cases",
        main: () => <CloudCaseResourceAccessVouchers />,
      },
      {
        path: `${match.path}/events`,
        exact: true,
        name: "Events",
        main: () => <Events />,
      },
      {
        path: `${match.path}/talent`,
        exact: true,
        name: "Talent",
        main: () => <SyncTalent />,
      },
      {
        path: `${match.path}/systems`,
        exact: true,
        name: "Systems",
        main: () => <Systems />,
      }
    );
  }

  const [breadcrumb, setBreadcrumb] = useState({
    path: routes[0]?.path ?? "",
    name: routes[0]?.name ?? "",
  });

  const sidebarList = routes
    .filter(
      // Hide attendings and hospitals routes from sidebar — they're loaded as "submenus" from Logbook
      (route) => route.name !== "Attendings" && route.name !== "Hospitals"
    )
    .map((route) => {
      return (
        <SidebarListItem
          active={location.pathname === route.path}
          key={route.name}
        >
          <StyledLink
            id={`sidebar-${route.name.replace(" ", "-").toLowerCase()}`}
            to={route.path}
            onClick={() =>
              setBreadcrumb({ path: route.path, name: route.name })
            }
          >
            {route.name}
          </StyledLink>
        </SidebarListItem>
      );
    });

  if (!(isGroupAdmin || isCustomerAdmin || isSysAdmin)) {
    return <h1>You do not have the necessary rights to view this page.</h1>;
  } else {
    return (
      <PageLayout title="Mentice Live | Administration">
        <Breadcrumbs
          crumbs={[
            {
              path: `${match.path}`,
              name: "Administration",
            },
            {
              path: `${breadcrumb.path}`,
              name: breadcrumb.name,
            },
            ...(location.pathname.includes("attendings")
              ? [
                  {
                    path: `${match.path}/attendings/:id`,
                    name: "Attendings",
                  },
                ]
              : []),
            ...(location.pathname.includes("hospitals")
              ? [
                  {
                    path: `${match.path}/hospitals/:id`,
                    name: "Hospitals",
                  },
                ]
              : []),
          ]}
        />
        <SectionHeader title="Administration">
          <SidebarCard sidebarList={sidebarList}>
            {(isGroupAdmin || isCustomerAdmin || isSysAdmin) && (
              <Switch>
                <Redirect exact from={match.path} to={routes[0].path} />
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    children={<route.main />}
                  />
                ))}
              </Switch>
            )}
          </SidebarCard>
        </SectionHeader>
      </PageLayout>
    );
  }
};

export default Administration;
