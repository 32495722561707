import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const h1Style = css`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 64px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.7px;
  color: ${(props) => props.theme.colors.grey};
  margin: 0;
`;

export const H1 = styled.h1`
  ${h1Style}
`;

export const h2Style = css`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 24px;
  letter-spacing: 0.6px;
  font-weight: 700;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0;
`;

export const H2 = styled.h2`
  ${h2Style}
`;

export const h3Style = css`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.darkGrey};
  letter-spacing: 0.4px;
  margin: 0;
`;

export const H3 = styled.h3`
  ${h3Style}
`;

export const h4Style = css`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.darkGrey};
  letter-spacing: 0.2px;
  margin: 0;
`;

export const H4 = styled.h4`
  ${h4Style}
`;

export const H4NoWrap = styled.h4`
  ${h4Style}
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const h5Style = css`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.grey};
  letter-spacing: 0.2px;
  line-height: 1.5;
  margin: 0;
`;

export const H5 = styled.h5`
  ${h5Style}
`;

export const h6Style = css`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.darkGrey};
  letter-spacing: 0.2px;
  margin: 0;
`;

export const H6 = styled.h6`
  ${h6Style}
`;

export const largeTextStyle = css`
  font-family: ${(props) => props.theme.fonts.heading};
  color: ${(props) => props.theme.colors.darkDarkGrey};
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.2px;
  margin: 0;
`;

export const LargeText = styled.h6`
  ${largeTextStyle}
`;

export const LargeTextNoWrap = styled(LargeText)`
  white-space: nowrap;
`;

export const textStyle = css`
  font-family: ${(props) => props.theme.fonts.heading};
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  margin: 8px 0;
`;

export const Text = styled.p`
  ${textStyle}
  font-weight: normal;
`;

export const TextNoWrap = styled(Text)`
  white-space: nowrap;
`;

export const semiBoldTextStyle = css`
  ${textStyle}
  font-weight: 600;
`;

export const SemiBoldText = styled.p`
  ${semiBoldTextStyle}
`;

export const SemiBoldTextNoWrap = styled.p`
  ${semiBoldTextStyle}
  height: 20px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const boldTextStyle = css`
  ${textStyle}
  font-weight: 700;
`;

export const BoldText = styled.p`
  ${boldTextStyle}
`;

export const BoldTextNoWrap = styled.p`
  ${boldTextStyle}
  height: 20px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const spanStyle = css`
  font-family: ${(props) => props.theme.fonts.heading};
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
`;

export const Span = styled.span`
  ${spanStyle}
`;

export const SpanNoWrap = styled.span`
  ${spanStyle}
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const button1Style = css`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.7px;
`;

export const Button1 = styled.span`
  ${button1Style}
`;

export const button2Style = css`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.4px;
`;

export const Button2 = styled.span`
  ${button2Style}
`;

export const button3Style = css`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.7px;
`;

export const Button3 = styled.span`
  ${button3Style}
`;

export const newsDateHeaderStyle = css`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  opacity: 0.75;
  color: ${(props) => props.theme.colors.grey};
  margin: 0;
`;

export const NewsDateHeader = styled.h4`
  ${newsDateHeaderStyle}
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const HR = styled.hr`
  margin: 20px 0;
  border: 0;
  border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
  height: 0;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const activeLinkStyle = css<{ active: boolean }>`
  padding: 4px 2px 3px;
  display: inline-block;
  border-bottom: 2px
    ${(props) => (props.active ? props.theme.colors.primary500 : "transparent")}
    solid;
  color: ${(props) => props.theme.colors.darkGrey};
`;

export const TextButton = styled.button`
  ${button2Style}
  border: 0;
  padding: 0;
  background: 0;
  color: ${(props) => props.theme.colors.primary500};
  white-space: nowrap;
  cursor: pointer;
`;

export const PrimaryBoldSpan = styled.span`
  ${button2Style}
  color: ${(props) => props.theme.colors.primary500};
  text-transform: none;
  font-size: 14px;
`;

// Deprecated (Same as LargeText), will be replaced
export const DeprecatedH6 = styled.h6`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  color: ${(props) => props.theme.colors.grey};
  opacity: 0.75;
`;

// Deprecated, will be replaced
export const DeprecatedLi = styled.li`
  font-family: ${(props) => props.theme.fonts.heading};
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
`;
