import { QWebChannel } from "qwebchannel";
import { useEffect, useState } from "react";
import { VistCourse, Simulation } from "../generated/graphql";
import url from "url";
import { useKeycloak } from "@react-keycloak/web";
import zlib from "zlib";
import { promisify } from "util";

interface QtWindow {
  qt?: QtBrowser;
}

interface QtBrowser {
  webChannelTransport: any;
}

interface QtChannel {
  objects: {
    simulationProcess: QtSimulationProcess;
  };
}

interface QtSimulationProcess {
  startJson(config: SimulatorConfig): void;
  stop(): void;

  stateChanged: QtSignal<(state: string) => void>;
  doneJson: QtSignal<(result: SimulatorResult) => void>;
  failed: QtSignal<(error: string) => void>;
  lrsUploadDone: QtSignal<(message: string, ok: boolean) => void>;
}

interface QtSignal<F> {
  connect(callback: F): void;
}

const connectQt = (): Promise<QtSimulationProcess> => {
  return new Promise((resolve, reject) => {
    const qt = (window as QtWindow).qt || (window.parent as QtWindow).qt;
    if (!qt) return reject("Not connected to Qt");

    new QWebChannel(qt.webChannelTransport, (channel: QtChannel) => {
      const simulationProcess = channel.objects.simulationProcess;
      resolve(simulationProcess);
    });
  });
};

interface SimulatorConfig {
  packageId: string;
  packageVersion: string;
  resultFormatVersion: string;
  exerciseId: string;
  inputData: { simulator_inputs: Array<any> };
  uploadUrl: string;
  resultUrl: string;
  compressResults: boolean;
  uploadAll: boolean;

  courseName?: string;
  userName?: string;
  locale?: string;
}

export interface SimulationResultImage {
  src: string;
  type: string;
  description: string;
  time: string;
  imageData: string;
  xmlDescription: string;
}

export interface SimulatorResult {
  files: { [key: string]: string };
  errors: string[];
  images: SimulationResultImage[];
  resultFileListXml: string;
}

const startJson = (
  simulationProcess: QtSimulationProcess,
  config: SimulatorConfig
): Promise<SimulatorResult> => {
  return new Promise<SimulatorResult>((resolve, reject) => {
    simulationProcess.failed.connect(error => reject(error));
    simulationProcess.doneJson.connect(result => {
      resolve(result);
    });
    simulationProcess.startJson(config);
  });
};

const storeResult = async (
  result: SimulatorResult,
  keycloak: Keycloak.KeycloakInstance,
  simulation: Simulation
) => {
  const form = new FormData();

  const deflate = promisify<string, Buffer>(zlib.deflate);
  const ResultFileList = await deflate(result.resultFileListXml);
  form.append("ResultFileList.xml", new Blob([ResultFileList.buffer]));
  for (const [name, file] of Object.entries(result.files)) {
    const data = await deflate(file);
    form.append(name, new Blob([data.buffer]));
  }
  for (const image of result.images) {
    const data = await deflate(image.imageData);
    form.append(image.src, new Blob([data.buffer]));
  }

  const options = {
    method: "POST",
    body: form,
    headers: {
      Authorization: "Bearer " + keycloak.token || "" // TODO: Refactor to useGraphQL/Apollo
    }
  };

  try {
    await fetch(
      `${process.env.NEXT_PUBLIC_API_SERVER}/api/simulator/store_result/${simulation.id}`,
      options
    );
  } catch (error) {
    console.error(error);
  }
};

const newSimulatorLauncher = (
  keycloak: Keycloak.KeycloakInstance,
  simulationProcess?: QtSimulationProcess
): SimulatorLauncher => {
  if (!simulationProcess)
    return () => Promise.reject(new Error("Simulator not connected."));

  return async (
    course: VistCourse,
    simulation: Simulation
  ): Promise<SimulatorResult> => {
    const uploadUrl = url.resolve(
      window.location.href,
      "/api/simulator/upload_install"
    );

    const inputs = !simulation.options
      ? []
      : JSON.parse(simulation.options).simulator_options.map((option: any) => {
          return { name: option.name, value: option.default };
        });

    const config: SimulatorConfig = {
      packageId: course.code,
      packageVersion: course.version,
      resultFormatVersion: course.resultFormatVersion,
      inputData: { simulator_inputs: inputs },
      exerciseId: simulation.code,
      uploadUrl: uploadUrl,
      resultUrl: "",
      compressResults: true,
      uploadAll: false
    };

    console.log("Starting simulation", config);
    const result = await startJson(simulationProcess, config);

    await storeResult(result, keycloak, simulation);

    return result;
  };
};

export interface SimulatorLauncher {
  (course: VistCourse, simulation: Simulation): Promise<SimulatorResult>;
}

export const useSimulator = (): [boolean, SimulatorLauncher] => {
  const [simulationProcess, setSimulationProcess] = useState<
    QtSimulationProcess | undefined
  >(undefined);

  // Connect to VIST
  useEffect(() => {
    connectQt()
      .then(simulationProcess => setSimulationProcess(simulationProcess))
      .catch(error => console.warn(error));
  }, []);

  const { keycloak } = useKeycloak();

  return [
    !!simulationProcess,
    newSimulatorLauncher(keycloak, simulationProcess)
  ];
};
