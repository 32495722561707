import React from "react";
import styled from "styled-components";
import {
  Tr,
  ThPrimary,
  ThSecondary,
  ThTertiary,
  HeaderTitle,
} from "./RunningVistCardComponents";
import { Span } from "../../shared/typography";
import ArrowRight from "../../icons/ArrowRight";
import { theme } from "../../../lib/theme";

const CollapseButton = styled.button<{ isExpanded: boolean }>`
  background: none;
  background-position: center center;
  transform: rotate(${(props) => (props.isExpanded ? "90deg" : "0deg")});
  transition: transform ${(props) => props.theme.transitions.outSlow};
  border: none;
  width: 32px;
  height: 32px;
  margin: 0 4px;
  position: relative;
  top: 10px;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

interface MetricGroupHeaderProps {
  depth: number;
  close: () => void;
  isExpanded: boolean;
  label: string;
  description: string;
  passedMetrics?: number;
  totalMetrics?: number;
}

const MetricGroupHeader: React.FC<MetricGroupHeaderProps> = ({
  depth,
  close,
  isExpanded,
  label,
  description,
  passedMetrics,
  totalMetrics,
}) => (
  <Tr>
    <ThPrimary depth={depth}>
      <CollapseButton onClick={close} isExpanded={isExpanded}>
        <ArrowRight size="medium" color={theme.colors.darkGrey} />
      </CollapseButton>
      <HeaderTitle>{label}</HeaderTitle> <Span>({description})</Span>
    </ThPrimary>
    <ThSecondary />
    <ThTertiary style={{ textAlign: "right" }}>
      <HeaderTitle>
        {/* Is undefined on the start screen - in that case, don't render */}
        {passedMetrics !== undefined &&
          totalMetrics !== undefined &&
          `${passedMetrics} / ${totalMetrics}`}
      </HeaderTitle>
    </ThTertiary>
    <ThSecondary />
    <ThTertiary />
  </Tr>
);

export default MetricGroupHeader;
