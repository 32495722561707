import { gql } from "@apollo/client";

export const cloudModulesResourceAccessVouchersFields = gql`
  fragment CloudModulesResourceAccessVouchersFields on CloudModulesResourceAccessVoucher {
    id
    type
    code
    validFrom
    validTo
    customer {
      id
      name
    }
    learningSpace {
      id
      code
      name
      description
      image
    }
    creator {
      keycloak_id
      first_name
      last_name
      email
    }
    createdAt
    modifiedAt
    archivedAt
    archivedByUserId
    used
    maxUses
    users {
      keycloak_id
      first_name
      last_name
      email
    }
    modules {
      moduleId
      name
      description
      cases {
        caseId
        name
        description
      }
    }
    maxDurationInSeconds
    userQuota {
      user {
        keycloak_id
        first_name
        last_name
        email
      }
      durationLeft
    }
  }
`;
