import { FormikErrors } from "formik";
import React, { useEffect, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { ErrorMessage, LabelText } from "./TextField";
import {
  getDatePickerLocalizedTimePickerFormat,
  getDatePickerLocalizedDatePickerFieldFormat,
} from "../../lib/formatDateAndTime";
interface DateFieldProps {
  error?: string | boolean;
  theme: any;
  img: string;
  label?: string;
  range?: boolean;
  excludeDateIntervals?: Array<{ start: Date; end: Date }> | undefined;
}

const DateField = styled(DatePicker)`
  height: 40px;
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.error500 : props.theme.colors.darkGrey};
  width: ${(props) => (props.range ? props.theme.sizes.spacing19 : "100%")};
  margin-top: ${(props) => (props.label ? (props.range ? "2px" : "12px") : 0)};
  color: ${(props) => props.theme.colors.darkDarkGrey};
  display: flex;
  justify-content: space-between;
  background: url(${(props: DateFieldProps) => props.img}) no-repeat scroll;
  background-color: ${(props) => props.theme.colors.white};
  background-position: calc(100% - 8px);

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.primary500};
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.colors.grey};
    border-color: ${(props) => props.theme.colors.midGrey};
  }
  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointS}) {
    width: 100%;
  }
`;

const DatePickerWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 28px;
  }
  .date_picker.full-width {
    width: 100%;
  }
  .react-datepicker {
    border-color: ${(props) => props.theme.colors.paleGrey};
    box-shadow: ${(props) => props.theme.shadows.cardShadow};
  }
  .react-datepicker__header {
    background-color: white;
    font-family: ${(props) => props.theme.fonts.heading};
    border-bottom: none;
    padding: 29px 29px 0;
  }
  .react-datepicker__day-name {
    color: ${(props) => props.theme.colors.grey};
  }
  .react-datepicker__current-month {
    color: ${(props) => props.theme.colors.primary300} !important;
    margin-bottom: 12px;
  }
  .react-datepicker__month {
    padding: 8px 29px 29px;
    font-family: ${(props) => props.theme.fonts.heading};
    margin: 0;
  }
  .react-datepicker__day--outside-month {
    color: ${(props) => props.theme.colors.grey} !important;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day {
    border-radius: 50%;
    color: ${(props) => props.theme.colors.darkDarkGrey};
  }
  .react-datepicker__day--selected {
    background-color: ${(props) => props.theme.colors.primary500} !important;
    color: white;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: ${(props) => props.theme.colors.disabledButtonColor};
    color: ${(props) => props.theme.colors.darkDarkGrey};
  }
  .react-datepicker__navigation {
    margin: 16px;
  }
  .react-datepicker__navigation--next {
    background: url("/static/assets/arrow-next-month.svg") no-repeat;
    width: 24px;
    height: 24px;
    border: none;
  }
  .react-datepicker__navigation--previous {
    background: url("/static/assets/arrow-prev-month.svg") no-repeat;
    width: 24px;
    height: 24px;
    border: none;
  }
  .react-datepicker__day--disabled {
    color: ${(props) => props.theme.colors.lightGrey} !important;
  }
`;

const DateFieldLabel = styled.label`
  white-space: nowrap;
`;

const CustomInput = React.forwardRef(
  (
    props: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return <input {...props}></input>;
  }
);

interface DatePickerProps {
  name?: string;
  value?: Date;
  onChange: any;
  label?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  onBlur?: () => void;
  selected?: Date;
  formikValue?: boolean;
  inline?: boolean;
  id?: string;
  range?: boolean;
  excludeDateIntervals?: Array<{ start: Date; end: Date }> | undefined;
  showTimeSelect?: boolean;
}

const DatePickerField: React.FC<DatePickerProps> = ({
  name,
  value,
  onChange,
  label,
  error,
  onBlur,
  selected,
  formikValue = false,
  inline,
  range = false,
  id = undefined,
  excludeDateIntervals = undefined,
  showTimeSelect = false,
}) => {
  const datepickerRef = useRef<ReactDatePicker>(null);
  const closeDatepicker = () => datepickerRef?.current?.setOpen(false);

  // Allows switching between input fields (instead of within calendar) using the tab button
  const onKeyDown = (e: { keyCode: number; which: number }) => {
    if (e.keyCode === 9 || e.which === 9) {
      closeDatepicker();
    }
  };

  // Prevents overflowing calendar to cause unwanted behavior on scroll by closing it
  useEffect(() => {
    if (!showTimeSelect) {
      document.addEventListener("wheel", closeDatepicker);
      return () => {
        document.removeEventListener("wheel", closeDatepicker);
      };
    }
  }, [showTimeSelect]);

  return (
    <DatePickerWrapper>
      <DateFieldLabel>
        <LabelText disabled={false}>{label}</LabelText>
      </DateFieldLabel>
      <DateField
        id={id}
        ref={datepickerRef}
        onKeyDown={onKeyDown}
        label={label}
        onBlur={onBlur}
        error={Boolean(error)}
        dateFormat={getDatePickerLocalizedDatePickerFieldFormat(showTimeSelect)}
        img={"/static/assets/calendar.svg"}
        wrapperClassName="date_picker full-width"
        popperClassName="date_picker position"
        selected={selected ? selected : value || undefined}
        inline={inline}
        customInput={<CustomInput />}
        onChange={(val: Date) => {
          formikValue ? onChange(name, val) : onChange(val);
        }}
        range={range}
        excludeDateIntervals={excludeDateIntervals}
        showTimeSelect={showTimeSelect}
        timeFormat={getDatePickerLocalizedTimePickerFormat()}
      />
      <ErrorMessage>{error}</ErrorMessage>
    </DatePickerWrapper>
  );
};

export default DatePickerField;
