import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { Section } from "../../components/Page/Section";
import Button from "../../components/shared/Button";
import Dialog from "../../components/shared/Dialog";
import TextField from "../../components/shared/TextField";
import { H3, Text } from "../../components/shared/typography";
import { TalentBranch } from "../../components/TalentBranches/TalentBranch";
import { TalentBranch as TalentBranchEntity } from "../../types/TalentBranch";
import {
  convertBase64ToImage,
  convertImageToBase64,
  getDefaultLogo,
} from "../../lib/convertBase64";
import FileInput from "./FileInput";
import LoadingIndicator, {
  Center,
  renderLoadingText,
} from "../../components/shared/LoadingIndicator";

const LearningSpaces = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [currentLearningSpace, setCurrentLearningSpace] =
    useState<TalentBranchEntity>();
  const [inputValues, setInputValues] = useState({
    name: "",
    description: "",
    code: "",
    image: "",
  });

  const {
    loading: learningSpacesLoading,
    data: learningSpacesData,
    error: learningSpacesErrors,
  } = useQuery(
    gql`
      query TalentBranches {
        talentBranches {
          id
          code
          name
          description
          image
        }
      }
    `
  );

  const [updateLearningSpace, { loading: loadingSaveLearningSpace }] =
    useMutation(
      gql`
        mutation SaveLearningSpace(
          $id: String!
          $displayName: String!
          $image: String!
        ) {
          saveLearningSpace(id: $id, displayName: $displayName, image: $image) {
            refetch {
              talentBranches {
                id
                code
                name
                description
                image
              }
            }
          }
        }
      `,
      {
        variables: {
          id: currentLearningSpace?.id.toString(),
          displayName: inputValues.name,
          image: inputValues?.image,
        },
      }
    );

  const handleOnEdit =
    (event: React.MouseEvent<HTMLImageElement, MouseEvent>) =>
    (branch: TalentBranchEntity) => {
      event.preventDefault();
      setCurrentLearningSpace(branch);
      setInputValues({ ...inputValues, ...branch });
      setShowDialog(true);
    };

  const handleOnClose = () => {
    setCurrentLearningSpace(undefined);
    setShowDialog(false);
  };

  const handleOnSave = async () => {
    await updateLearningSpace();
    setShowDialog(false);
    setCurrentLearningSpace(undefined);
  };

  const handleFileRead = async (
    event: React.FormEvent<any>
  ): Promise<string | ArrayBuffer | null> => {
    const file = event.currentTarget.files[0];
    if (file?.size > 500000) {
      alert("File size cannot be larger than 0.5 MB");
      return null;
    } else {
      const base64 = await convertImageToBase64(file);
      return base64;
    }
  };

  const handleChange =
    (name: string) => async (event: React.FormEvent<any>) => {
      if (name === "image") {
        const base64 = await handleFileRead(event);
        if (typeof base64 === "string") {
          setInputValues({ ...inputValues, image: base64 });
        }
      } else {
        const value = event.currentTarget.value;
        setInputValues({
          ...inputValues,
          [name]: value,
        });
      }
    };

  const handleResetImage = () => {
    if (currentLearningSpace?.image) {
      setInputValues({ ...inputValues, image: currentLearningSpace?.image });
    }
  };

  const shouldDisplayTalentBranches =
    learningSpacesData && !learningSpacesErrors;
  return (
    <>
      <H3>Learning Spaces</H3>
      <Section title="" maxColumns={1}>
        {shouldDisplayTalentBranches &&
          learningSpacesData.talentBranches &&
          learningSpacesData.talentBranches.length > 0 &&
          learningSpacesData.talentBranches.map(
            (branch: TalentBranchEntity) => (
              <TalentBranch
                key={branch.id}
                talentBranchId={branch.id}
                talentBranchName={branch.code}
                code={branch.code}
                title={branch.name}
                description={branch.description}
                image={branch.image}
                onEditClick={(e) => handleOnEdit(e)(branch)}
              />
            )
          )}
        {shouldDisplayTalentBranches &&
          learningSpacesData.talentBranches.length === 0 && (
            <Center>
              <Text>No learning spaces available</Text>
            </Center>
          )}
        {learningSpacesErrors &&
          learningSpacesErrors.graphQLErrors.map(
            (error: { message: string }) => (
              <Center>
                <Text key={error.message}>{error.message}</Text>
              </Center>
            )
          )}
        {learningSpacesLoading && <LoadingIndicator />}
        {showDialog && (
          <Dialog
            title="Edit Learning Space"
            onClose={handleOnClose}
            buttons={
              <>
                <Button secondary onClick={handleOnClose}>
                  Cancel
                </Button>
                <Button
                  onClick={handleOnSave}
                  disabled={loadingSaveLearningSpace}
                >
                  {renderLoadingText(loadingSaveLearningSpace, "Save changes")}
                </Button>
              </>
            }
          >
            <FileInput
              handleChange={handleChange}
              handleResetImage={handleResetImage}
              src={
                inputValues?.image
                  ? convertBase64ToImage(inputValues?.image)
                  : getDefaultLogo()
              }
            />
            <TextField
              label={"Learning Space Name"}
              value={inputValues.name}
              onChange={handleChange("name")}
              placeholder="Learning Space"
            />
            <TextField
              label={"Description"}
              value={inputValues.description}
              onChange={handleChange("description")}
              disabled
              multiline
            />
            <TextField
              label={"Abbreviation"}
              value={inputValues.code}
              onChange={handleChange("code")}
              disabled
            />
          </Dialog>
        )}
      </Section>
    </>
  );
};

export default LearningSpaces;
