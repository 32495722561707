import React from "react";

const Clock: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g stroke="#575C61">
          <g>
            <g transform="translate(-237.000000, -718.000000) translate(237.000000, 718.000000) translate(2.000000, 2.000000)">
              <circle cx="6" cy="6" r="6" />
              <path d="M5 3L5 7 9 7" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Clock;
