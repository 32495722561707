import React from "react";
import styled from "styled-components";
import Loading from "../icons/Loading";

export const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CenterFullHeight = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingButton = styled.span`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

export const renderLoadingText = (
  loading: boolean,
  notLoadingText: string,
  loadingText: string = "Saving"
) =>
  loading ? (
    <LoadingButton>
      {loadingText}
      <Loading small></Loading>
    </LoadingButton>
  ) : (
    notLoadingText
  );

interface LoadingIndicatorProps {
  fullHeight?: boolean;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  fullHeight = false,
}) =>
  fullHeight ? (
    <CenterFullHeight>
      <Loading />
    </CenterFullHeight>
  ) : (
    <Center>
      <Loading />
    </Center>
  );

export default LoadingIndicator;
